import { GET_UPGRADE_TYPES } from '../actions/types';
import { normalizeUpgradeType } from '../schemas/upgradeType';

const initialState = {
  upgradeTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_UPGRADE_TYPES:
      const normalizedUpgradeType = normalizeUpgradeType(action.payload);
      return { ...state, upgradeTypes: normalizedUpgradeType.entities.upgrade_types };
    default:
      return state;
  }
};
