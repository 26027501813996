import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";
import AppTextInput from "../../Common/formElements/AppTextInput";
import GridContainer from "../../GridContainer";
import AppSelectBox from "../../../../@jumbo/components/Common/formElements/AppSelectBox";
import TextField from "@material-ui/core/TextField";
import { editAdminUserService } from "services/user";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue, purple, red } from "@material-ui/core/colors";
import Update from "@mui/icons-material/Update";
import {
  loginUser,
  companyData,
  officeMemo,
  get_destinations
} from "redux/selector/companyHasOfficeSelector";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { requiredMessage } from "../../../../@jumbo/constants/ErrorMessages";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const MySwal = withReactContent(Swal);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  },
  option: {
    fontSize: 15
  },
  dark: {
    "& .MuiChip-label": {
      color: "white"
    }
  },
  light: {
    "& .MuiChip-label": {
      color: "#000" // black
    }
  }
}));

export default function UpdateOfficeDialog({
  open,
  setOpen,
  userData,
  setUserDat,
  officeData
}) {
  const theme = createTheme({
    palette: {
      primary: blue
    }
  });
  const themeCancel = createTheme({
    palette: {
      primary: red
    }
  });

  const themeMode = localStorage.getItem("theme-type");
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const officesState = useSelector(officeMemo);
  const oldPivot = userData.pivot_office.map(element => officeData[element]);
  /*   const [passwordConfirm, setPasswordConfirm] = useState('');
  const [password, setPassword] = useState(''); */
  const [passControl, setPassControl] = useState("");
  const companies = useSelector(companyData);
  const authUser = useSelector(loginUser);
  const destinationData = useSelector(get_destinations);

  const [choosedOffices, setChoosedOffices] = useState(oldPivot);
  const [choosedCompanies, setChoosedCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [showOffice, setShowOffice] = useState([]);
  const [save, setSave] = useState(false);

  const [userInfo, setUserInfo] = useState({
    fullname: userData.fullname,
    email: userData.email,
    company: userData.company,
    office: userData.office,
    authorityLevel: userData.authority_level
  });

  const [errorData, setErrorData] = useState({
    fullname: "",
    email: "",
    company: "",
    office: "",
    authorityLevel: ""
  });

  const { error_message } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );
  const { success_message } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );

  useEffect(() => {
    if (
      userInfo.fullname &&
      userInfo.authorityLevel &&
      errorData.office == "" &&
      userInfo.email &&
      save &&
      error_message == "" &&
      success_message == "success"
    ) {
      MySwal.fire(
        "Success",
        intl.formatMessage({ id: "user.has.been.updated" }),
        "success"
      );
      setOpen(false);
    } else {
      if (error_message.email) {
        setErrorData({ ...errorData, email: error_message.email[0] });
      }
    }
  }, [error_message, success_message]);

  useEffect(() => {
    let companiesID = [];
    let choosed = [];

    userData.pivot_office.map(element => {
      if (choosed.length == 0) {
        choosed.push(officesState[element].company_id);
      } else if (
        !choosed.find(company => officesState[element].company_id == company)
      ) {
        choosed.push(officesState[element].company_id);
      }
    });

    destinationData.map(element => {
      if (companiesID.length == 0) {
        companiesID.push(element.company_id);
      } else if (!companiesID.find(company => element.company_id == company)) {
        companiesID.push(element.company_id);
      }
    });
    setSelectedCompanies(choosed);
    setChoosedCompanies(companiesID);
  }, [destinationData]);

  useEffect(() => {
    let officeControl = [];
    let officeData = [];
    let selectedID = [];

    if (userInfo.authorityLevel == 10 || userInfo.authorityLevel == 11) {
      setChoosedOffices(destinationData);
    }

    // Get all offices of selected company
    else if (userInfo.authorityLevel == 20) {
      destinationData.map(element => {
        if (selectedCompanies.includes(element.company_id)) {
          officeControl.push(element);
        }
      });
      setChoosedOffices(officeControl);
    } else if (selectedCompanies && userInfo.authorityLevel == 30) {
      if (selectedCompanies.length != 0) {
        destinationData.map(element => {
          if (selectedCompanies.includes(element.company_id)) {
            selectedID.push(element.id);
            officeData.push(element);
          }
        });
        choosedOffices.map(element => {
          if (selectedID.includes(element.id)) {
            officeControl.push(element);
          }
        });
        setChoosedOffices(officeControl);
      }
      officeData.sort(function(a, b) {
        return a.company_id - b.company_id;
      });
    }

    setShowOffice(officeData);
  }, [selectedCompanies, userInfo.company]);

  const updateUserModal = updatedUser => {
    Swal.fire({
      title: intl.formatMessage({ id: "are.you.sure?" }),
      text: intl.formatMessage(
        {
          id: "you.want.to.update.user"
        },
        { name: updatedUser }
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
      confirmButtonText: intl.formatMessage({ id: "yes.update.it." })
    }).then(result => {
      if (result.isConfirmed) {
        onSubmitClick();
      }
    });
  };

  const onSubmitClick = () => {
    let officeID = [];
    let oldOfficeID = [];
    choosedOffices.map(element => officeID.push(element.id));
    oldPivot.map(element => oldOfficeID.push({ id: element.id }));
    const user_id = userData.id;
    const updateUser = {
      user_id,
      ...userInfo,
      officeID,
      oldOfficeID
    };

    setErrorData({
      ...errorData,
      fullname: userInfo.fullname ? "" : requiredMessage,
      authorityLevel: userInfo.authorityLevel ? "" : requiredMessage,
      email: userInfo.email ? "" : requiredMessage,
      office: officeID.length !== 0 ? "" : requiredMessage,
      company: selectedCompanies.length !== 0 ? "" : requiredMessage
    });

    if (
      userInfo.fullname &&
      userInfo.authorityLevel &&
      errorData.office == "" &&
      errorData.company == "" &&
      userInfo.email &&
      choosedOffices
    ) {
      dispatch(editAdminUserService(updateUser));
      setSave(true);
    }
  };
  const onCancelClick = () => {
    setOpen(false);
  };

  const onLabelChange = value => {
    setUserInfo({ ...userInfo, authorityLevel: value });
  };

  const [labels, setLabels] = useState(
    authUser.authority_level === 10
      ? [
          { title: "Enterprise", slug: "10" },
          { title: "Company", slug: "20" },
          { title: "Office", slug: "30" }
        ]
      : authUser.authority_level === 20
      ? [
          { title: "Company", slug: "20" },
          { title: "Office", slug: "30" }
        ]
      : [
          { title: "Company", slug: "11" },
          { title: "Office", slug: "30" }
        ]
  );
  const handleChange = name => event => {
    setUserInfo({ ...userInfo, [name]: event.target.value });
    setErrorData({ ...errorData, [name]: "" });
  };
  return (
    <Dialog
      open={open}
      className={classes.dialogRoot}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth="on"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        Update {userData.fullname} User
      </DialogTitle>
      <DialogContent dividers>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppTextInput
              variant="standard"
              id="standard-basic"
              label={<IntlMessages id="name" />}
              value={userInfo.fullname}
              required
              helperText={errorData.fullname}
              onChange={e => {
                if (e.target.value.trim().length >= 0) {
                  handleChange("fullname")(e);
                } else {
                  setErrorData("fullname");
                }
              }}
            />
          </Grid>
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppTextInput
              disabled={authUser.id != userData.id}
              variant="standard"
              id="standard-basic"
              label={<IntlMessages id="email" />}
              value={userInfo.email}
              onChange={e => {
                handleChange("email")(e);
              }}
              required
              helperText={errorData.email}
            />
          </Grid>
        </GridContainer>

        {userInfo.authorityLevel !== 10 && (
          <GridContainer style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={12}>
              <AppSelectBox
                fullWidth
                data={labels}
                label={<IntlMessages id="authority.level" />}
                valueKey="slug"
                variant="standard"
                id="standard-basic"
                labelKey="title"
                value={userInfo.authorityLevel}
                /* value={
                      region !== '' && normRegionsState.length !== 0
                        ? normRegionsState[normRegionsState.findIndex(i => i.id === region)]
                        : ''
                    } */
                required
                helperText={errorData.authorityLevel}
                onChange={e => onLabelChange(e.target.value)}
              />
            </Grid>
          </GridContainer>
        )}

        {userInfo.authorityLevel != 10 && userInfo.authorityLevel != 11 && (
          <GridContainer style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                limitTags={2}
                options={choosedCompanies}
                getOptionLabel={option => companies[option].name}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {companies[option].name}
                  </li>
                )}
                defaultValue={selectedCompanies}
                onChange={(e, value) => {
                  if (value.length == 0) {
                    setErrorData({ ...errorData, company: requiredMessage });
                    setChoosedOffices(value);
                  } else {
                    setUserInfo({ ...userInfo, company: value });
                    setSelectedCompanies(value);
                    setErrorData({ ...errorData, company: "" });
                  }
                }}
                renderInput={params => (
                  <AppTextInput
                    required
                    helperText={errorData.company}
                    {...params}
                    className={
                      themeMode === "dark" ? classes.dark : classes.light
                    }
                    variant="standard"
                    id="standard-basic"
                    label={<IntlMessages id="select.company" />}
                    placeholder={intl.formatMessage({ id: "select.company" })}
                  />
                )}
              />
            </Grid>
          </GridContainer>
        )}

        {selectedCompanies.length != 0 && userInfo.authorityLevel == 30 && (
          <GridContainer style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                limitTags={2}
                options={showOffice}
                getOptionLabel={option =>
                  option.name +
                  " " +
                  " (" +
                  " " +
                  companies[option.company_id].name +
                  " " +
                  " )"
                }
                groupBy={option => companies[option.company_id].name}
                value={choosedOffices}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name +
                      " " +
                      " (" +
                      " " +
                      companies[option.company_id].name +
                      " " +
                      " )"}
                  </li>
                )}
                onChange={(e, value) => {
                  if (value.length == 0) {
                    setErrorData({ ...errorData, office: requiredMessage });
                  } else {
                    setErrorData({ ...errorData, office: "" });
                  }
                  setChoosedOffices(value);
                }}
                renderInput={params => (
                  <AppTextInput
                    required
                    helperText={errorData.office}
                    {...params}
                    className={
                      themeMode === "dark" ? classes.dark : classes.light
                    }
                    variant="standard"
                    id="standard-basic"
                    label={<IntlMessages id="select.office" />}
                    placeholder={intl.formatMessage({ id: "select.office" })}
                  />
                )}
              />
            </Grid>
          </GridContainer>
        )}

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <ThemeProvider theme={themeCancel}>
            <Button variant="contained" color="primary" onClick={onCancelClick}>
              <IntlMessages id="cancel" />
            </Button>
          </ThemeProvider>
          <Box ml={2}>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Update />}
                onClick={() => updateUserModal(userInfo.fullname)}
              >
                <IntlMessages id="update" />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
