import React, { useEffect, useState, useRef } from "react";
//STYLES
import searchPrice from "./SearchPriceStyle/searchPrice";
//SWAL
import Swal from "sweetalert2";
//MATERIAL UI COMPONENT
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/// INPUT YANINA BUTON EKLENECEK VE AKORDİON TARZINDA YAPILACAK

const ChildAge = React.memo(props => {
  const { setDefinitions, definitions } = props;
  const [childAge, setChildAge] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [showAge, setShowAge] = useState(false);

  let child = definitions.child != null ? definitions.child : 0;

  if (child > 10) {
    child = 10;
  }

  let totalInputTemplate = [];

  const inputVal = useRef([]);
  const buttonVal = useRef([]);
  const modalItem = useRef([]);
  const modalText = useRef([]);

  const eControl = e => {
    let eNumber = e.target.value.split("");

    if (eNumber.includes("e")) {
      Swal.fire(`You Can Enter Only Numbers`);
      e.target.value = 0;
    }
  };

  const acordion = i => {
    modalItem.current[i].style.display = "block";
    modalText.current[i].style.display = "none";
    setShowAge(!showAge);
  };

  const showModalItem = i => {
    console.log("test");
    modalText.current[i].style.display = "none";
    modalItem.current[i].style.display = "block";
  };

  const childAgeTemplate = () => {
    if (trigger == true && child > 0) {
      for (let i = 0; i < child; i++) {
        totalInputTemplate.push(
          <div key={i}>
            <div
              className="child-ages-modal-item-text"
              // key={i}
              ref={el => (modalText.current[i] = el)}
              onClick={() => acordion(i)}
            >
              {" "}
              {i + 1}.Child Age <KeyboardArrowDownIcon />
            </div>
            <div
              // key={i}
              ref={el => (modalItem.current[i] = el)}
              className="child-ages-list-modal"
            >
              <div className="child-ages-list-modal-item">
                <input
                  // key={i}
                  ref={el => (inputVal.current[i] = el)}
                  className="child-ages-list-modal-item-input"
                  placeholder={definitions.childAges[i]}
                  onChange={e => {
                    const x = e.target.value;
                    if (
                      Math.sign(x) == -1 ||
                      x == ";" ||
                      x == "." ||
                      x == "," ||
                      x == "+"
                    ) {
                      Swal.fire(`You Can Enter Only Positive Numbers`);
                      e.target.value = 0;
                    }
                    if (e.target.value > 17) {
                      Swal.fire(`Child ages must be max 17`);
                      e.target.value = 0;
                    }
                    eControl(e);
                  }}
                  type="number"
                />
                <button
                  // key={i}
                  ref={el => (buttonVal.current[i] = el)}
                  onClick={() => handleChildClick(i)}
                  className="child-ages-list-modal-item-buton"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        );
      }
      const result = totalInputTemplate.map(param => {
        return param;
      });

      return result;
    }
  };

  const handleChildClick = i => {
    const payload = inputVal.current[i].value;
    if (payload != "") {
      setChildAge([...childAge, Number(payload)]);
    }
  };
  useEffect(() => {
    setDefinitions({ ...definitions, childAges: childAge });
  }, [childAge]);

  return (
    <>
      <div
        style={{
          height: "4rem",
          marginTop: "1rem",
          width: 12.5 + "rem",
          marginRight: window.innerWidth < 1300 ? 10 + "rem" : ""
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row"
          }}
        >
          <input
            onChange={e => {
              setTrigger(false);
              const x = e.target.value;
              if (
                Math.sign(x) == -1 ||
                x == ";" ||
                x == "." ||
                x == "," ||
                x == "+"
              ) {
                Swal.fire(`You Can Enter Only Numbers`);
                e.target.value = 0;
              }
              if (e.target.value > 10) {
                Swal.fire(`Child number must be max 10`);
                e.target.value = 0;
              }
              eControl(e);

              setDefinitions({
                ...definitions,
                child: Number(x)
              });
            }}
            type="number"
            placeholder="Enter a number"
            style={{
              border: "1px solid rgba(0,0,0,.3)",
              outline: "none",
              height: "2.7rem",
              textAlign: "center",
              borderBottomLeftRadius: "4px",
              borderTopLeftRadius: "4px",
              width: "70%"
            }}
          />
          <button
            style={{
              border: "1px solid grey",
              outline: "none",
              height: "2.7rem",
              backgroundColor: "#33658A",
              color: "white"
            }}
            onClick={() => setTrigger(true)}
          >
            <AddIcon />
          </button>
          <button
            style={{
              border: "1px solid grey",
              outline: "none",
              height: "2.7rem",
              borderBottomRightRadius: "4px",
              borderTopRightRadius: "4px",
              backgroundColor: "#33658A",
              color: "white",
              borderBottomRightRadius: "4px",
              borderTopRightRadius: "4px"
            }}
            onClick={() => setTrigger(false)}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="child-ages-modal-template">{childAgeTemplate()}</div>
      </div>
    </>
  );
});

export default ChildAge;
