import { createSelector } from 'reselect';
export const contract = createSelector(
  state => state.contract.contracts,
  contracts => {
    return contracts;
  },
);
export const contractRoom = createSelector(
  state => state.contract.contract_room,
  contract_room => {
    return contract_room;
  },
);
export const contractRoomPrice = createSelector(
  state => state.contract.contract_room_price,
  contract_room_price => {
    return contract_room_price;
  },
);
export const contractHasBoard = createSelector(
  state => state.contract.contract_has_board,
  contract_has_board => {
    return contract_has_board;
  },
);
export const board_selector = createSelector(
  state => state.contract.board,
  board => {
    return board;
  },
);
export const selected_contract = createSelector(
  state => state.contract.selectedContract,
  selectedContract => {
    return selectedContract;
  },
);
export const contracts = createSelector(
  state => state.contract.contracts,
  contracts => {
    return contracts;
  },
);

export const hotelsSelector = createSelector(
  state => state.hotels.hotels,
  hotels => {
    return hotels;
  },
);

export const restrictionRooms = createSelector(
  restrictions => restrictions,
  restrictions => {
    return restrictions;
  },
);

export const boardsSelector = createSelector(
  state => state.boards.boards,
  boards => {
    return boards;
  },
);
