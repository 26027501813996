import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getAllotmentUpdate, addAllotmentUpdate, updateAllotmentUpdate } from 'redux/actions/allotmentUpdate';
import axios from './axios';

/** PROXOLAB LOGGER * */
import sendLogService from './proxolabLogger/Logger.js';
/** PROXOLAB LOGGER * */

export const getAllotmentUpdateService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('allotmentUpdate')
    .then(res => {
      dispatch(getAllotmentUpdate(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addAllotmentUpdateService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('allotmentUpdate', data)
    .then(res => {
      dispatch(addAllotmentUpdate(res));
      dispatch(fetchSuccess());
      sendLogService.allotmentServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateAllotmentUpdateService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('allotmentUpdate', data)
    .then(res => {
      dispatch(updateAllotmentUpdate(res));
      dispatch(fetchSuccess());
      sendLogService.allotmentServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
