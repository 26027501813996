import { normalize, schema } from 'normalizr';

const oldAddPaxRates = new schema.Entity(
  'addPaxRates',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);

const addPaxRates = new schema.Entity('addPaxRates');
const updatePaxRates = new schema.Entity('addPaxRates', {}, { idAttribute: 'id' });

const response = {
  addPaxRates: [addPaxRates],
  addStateAddPaxRates: [oldAddPaxRates],
  updateStateAddPaxRates: [updatePaxRates],
  deleteStateAddPaxRates: [addPaxRates],
  oldAddPaxRates: [oldAddPaxRates],
};

export const normalizeAddPaxRates = data => normalize(data, [response]);
