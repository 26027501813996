import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import SaveIcon from "@material-ui/icons/Save";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import { Button, FormControl, TextField, Typography } from "@material-ui/core";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";
import { Grid, List, ListItem } from "@material-ui/core";
import { Stack } from "@mui/material";
import { sendMailServices } from "services/sendMail";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "95%",
    height: "100%",
    marginLeft: theme.spacing(5),
    fontSize: "12px!important",
    color: localStorage.getItem("theme-type") === "dark" ? "white" : "black"
  },
  header: {
    width: "40%",
    margin: 5
  },
  richText: {
    width: "70%"
  },
  avatar: {
    backgroundColor: red[500]
  },
  title: {
    fontSize: 15,
    margin: 5
  },
  buttonStyle: {
    float: "right",
    maxWidth: "90%",
    color: localStorage.getItem("theme-type") === "dark" ? "white" : "black"
  }
}));

let Editor = () => <Fragment />;

export default function Email() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [messageString, setMessageString] = useState("");

  let today = moment().format("DD MM YYYY");

  //For save button
  const theme = createTheme({
    palette: {
      primary: green
    }
  });

  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text
    });
  };

  const onEditorStateChange = editorStateData => {
    setEditorState(editorStateData);
  };

  useEffect(() => {
    Editor = require("react-draft-wysiwyg").Editor;
    setEditorState(EditorState.createEmpty());
  }, []);

  const handleChangeEditor = event => {
    setMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setMessageString(event.blocks[0].text);
  };

  const sendMail = () => {
    let content = {
      subject: subject,
      message: message,
      mailAddress: authUser.email,
      userName: authUser.fullname
    };
    if (!subject || !messageString) {
      sweetAlerts("oops", `Subject or message can't be empty!`);
    } else {
      dispatch(sendMailServices(content)).then(res => {
        if (res != 500) {
          Swal.fire({
            icon: "success",
            title: "Your request has reached us successfully",
            showConfirmButton: false,
            timer: 1200
          });
          setSubject("");
          setEditorState(EditorState.createEmpty());
        } else {
          Swal.fire({
            icon: "error",
            title: "Your request has failed",
            showConfirmButton: false,
            timer: 1200
          });
        }
      });
    }
  };
  return (
    <Card className={classes.root}>
      <Typography variant="inherit" color="inherit" className={classes.title}>
        Mundos AI Mail Support
      </Typography>
      <CardHeader
        className={classes.header}
        avatar={<Avatar className={classes.avatar}>Mail</Avatar>}
        title={authUser.fullname}
        subheader={today}
      />
      <CardContent>
        <Stack spacing={5}>
          <TextField
            label="Subject"
            style={{ margin: 8 }}
            variant="outlined"
            value={subject}
            placeholder="Subject"
            onChange={event => setSubject(event.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <Fragment>
            <Editor
              editorStyle={{
                height: "300px",
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "lightgray",
                color:
                  localStorage.getItem("theme-type") === "dark"
                    ? "white"
                    : "black"
              }}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              onChange={handleChangeEditor}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
            />
          </Fragment>

          <Grid xs={12}>
            <FormControl className={classes.buttonStyle}>
              <ThemeProvider theme={theme}>
                <Button
                  onClick={sendMail}
                  variant="contained"
                  size="medium"
                  color="primary"
                  startIcon={<SaveIcon />}
                >
                  SAVE
                </Button>
              </ThemeProvider>
            </FormControl>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
}
