import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getOnSaleContract, addOnSaleContract } from 'redux/actions/onSaleContract';
import axios from './axios';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

export const getOnSaleContractService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());

  async function getOnSale() {
    await axios
      .get('onSaleContract')
      .then(res => {
        dispatch(getOnSaleContract(res));
        dispatch(fetchSuccess());
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  }
  return getOnSale();
};
export const addOnSaleContractService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('onSaleContract', data)
    .then(res => {
      dispatch(addOnSaleContract(res));
      dispatch(fetchSuccess());
      sendLogService.sendContractServiceLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
