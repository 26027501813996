import React, { useState } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import UpdateDialog from "./EnterpriseDialog/EnterpriseUpdate";
import {
  countryMemo,
  regionsMemo,
  divisionMemo,
  sub_cityMemo,
  cityMemo,
  enterpriseCompany
} from "redux/selector/companyHasOfficeSelector";
import { useSelector } from "react-redux";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Loader from "../Common/Loader";

export default function Enterprise({
  countryCache,
  cityCache,
  divisionCache,
  divisionCityCache,
  subCityCache,
  setCountryCache,
  setCityCache,
  setDivisionCache,
  setDivisionCityCache,
  setSubCityCache
}) {
  const [updateData, setUpdateData] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const enterprise = useSelector(enterpriseCompany);
  const countryState = useSelector(countryMemo);
  const regionsState = useSelector(regionsMemo);
  const divisionState = useSelector(divisionMemo);
  const subCityState = useSelector(sub_cityMemo);
  const cityState = useSelector(cityMemo);

  /*   const [oldRegionID, setOldRegionID] = useState();
  const [oldCountryID, setOldCountryID] = useState();
  const [oldCityID, setOldCityID] = useState(); */

  return (
    <div>
      {openUpdateDialog ? (
        <UpdateDialog
          open={openUpdateDialog}
          setOpen={setOpenUpdateDialog}
          updateData={updateData}
          countryCache={countryCache}
          cityCache={cityCache}
          divisionCache={divisionCache}
          divisionCityCache={divisionCityCache}
          subCityCache={subCityCache}
          setCountryCache={setCountryCache}
          setCityCache={setCityCache}
          setDivisionCache={setDivisionCache}
          setDivisionCityCache={setDivisionCityCache}
          setSubCityCache={setSubCityCache}
          /* setOldRegionID={setOldRegionID}
          oldRegionID={oldRegionID}
          setOldCountryID={setOldCountryID}
          oldCountryID={oldCountryID}
          setOldCityID={setOldCityID}
          oldCityID={oldCityID} */
        />
      ) : (
        <div />
      )}

      <MaterialTable
        options={{
          showTitle: false,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          // actionsColumnIndex: -1,
          search: false,
          filtering: false,
          paging: false,
          addRowPosition: "first"
        }}
        components={{
          OverlayLoading: () => <Loader />,
          Header: props => (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <IntlMessages id="enterprise.name" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="enterprise.name" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="tax.office" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="tax.no" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="email" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="phone" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="region" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="country" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="city" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="sub.city" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="district" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="actions" />
                  </TableCell>
                </TableRow>
              </TableHead>
            </>
          ),
          Body: props => (
            <>
              <TableBody>
                {enterprise?.map(element => {
                  return (
                    <TableRow>
                      <TableCell>{element.name}</TableCell>
                      <TableCell>{element.official_title}</TableCell>
                      <TableCell>{element.tax_office}</TableCell>
                      <TableCell>{element.tax_no}</TableCell>
                      <TableCell>
                        {element.email ? element.email : "---"}
                      </TableCell>
                      <TableCell>
                        {element.phone ? element.phone : "---"}
                      </TableCell>
                      <TableCell>
                        {element.region_id
                          ? regionsState[element.region_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.country_id
                          ? countryState[element.country_id]?.name
                          : "---"}
                      </TableCell>
                      {/* <TableCell>{element.division_id ? divisionState[element.division_id]?.name : '---'} </TableCell> */}
                      <TableCell>
                        {element.city_id
                          ? cityState[element.city_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.subcity_id
                          ? subCityState[element.subcity_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.district ? element.district : "---"}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={(event, rowData) => {
                            setUpdateData(element);
                            setOpenUpdateDialog(true);
                          }}
                        >
                          <CreateOutlinedIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          )
        }}
      />
    </div>
  );
}
