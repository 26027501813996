import { GET_SEASONS, ADD_SEASONS, UPDATE_SEASONS, DELETE_SEASONS } from './types';
export const getSeason = res => dispatch => {
  dispatch({
    type: GET_SEASONS,
    payload: res.data.data,
  });
};

export const addSeason = res => dispatch =>
  dispatch({
    type: ADD_SEASONS,
    payload: res.data.data,
  });

export const updateSeason = res => dispatch => {
  dispatch({
    type: UPDATE_SEASONS,
    payload: res.data.data,
  });
};

export const deleteSeason = res => dispatch =>
  dispatch({
    type: DELETE_SEASONS,
    payload: res.data.data,
  });
