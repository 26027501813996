import {
  GET_PAYMENT_CONDITIONS,
  ADD_PAYMENT_CONDITIONS,
  UPDATE_PAYMENT_CONDITIONS,
  DELETE_PAYMENT_CONDITIONS,
} from '../actions/types';

const initialState = {
  paymentConditions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_CONDITIONS:
      return { ...state, paymentConditions: action.payload };

    case ADD_PAYMENT_CONDITIONS:
      return {
        ...state,
        paymentConditions: action.payload,
      };

    case UPDATE_PAYMENT_CONDITIONS:
      const index = state.paymentConditions.findIndex(paymentCon => paymentCon.id === action.payload.id);
      const newPaymentCon = [...state.paymentConditions];
      newPaymentCon[index] = action.payload;
      return {
        ...state,
        paymentConditions: newPaymentCon,
      };

    case DELETE_PAYMENT_CONDITIONS:
      return {
        ...state,
        paymentConditions: state.paymentConditions.filter(paymentCon => paymentCon.id !== action.payload.id),
      };

    default:
      return state;
  }
};
