// prettier-ignore
import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import GridContainer from "@jumbo/components/GridContainer";
import { Paper, Tab, Tabs } from "@material-ui/core";
import CustomerTable from "./CustomerTable";
import IntlMessages from "@jumbo/utils/IntlMessages";

function TabPanel(props) {
  const rooms = Object.values(props.reservationDetail.rooms);
  switch (props.index) {
    case props.index:
      return (
        <CustomerTable
          selectedContract={props.selectedContract}
          customerAdd={props.customerAdd}
          reservationDetail={props.reservationDetail}
          customers={
            rooms[props.index] !== undefined ? rooms[props.index].customers : []
          } // eslint-disable-line
          index={props.index}
        />
      );
    default:
      return (
        <div>
          <IntlMessages id="its.not.possible" />
        </div>
      );
  }
}

const CustomerTab = props => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(0);
  }, [props.roomLength]);
  const tabList = () => {
    const tabs = [];
    for (let i = 0; i < props.roomLength; i++) {
      tabs.push(<Tab label={`Room ${i + 1}`} />);
    }
    return tabs;
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <GridContainer direction="row" alignItems="center">
      <Grid item xs={12}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons
          onChange={handleChange}
        >
          {tabList()}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {props.roomLength > 0 ? (
          <TabPanel
            index={value}
            setIndex={setValue}
            roomLength={props.roomLength}
            customerAdd={props.setCustomer}
            reservationDetail={props.reservationDetail}
            selectedContract={props.selectedContract}
          />
        ) : (
          ""
        )}
      </Grid>
    </GridContainer>
  );
};

export default CustomerTab;
