import { normalize, schema } from 'normalizr';

const countries = new schema.Entity('countries');
const stateCountries = new schema.Entity(
  'countries',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  },
);

const market = new schema.Entity(
  'markets',
  {
    countries: [countries],
  },
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);

const response = {
  markets: [market],
  add_market: [market],
  updated_market: [market],
  deleted_market: [market],
  state_market: [market],
  state_countries: [stateCountries],
};

export const normalizeMarkets = data => normalize(data, [response]);
