import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  CHOOSE_COMPANY,
  CHOOSE_OFFICE,
} from '../../@jumbo/constants/ActionTypes';
import { RESET_REDUCER } from './types';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};

export const chooseCompany = company => {
  return dispatch => {
    dispatch({
      type: CHOOSE_COMPANY,
      payload: company,
    });
  };
};

export const chooseOffice = office => {
  return dispatch => {
    dispatch({
      type: CHOOSE_OFFICE,
      payload: office,
    });
  };
};

export const resetReducer = () => {
  window.location.reload();
  return dispatch => {
    dispatch({
      type: RESET_REDUCER,
    });
  };
};
