export const errstyle = {
  layout: {
    boxShadow: '3px 5px 10px grey',
    borderRadius: '10px',
    height: '50vh',
    width: '100%',
    marginTop: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  content: {
    width: '85%',
    height: '55%',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    letterSpacing: '3px',
    fontSize: '1.3rem',
    textWrap: 'wrap',
    fontFamily: 'arial',
  },
  header: {
    width: '100%',
    height: '30%',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  buttons: {
    width: '100%',
    height: '10%',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancel: {
    backgroundColor: 'orangered',
    color: 'white',
  },
  icons: {
    fontSize: '4rem',
    margin: '10px',
  },
  headerText: {
    letterSpacing: '.3rem',
    color: 'orangered',
    fontWeight: 'bold',
  },
};
