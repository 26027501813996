import React from 'react';
import UsersModule from './UserList';

const Main = () => (
  <div>
    <UsersModule />
  </div>
);

export default Main;
