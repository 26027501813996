import {
  getBoardSupplament,
  addBoardSupplament,
  updateBoardSupplament,
  deleteBoardSupplament,
  addBoardSupplamentReduction,
  updateBoardSupplamentReduction,
  deleteBoardSupplamentReduction,
} from 'redux/actions/boardSupplament';
import axios from './axios';
import { fetchStart, fetchError, fetchSuccess } from '../redux/actions/Common';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

export const getBoardSupplamentService = contract_id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('boardSupplament', {
      params: {
        contract_id: contract_id,
      },
    })
    .then(res => {
      dispatch(getBoardSupplament(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const addBoardSupplamentService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('boardSupplament', data)
    .then(res => {
      dispatch(addBoardSupplament(res));
      dispatch(fetchSuccess());
      sendLogService.boardSupplementServiceLogs(res, data);
      return res.data.data;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateBoardSupplamentService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('boardSupplament', data)
    .then(res => {
      dispatch(updateBoardSupplament(res));
      dispatch(fetchSuccess());
      sendLogService.boardSupplementServiceLogs(res, data);
      return res.data.data;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteBoardSupplamentService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`boardSupplament/${id}`)
    .then(res => {
      dispatch(deleteBoardSupplament(res));
      dispatch(fetchSuccess());
      sendLogService.boardSupplementServiceLogs(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};

// Reduciton services
export const addBoardSupplamentReductionService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('boardSupplamentReduction', data)
    .then(res => {
      dispatch(addBoardSupplamentReduction(res));
      dispatch(fetchSuccess());
      sendLogService.bsReductionServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateBoardSupplamentReductionService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('boardSupplamentReduction', data)
    .then(res => {
      dispatch(updateBoardSupplamentReduction(res));
      dispatch(fetchSuccess());
      sendLogService.bsReductionServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteBoardSupplamentReductionService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`boardSupplamentReduction/${id}`)
    .then(res => {
      dispatch(deleteBoardSupplamentReduction(res));
      dispatch(fetchSuccess());
      sendLogService.bsReductionServiceLogs(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
