import GridContainer from '@jumbo/components/GridContainer';
import { Grid, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomerTableUpdate from './CustomerTableUpdate';

function TabPanel({ index, reservationRooms, setReservationRooms, selectedContract, reservation, type }) {
  switch (index) {
    case index:
      return (
        <CustomerTableUpdate
          customers={reservationRooms[index + 1] !== undefined ? reservationRooms[index + 1].customers : []}
          roomNo={index + 1}
          setReservationRooms={setReservationRooms}
          selectedContract={selectedContract}
          reservation={reservation}
          room={reservationRooms[index + 1]}
          type={type}
        />
      );
    default:
      return <div>its Not Possible!</div>;
  }
}

function CustomerTabUpdate({
  reservationRooms,
  roomLength,
  setReservationRooms,
  selectedContract,
  reservationDetail,
  type,
}) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    //setValue(0);
  }, [roomLength, reservationRooms]);

  const tabList = () => {
    const tabs = [];
    for (let i = 0; i < roomLength; i++) {
      tabs.push(<Tab label={`Room ${i + 1}`} />);
    }
    return tabs;
  };

  return (
    <GridContainer direction="row" alignItems="center">
      <Grid item xs={12}>
        <Paper square>
          <Tabs value={value} indicatorColor="primary" textColor="primary" scrollButtons onChange={handleChange}>
            {tabList()}
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {roomLength > 0 ? (
          <TabPanel
            index={value}
            roomLength={roomLength}
            reservationRooms={reservationRooms}
            setReservationRooms={setReservationRooms}
            selectedContract={selectedContract}
            reservation={reservationDetail}
            type={type}
          />
        ) : (
          ''
        )}
      </Grid>
    </GridContainer>
  );
}

export default CustomerTabUpdate;
