import { Box } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import ResStatisticsCard from './ResStatisticsCard';
import ReservationView from './ReservationView';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    color: theme.palette.common.white,
    marginRight: '80px',
  },
  titleRoot: {
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: 0.4,
    margin: '50px',
    marginLeft: '50px',
  },
}));

function ResWeekDayView() {
  const classes = useStyles();
  const reservations = useSelector(state => state.reservation?.entities?.reservations);
  const intl = useIntl();

  const today = moment();
  const begginingOfCurrentWeek = moment(today.startOf('week')).format('YYYY-MM-DD');
  const endOfWeek = moment(today.endOf('week')).format('YYYY-MM-DD');

  let totalRes = reservations != undefined ? Object.values(reservations).length : 0;
  let cancelledRes = [];
  let confirmedRes = [];

  if (reservations != undefined) {
    Object.values(reservations).map(item => {
      const created_date = item.updated_at.split('T')[0];
      if (moment(created_date).isBetween(begginingOfCurrentWeek, endOfWeek, undefined, []) && item.hotel_status == 3) {
        cancelledRes.push(item);
      } else if (
        moment(created_date).isBetween(begginingOfCurrentWeek, endOfWeek, undefined, []) &&
        item.hotel_status == 4
      ) {
        confirmedRes.push(item);
      }
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        pl: 1,
        ml: '70px',
        mt: '-47px',
        flexGrow: 1,
        justifyContent: 'flex-start',
      }}>
      <ResStatisticsCard
        className={classes.cardRoot}
        backgroundColor="#246e89"
        title={intl.formatMessage({ id: 'total.reservation' })}
        titleProps={{
          variant: 'inherit',
          component: 'h4',
          className: classes.titleRoot,
        }}
        amount={totalRes}
        description={intl.formatMessage({ id: 'all.time' })}>
        <ReservationView />
      </ResStatisticsCard>

      <ResStatisticsCard
        className={classes.cardRoot}
        backgroundColor="#388ea3"
        title={intl.formatMessage({ id: 'cancelled.reservation' })}
        titleProps={{
          variant: 'inherit',
          component: 'h4',
          className: classes.titleRoot,
        }}
        amount={cancelledRes.length}
        description={intl.formatMessage({ id: 'this.week' })}>
        <ReservationView />
      </ResStatisticsCard>
      <ResStatisticsCard
        className={classes.cardRoot}
        backgroundColor="#55afc0"
        title={intl.formatMessage({ id: 'confirmed.reservation' })}
        titleProps={{
          variant: 'inherit',
          component: 'h4',
          className: classes.titleRoot,
        }}
        amount={confirmedRes.length}
        description={intl.formatMessage({ id: 'this.week' })}>
        <ReservationView />
      </ResStatisticsCard>
    </Box>
  );
}

export default ResWeekDayView;
