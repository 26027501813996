import { normalize, schema } from 'normalizr';

const country = new schema.Entity(
  'officeDestination',
  {},
  {
    idAttribute: 'id',
  },
);

const stateCountry = new schema.Entity(
  'officeDestination',
  {},
  {
    idAttribute: 'id',
    mergeStrategy: (a, b) => {
      return { ...a };
    },
  },
);

const response = {
  country: [country],
  stateCountry: [stateCountry],
};

export const normalizrDestination = data => normalize(data, [response]);
