// import axios from "axios";

// const token = localStorage.getItem("token");
// export default axios.create({
//   baseURL: `https://stageb2bapi.mundosai.com/api/`,
//   headers: {
//     "Content-Type": "application/json"
//     // Authorization: `Bearer ${token}`,
//   }
// });

// import axios from "axios";

// const token = localStorage.getItem("token");
// export default axios.create({
//   baseURL: `http://localhost:80/api/`,
//   headers: {
//     "Content-Type": "application/json"
//     // Authorization: `Bearer ${token}`,
//   }
// });




import axios from "axios";

const token = localStorage.getItem("token");
export default axios.create({
  baseURL: `https://b2bapi.mundosai.com/api/`,
  headers: {
    "Content-Type": "application/json"
    // Authorization: `Bearer ${token}`,
  }
});



