import { normalize, schema } from 'normalizr';

const stateOffice = new schema.Entity(
  'offices',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      //USER O OFİSTE ÖNCEDENDE VARDI ŞİMDİDE VAR
      if (entityA.oldPivotOffice && entityA.pivot_office) {
        return {
          ...entityB,
        };
      }
      //USER ESKİDEN BU OFFİCEDE VARDI ŞİMDİ YOK ESKİ OFFİCEDEN İDSİ SİLİNECEK
      else if (entityA.oldPivotOffice) {
        let deleteUserID = entityB.user.filter(element => element !== entityA.oldPivotOffice);
        return { ...entityB, user: deleteUserID };
      }
      //USER ESKİDEN BURADA YOKTU ŞİMDİ VAR İDSİ O OFFİCEYE EKLENECEK
      else {
        entityB.user.push(entityA.pivot_office);
        return {
          ...entityB,
        };
      }
    },
  },
);

const pivot_office = new schema.Entity(
  'offices',
  {},
  {
    idAttribute: 'office_id',
    processStrategy: (value, parent, key) => {
      return { ...value, pivot_office: parent.id };
    },
  },
);

const oldPivotOffice = new schema.Entity(
  'offices',
  {},
  {
    idAttribute: 'id',
    processStrategy: (value, parent, key) => {
      return { ...value, oldPivotOffice: parent.id };
    },
  },
);

const get_contract = new schema.Entity('get_contract');

const updatedUser = new schema.Entity('users', {
  pivot_office: [pivot_office],
  oldPivot: [oldPivotOffice],
  get_contract: [get_contract],
});

const response = {
  updateUser: [updatedUser],
  stateOffice: [stateOffice],
};

export const userUpdateNormalizr = data => normalize(data, [response]);
