import { GET_ALLOTMENTACTIONTYPE } from './types';
import { normalizeAllotmentActionType } from 'redux/schemas/allotmentActionTypes';

export const getAllotmentActionType = res => dispatch => {
  const normalizedAllotmentActionTypes = normalizeAllotmentActionType(res.data.data);

  dispatch({
    type: GET_ALLOTMENTACTIONTYPE,
    payload: normalizedAllotmentActionTypes.entities.allotmentActionTypes,
  });
};
