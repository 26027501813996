import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HotelIcon from '@mui/icons-material/Hotel';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import CalendarMonthIcon from '@mui/icons-material/CalendarToday';
import StoreIcon from '@mui/icons-material/Store';
import LanguageIcon from '@mui/icons-material/Language';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import NewBoard from './NewBoard';
import ProductType from './ProductType';
import AddRoomType from './AddRoomType';
import NewSeason from './NewSeason';
import Market from './Market';
import HotelTable from './HotelTable';
import NewOperator from './NewOperator';
import NewService from './NewService';
import { useSelector } from 'react-redux';

 function Definitons() {
  const [value, setValue] = useState(0);

  /*   useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem('selectedCompany');
      if (item) {
        setUserData(item);
      }
    }
    window.addEventListener('storage', checkUserData);
    return () => {
      window.removeEventListener('storage', checkUserData);
    };
  }, []);
 */

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);

  let isAuthAdd = permissionsByAuthUser.some(permission => Number(permission.route_id) === 3 && permission.post === 1);
  let isAuthUpdate = permissionsByAuthUser.some(permission => Number(permission.route_id) === 3 && permission.put === 1);
  let isAuthDelete = permissionsByAuthUser.some(permission => Number(permission.route_id) === 3 && permission.delete === 1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
      <Box sx={{ maxWidth: { xs: '%100', sm: '%100' }, bgcolor: 'background.paper' }}>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            onChange={handleChange}>
            <Tab icon={<HotelIcon />} label={<IntlMessages id="new.board" />} />
            <Tab icon={<ApartmentIcon />} label={<IntlMessages id="new.product" />} />
            <Tab icon={<DomainAddIcon />} label={<IntlMessages id="new.product.type" />} />
            <Tab icon={<RoomPreferencesIcon />} label={<IntlMessages id="new.roomtype" />} />
            <Tab icon={<CalendarMonthIcon />} label={<IntlMessages id="new.season" />} />
            <Tab icon={<StoreIcon />} label={<IntlMessages id="new.market" />} />
            <Tab icon={<LanguageIcon />} label={<IntlMessages id="new.operator" />} />
            <Tab icon={<RoomServiceIcon />} label={<IntlMessages id="new.service" />} />
          </Tabs>
        </Grid>
        <Grid container direction="column" justifyContent="center" alignItems="stretch">
          <TabPanel value={0} index={0}>
            <NewBoard isAuthAdd={isAuthAdd} isAuthUpdate={isAuthUpdate} isAuthDelete={isAuthDelete} />
          </TabPanel>
          <TabPanel value={1} index={1} style={{ maxWidth: '100%' }}>
            <HotelTable isAuthAdd={isAuthAdd} isAuthUpdate={isAuthUpdate} isAuthDelete={isAuthDelete} />
          </TabPanel>
          <TabPanel value={2} index={2}>
            <ProductType isAuthAdd={isAuthAdd} isAuthUpdate={isAuthUpdate} isAuthDelete={isAuthDelete} />
          </TabPanel>
          <TabPanel value={3} index={3}>
            <AddRoomType isAuthAdd={isAuthAdd} isAuthUpdate={isAuthUpdate} isAuthDelete={isAuthDelete} />
          </TabPanel>
          <TabPanel value={4} index={4}>
            <NewSeason isAuthAdd={isAuthAdd} isAuthUpdate={isAuthUpdate} isAuthDelete={isAuthDelete} />
          </TabPanel>
          <TabPanel value={5} index={5}>
            <Market isAuthAdd={isAuthAdd} isAuthUpdate={isAuthUpdate} isAuthDelete={isAuthDelete} />
          </TabPanel>
          <TabPanel value={6} index={6}>
            <NewOperator isAuthAdd={isAuthAdd} isAuthUpdate={isAuthUpdate} isAuthDelete={isAuthDelete} />
          </TabPanel>
          <TabPanel value={7} index={7}>
            <NewService isAuthAdd={isAuthAdd} isAuthUpdate={isAuthUpdate} isAuthDelete={isAuthDelete} />
          </TabPanel>
        </Grid>
      </Box>
    </TabContext>
  );
}

export default React.memo(Definitons);
