import React, { useState, useEffect, lazy, Suspense, useRef } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { getHotelService, deleteHotelService } from "services/hotels";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedHotel } from "redux/actions/hotels";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { companyHasOfficeMemo } from "redux/selector/companyHasOfficeSelector";
import { getAdminCompaniesService } from "services/company";
import _ from "lodash";
import { useIntl } from "react-intl";

const NewHotel = lazy(() => import("./NewHotel"));

const MySwal = withReactContent(Swal);

const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);
export default function HotelTable({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (stateCompanyHasOffice.length === 0) {
      dispatch(getAdminCompaniesService());
    }
    if (Object.values(hotels).length === 0) {
      dispatch(getHotelService());
    }
  }, [dispatch]);

  const stateCompanyHasOffice = useSelector(companyHasOfficeMemo);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { contracts } = useSelector(({ contract }) => contract);
  const { authUser } = useSelector(({ auth }) => auth);
  const [filteredData, setFilteredData] = useState([]);
  //Office içerisinde herhnagi bir destination seçili değilse checkDestinationOffice true oluyor

  const isOfficeHasDestination = () => {
    let checkDestionationOffice = [];
    Object.values(stateCompanyHasOffice.offices).map(office => {
      if (
        JSON.parse(
          office.get_destination === null ||
            office.get_destination === undefined
        )
      ) {
        checkDestionationOffice.push(office);
      }
    });
    return checkDestionationOffice;
  };

  useEffect(() => {
    let companyDestination = {};

    if (hotels.length != 0) {
      //Enterprise user ve Company companynin company level userı bütün otelleri görüntüleyebilir
      if (authUser.authority_level == 10 || authUser.authority_level == 11) {
        if (Object.values(stateCompanyHasOffice.offices).length != 0) {
          setFilteredData(Object.values(hotels));
        }
      }
      //Enterprise companynin , company seviyesindeki user seçmiş olduğu company (sideBarda yazılı olan) sadece onun destinationa ait kayıtlı olan hotelleri görebilir
      else if (authUser.authority_level == 20) {
        if (stateCompanyHasOffice.users[authUser.id]) {
          stateCompanyHasOffice.companies[
            parseInt(localStorage.getItem("selectedCompany"))
          ].offices.map(element => {
            if (
              stateCompanyHasOffice.users[authUser.id].pivot_office.includes(
                element
              )
            ) {
              if (companyDestination[0]) {
                companyDestination[0] = JSON.parse(
                  stateCompanyHasOffice.offices[element].get_destination
                    ?.destinations
                ).reduce((acc, curr) => {
                  if (!acc[curr.id]) {
                    acc[curr.id] = curr;
                  }
                  return acc;
                }, companyDestination[0]);
              } else {
                companyDestination[0] = JSON.parse(
                  stateCompanyHasOffice.offices[element].get_destination
                    ?.destinations
                ).reduce((acc, curr) => {
                  acc[curr.id] = curr;
                  return acc;
                }, {});
              }
            }
          });
          let filteredHotel = [];
          Object.values(hotels).map(hotel => {
            return Object.values(companyDestination[0]).find(
              element =>
                element.country_id == hotel.country_id &&
                element.id == hotel.city_id
            )
              ? filteredHotel.push(hotel)
              : null;
          });
          setFilteredData(filteredHotel);
        }
      }
      //Office seviyesindeki userın seçmiş olduğu officein destinatonlarına ait olan hoteller gelecek
      else if (authUser.authority_level === 30) {
        if (stateCompanyHasOffice.users[authUser.id]) {
          companyDestination[0] = JSON.parse(
            stateCompanyHasOffice.offices[
              parseInt(localStorage.getItem("selectedOffice"))
            ]["get_destination"]["destinations"]
          );
          let filteredHotel = [];
          Object.values(hotels).map(hotel => {
            return companyDestination[0].find(
              element =>
                element.country_id == hotel.country_id &&
                element.city_id == hotel.city_id
            )
              ? filteredHotel.push(hotels)
              : null;
          });
          setFilteredData(filteredHotel);
        }
      }
    }
  }, [hotels, stateCompanyHasOffice]);

  const onRowUpdate = rowData => {
    setOpenModal(true);
    dispatch(setSelectedHotel(rowData.id));
  };

  const [openModal, setOpenModal] = useState(false);
  //To activate the add button
  const addButtonActive = () => {
    const destinations = [];
    Object.values(stateCompanyHasOffice["offices"]).map(data => {
      if (data.get_destination == null || data.get_destination == undefined) {
        destinations.push(false);
      } else {
        destinations.push(true);
      }
    });
    if (
      isOfficeHasDestination().length ===
      Object.values(stateCompanyHasOffice["offices"]).length
    ) {
      Swal.fire({
        title: "Error",
        text:
          intl.formatMessage({
            id: "you.need.office.destination.to.confim"
          }) +
          intl.formatMessage({ id: "please.contact.your.company.manager" }),
        icon: "error",
        confirmButtonText: "Ok"
      });
    } else if (isOfficeHasDestination().length > 0) {
      Swal.fire({
        html: isOfficeHasDestination()
          .map((data, index) => {
            let string =
              data.name +
              " " +
              intl.formatMessage({ id: "has.no.destination" }) +
              "<br/>";
            return string;
          })
          .join(""),

        icon: "warning",
        confirmButtonText: "Ok"
      });
    } else {
      setOpenModal(true);
      dispatch(setSelectedHotel([]));
    }
  };

  const hasHotel = hotel_id => {
    //To check if the hotel is in the contract
    var has = true;

    Object.values(contracts).map(contract => {
      if (contract.hotel_id === hotel_id) {
        has = false;
      }
    });
    return has;
  };

  const tableColumns = [
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="product.name" />
          </b>
        </h5>
      ),
      field: "name"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="product.type" />
          </b>
        </h5>
      ),
      field: "product_type.name"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="product.code" />
          </b>
        </h5>
      ),
      field: "hotel_code"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="company" />
          </b>
        </h5>
      ),
      field: "company"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="tax.no" />
          </b>
        </h5>
      ),
      field: "tax_no"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="tax.office" />
          </b>
        </h5>
      ),
      field: "tax_office"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="region" />
          </b>
        </h5>
      ),
      field: "region.name"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="country" />
          </b>
        </h5>
      ),
      field: "country.name"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="city" />
          </b>
        </h5>
      ),
      field: "city.name"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="sub.city" />
          </b>
        </h5>
      ),
      field: "sub_city.name"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="district" />
          </b>
        </h5>
      ),
      field: "district"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="phone" />
          </b>
        </h5>
      ),
      field: "tel"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="fax" />
          </b>
        </h5>
      ),
      field: "fax"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="email" />
          </b>
        </h5>
      ),
      field: "e_mail"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="website" />
          </b>
        </h5>
      ),
      field: "web_page"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="star.rate" />
          </b>
        </h5>
      ),
      field: "star_rate.value"
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="giata.code" />
          </b>
        </h5>
      ),
      field: "giata_codes.giata_id",
      render: rowData => {
        return (
          rowData.giata_codes.hotel_name +
          "(" +
          rowData.giata_codes.giata_id +
          ")"
        );
      }
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="airport.code" />
          </b>
        </h5>
      ),
      field: "airport_codes.code"
    }
  ];

  return (
    <Grid>
      <Suspense fallback={<div>Loading...</div>}>
        <NewHotel openModal={openModal} setOpenModal={setOpenModal} />
      </Suspense>
      <MaterialTable
        columns={tableColumns}
        data={filteredData}
        title=""
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          search: false,
          actionsColumnIndex: -1,
          addRowPosition: "first"
        }}
        actions={[
          {
            icon: "edit",
            tooltip: isAuthUpdate ? "Edit Row" : "You are not authorized",
            disabled: isAuthUpdate ? false : true,
            onClick: (event, rowData) => {
              onRowUpdate(rowData);
            }
          },

          {
            icon: () => (
              <ThemeProvider theme={theme}>
                <ColorButton
                  variant="contained"
                  color="backgroundColor"
                  startIcon={<AddBoxIcon />}
                >
                  <IntlMessages id="add" />
                </ColorButton>
              </ThemeProvider>
            ),
            tooltip: isAuthAdd ? "Add Row" : "You are not authorized",
            disabled: isAuthAdd ? false : true,
            position: "toolbar",
            onClick: () => {
              addButtonActive();
            }
          }
        ]}
        editable={{
          deleteTooltip: row =>
            isAuthDelete ? "Delete" : "You are not authorized",
          isDeletable: row => (isAuthDelete ? true : false),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                if (hasHotel(oldData.id)) {
                  dispatch(deleteHotelService(oldData.id)).then(res => {
                    if (res == 201) {
                      MySwal.fire({
                        icon: "success",
                        text:
                          intl.formatMessage({ id: "hotel" }) +
                          intl.formatMessage({ id: "deleted.successfully" })
                      });
                    }
                  });
                } else {
                  MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text:
                      intl.formatMessage({ id: "hotel" }) +
                      intl.formatMessage({ id: "is.in.use" })
                  });
                }
                resolve();
              }, 1000);
            })
        }}
      />
    </Grid>
  );
}
