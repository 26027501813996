import IntlMessages from "@jumbo/utils/IntlMessages";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPeriodService, getReservationPeriod } from "services/period";
import { useIntl } from "react-intl";
const ContractSelection = props => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const contracts = props.contracts;
  const [contract_type, setContract_type] = useState("true");
  const { boards } = useSelector(({ boards }) => boards);
  const contract_has_board = useSelector(
    state => state.contract.contract_has_board
  );
  const { markets } = useSelector(({ markets }) => markets);
  const { roomType } = useSelector(({ rooms }) => rooms.rooms);
  const selectedContract = useSelector(
    state => state.contract.contracts[props.reservationDetail.contract_id]
  );
  const [boardState, setBoardState] = useState([]);
  const [reservationsPeriod, setReservationsPeriod] = useState([]);
  const handleChange = event => {
    setContract_type(event.target.value);
  };

  useEffect(() => {
    let contract_boards = [];
    let newReservationDetail = { ...props.reservationDetail };
    if (contract_type === "true") {
      //console.log(selectedContract);
      if (selectedContract !== undefined) {
        SelectingReservationPeriod(selectedContract.id);
        selectedContract.contract_has_board.map(board => {
          var newBoard = contract_has_board[board];
          newBoard["name"] = boards[newBoard.board_id].code;
          newBoard["label"] = boards[newBoard.board_id].name;
          contract_boards.push(newBoard);
        });
        setBoardState(contract_boards);
        props.setSelectedContract(selectedContract);
        //SelectingReservationPeriod(selectedContract.id);
      } else {
        setBoardState([]);
      }
    } else {
      delete newReservationDetail.contract_id;
      setBoardState(boards);
    }
    delete newReservationDetail.board_id;
    /*   props.setReservationDetail(prev => ({
      ...newReservationDetail,
    })); */
    //props.setReservationDetail(newReservationDetail);
  }, [contract_type, selectedContract]);

  const getContractPeriods = async contract_id => {
    try {
      var result = await getReservationPeriod(contract_id);
      return result.data.data;
    } catch (e) {
      return [];
    }
  };
  const SelectingReservationPeriod = async contract_id => {
    let periods = await getContractPeriods(contract_id);
    let start_date_period = {};
    let end_date_period = {};
    start_date_period = periods.find(period =>
      moment(props.reservationDetail.start_date).isBetween(
        period.start_date,
        period.end_date
      )
    );
    end_date_period = periods.find(period =>
      moment(props.reservationDetail.end_date).isBetween(
        period.start_date,
        period.end_date
      )
    );
    if (start_date_period?.id === end_date_period?.id) {
      setReservationsPeriod(start_date_period);
    } else {
      setReservationsPeriod({
        startDate: start_date_period,
        endDate: end_date_period
      });
    }
  };

  const contract_markets = Object.values(markets);

  const contractMarket = [];

  props.contracts.map(param => {
    param.market.forEach(element => {
      contract_markets.forEach(val => {
        contractMarket.push(val.name);
      });
    });
  });

  (contract_type === false ? boardState : Object.values(boardState)).map(
    param => {
      if (param.name == "AI") {
        param.label = "All Inclusive";
      }
      if (param.name == "AI+") {
        param.label = "All Inclusive + Additional Services";
      }
      if (param.name == "BB") {
        param.label = "Bed & Breakfast";
      }
      if (param.name == "DO") {
        param.label = "Dinner Only";
      }
      if (param.name == "FB") {
        param.label = "Full Board";
      }
      if (param.name == "FB+") {
        param.label = " Full Board + Additional Services";
      }
      if (param.name == "HB") {
        param.label = "Half Board";
      }
      if (param.name == "HB+") {
        param.label = " Half Board + Additional Services";
      }
      if (param.name == "LO") {
        param.label = "Lunch Only";
      }
      if (param.name == "RO") {
        param.label = " Room Only";
      }
      if (param.name == "SC") {
        param.label = "Self Catering";
      }
    }
  );
  console.log(boardState);
  return (
    <Grid container>
      <Grid item xs={12}>
        <RadioGroup
          row
          aria-label={intl.formatMessage({ id: "contract" })}
          value={String(contract_type)}
          name={intl.formatMessage({ id: "contract.based" })}
          onChange={handleChange}
        >
          <FormControlLabel
            value={"true"}
            control={<Radio />}
            label={intl.formatMessage({ id: "contract.based" })}
          />
          <FormControlLabel
            value={"false"}
            control={<Radio />}
            label={intl.formatMessage({ id: "not.contract.based" })}
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={8}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            <IntlMessages id="contracts" />
          </InputLabel>
          {contract_type == "true" ? (
            <Select
              labelId="demo-simple-select-label"
              name="contract_id"
              value={props.reservationDetail.contract_id}
              label={intl.formatMessage({ id: "contracts" })}
              displayEmpty={true}
              disabled={contract_type !== "true"}
              onChange={props.handleChange}
            >
              {contracts.map((contract, index) => (
                <MenuItem value={`${contract.id}`}>
                  {contract.contract_name + " " + contractMarket}
                </MenuItem>
              ))}
            </Select>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            <IntlMessages id="board.type" />{" "}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            name="board_id"
            value={props.reservationDetail?.board_id}
            label={intl.formatMessage({ id: "board.type" })}
            onChange={props.handleChange}
          >
            {Object.values(boardState).map(board => (
              <MenuItem value={board.id}>
                {contract_type === "false"
                  ? board.code + " - " + board.name
                  : board.name + " - " + board.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ContractSelection;
