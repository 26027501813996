import { GET_ARRIVAL_STAY } from '../actions/types';
import { normalizeArrivalStay } from '../schemas/arrivalStay';

const initialState = {
  arrivalStay: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ARRIVAL_STAY:
      const normalizedArrivalStay = normalizeArrivalStay(action.payload);
      return { ...state, arrivalStay: normalizedArrivalStay.entities.arrival_stay };
    default:
      return state;
  }
};
