import { normalize, schema } from 'normalizr';
// Define a users schema

// Define your article
const response = new schema.Entity(
  'markups',
  {},
  {
    processStrategy: (entityA, entityB, key) => {
      let all_rate_ranges = entityA.rate_ranges;
      all_rate_ranges.forEach(element => {
        delete entityB[0].stateRateRanges[element.id];
      });
      delete entityB[0].stateDeleteMarkups[entityA.id];

      return { ...entityA };
    },
  },
);
export const deleteOfferNormalize = data => normalize(data, [response]);
