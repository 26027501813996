import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';

const PhoneFormatCustom = React.forwardRef(function PhoneFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+00 (#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

PhoneFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

const AgeNumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      format={
        Number(props.value) === props.value && props.value % 1 !== 0
          ? '#####'
          : Number(props.value) === props.value && props.value % 1 === 0
          ? '##.00'
          : '##.##'
      }
    />
  );
});
const PriceFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      decimalScale="2"
      allowNegative={false}
      fixedDecimalScale={true}
      thousandSeparator={','}
      decimalSeparator={'.'}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

PriceFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
AgeNumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { AgeNumberFormatCustom, PhoneFormatCustom, PriceFormatCustom };
