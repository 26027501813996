import axios from './axios';
import { fetchStart, fetchError, fetchSuccess } from '../redux/actions/Common';
import {
  addReservation,
  deleteReservation,
  getReservations,
  sendHotelMail,
  sendHotelResponse,
  updateReservation,
} from 'redux/actions/reservations';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

export const getReservationsService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('reservation')
    .then(res => {
      dispatch(getReservations(res.data));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addReservationService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('reservation', data)
    .then(res => {
      dispatch(addReservation(res));
      dispatch(fetchSuccess());
      sendLogService.reservationServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteReservationService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`reservation/${id}`)
    .then(res => {
      dispatch(deleteReservation(res));
      dispatch(fetchSuccess());
      sendLogService.reservationServiceLogs(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};

export const hotelMailService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post(`reservation/hotelMail/${id}`)
    .then(res => {
      dispatch(sendHotelMail(res));
      dispatch(fetchSuccess());
      sendLogService.reservationServiceLogs(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};

export const hotelResponseService = (hotel_response, reservation_id) => dispatch => {
  const token = localStorage.getItem('token');
  let data = {
    hotel_response: hotel_response,
    reservation_id: reservation_id,
  };
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post(`reservation/hotelResponse`, data)
    .then(res => {
      dispatch(sendHotelResponse(res));
      dispatch(fetchSuccess());
      sendLogService.reservationServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateReservationService = reservationDetail => dispatch => {
  const token = localStorage.getItem('token');
  let data = reservationDetail;
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put(`reservation`, data)
    .then(res => {
      dispatch(updateReservation(res.data));
      dispatch(fetchSuccess());
      sendLogService.reservationServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
