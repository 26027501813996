import {
  getMasterUsers,
  addMasterUser,
  editMasterUser,
  deleteMasterUser
} from "redux/actions/User";

import {
  addUser,
  updateUser,
  deleteUser,
  fetchCompanyError,
  fetchCompanySuccess
} from "redux/actions/CompanyHasOffice";

import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
import Swal from "sweetalert2";
/**PROXOLAB LOGGER **/

export const getMasterUsersService = callbackFunc => dispatch => {
  dispatch(fetchStart());
  return axios
    .get("/master/users")
    .then(({ data }) => {
      dispatch(getMasterUsers(data.data));
      dispatch(fetchSuccess());
      if (callbackFunc) callbackFunc();
    })
    .catch(error => {
      dispatch(fetchError(error));
    });
};

export const addMasterUserService = (user, callbackFunc) => dispatch => {
  dispatch(fetchStart());
  return axios
    .post("master/register", user)
    .then(({ data }) => {
      dispatch(addMasterUser(data.user));
      dispatch(fetchSuccess());
      if (callbackFunc) callbackFunc();
      sendLogService.masterUserServiceLogs(user, data);
    })
    .catch(e => {
      if (callbackFunc) {
        setTimeout(() => {
          callbackFunc();
        }, 300);
      }
      sendLogService.errorlog(e, callbackFunc);
    });
};

export const editMasterUserService = (user, callbackFunc) => dispatch => {
  dispatch(fetchStart());

  return axios
    .put("master/users/", user)
    .then(({ data }) => {
      dispatch(editMasterUser(data.user));
      dispatch(fetchSuccess());
      if (callbackFunc) callbackFunc();
      sendLogService.masterUserServiceLogs(user, data);
    })
    .catch(error => {
      dispatch(fetchError(error));
      sendLogService.errorlog(error, callbackFunc);
    });
};

export const deleteMasterUserService = (user, callbackFunc) => dispatch => {
  return axios
    .delete(`master/users/${user.id}`)
    .then(({ data }) => {
      dispatch(deleteMasterUser(data.data));
    })
    .catch(error => {});
};

export const changeDbForSupport = company => dispatch => {
  return axios.put("master/change-sp-admin-db", company).then(({ data }) => {
    dispatch(fetchSuccess("Db changed successfully"));
  });
};
export const addAdminUserService = (user, callbackFunc) => dispatch => {
  dispatch(fetchStart());
  let copyUser = JSON.parse(JSON.stringify(user));
  Object.values(copyUser.choosedOffices).map(element => {
    delete element.get_destination;
  });
  return axios
    .post("user", copyUser)
    .then(({ data }) => {
      dispatch(addUser(data));
      dispatch(fetchSuccess());
      dispatch(fetchCompanySuccess("success"));
      sendLogService.sendUserLog(copyUser, data);
      if (callbackFunc) callbackFunc();
    })
    .catch(error => {
      let errorData = error.response.data.split(`"`);
      dispatch(fetchError());
      Swal.fire({
        title: "Error",
        text: JSON.parse(error.response.data).hasOwnProperty("email")
          ? `${errorData[3]} `
          : "Something went wrong!",
        icon: "error",
        confirmButtonText: "Ok"
      });
      sendLogService.errorlog(error, callbackFunc);
    });
};

export const editAdminUserService = (user, callbackFunc) => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("user", user)
    .then(({ data }) => {
      dispatch(updateUser(data));
      dispatch(fetchSuccess());
      dispatch(fetchCompanySuccess("success"));
      sendLogService.sendUserLog(user, data);
      if (callbackFunc) callbackFunc();
    })
    .catch(error => {
      dispatch(fetchError());
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok"
      });
      sendLogService.errorlog(error, callbackFunc);
    });
};

export const deleteAdminUserService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`user/${data.id}`)
    .then(res => {
      dispatch(deleteUser(res));
      Swal.fire({
        title: "Success",
        text: "User deleted successfully",
        icon: "success",
        confirmButtonText: "Ok"
      });
      dispatch(fetchSuccess());
      sendLogService.sendUserLog(data, res);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok"
      });
      sendLogService.errorlog(e, data);
    });
};
