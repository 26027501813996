import React, { useEffect, lazy, Suspense, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { CircularProgress } from "@material-ui/core";
import { getHotelService } from "services/hotels";
import { getContractService } from "services/contract";
import { useDispatch, useSelector } from "react-redux";
import { getStopSaleService } from "services/stopSale";
import { getSeasonService } from "services/season";
import { getCurrenciesService } from "services/currency";
import { getRoomService } from "services/rooms";
import { getOperatorService } from "services/operator";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Loader from "@jumbo/components/Common/Loader";

const StopSale = lazy(() => import("./StopSale"));
const OpenSale = lazy(() => import("./OpenSale"));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  useEffect(async () => {
    await dispatch(
      getContractService(
        authUser.authority_level,
        localStorage.getItem("selectedCompany"),
        null,
        null,
        authUser.id
      )
    );
    await dispatch(getHotelService());
    await dispatch(getSeasonService());
    await dispatch(getCurrenciesService());
    await dispatch(getRoomService());
    await dispatch(getOperatorService());
    await dispatch(getStopSaleService());
  }, [dispatch]);

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
export default function OpenStopSale() {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [value, setValue] = useState(0);
  const [navbarHotel, setNavbarHotel] = useState([]);

  const { hotels } = useSelector(({ hotels }) => hotels);
  const { contracts } = useSelector(({ contract }) => contract);

  //Navbar hotels => Hotels which belongs to onsale contracts
  useEffect(() => {
    let onsaleContracts = Object.values(contracts).filter(
      item => item.contract_statuses_id == 4
    );
    let onSaleHotels = [];
    Object.values(hotels)
      .filter(hotel => onsaleContracts.some(osc => osc.hotel_id === hotel.id))
      .map(elem => onSaleHotels.push({ id: elem.id, label: elem.name }));
    setNavbarHotel(onSaleHotels);
  }, [hotels]);

  return (
    <Paper>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab label={<IntlMessages id="stop.sale" />} />
        <Tab label={<IntlMessages id="open.sale" />} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Suspense fallback={<Loader />}>
          <StopSale navbarHotel={navbarHotel} />
        </Suspense>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Suspense fallback={<Loader />}>
          <OpenSale navbarHotel={navbarHotel} />
        </Suspense>
      </TabPanel>
    </Paper>
  );
}
