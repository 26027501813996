import {
  GET_TERMS_CONDITIONS,
  ADD_TERMS_CONDITIONS,
  UPDATE_TERMS_CONDITIONS,
  DELETE_TERMS_CONDITIONS,
  GET_CONTRACT_TERMS_CONDITIONS,
  GET_ALLCONTRACT_TERMS_CONDITIONS,
  ADD_CONTRACT_TERMS_CONDITIONS,
  UPDATE_CONTRACT_TERMS_CONDITIONS,
  DELETE_CONTRACT_TERMS_CONDITIONS,
} from './types';

export const getTermsConditions = res => dispatch => {
  dispatch({
    type: GET_TERMS_CONDITIONS,
    payload: res.data.data,
  });
};

export const addTermsConditions = res => dispatch =>
  dispatch({
    type: ADD_TERMS_CONDITIONS,
    payload: res.data.data,
  });

export const updateTermsConditions = res => dispatch =>
  dispatch({
    type: UPDATE_TERMS_CONDITIONS,
    payload: res.data.data,
  });

export const deleteTermsConditions = res => dispatch =>
  dispatch({
    type: DELETE_TERMS_CONDITIONS,
    payload: res.data.data,
  });

export const getContractTermsConditions = res => dispatch => {
  dispatch({
    type: GET_CONTRACT_TERMS_CONDITIONS,
    payload: res.data.data,
  });
};

export const getAllContractTermsConditions = res => dispatch => {
  dispatch({
    type: GET_ALLCONTRACT_TERMS_CONDITIONS,
    payload: res.data.data,
  });
};

export const addContractTermsConditions = res => dispatch =>
  dispatch({
    type: ADD_CONTRACT_TERMS_CONDITIONS,
    payload: res.data.data,
  });

export const updateContractTermsConditions = res => dispatch =>
  dispatch({
    type: UPDATE_CONTRACT_TERMS_CONDITIONS,
    payload: res.data.data,
  });

export const deleteContractTermsConditions = res => dispatch =>
  dispatch({
    type: DELETE_CONTRACT_TERMS_CONDITIONS,
    payload: res.data.data,
  });
