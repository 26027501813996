import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardFooter from '@coremat/CmtCard/CmtCardFooter';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import { Box, Button, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

function LogCard({ log, index, page }) {
  return (
    <CmtCard>
      <CmtCardHeader
        title={log.service_type + ' ' + Number(index + 1 + (page - 1) * 20)}
        subTitle={
          log?.user?.fullname == undefined
            ? moment(log.process_date).format('DD/MM/YYYY hh:mm:ss')
            : log.user.fullname + ' , ' + moment(log.process_date).format('DD/MM/YYYY hh:mm:ss')
        }
      />
      <CmtCardContent>
        <Typography variant="h2" color="textSecondary" component="p">
          {log.data.message}
        </Typography>
      </CmtCardContent>
    </CmtCard>
  );
}

export default LogCard;
