import React, { useEffect, useState } from "react";
import { getOperatorService } from "services/operator";
import { getHotelService } from "services/hotels";
import { getContractService } from "services/contract";
import { useDispatch, useSelector } from "react-redux";
import { getRoomService } from "services/rooms";
import { getBoardsService } from "services/boards";
import { getOnSaleContractService } from "services/onSaleContract";
import { getReservationsService } from "services/reservation";
import { getMarketService } from "services/market";
import { getCurrenciesService } from "services/currency";
import ShowReservation from "./ShowReservation";
import { addShowReservationsLoaded } from "redux/actions/hasLoaded";
const ShowReservationLoader = () => {
  const dispatch = useDispatch();
  const [isFinished, setIsFinished] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);
  const hasLoaded = useSelector(state => state?.hasLoaded.hasLoaded); //prettier-ignore
  useEffect(() => {
    const firstLoader = async () => {
      await Promise.all([
        dispatch(getOnSaleContractService()),
        dispatch(
          getContractService(
            authUser.authority_level,
            localStorage.getItem("selectedCompany"),
            null,
            null,
            authUser.id
          )
        ),
        dispatch(getOperatorService()),
        dispatch(getHotelService()),
        dispatch(getBoardsService()),
        dispatch(getRoomService()),
        dispatch(getMarketService()),
        dispatch(getReservationsService()),
        dispatch(getCurrenciesService())
      ]);
    };
    if (hasLoaded.showReservations == false && hasLoaded.dashboard == false) {
      firstLoader()
        .then(() => {
          dispatch(addShowReservationsLoaded(true));
          setIsFinished(true);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setIsFinished(true);
    }
  }, []);

  return <React.Fragment>{isFinished && <ShowReservation />}</React.Fragment>;
};

export default ShowReservationLoader;
