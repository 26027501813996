import { normalize, schema } from 'normalizr';

const officeState = new schema.Entity('offices');

const pivot_office = new schema.Entity(
  'offices',
  {},
  {
    processStrategy: (value, parent, key) => {
      let deleteUser = value.user.filter(element => element !== parent.id);
      return { ...value, user: deleteUser };
    },
  },
);

const deleteUser = new schema.Entity('users', {
  pivot_office: [pivot_office],
});

const response = {
  deleteUser: [deleteUser],
  officeState: [officeState],
};

export const userDeleteNormalizr = data => normalize(data, [response]);
