import { getStarRates } from 'redux/actions/starRate';
import axios from './axios';
import { fetchStart, fetchError, fetchSuccess } from '../redux/actions/Common';

export const getStarRatesService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('starRate')
    .then(res => {
      dispatch(getStarRates(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
