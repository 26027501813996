import { addBoard, deleteBoard, getBoards, updateBoard } from 'redux/actions/boards';
import axios from './axios';
import { fetchStart, fetchError, fetchSuccess } from '../redux/actions/Common';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
//import { intl } from 'modules/core/IntlGlobalProvider';
import { FormattedMessage } from 'react-intl';
import { formatMessage } from 'devextreme/localization';

/**PROXOLAB LOGGER **/
//const intlProvider = new IntlProvider({ locale, messages });
//const { intl } = intlProvider.getChildContext();
const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};

export const getBoardsService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('board')
    .then(res => {
      dispatch(getBoards(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addBoardsService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('board', data)
    .then(res => {
      dispatch(addBoard(res));
      dispatch(fetchSuccess());
      sendLogService.sendBoardLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateBoardsService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());

  return axios
    .put('board', data)
    .then(res => {
      dispatch(updateBoard(res));
      dispatch(fetchSuccess());
      sendLogService.sendBoardLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteBoardsService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`board/${id}`)
    .then(res => {
      dispatch(deleteBoard(res));
      dispatch(fetchSuccess());
      sendLogService.sendBoardLog(res, id);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
