import { normalize, schema } from 'normalizr';

const eb_offer = new schema.Entity('eb_offer');
const rolling_eb_offer = new schema.Entity('rolling_eb_offer');
const free_night_offer = new schema.Entity('free_night_offer');
const long_stay_offer = new schema.Entity('long_stay_offer');
const turbo_eb_offer = new schema.Entity('turbo_eb_offer');
const upgrade_offer = new schema.Entity('upgrade_offer');
const special_discount_offer = new schema.Entity('special_discount_offer');
const special_rate_offer = new schema.Entity('special_rate_offer');
const senior_reduction_offer = new schema.Entity('senior_reduction_offer');
const honeymoon_reduction_offer = new schema.Entity('honeymoon_reduction_offer');
const expedient_reduction_offer = new schema.Entity('expedient_reduction_offer');

const get_board = new schema.Entity('get_board');
const selectedBoard = new schema.Entity('boards', { get_board: [get_board] });

const get_operator = new schema.Entity('get_operator');
const selectedOperators = new schema.Entity('operators', { get_operator: [get_operator] });

const get_rooms = new schema.Entity('get_room');
const selectedRoom = new schema.Entity('selectedRooms', {
  get_room: [get_rooms],
});

const upgradeSelectedRoom = new schema.Entity('upgradeSelectedRoom', { get_room: [get_rooms] });
const upgradeSelectedBoard = new schema.Entity('upgradeSelectedBoard', { get_board: [get_board] });

const offers = new schema.Entity(
  'offers',
  {
    eb_offer: [eb_offer],
    rolling_eb_offer: [rolling_eb_offer],
    free_night_offer: [free_night_offer],
    long_stay_offer: [long_stay_offer],
    turbo_eb_offer: [turbo_eb_offer],
    upgrade_offer: [upgrade_offer],
    special_discount_offer: [special_discount_offer],
    special_rate_offer: [special_rate_offer],
    senior_reduction_offer: [senior_reduction_offer],
    honeymoon_reduction_offer: [honeymoon_reduction_offer],
    expedient_reduction_offer: [expedient_reduction_offer],
    selectedBoard: [selectedBoard],
    selectedRoom: [selectedRoom],
    selectedOperator: [selectedOperators],
    upgradeSelectedRoom: [upgradeSelectedRoom],
    upgradeSelectedBoard: [upgradeSelectedBoard],
  },
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityB };
    },
  },
);

//UPDATE
const update_eb_offer = new schema.Entity(
  'eb_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);
const update_rolling_eb_offer = new schema.Entity(
  'rolling_eb_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);
const update_free_night_offer = new schema.Entity(
  'free_night_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);
const update_long_stay_offer = new schema.Entity(
  'long_stay_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);
const update_turbo_eb_offer = new schema.Entity(
  'turbo_eb_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);
const update_upgrade_offer = new schema.Entity(
  'upgrade_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);
const update_special_discount_offer = new schema.Entity(
  'special_discount_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);
const update_special_rate_offer = new schema.Entity(
  'special_rate_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);
const update_senior_reduction_offer = new schema.Entity(
  'senior_reduction_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);
const update_honeymoon_reduction_offer = new schema.Entity(
  'honeymoon_reduction_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);
const update_expedient_reduction_offer = new schema.Entity(
  'expedient_reduction_offer',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);

const update_get_operator = new schema.Entity(
  'get_operator',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
      };
    },
  },
);
const update_selectedOperator = new schema.Entity('operators', { get_operator: [update_get_operator] });

const update_get_board = new schema.Entity(
  'get_board',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
      };
    },
  },
);
const update_selectedBoard = new schema.Entity('boards', { get_board: [update_get_board] });
const update_get_rooms = new schema.Entity(
  'get_room',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
      };
    },
  },
);
const update_selectedRoom = new schema.Entity('selectedRooms', {
  get_room: [update_get_rooms],
});

const updateUpgradeSelectedRoom = new schema.Entity(
  'upgradeSelectedRoom',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
      };
    },
  },
);
const updateUpgradeSelectedBoard = new schema.Entity(
  'upgradeSelectedBoard',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
      };
    },
  },
);

const update_offers = new schema.Entity(
  'offers',
  {
    eb_offer: [update_eb_offer],
    rolling_eb_offer: [update_rolling_eb_offer],
    free_night_offer: [update_free_night_offer],
    long_stay_offer: [update_long_stay_offer],
    turbo_eb_offer: [update_turbo_eb_offer],
    upgrade_offer: [update_upgrade_offer],
    special_discount_offer: [update_special_discount_offer],
    special_rate_offer: [update_special_rate_offer],
    senior_reduction_offer: [update_senior_reduction_offer],
    honeymoon_reduction_offer: [update_honeymoon_reduction_offer],
    expedient_reduction_offer: [update_expedient_reduction_offer],
    selectedBoard: [update_selectedBoard],
    selectedRoom: [update_selectedRoom],
    selectedOperator: [update_selectedOperator],
    upgradeSelectedRoom: [updateUpgradeSelectedRoom],
    upgradeSelectedBoard: [updateUpgradeSelectedBoard],
  },
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
        eb_offer: entityA.eb_offer,
        selectedBoard: entityA.selectedBoard,
        selectedRoom: entityA.selectedRoom,
        selectedOperator: entityA.selectedOperator,
        upgradeSelectedRoom: entityA.upgradeSelectedRoom,
        upgradeSelectedBoard: entityA.upgradeSelectedBoard,
      };
    },
  },
);

const response = {
  early_booking_offers: [offers],
  rolling_early_booking_offers: [offers],
  free_night_offers: [offers],
  long_stay_offers: [offers],
  turbo_early_booking_offers: [offers],
  upgrade_offers: [offers],
  special_discount_offers: [offers],
  special_rate_offers: [offers],
  senior_reduction_offers: [offers],
  honeymoon_reduction_offers: [offers],
  expedient_reduction_offers: [offers],
  stateBoards: [get_board],
  stateSelectedBoard: [selectedBoard],
  stateOffers: [offers],
  stateRooms: [get_rooms],
  stateSelectedOperators: [selectedOperators],
  stateOperators: [get_operator],
  stateSelectedRooms: [selectedRoom],
  upgradeSelectedRoom: [upgradeSelectedRoom],
  upgradeSelectedBoard: [upgradeSelectedBoard],
  stateearly_booking_offers: [eb_offer],
  staterolling_early_booking_offers: [rolling_eb_offer],
  statefree_night_offers: [free_night_offer],
  statelong_stay_offers: [long_stay_offer],
  stateturbo_early_booking_offers: [turbo_eb_offer],
  stateupgrade_offers: [upgrade_offer],
  statespecial_discount_offers: [special_discount_offer],
  statespecial_rate_offers: [special_rate_offer],
  statesenior_reduction_offers: [senior_reduction_offer],
  statehoneymoon_reduction_offers: [honeymoon_reduction_offer],
  stateexpedient_reduction_offers: [expedient_reduction_offer],
  changeStatusTheOffer: [offers],

  //UPDATE
  updateStateSelectedOperator: [update_selectedOperator],
  updateStateBoards: [update_get_board],
  updateStateSelectedBoard: [update_selectedBoard],
  updateStateOffers: [update_offers],
  updateStateRooms: [update_get_rooms],
  updateStateSelectedRooms: [update_selectedRoom],
  updateStateearly_booking_offers: [update_eb_offer],
  updateStaterolling_early_booking_offers: [update_rolling_eb_offer],
  updateStatefree_night_offers: [update_free_night_offer],
  updateStatelong_stay_offers: [update_long_stay_offer],
  updateStateturbo_early_booking_offers: [update_turbo_eb_offer],
  updateStateupgrade_offers: [update_upgrade_offer],
  updateStatespecial_discount_offers: [update_special_discount_offer],
  updateStatespecial_rate_offers: [update_special_rate_offer],
  updateStatesenior_reduction_offers: [update_senior_reduction_offer],
  updateStatehoneymoon_reduction_offers: [update_honeymoon_reduction_offer],
  updateStateexpedient_reduction_offers: [update_expedient_reduction_offer],
  upgradeSelectedRoom: [updateUpgradeSelectedRoom],
  upgradeSelectedBoard: [updateUpgradeSelectedBoard],
  updateChangeStatusTheOffer: [offers],
};
export const normalizrCrud = data => normalize(data, [response]);
