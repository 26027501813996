import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getPermissionsByAuthUser } from '../redux/actions/Permission';
import axios from './axios';

export const getPermissionsByAuthUserService = () => dispatch => {
  dispatch(fetchStart());
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .get(`permissions/authUser`)
    .then(({ data }) => {
      dispatch(getPermissionsByAuthUser(data.permissions));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
