import { GET_SEASONS, ADD_SEASONS, UPDATE_SEASONS, DELETE_SEASONS } from '../actions/types';
import { normalizeSeasons } from 'redux/schemas/season';

const initialState = {
  seasons: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEASONS:
      const data = [];
      data['seasons'] = action.payload;
      const normalizedSeason = normalizeSeasons([data]);
      return {
        ...state,
        seasons: normalizedSeason.entities.seasons ? normalizedSeason.entities.seasons : [],
      };
    case ADD_SEASONS:
      const addData = [];
      addData['seasons'] = [action.payload];
      addData['stateSeasons'] = state.seasons;
      const addNormalizedSeason = normalizeSeasons([addData]);
      return {
        ...state,
        seasons: addNormalizedSeason.entities.seasons,
      };
    case UPDATE_SEASONS:
      const updateData = [];
      updateData['updatedSeason'] = [action.payload];
      updateData['stateSeasons'] = state.seasons;
      const updateNormalizedSeason = normalizeSeasons([updateData]);
      return {
        ...state,
        seasons: updateNormalizedSeason.entities.seasons,
      };
    case DELETE_SEASONS:
      const deletedSeason = Object.keys(state.seasons)
        .filter(id => Number(id) !== action.payload.id)
        .reduce((obj, key) => {
          obj[key] = state.seasons[key];
          return obj;
        }, {});

      return {
        ...state,
        seasons: deletedSeason,
      };
    default:
      return state;
  }
};
