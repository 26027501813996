import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { useDispatch, useSelector } from 'react-redux';
import { getMasterCompaniesService } from 'services/company';
import { getMasterUsersService, deleteMasterUserService } from 'services/user';
import { setCurrentMasterUser } from 'redux/actions/User';
import UserListRow from './UserListRow';
import UserTableHead from './UserTableHead';
import UserTableToolbar from './UserTableToolbar';
import { getComparator, stableSort } from '../../../utils/tableHelper';
import AddEditUser from './AddEditUser';
import ConfirmDialog from '../../Common/ConfirmDialog';
import useStyles from './index.style';
import UserDetailView from './UserDetailView';
import NoRecordFound from './NoRecordFound';

const UsersModule = () => {
  const classes = useStyles();
  const { masterUsers } = useSelector(({ user }) => user);
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [usersFetched, setUsersFetched] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMasterCompaniesService());
    dispatch(
      getMasterUsersService(() => {
        setUsersFetched(true);
      }),
    );
  }, [dispatch]);
  useEffect(() => {
    setFilteredUsers(
      masterUsers.filter(
        user =>
          user?.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user?.email.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, masterUsers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    dispatch(setCurrentMasterUser(user));
    setOpenViewDialog(true);
  };

  const handleUserEdit = user => {
    dispatch(setCurrentMasterUser(user));
    setOpenUserDialog(true);
  };
  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentMasterUser(null));
  };
  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteMasterUserService(selectedUser));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar onUserAdd={setOpenUserDialog} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
            <UserTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredUsers.length}
            />
            <TableBody>
              {filteredUsers.length ? (
                stableSort(filteredUsers, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <UserListRow
                      key={index}
                      row={row}
                      onUserEdit={handleUserEdit}
                      onUserDelete={() => handleUserDelete(row)}
                      onUserView={handleUserView}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    <NoRecordFound>{usersFetched ? 'There are no records found.' : 'Loading users...'}</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openUserDialog && <AddEditUser open={openUserDialog} onCloseDialog={handleCloseUserDialog} />}
      {/* {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />} */}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete ${selectedUser.name}`}
        content="Are you sure, you want to  delete this user?"
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default UsersModule;
