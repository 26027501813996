import { createSelector } from 'reselect';

export const normalizeRegion = createSelector(
  state => state.destinations.regions,

  regions => {
    return regions;
  },
);

export const normalizeCountry = createSelector(
  state => state.destinations.countries,

  countries => {
    return countries;
  },
);

export const normalizeDivisions = createSelector(
  state => state.destinations.divisions,
  
  divisions => {
    return divisions;
  },
);


export const normalizeCity = createSelector(
  state => state.destinations.cities,

  cities => {
    return cities;
  },
);

export const normalizeSubCity = createSelector(
  state => state.destinations.subCities,

  subCities => {
    return subCities;
  },
);
