import { Typography } from "@material-ui/core";
import React from "react";

import { MultiSelect } from "react-multi-select-component";

export default function index(props) {

  const { label, options, onChange, value, overrideStrings, ...rest } = props;

  const theme = window.localStorage.getItem("theme-type");
  return (
    <React.Fragment>
      <Typography
        variant="h6"
        align="left"
        style={
          ({ zIndex: 1 },
          theme === "dark"
            ? { color: "rgba(255, 255, 255, 0.6)" }
            : { color: "rgba(0, 0, 0, 0.6)" })
        }
      >
        {label}
      </Typography>
      {/* <MultiSelect options={options} value={value} onChange={onChange} /> */}
      <MultiSelect
        className={"mso " + theme}
        options={options}
        value={value}
        onChange={onChange}
        overrideStrings={overrideStrings}
        {...rest}
      />
    </React.Fragment>
  );
}
