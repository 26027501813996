import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import CmtCardFooter from "@coremat/CmtCard/CmtCardFooter";
import GridContainer from "@jumbo/components/GridContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import CardHeader from "@mui/material/CardHeader";
import { Button, Divider, Grid, makeStyles, Box } from "@material-ui/core";
import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservationsService,
  updateReservationService
} from "services/reservation";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ContractSelectionUpdate from "./ContractSelectionUpdate";
import CustomerTabUpdate from "./CustomerTabUpdate";
import HotelDetailsUpdate from "./HotelDetailsUpdate";
import ReservationDetails from "./ReservationDetails";
import RoomUpdate from "./RoomUpdate";
import message from "../MessageStyle/messageStyle";
import { orange } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  orangeButton: {
    backgroundColor: orange[400],
    color: "#ffffff",
    "&:hover": {
      backgroundColor: orange[500]
    }
  },
  blueButton: {
    backgroundColor: "blue",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#410041"
    }
    // you can add your specific CSS here.
  },
  redButton: {
    backgroundColor: "#f44336",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#821811"
    }
  },
  greenButton: {
    backgroundColor: "#4caf50",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#1E6620"
    }
    // you can add your specific CSS here.
  }
}));
const CmtCardStyle = {
  cardheader: {
    paddingLeft: "0px"
  }
};
const dividerStyle = {
  marginBottom: {
    margin: "0px 0px 24px 0"
  },
  margin: {
    margin: "24px 0px 24px 0"
  }
};

const MySwal = withReactContent(Swal);

function ReservationUpdate(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const {
    reservation,
    handleActionClose,
    handleHotelMail,
    handleResponseDialog,
    handleClose,
    type
  } = props;
  const [reservationDetail, setReservationDetail] = useState(reservation);
  const update_name = useSelector(state => state.auth.authUser.fullname);
  const [roomsLength, setRoomsLength] = useState(reservation.rooms.length);
  const [deletedRooms, setDeletedRooms] = useState([]);
  const contracts_selector = useSelector(state => state.contract.contracts);
  const [selectedContract, setSelectedContract] = useState(
    contracts_selector[reservation.contract_id]
  );
  const reservation_initial_rooms = useSelector(state => {
    let selected_rooms = reservation.rooms.map(room => {
      let initial_room = state.reservation.entities.rooms[room];
      let initial_room_customers = initial_room?.customers.map(customer => {
        return state.reservation.entities.customers[customer];
      });
      initial_room = { ...initial_room, customers: initial_room_customers };
      return initial_room;
    });
    return selected_rooms;
  });
  const [reservationRooms, setReservationRooms] = useState(() => {
    let roomsObject = {};
    reservation_initial_rooms.map((room, index) => {
      roomsObject[index + 1] = room;
    });
    return roomsObject;
  });
  const handleChange = e => {
    const { name, value } = e.target;
    // Set values
    if (name === "contract_id") {
      setReservationDetail(prev => ({
        ...prev,
        [name]: Number(value.split("-")[0])
      }));
    } else {
      setReservationDetail(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const roomList = () => {
    const rooms = [];
    for (let i = 0; i < roomsLength; i++) {
      rooms.push(
        <RoomUpdate
          no={i + 1}
          //handleRoomAdd={handleAddRoomReservation}
          //setRoomCount={setRoomCount}
          //setReservationDetail={setReservationDetail}
          //reservationDetail={reservationDetail}
          //setRoomTab={setRoomTab}
          setReservationRooms={setReservationRooms}
          reservationRooms={reservationRooms}
          selectedContract={contracts_selector[reservation.contract_id]}
          setRoomsLength={setRoomsLength}
          setDeletedRooms={setDeletedRooms}
          type={type}
        />
      );
    }
    return rooms;
  };
  const handleSubmit = () => {
    const messageRes = textMessage.current.value;
    let updatedReservation = {
      reservation: reservationDetail,
      rooms: reservationRooms
    };
    let adult_num = 0;
    let child_num = 0;
    let infant_num = 0;
    Object.keys(reservationRooms).forEach(room => {
      adult_num = updatedReservation.rooms[room].adult + adult_num;
      child_num = updatedReservation.rooms[room].child + child_num;
      infant_num = updatedReservation.rooms[room].infant + infant_num;
    });
    updatedReservation.reservation.message = messageRes;
    updatedReservation.reservation.adult_num = adult_num;
    updatedReservation.reservation.child_num = child_num;
    updatedReservation.reservation.infant_num = infant_num;
    updatedReservation.reservation.update_name = update_name;
    updatedReservation.reservation.deleted_rooms = deletedRooms;
    updatedReservation.reservation.lead_name = updatedReservation.rooms[1].customers.find(
      customer => customer.gender == "adult"
    ).name;
    console.log(updatedReservation);
    dispatch(updateReservationService(updatedReservation));

    MySwal.fire({
      title:
        intl.formatMessage({ id: "reservation" }) +
        intl.formatMessage({ id: "updated.successfully" }),
      text:
        intl.formatMessage({ id: "reservation" }) +
        intl.formatMessage({ id: "updated.successfully" }) +
        intl.formatMessage({ id: "please.send.hotel.confirmation.email" }),
      icon: "success",
      confirmButtonText: "OK"
    });
  };

  /**************************** MESSAGE AREA JS SECTION **************************/
  let toggle = false;
  const textMessage = useRef();
  const clear = () => {
    textMessage.current.value = "";
  };
  console.log(reservation);
  /*******************************************************************************/

  return (
    <CmtCard>
      <CmtCardContent>
        <GridContainer>
          <Grid item xs={12}>
            <ReservationDetails
              reservation={reservationDetail}
              setReservationDetail={setReservationDetail}
            />
          </Grid>
          <Grid item xs={12}>
            <HotelDetailsUpdate
              reservation={reservationDetail}
              setReservationDetail={setReservationDetail}
              handleChange={handleChange}
              type={type}
            />
          </Grid>
          <Grid item xs={12}>
            <ContractSelectionUpdate
              reservation={reservationDetail}
              setReservationDetail={setReservationDetail}
              setSelectedContract={setSelectedContract}
              handleChange={handleChange}
              type={type}
            />
          </Grid>
          <Grid item xs={12}>
            <CardHeader
              title={intl.formatMessage({ id: "room.details" })}
              sx={{ color: "#33658a" }}
            />
            <Divider style={dividerStyle.marginBottom} />
            {roomList()}
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center" />
          <Grid item xs={12}>
            <Button
              variant="contained"
              className={classes.orangeButton}
              color="inherit"
              onClick={() => setRoomsLength(roomsLength + 1)}
              disabled={type ? true : false}
            >
              <IntlMessages id="add" />
            </Button>
            <Divider style={dividerStyle.margin} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            {
              <CustomerTabUpdate
                roomLength={roomsLength}
                reservationDetail={reservationDetail}
                //setCustomer={setReservationDetail}
                reservationRooms={reservationRooms}
                setReservationRooms={setReservationRooms}
                selectedContract={contracts_selector[reservation.contract_id]}
                type={type}
              />
            }
          </Grid>

          {/***************************** RESERVATION UPDATE MESSAGE *******************************/}
          <Grid item xs={12}>
            <Box style={message.layout}>
              <CardHeader
                title={intl.formatMessage({ id: "new.reservation.message" })}
                sx={{ color: "#33658a", marginRight: "4rem" }}
              />
              <Divider style={{ marginTop: "1rem" }} />
              <Box style={message.head}>
                <input
                  placeholder={reservation.message}
                  type="text"
                  ref={textMessage}
                  style={message.text}
                />
                <Button onClick={clear} style={message.clear}>
                  Clear
                </Button>
              </Box>
            </Box>
          </Grid>
          {/***************************************************************************************/}
        </GridContainer>
      </CmtCardContent>
      <CmtCardFooter>
        <Grid container justifyContent="space-between">
          <Button
            autoFocus
            color="inherit"
            disabled={type ? true : false}
            onClick={handleHotelMail}
            className={classes.greenButton}
          >
            <IntlMessages id="send.to.hotel" />
          </Button>
          <Button
            autoFocus
            color="inherit"
            onClick={handleResponseDialog}
            disabled={reservation?.hotel_status === 1 || type ? true : false}
            className={classes.greenButton}
          >
            <IntlMessages id="save.hotel.response" />
          </Button>
          <Button
            autoFocus
            color="inherit"
            disabled={type ? true : false}
            onClick={handleClose}
            className={classes.redButton}
          >
            <IntlMessages id="reservation.cancel" />
          </Button>
          <Button
            autoFocus
            color="inherit"
            onClick={handleClose}
            className={classes.redButton}
          >
            {!type ? (
              <IntlMessages id="close.edit" />
            ) : (
              <IntlMessages id="close.view" />
            )}
          </Button>
          <Button
            variant="contained"
            disabled={type ? true : false}
            className={classes.blueButton}
            onClick={() => {
              handleActionClose();
              setTimeout(handleSubmit(), 1000);
            }}
          >
            <IntlMessages id="update" />
          </Button>
        </Grid>
      </CmtCardFooter>
    </CmtCard>
  );
}

export default ReservationUpdate;
