import { normalize, schema } from 'normalizr';

const stateOffice = new schema.Entity(
  'offices',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      let allID = entityB.user;
      allID.push(entityA.user_id);
      return {
        ...entityB,
        user: allID,
      };
    },
  },
);

const pivot_office = new schema.Entity('offices', {}, { idAttribute: 'office_id' });

const statePermision = new schema.Entity(
  'permission',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
        ...entityB,
      };
    },
  },
);

const permission = new schema.Entity('permission');

const get_contract = new schema.Entity('get_contract');

const addedUser = new schema.Entity('users', {
  get_permission: [permission],
  pivot_office: [pivot_office],
  get_contract: [get_contract],
});

const response = {
  addedUser: [addedUser],
  statePermision: [statePermision],
  stateOffice: [stateOffice],
};

export const normalizrUser = data => normalize(data, [response]);
