import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import RoomDetailItem from "./RoomDetailItem";

const RoomSystem = props => {
  const contract_rooms = useSelector(state => state.contract.contract_room);
  const allRoomTypes = useSelector(state => state.rooms.rooms.roomType);
  const [roomDetails, setRoomDetails] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({});
  /* const [currentRoomCode, setRoomCode] = useState() */
  useEffect(() => {
    roomHandler();
  }, [props.roomCount, props.trigger]);

  const roomHandler = () => {
    const temp = [];
    for (let i = 0; i < props.roomCount; i++) {
      temp.push({
        id: new Date().getMilliseconds() + Math.random() * 100,
        room_id: "",
        adult: "",
        child: "",
        infant: ""
      });
    }
    setRoomDetails(roomDetails => [...roomDetails].concat(temp));
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    setRoomDetails(roomDetails =>
      roomDetails.map(detail => {
        if (detail.id === id) {
          return { ...detail, [name]: value };
        }
        return detail;
      })
    );
    if (name === "room_id") {
      setSelectedRoom(rooms.find(room => room.id === e.target.value));
    }
  };

  useEffect(() => {
    roomDetails.map(room => {
      if (
        (room.room_id !== "") &
        (room.adult !== "") &
        (room.child !== "") &
        (room.infant !== "")
      ) {
        props.handleRoomAdd(room.id, room);
      }
    });
  }, [roomDetails]);

  useEffect(() => {
    if (props.selectedContract !== null) {
      var contract_room_types_with_code = [];
      props.selectedContract.contract_room.forEach(room => {
        var newRoom = { ...contract_rooms[room] };
        newRoom["room_code"] = allRoomTypes[newRoom.room_id].code;
        contract_room_types_with_code.push(newRoom);
      });
      setRooms(contract_room_types_with_code);
    } else {
      setRooms(Object.values(allRoomTypes));
    }
  }, [props.selectedContract]);

  const handleDeleteRoom = id => {
    props.handleDeleteReservartionRoom(id);
    setRoomDetails(roomDetails => roomDetails.filter(room => room.id !== id));
  };

  return roomDetails?.map((room, index) => (
    // Aşağıdaki componenti farklı bir dosyaya çek ve gerekli propları gönder. Component içerisinde de seçilen odaya göre min max cart curt girme olaylarını aç
    <RoomDetailItem
      room={room}
      rooms={rooms}
      index={index}
      selectedContract={props.selectedContract}
      selectedRoom={selectedRoom}
      handleDeleteRoom={handleDeleteRoom}
      handleChange={handleChange}
    />
  ));
};

export default RoomSystem;
