const message = {
  layout: {
    fontSize: '1.2rem',
    marginTop: '1rem',
    fontFamily: 'arial',
    color: '#33658a',
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  head: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '1.3rem',
  },
  text: {
    marginTop: '1rem',
    width: '100%',
    height: '3rem',
    resize: 'none',
    borderRadius: '5px',
    outline: 'none',
    padding: 5 + 'px',
    color: 'black',
    fontSize: '15px',
    letterSpacing: '2px',
    border: 'none',
    boxShadow: '0px 0px 2px black',
  },
  clear: {
    marginTop: '10px',
    marginLeft: '20px',
    backgroundColor: '#33658a',
    color: 'white',
  },
};

export default message;
