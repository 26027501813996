import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getOpenSale, addOpenSale } from 'redux/actions/openSale';
import axios from './axios';

/** PROXOLAB LOGGER * */
import sendLogService from './proxolabLogger/Logger.js';
/** PROXOLAB LOGGER * */

export const getOpenSaleService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('opensale')
    .then(res => {
      dispatch(getOpenSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addOpenSaleService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('opensale', data)
    .then(res => {
      dispatch(addOpenSale(res));
      dispatch(fetchSuccess());
      sendLogService.openSaleLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
