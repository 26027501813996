const theme = localStorage.getItem("theme-type");

const searchPrice = {
  boxStyle: {
    width: 100 + "%",
    height: 11 + "rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"

    // "&:hover": {
    //   backgroundColor: "primary.main",
    //   opacity: [0.9, 0.8, 0.7],
    // },
  },
  accordionStlye: {
    width: 100 + "%",
    height: 11 + "rem",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column"
  },
  autoComplete: { width: 300, margin: "5px auto" }
};

export default searchPrice;
