import defaultTheme from '../../../../theme/defaultTheme';
import {
  DRAWER_BREAK_POINT,
  HEADER_TYPE,
  LAYOUT_STYLES,
  LAYOUT_TYPES,
  SIDEBAR_TYPE,
  SIDEBAR_WIDTH,
  THEME_TYPES,
} from '../../../constants/ThemeOptions';

export default {
  theme: defaultTheme,
  defaultLng:
    localStorage.getItem('language') !== null
      ? JSON.parse(localStorage.getItem('language'))
      : {
          languageId: 'english',
          locale: 'en',
          name: 'English',
          icon: 'us',
          format: 'MM/DD/YYYY',
        },

  layout: LAYOUT_TYPES.VERTICAL_MINIMAL,
  layoutType: LAYOUT_STYLES.FULL_WIDTH,
  themeType: localStorage.getItem('theme-type') !== null ? localStorage.getItem('theme-type') : THEME_TYPES.LIGHT,
  drawerBreakPoint: DRAWER_BREAK_POINT.MD,
  headerType: HEADER_TYPE.STATIC,
  sidebarType: SIDEBAR_TYPE.FULL,
  isSidebarFixed: true,
  sidebarWidth: SIDEBAR_WIDTH.SMALL,
  showFooter: false,
};
