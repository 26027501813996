/*eslint-disable */

import { Button, Grid, makeStyles, MenuItem, Select } from '@material-ui/core';
import { TextField } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  greenButton: {
    backgroundColor: '#4caf50',
    color: '#ffffff',
    // you can add your specific CSS here.
  },
}));

//For keyboard date picker
const date = new Date();
const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

function LogSelects({ handleSubmit, filterSelect, setFilterSelect, serviceArray, usersEmail }) {
  const classes = useStyles();

  const handleChange = event => {
    const { name, value } = event.target;
    setFilterSelect({ ...filterSelect, [name]: value });
  };

  const dateControl = () => {
    let dateFlag = true;
    if (filterSelect.start_date == 'Invalid date' || filterSelect.end_date == 'Invalid date') {
      dateFlag = false;
    }
    if (moment(filterSelect.start_date).isAfter(filterSelect.end_date)) {
      dateFlag = false;
    }

    return dateFlag;
  };
  useEffect(() => {}, [filterSelect]);
  return (
    <Grid container xs={12} spacing={2} direction="row" justifyContent="space-around" alignItems="center">
      <Grid item xs={2}>
        <TextField
          labelId="demo-simple-select-label"
          name="log_type"
          select
          value={filterSelect.log_type}
          label="Log Type"
          placeholder="Log Type"
          onChange={handleChange}
          fullWidth>
          <MenuItem value={'all_logs'}>All Logs</MenuItem>
          <MenuItem value={'service_logs'}>Service Logs</MenuItem>
          <MenuItem value={'user_logs'}>User Logs</MenuItem>
          <MenuItem value={'user_with_service'}>User With Service Logs</MenuItem>
        </TextField>
      </Grid>

      {filterSelect.log_type === 'service_logs' || filterSelect.log_type === 'user_with_service' ? (
        <Grid item xs={2}>
          <TextField
            labelId="demo-simple-select-label"
            name="service_type"
            label="Select Service"
            select
            onChange={handleChange}
            fullWidth>
            {serviceArray.map(service => (
              <MenuItem value={service.value}>{service.label}</MenuItem>
            ))}
          </TextField>
        </Grid>
      ) : null}
      {filterSelect.log_type === 'user_logs' || filterSelect.log_type === 'user_with_service' ? (
        <Grid item xs={2}>
          <TextField
            onChange={handleChange}
            select
            labelId="demo-simple-select-label"
            name="user_email"
            label="User Email"
            placeholder={<IntlMessages id="log.type" />}
            fullWidth>
            {usersEmail.map(user => (
              <MenuItem value={user.value}>{user.label}</MenuItem>
            ))}
          </TextField>
        </Grid>
      ) : null}
      <Grid item xs={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            name="start_date"
            format="dd/MM/yyyy"
            autoOk="true"
            value={filterSelect.start_date}
            onChange={date => {
              let event = {
                target: {
                  name: 'start_date',
                  value: moment(date).format('YYYY-MM-DD'),
                },
              };
              handleChange(event);
            }}
            label={<IntlMessages id="start.date" />}
            animateYearScrolling
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            name="end_date"
            format="dd/MM/yyyy"
            autoOk="true"
            value={filterSelect.end_date}
            minDate={filterSelect.start_date ? filterSelect.start_date : today}
            onChange={date => {
              let event = {
                target: {
                  name: 'end_date',
                  value: moment(date).format('YYYY-MM-DD'),
                },
              };
              handleChange(event);
            }}
            label={<IntlMessages id="end.date" />}
            animateYearScrolling
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          className={classes.greenButton}
          disabled={dateControl() ? false : true}
          onClick={() => handleSubmit(filterSelect)}>
          <IntlMessages id="show" />
        </Button>
      </Grid>
    </Grid>
  );
}

export default LogSelects;
