import axios from './axios';
import { fetchStart, fetchError, fetchSuccess } from '../redux/actions/Common';
import { getPeriod, addPeriod, updatePeriod, deletePeriod, periodRowStatus } from '../redux/actions/periods';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);

const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};

export const getPeriodService = contract_id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('period', {
      params: {
        cId: contract_id,
      },
    })
    .then(res => {
      dispatch(getPeriod(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const getReservationPeriod = contract_id => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios.get('period', {
    params: {
      cId: contract_id,
    },
  });
};

export const addPeriodService = (data, contract_id) => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('period', data, {
      params: {
        cId: contract_id,
      },
    })
    .then(res => {
      dispatch(addPeriod(res));
      dispatch(fetchSuccess());
      sendLogService.sendPeriodServiceLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Error');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updatePeriodService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('period', data)
    .then(res => {
      dispatch(updatePeriod(res));
      dispatch(fetchSuccess());
      sendLogService.sendPeriodServiceLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Error');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deletePeriodService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`period/${id}`)
    .then(res => {
      dispatch(deletePeriod(res));
      dispatch(fetchSuccess());
      sendLogService.sendPeriodServiceLog(res, id);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Error');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};

export const periodRowStatusService = statu => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(periodRowStatus(statu));
};
