import React, { useState, useEffect } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountriesService } from "services/destinations";
import {
  addBoardsService,
  deleteBoardsService,
  getBoardsService,
  updateBoardsService
} from "services/boards";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button, MenuItem, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "../Common/Loader";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);

const MySwal = withReactContent(Swal);

const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text
  });
};

export default function NewBoard({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (boards.length > 0) {
      dispatch(getBoardsService());
    }
  }, [dispatch]);
  const [tableData, setTableData] = useState({
    codeLookup: {},
    codeOptions: [],
    nameLookup: {},
    nameOptions: []
  });
  const { boards } = useSelector(({ boards }) => boards);
  const { contract_has_board } = useSelector(({ contract }) => contract);
  const contracts = useSelector(state => state.contract.contracts);

  const hasBoard = board_id => {
    var has = true;
    Object.values(contracts).forEach(contract => {
      contract.contract_has_board.forEach(board => {
        if (boards[contract_has_board[board].board_id].id == board_id) {
          has = false;
        }
      });
    });
    return has;
  };
  useEffect(() => {
    let newTableData = {
      codeLookup: {},
      codeOptions: [],
      nameLookup: {},
      nameOptions: []
    };
    Object.values(boards).map(board => {
      newTableData.codeLookup[board.code] = board.code;
      newTableData.codeOptions.push({ value: board.code, label: board.code });
      newTableData.nameLookup[board.name] = board.name;
      newTableData.nameOptions.push({ value: board.name, label: board.name });
    });
    setTableData(newTableData);
  }, [boards]);

  const tableRef = React.createRef();
  const tableColumns = [
    {
      title: (
        <h4>
          <b>
            <IntlMessages id="code" />
          </b>
        </h4>
      ),
      field: "code",
      lookup: tableData.codeLookup,
      editComponent: props => (
        <TextField
          //select
          // label="Code"
          style={{ width: "100%" }}
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
        >
          {/* {tableData.codeOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))} */}
        </TextField>
        /*  <SingleSelect
          id="code"
          options={tableData.codeOptions}
          color="primary"
          SelectProps={{
            isCreatable: true,
          }}
          onKeyDown={e => handleKeyDown(e)}
          ref={ref => {
            ref = ref;
          }}
          value={props.value}
          placeholder="Code"
          onChange={value => props.onChange(value)}
        /> */
      )
    },
    {
      title: (
        <h4>
          <b>
            <IntlMessages id="name" />
          </b>
        </h4>
      ),
      field: "name",
      lookup: tableData.nameLookup,
      editComponent: props => (
        <TextField
          //select
          // label={<IntlMessages id="name" />}
          value={props.value}
          style={{ width: "100%" }}
          onChange={e => props.onChange(e.target.value)}
        >
          {/* {tableData.nameOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))} */}
        </TextField>
      )
    }
  ];
  return (
    <MaterialTable
      columns={tableColumns}
      data={Object.values(boards)}
      title=""
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        actionsColumnIndex: -1,
        search: false,
        addRowPosition: "first"
      }}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: "add",
              disabled: false,
              position: "toolbar",
              tooltip: "You are not authorized"
            }
      ]}
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: props => {
          return (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == "update") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "add") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          );
        },
        onRowAdd: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        )
      }}
      icons={{
        Add: props => (
          <ThemeProvider theme={theme}>
            <ColorButton
              variant="contained"
              color="backgroundColor"
              startIcon={<AddBoxIcon />}
            >
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Clear: () => <ModeCloseIcon sx={{ color: "red" }} />
      }}
      editable={{
        deleteTooltip: row =>
          isAuthDelete ? "Delete" : "You are not authorized",
        editTooltip: row =>
          isAuthUpdate ? "Update" : "You are not authorized",
        isDeletable: row => (isAuthDelete ? true : false),
        isEditable: row => (isAuthUpdate ? true : false),
        onRowAdd: isAuthAdd
          ? newData =>
              new Promise(resolve => {
                setTimeout(() => {
                  if (newData.code && newData.name) {
                    if (
                      Object.values(boards).filter(
                        board =>
                          board.code === newData.code &&
                          board.name === newData.name
                      ).length == 0
                    ) {
                      dispatch(addBoardsService(newData)).then(res => {
                        if (res == 201) {
                          sweetAlerts(
                            "success",
                            intl.formatMessage({ id: "new.board" }) +
                              " " +
                              intl.formatMessage({ id: "created.successfully" })
                          );
                        }
                      });
                      resolve();
                    } else {
                      MySwal.fire({
                        icon: "error",
                        title: "Oops...",
                        text:
                          intl.formatMessage({ id: "board" }) +
                          intl.formatMessage({ id: "is.already.exists" })
                      });
                      resolve();
                    }
                  } else {
                    MySwal.fire(
                      "Oops...",
                      intl.formatMessage({ id: "please.fill.in.all.fields" }),
                      "error"
                    );
                    resolve();
                  }
                }, 1000);
              })
          : undefined,
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            const otherBoards = Object.values(boards).filter(
              board => board.code !== oldData.code
            );
            setTimeout(() => {
              if (
                oldData.code !== newData.code ||
                oldData.name !== newData.name
              ) {
                if (newData.code && newData.name) {
                  if (
                    otherBoards.filter(
                      board =>
                        board.code === newData.code &&
                        board.name === newData.name
                    ).length == 0
                  ) {
                    dispatch(updateBoardsService(newData)).then(res => {
                      if (res == 201) {
                        sweetAlerts(
                          "success",
                          intl.formatMessage({ id: "board" }) +
                            intl.formatMessage({ id: "updated.successfully" })
                        );
                      }
                    });
                    resolve();
                  } else {
                    MySwal.fire({
                      icon: "error",
                      title: "Oops...",
                      text:
                        intl.formatMessage({ id: "board" }) +
                        intl.formatMessage({ id: "is.already.exists" })
                    });
                    resolve();
                  }
                } else {
                  MySwal.fire(
                    "Oops...",
                    intl.formatMessage({ id: "please.fill.in.all.fields" }),
                    "error"
                  );
                  resolve();
                }
              } else {
                MySwal.fire(
                  "Oops...",
                  intl.formatMessage({ id: "same.name.or.code" }),
                  "error"
                );
                resolve();
              }
            }, 1000);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              if (!contract_has_board[oldData.id]) {
                if (hasBoard(oldData.id)) {
                  dispatch(deleteBoardsService(oldData.id)).then(res => {
                    if (res == 201) {
                      sweetAlerts(
                        "success",
                        intl.formatMessage({ id: "board" }) +
                          intl.formatMessage({ id: "deleted.successfully" })
                      );
                    }
                  });
                } else {
                  MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Board in use!"
                  });
                }
              } else {
                MySwal.fire({
                  icon: "error",
                  title: "Oops...",
                  text:
                    intl.formatMessage({ id: "board" }) +
                    intl.formatMessage({ id: "is.already.exists" })
                });
              }
              resolve();
            }, 1000);
          })
      }}
    />
  );
}
