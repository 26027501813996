import { GET_STOP_SALE, ADD_STOP_SALE } from '../actions/types';

const initialState = {
  stopSale: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STOP_SALE:
      return { ...state, stopSale: action.payload };
    case ADD_STOP_SALE:
      return { ...state, stopSale: [...state.stopSale, action.payload.data] };
    default:
      return state;
  }
};
