import {
  getMarkup,
  addMarkup,
  updateMarkup,
  deleteMarkup,
  addRateRange,
  updateRateRange,
  deleteRateRange,
} from 'redux/actions/markups';
import axios from './axios';
import { fetchStart, fetchError, fetchSuccess } from '../redux/actions/Common';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

export const getMarkupsService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('markup')
    .then(res => {
      dispatch(getMarkup(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addMarkupService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('markup', data)
    .then(res => {
      dispatch(addMarkup(res));
      dispatch(fetchSuccess());
      sendLogService.markupServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateMarkupService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('markup', data)
    .then(res => {
      dispatch(updateMarkup(res));
      dispatch(fetchSuccess());
      sendLogService.markupServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteMarkupService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`markup/${id}`)
    .then(res => {
      dispatch(deleteMarkup(res));
      dispatch(fetchSuccess());
      sendLogService.markupServiceLog(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
// Rate Ranges
export const addRateRangesServices = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('rateRange', data)
    .then(res => {
      dispatch(addRateRange(res));
      dispatch(fetchSuccess());
      sendLogService.markupServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateRateRangesServices = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('rateRange', data)
    .then(res => {
      dispatch(updateRateRange(res));
      dispatch(fetchSuccess());
      sendLogService.markupServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteRateRangesServices = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`rateRange/${id}`)
    .then(res => {
      dispatch(deleteRateRange(res));
      dispatch(fetchSuccess());
      sendLogService.markupServiceLog(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
