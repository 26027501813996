import {
  GET_MARKUP,
  ADD_MARKUP,
  UPDATE_MARKUP,
  DELETE_MARKUP,
  UPDATE_RATE_RANGE,
  DELETE_RATE_RANGE,
  ADD_RATE_RANGE,
} from './types';

export const getMarkup = res => dispatch => {
  dispatch({ type: GET_MARKUP, payload: res.data.data });
};

export const addMarkup = res => dispatch => {
  dispatch({ type: ADD_MARKUP, payload: res.data.data });
};

export const updateMarkup = res => dispatch => {
  // const normalizeData = normalizeTasks(res.data.data);
  dispatch({ type: UPDATE_MARKUP, payload: res.data.data });
};

export const deleteMarkup = res => dispatch => {
  dispatch({ type: DELETE_MARKUP, payload: res.data.data });
};

// rateRanges
export const addRateRange = res => dispatch => {
  dispatch({ type: ADD_RATE_RANGE, payload: res.data.data });
};

export const updateRateRange = res => dispatch => {
  dispatch({ type: UPDATE_RATE_RANGE, payload: res.data.data });
};

export const deleteRateRange = res => dispatch => {
  dispatch({ type: DELETE_RATE_RANGE, payload: res.data.data });
};
