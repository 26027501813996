const { schema } = require('normalizr');

const reservationMergeStrategy = (entityA, entityB) => {
  return {
    ...entityA,
    ...entityB,
    rooms: [...(entityA.rooms || []), ...(entityB.rooms || [])],
    //customers: [...(entityA.customers || []), ...(entityB.customers || [])],
  };
};

const reservationProcessStrategy = (value, parentObj) => {
  switch (parentObj.type) {
    case 'reservation': {
      return { ...value, parents: [parentObj.id] };
    }
    default:
      return value;
  }
};

const reservationSchema = new schema.Entity('reservations', {}, { processStrategy: reservationProcessStrategy });
const roomSchema = new schema.Entity('rooms', {});
const customerSchema = new schema.Entity('customers', {});

reservationSchema.define({
  rooms: [roomSchema],
  customers: [customerSchema],
});

roomSchema.define({
  customers: [customerSchema],
  reservation: [reservationSchema],
});

customerSchema.define({
  rooms: [roomSchema],
  reservation: [reservationSchema],
});

export default [reservationSchema];
