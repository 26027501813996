import { GET_TYPES } from './types';
import { normalizeCincoutType } from 'redux/schemas/cincoutTypes';

export const getTypes = res => dispatch => {
  const normalizedTypes = normalizeCincoutType(res.data.data);

  dispatch({
    type: GET_TYPES,
    payload: normalizedTypes.entities,
  });
};
