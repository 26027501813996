import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Office from './Office';
import Company from './Company';
import Users from './Users';
import Enterprise from './Enterprise';
import UserPermission from './UserPermission';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  nonEnterprise,
  authUserMemo,
  userMemo,
  officeMemo,
  get_destinations,
} from 'redux/selector/companyHasOfficeSelector';
import { useSelector } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  root2: {
    flexGrow: 0.7,
    textAlign: 'center',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}>
      {value == index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default function EnterpriseLogin({
  countryCache,
  divisionCache,
  divisionCityCache,
  cityCache,
  subCityCache,
  setCountryCache,
  setDivisionCache,
  setDivisionCityCache,
  setCityCache,
  setSubCityCache,
}) {
  const classes = useStyles();
  const onlyCompany = useSelector(nonEnterprise);
  const userData = useSelector(userMemo);
  //const offices = useSelector(officeMemo);
  const destinationData = useSelector(get_destinations);
  const { offices } = useSelector(({ companyHasOffice }) => companyHasOffice.entities);

  const [value, setValue] = React.useState(0);
  const [checkedDestination, setCheckedDestination] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CloneProps(props) {
    const { children, ...other } = props;
    return children(other);
  }

  useEffect(() => {
    setCheckedDestination(destinationData);
  }, [destinationData]);

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example">
        <Tab icon={<ApartmentOutlinedIcon />} label={<IntlMessages id="enterprise" />} />

        <Tab icon={<BusinessOutlinedIcon />} label={<IntlMessages id="company" />} />

        <CloneProps>
          {tabProps =>
            onlyCompany.length != 0 ? (
              <Tab {...tabProps} icon={<HomeWorkOutlinedIcon />} label={<IntlMessages id="office" />} />
            ) : (
              <Tooltip title="Firstly, Please Add Company">
                <div className={classes.root2}>
                  <Tab {...tabProps} icon={<HomeWorkOutlinedIcon />} label={<IntlMessages id="office" />} disabled />
                </div>
              </Tooltip>
            )
          }
        </CloneProps>

        <CloneProps>
          {tabProps =>
            userData.length != 0 ? (
              <Tab {...tabProps} icon={<PersonOutlineOutlinedIcon />} label={<IntlMessages id={'user'} />} />
            ) : Object.values(offices)?.length != 0 ? (
              checkedDestination.length != 0 ? (
                <Tab {...tabProps} icon={<PersonOutlineOutlinedIcon />} label={<IntlMessages id={'user'} />} />
              ) : (
                <Tooltip title="Firstly, Define office destination">
                  <div className={classes.root2}>
                    <Tab {...tabProps} icon={<PersonOutlineOutlinedIcon />} label={<IntlMessages id={'user'} />} disabled />
                  </div>
                </Tooltip>
              )
            ) : (
              <Tooltip title="Firstly, Please Add Office">
                <div className={classes.root2}>
                  <Tab {...tabProps} icon={<PersonOutlineOutlinedIcon />} label={<IntlMessages id={'user'} />} disabled />
                </div>
              </Tooltip>
            )
          }
        </CloneProps>

        <CloneProps>
          {tabProps =>
            userData.length != 0 ? (
              <Tab
                {...tabProps}
                icon={<ManageAccountsOutlinedIcon size={21} />}
                label={<IntlMessages id={'user.permission'} />}
              />
            ) : (
              <Tooltip title="Firstly, Please Add User">
                <div className={classes.root2}>
                  <Tab
                    {...tabProps}
                    icon={<ManageAccountsOutlinedIcon size={21} />}
                    label={<IntlMessages id={'user.permission'} />}
                    disabled
                  />
                </div>
              </Tooltip>
            )
          }
        </CloneProps>
      </Tabs>
      <TabPanel value={value} index={0}>
        <Enterprise
          countryCache={countryCache}
          divisionCache={divisionCache}
          divisionCityCache={divisionCityCache}
          cityCache={cityCache}
          subCityCache={subCityCache}
          setCountryCache={setCountryCache}
          setCityCache={setCityCache}
          setDivisionCache={setDivisionCache}
          setDivisionCityCache={setDivisionCityCache}
          setSubCityCache={setSubCityCache}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Company
          companyState={onlyCompany}
          countryCache={countryCache}
          divisionCache={divisionCache}
          divisionCityCache={divisionCityCache}
          cityCache={cityCache}
          subCityCache={subCityCache}
          setCountryCache={setCountryCache}
          setDivisionCache={setDivisionCache}
          setDivisionCityCache={setDivisionCityCache}
          setCityCache={setCityCache}
          setSubCityCache={setSubCityCache}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Office
          countryCache={countryCache}
          divisionCache={divisionCache}
          divisionCityCache={divisionCityCache}
          cityCache={cityCache}
          subCityCache={subCityCache}
          setCountryCache={setCountryCache}
          setDivisionCache={setDivisionCache}
          setDivisionCityCache={setDivisionCityCache}
          setCityCache={setCityCache}
          setSubCityCache={setSubCityCache}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Users />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <UserPermission />
      </TabPanel>
    </Paper>
  );
}
