import React, { useState, useEffect } from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import PublicIcon from "@mui/icons-material/Public";
import WorkIcon from "@mui/icons-material/Work";
import ArticleIcon from "@mui/icons-material/Article";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import ApprovalIcon from "@mui/icons-material/Approval";
import CardHeader from "@mui/material/CardHeader";
import { getAdminCompaniesService } from "services/company";
import EmailIcon from "@mui/icons-material/Email";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IntlMessages from "../../utils/IntlMessages";
import LanguageSwitcher from "../AppLayout/partials/LanguageSwitcher";
import ThemeItem from "../AppLayout/partials/Header/HeaderTheme/ThemeItem";
import { IconButton, Popover, Tooltip, useTheme } from "@material-ui/core";
import PaletteIcon from "@material-ui/icons/Palette";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import clsx from "clsx";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import {
  userMemo,
  authUserMemo,
  loginUser,
  usersObject,
  companyData
} from "redux/selector/companyHasOfficeSelector";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9)
];

/***************** STYLES PROPERTIES ********************/
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiPaper-root": {
      backgroundColor: lighten(theme.palette.background.paper, 0.1)
    }
  },
  heading: {
    fontSize: "medium",
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  cardStyle: {
    height: "5rem",
    marginRight: "5rem"
    //margin: "auto",
    // paddingTop: "2rem",
    //  paddingLeft: "1rem",
  },
  gridStyle: {
    marginTop: "5rem"
  },
  textStyle: {
    fontSize: "medium"
  }
}));
/*************************************************************/

/********** CSS STYLES*******/
const defColor = "#33658a";
const title = {
  color: defColor,
  width: "200%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  letterSpacing: "3px",
  fontWeight: "bolder",
  borderBottom: `1px solid ${defColor}`,
  padding: "1rem"
};
const titleLeft = {
  display: "flex",
  gap: 10
};
const titleText = {
  marginLeft: "1rem"
};
const profileSettings = {
  marginLeft: "76%"
};
const logo = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: ".2rem",
  border: `2px solid ${defColor}`,
  borderRadius: "50%"
};
const info = {
  display: "flex",
  justifyContent: "center",
  alignItems: "left",
  flexDirection: "column",
  marginTop: "1rem",
  width: "100%"
  //border: `2px solid ${defColor}`,
};
const userSection = {
  margin: "2rem"
  //border: `2px solid ${defColor}`,
};
const clientPermission = {
  width: "85%",
  margin: "auto"

  //border: `2px solid ${defColor}`,
};
const userName = {
  color: defColor,
  fontSize: "20px"
};
const itemIcon = {
  color: defColor,
  fontWeight: "bold"
};
const subheader = {
  color: "black",
  letterSpacing: "2px",
  fontSize: "14px"
};
const permissionsTitle = {
  color: defColor,
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  marginLeft: "10px"
};
const permissionsText = {
  marginLeft: "15px",
  fontFamily: "arial"
};

const permissionIcon = {
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  flexDirection: "row",
  color: defColor,
  fontWeight: "bolder"
};

/****************************/

export default function Profile() {
  //IMPORT CLASSESS
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const themed = localStorage.getItem("theme-type");
  //When theme changed reload application
  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, [themed]);
  const classes = useStyles();

  /**********************GET DATA WITH SELECTOR FROM REDUX***************************/
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminCompaniesService());
  }, [dispatch]);

  const { authUser } = useSelector(({ auth }) => auth);
  const { companiesId } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.result
  );
  const { companies } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );

  const permissionStateSelector = useSelector(
    state => state.companyHasOffice.entities.get_permission
  );
  /******************************************************************/

  //STATE AND VARIABLES SECTION

  const [companyName, setCompanyName] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  const userSelectorData = useSelector(usersObject);
  const stateCompany = useSelector(companyData);
  const loginUserData = useSelector(loginUser);
  const officesObject = useSelector(
    state => state.companyHasOffice.entities.offices
  );

  const userLevel = () => {
    if (authUser.authority_level == 10) {
      return "Enterprise";
    } else if (
      authUser.authority_level == 11 ||
      authUser.authority_level == 20
    ) {
      return "Company";
    } else {
      return "Office";
    }
  };
  const getUserCompanies = () => {
    let loginUserCompanies = [];
    if (userSelectorData[loginUserData.id]?.pivot_office && stateCompany) {
      userSelectorData[loginUserData.id].pivot_office.map(officeID => {
        if (!loginUserCompanies.includes(officesObject[officeID].company_id)) {
          loginUserCompanies.push(officesObject[officeID].company_id);
        }
      });
      return loginUserCompanies;
    }
    return loginUserCompanies;
  };
  const loginUserCompanies = getUserCompanies();

  const filterPermissionState = Object.values(permissionStateSelector).filter(
    suppRed => suppRed.user_id == authUser.id
  );
  useEffect(() => {
    let compName = [];

    loginUserCompanies.map((id, index) => {
      if (loginUserCompanies.length == index + 1) {
        if (companies[id].is_enterprise !== 1) {
          compName.push(companies[id].name);
        }
      } else {
        if (companies[id].is_enterprise !== 1) {
          compName.push(companies[id].name + " , ");
        }
      }
    });

    setCompanyName(compName);
  }, [companies, isEmpty]);

  if (companies && isEmpty === true) {
    setIsEmpty(false);
  }

  const routes = [
    {
      id: 1,
      name: <IntlMessages id="accounting" />,
      icon: <WorkIcon />
    },
    {
      id: 2,
      name: <IntlMessages id="contract" />,
      icon: <ArticleIcon />
    },
    {
      id: 3,
      name: <IntlMessages id="product" />,
      icon: <PublicIcon />
    },
    {
      id: 4,
      name: <IntlMessages id="reservation" />,
      icon: <ApprovalIcon />
    },
    {
      id: 5,
      name: <IntlMessages id="yield" />,
      icon: <SwapVerticalCircleIcon />
    },
    {
      id: 6,
      name: <IntlMessages id="sale.management" />,
      icon: <SettingsIcon />
    },
    {
      id: 7,
      name: <IntlMessages id="reports.operations" />,
      icon: <DescriptionIcon />
    }
  ];

  /*************** HANDLECHANGE SECTION****************************/
  let controlPermissionGet = [];
  let controlPermissionPost = [];
  let controlPermissionPut = [];
  let controlPermissionDelete = [];

  filterPermissionState.map(elem => {
    const routesItem = routes.find(route => route.id === elem.route_id);

    if (elem.user_id == authUser.id) {
      //GET PERMİSSION CONTROL
      if (routesItem.id == elem.route_id) {
        if (elem.get == 1) {
          controlPermissionGet[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}>&#x2713;</span>
          );
        } else {
          controlPermissionGet[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}> &#10006;</span>
          );
        }
        //POST PERMİSSİON CONTROL
        if (elem.post == 1) {
          controlPermissionPost[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}>&#x2713;</span>
          );
        } else {
          controlPermissionPost[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}> &#10006;</span>
          );
        }
        //UPDATE PERMİSSİON CONTROLL
        if (elem.put == 1) {
          controlPermissionPut[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}>&#x2713;</span>
          );
        } else {
          controlPermissionPut[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}> &#10006;</span>
          );
        }
        //DELETE PERMİSSİON CONTROL
        if (elem.delete == 1) {
          controlPermissionDelete[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}>&#x2713;</span>
          );
        } else {
          controlPermissionDelete[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}> &#10006;</span>
          );
        }
      }
    }
  });
  /*****************************************************************/

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item style={title}>
          <Grid item style={titleLeft}>
            <AccountCircleIcon style={{ fontSize: "xx-large" }} />
            <h2>
              <IntlMessages id="user.profile" />
            </h2>
          </Grid>
          <Grid item>
            <LanguageSwitcher />
            <Tooltip title="Theme Settings" className={classes.profileSettings}>
              <IconButton
                onClick={onOpenPopOver}
                className={clsx(classes.iconRoot, "Cmt-appIcon")}
              >
                <PaletteIcon />
              </IconButton>
            </Tooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={onClosePopOver}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <CmtCard>
                <CmtCardHeader
                  title="Theme Settings"
                  actionsPos="top-corner"
                  separator={{
                    color: theme.palette.borderColor.dark,
                    borderWidth: 1,
                    borderStyle: "solid"
                  }}
                />
                <CmtCardContent>
                  <ThemeItem />
                  {/* <ThemeColor /> */}
                </CmtCardContent>
              </CmtCard>
            </Popover>
          </Grid>
        </Grid>
      </Grid>

      {/************************ USERNAME - EMAIL ****************************/}
      <div style={info}>
        <div style={userSection}>
          <Grid container spacing={5}>
            <Grid xs={12} item>
              <Card className={classes.cardStyle}>
                <CardHeader
                  title={
                    <div style={userName}>
                      <IntlMessages id="name.surname" />
                    </div>
                  }
                  avatar={
                    <div style={itemIcon}>
                      <BorderColorIcon />
                    </div>
                  }
                  titleTypographyProps={{ variant: "h6" }}
                  subheader={<span style={subheader}>{authUser.fullname}</span>}
                />
              </Card>
            </Grid>
            <Grid xs={12} item>
              <Card className={classes.cardStyle}>
                <CardHeader
                  title={
                    <div style={userName}>
                      <IntlMessages id="email" />
                    </div>
                  }
                  avatar={
                    <div style={itemIcon}>
                      <EmailIcon />
                    </div>
                  }
                  titleTypographyProps={{ variant: "h6" }}
                  subheader={<span style={subheader}>{authUser.email}</span>}
                />
              </Card>
            </Grid>

            <Grid xs={12} item>
              <Card className={classes.cardStyle}>
                <CardHeader
                  title={
                    <div style={userName}>
                      <IntlMessages id="companies" />
                    </div>
                  }
                  avatar={
                    <div style={itemIcon}>
                      <LocationCityIcon />
                    </div>
                  }
                  titleTypographyProps={{ variant: "h6" }}
                  subheader={<span style={subheader}>{companyName}</span>}
                />
              </Card>
            </Grid>

            <Grid xs={12} item>
              <Card className={classes.cardStyle}>
                <CardHeader
                  title={
                    <div style={userName}>
                      <IntlMessages id="authority.level" />
                    </div>
                  }
                  avatar={
                    <div style={itemIcon}>
                      <PersonPinIcon />
                    </div>
                  }
                  titleTypographyProps={{ variant: "h6" }}
                  subheader={<span style={subheader}>{userLevel()}</span>}
                />
              </Card>
            </Grid>
          </Grid>
        </div>
        <div style={clientPermission}>
          <Grid item>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: defColor, fontWeight: "900" }}>
                      {" "}
                      <IntlMessages id="user.permission" />
                    </TableCell>
                    <TableCell style={itemIcon} align="right">
                      <IntlMessages id="view" />
                    </TableCell>
                    <TableCell style={itemIcon} align="right">
                      <IntlMessages id="create" />
                    </TableCell>
                    <TableCell style={itemIcon} align="right">
                      <IntlMessages id="update" />
                    </TableCell>
                    <TableCell style={itemIcon} align="right">
                      <IntlMessages id="delete" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {routes.map(route => (
                    <TableRow
                      width="30%"
                      key={route.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div style={permissionIcon}>
                          {route.icon}
                          &nbsp;
                          <span
                            style={{ color: "black", fontWeight: "lighter" }}
                          >
                            {route.name}
                          </span>
                        </div>
                      </TableCell>

                      <TableCell align="right">
                        {controlPermissionGet[route.id]}
                      </TableCell>
                      <TableCell align="right">
                        {controlPermissionPost[route.id]}
                      </TableCell>
                      <TableCell align="right">
                        {controlPermissionPut[route.id]}
                      </TableCell>
                      <TableCell align="right">
                        {controlPermissionDelete[route.id]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </div>
      </div>
      {/**************************************************************/}
    </Box>
  );
}
