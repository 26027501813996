import { normalizeGiataCodes } from 'redux/schemas/giataCode';
import { GET_GIATA_CODE } from '../actions/types';

const initialState = {
  giataCodes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GIATA_CODE:
      const normalizedGiataCodes = normalizeGiataCodes(action.payload);
      let giataCodes = normalizedGiataCodes.entities.giataCodes
        ? normalizedGiataCodes.entities.giataCodes
        : state.giataCodes;
      return { ...state, giataCodes };
    default:
      return state;
  }
};
