import { GET_CURRENCY } from '../actions/types';

const initialState = {
  currencies: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCY:
      return {
        ...state,
        currencies: action.payload.currencies ? action.payload.currencies : [],
      };

    default:
      return state;
  }
};
