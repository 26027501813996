import { GET_ALLOTMENTUPDATE, ADD_ALLOTMENTUPDATE, UPDATE_ALLOTMENTUPDATE } from '../actions/types';

const initialState = {
  allotmentUpdate: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOTMENTUPDATE:
      return { ...state, allotmentUpdate: action.payload };
    case ADD_ALLOTMENTUPDATE:
      var newAllotmentUpdate = [...state.allotmentUpdate];
      action.payload.forEach(allUpdate => {
        newAllotmentUpdate.push(allUpdate);
      });
      return {
        ...state,
        allotmentUpdate: newAllotmentUpdate,
      };
    case UPDATE_ALLOTMENTUPDATE:
      return {
        ...state,
        allotmentUpdate: [...state.allotmentUpdate, action.payload],
      };
    default:
      return state;
  }
};
