import React, { cloneElement, isValidElement, useContext } from 'react';

import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { Link, List } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SidebarThemeContext from '../../CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import { useSelector } from 'react-redux';
import LayoutContext from '@coremat/CmtLayouts/LayoutContext';

const useStyles = makeStyles(theme => ({
  navMenuItem: {
    padding: '0 16px 0 0',
    position: 'relative',
    /*  '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      paddingLeft: 16,
    }, */
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    color: props => props.sidebarTheme.textColor,
    '&:hover, &:focus': {
      color: props => props.sidebarTheme.textDarkColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textDarkColor,
      },
    },
    '&.active': {
      color: props => props.sidebarTheme.textActiveColor,
      backgroundColor: props => props.sidebarTheme.navActiveBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: props => props.sidebarTheme.textActiveColor,
        },
      },
    },
    // '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
    //   justifyContent: 'center',
    //   padding: 0,
    //   height: 40,
    //   width: 40,
    //   borderRadius: '50%',
    //   marginLeft: 4,
    //   marginRight: 4,
    // },
  },
  navMenuLink2: {
    color: props => props.sidebarTheme.textColor,
    '&:hover, &:focus': {
      color: props => props.sidebarTheme.textDarkColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textDarkColor,
      },
    },
    '&.active': {
      color: props => props.sidebarTheme.textActiveColor,
      backgroundColor: props => props.sidebarTheme.navActiveBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: props => props.sidebarTheme.textActiveColor,
        },
      },
    },
  },
  navText: {
    flex: 1,
    fontSize: 14,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      marginRight: 0,
    },
  },
  justLogout: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    color: props => props.sidebarTheme.textColor,
    '&:hover, &:focus': {
      color: props => props.sidebarTheme.textDarkColor,
      backgroundColor: props => props.sidebarTheme.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textDarkColor,
      },
    },
  },
  justLogout2: {
    color: props => props.sidebarTheme.textColor,
    '&:hover, &:focus': {
      color: props => props.sidebarTheme.textDarkColor,
      backgroundColor: props => props.sidebarTheme.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textDarkColor,
      },
    },
  },
}));

const NavMenuItem = props => {
  const history = useHistory();
  let { name, icon, link, onClickFunc } = props;
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  let isAuth = false;

  const { routes } = useSelector(({ routes }) => routes);
  let route_id = 0;
  if (routes && routes.length > 0) {
    route_id = routes.findIndex(i => link.startsWith('/' + i.route_name));
  }

  if (link.startsWith('/salemanagement') || link.startsWith('/definitions')) {
    //product routenın idsi statik olarak verildi
    isAuth = permissionsByAuthUser.some(permission => permission.route_id == 3 && permission.get === 1);
  } else {
    isAuth = permissionsByAuthUser.some(permission => routes[route_id]?.id == permission.route_id && permission.get === 1);
  }

  if (
    link === '/admin' ||
    link == '/dashboard' ||
    link == '/operatorcredential' ||
    link == '/master/user' ||
    link == '/master/company' ||
    link == '/roommatch' ||
    link == '/hotelmatch' ||
    link == '/master/logger' ||
    link == '/hotelReport' ||
    link == '/whatsappsupport' ||
    link == '/sendmail' ||
    link == '/sss' ||
    link == '/profile' ||
    link == '/logout' ||
    link == '/master/logout' ||
    link == '/reservation/searchprice' ||
    link == '/reports/reservationReport' ||
    link == '/reports/contractReport' ||
    link == '/reports/hotelReport'
  ) {
    isAuth = true;
  }

  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const { isSidebarOpen } = useContext(LayoutContext);

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  return (
    <List component="div" className={clsx(classes.navMenuItem, 'Cmt-nav-menu-item')}>
      {isAuth ? (
        isSidebarOpen ? (
          <NavLink
            className={
              link.endsWith('/logout')
                ? clsx(classes.justLogout, 'Cmt-nav-menu-link')
                : clsx(classes.navMenuLink, 'Cmt-nav-menu-link')
            }
            to={link.endsWith('/logout') ? history.location.pathname : link} //link '/logout' ise o an için bulunduğu path her ne ise o konuluyor bu sayede gereksiz bir yönlendirme yapılmıyor
            onClick={onClickFunc && (() => onClickFunc())}
            /* style={
              link == '/logout'
                ? { background: '#FFF', color: '#rgb(0 0 0 / 60%)' } //color eziliyor bak sonra
                : null
            } */
          >
            {/* Display an icon if any */}
            {renderIcon()}
            <span className={clsx(classes.navText, 'Cmt-nav-text')} style={{ display: isSidebarOpen ? 'block' : 'none' }}>
              {name}
            </span>
          </NavLink>
        ) : (
          <NavLink
            to={link.endsWith('/logout') ? history.location.pathname : link}
            onClick={onClickFunc && (() => onClickFunc())}
            className={
              link.endsWith('/logout')
                ? clsx(classes.justLogout2, 'Cmt-nav-menu-link')
                : clsx(classes.navMenuLink2, 'Cmt-nav-menu-link')
            }>
            <div className={clsx(classes.navMenuLink, 'Cmt-nav-menu-link')}>{renderIcon()}</div>
          </NavLink>
        )
      ) : (
        <Link className={clsx(classes.navMenuLink, 'Cmt-nav-menu-link')}>
          <span className={clsx(classes.navText, 'Cmt-nav-text')} style={{ display: isSidebarOpen ? 'block' : 'none' }}>
            {name}
          </span>
        </Link>
      )}
    </List>
  );
};

export default NavMenuItem;
