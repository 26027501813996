import GridContainer from '@jumbo/components/GridContainer';
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CardHeader from '@mui/material/CardHeader';
import IntlMessages from '@jumbo/utils/IntlMessages';

function HotelDetailsUpdate(props) {
  const { reservation, setReservationDetail, handleChange, type } = props;
  const operators = useSelector(state => state.operators.operators);
  const hotels = useSelector(state => state.hotels.hotels);
  const CmtCardStyle = {
    cardheader: {
      paddingLeft: '0px',
    },
  };
  const dividerStyle = {
    marginBottom: {
      margin: '0px 0px 24px 0',
    },
    margin: {
      margin: '34px 0px 24px 0',
    },
  };
  const datePick = (name, date) => {
    var e = {
      target: {
        name: name,
        value: date,
        // .toLocaleDateString()
        // .split('.')
        // .reverse()
        // .join('-'),
      },
    };
    handleChange(e);
  };
  return (
    <GridContainer justifyContent="space-between">
      <Grid item xs={12}>
        <CardHeader title={<IntlMessages id="hotel.details.and.dates" />} sx={{ color: '#33658a' }} />
        <Divider style={dividerStyle.marginBottom} />
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            <IntlMessages id="operator" />
          </InputLabel>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            labelId="demo-simple-select-label"
            name="operator_id"
            value={reservation.operator_id}
            label="Operator Name"
            onChange={handleChange}
            readOnly={type ? true : false}>
            {Object.values(operators).map(operator => (
              <MenuItem value={Number(operator.id)}>{operator.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            <IntlMessages id="hotel" />
          </InputLabel>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            labelId="demo-simple-select-label"
            name="hotel_id"
            value={reservation.hotel_id}
            label="Hotel Name"
            onChange={handleChange}
            readOnly={type ? true : false}>
            {Object.values(hotels).map(hotel => (
              <MenuItem value={hotel.id}>{hotel.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          name="selling_date"
          // format={localStorage.getItem('format')}
          // format={'MM/DD/YYYY'}
          autoOk="true"
          // value={reservation.selling_date}
          // onChange={date => {
          //   datePick('selling_date', date._d);
          // }}
          format="DD/MM/YYYY"
          value={moment(reservation.selling_date).format('YYYY-MM-DD')}
          onChange={date => {
            datePick('selling_date', moment(date._d).format('YYYY-MM-DD'));
          }}
          label={<IntlMessages id="reservation.create.date" />}
          animateYearScrolling
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
          }}
          readOnly={true}
        />
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          name="start_date"
          disablePast
          minDate={reservation.selling_date}
          maxDate={reservation.end_date}
          // format={localStorage.getItem('format')}
          // format={'MM/DD/YYYY'}
          autoOk="true"
          // value={reservation.start_date}
          // onChange={date => {
          //   datePick('start_date', date._d);
          // }}
          format="DD/MM/YYYY"
          value={moment(reservation.start_date).format('YYYY-MM-DD')}
          onChange={date => {
            datePick('start_date', moment(date._d).format('YYYY-MM-DD'));
          }}
          label={<IntlMessages id="check.in.date" />}
          animateYearScrolling
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: type ? true : false,
          }}
          readOnly={type ? true : false}
        />
        {/* <KeyboardDatePicker
            name="start_date"
            // format={localStorage.getItem('format')}
             format={'MM/DD/YYYY'}
            label="Res. Start Date"
            value={props.reservationDetail.start_date}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={date => {
  
              datePick('start_date', date._d.toDateString());
            }}
          /> */}
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          name="end_date"
          disablePast
          minDate={reservation.start_date}
          // format={localStorage.getItem('format')}
          // format={'MM/DD/YYYY'}
          autoOk="true"
          // value={reservation.end_date}
          // onChange={date => {
          //   datePick('end_date', date._d);
          // }}
          format="DD/MM/YYYY"
          value={moment(reservation.end_date).format('YYYY-MM-DD')}
          onChange={date => {
            datePick('end_date', moment(date._d).format('YYYY-MM-DD'));
          }}
          label={<IntlMessages id="check.out.date" />}
          animateYearScrolling
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: type ? true : false,
          }}
          readOnly={type ? true : false}
        />
      </Grid>
    </GridContainer>
  );
}

export default HotelDetailsUpdate;
