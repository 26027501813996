import { GET_PRODUCT_TYPES, ADD_PRODUCT_TYPE, UPDATE_PRODUCT_TYPE, DELETE_PRODUCT_TYPE } from './types';

export const getProductTypes = res => dispatch => {
  dispatch({
    type: GET_PRODUCT_TYPES,
    payload: res.data.data,
  });
};

export const addProductType = res => dispatch => dispatch({
  type: ADD_PRODUCT_TYPE,
  payload: res.data.data,
});

export const updateProductType = res => dispatch => dispatch({
  type: UPDATE_PRODUCT_TYPE,
  payload: res.data.data,
});

export const deleteProductType = res => dispatch => dispatch({
  type: DELETE_PRODUCT_TYPE,
  payload: res.data.data,
});
