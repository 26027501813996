import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router";
import Login from "routes/Auth/Login";
import { useDispatch, useSelector } from "react-redux";
import Main from "./Main";
import CompanyList from "./CompanyList";
import Logger from "./Logger";
import { AuhMethods } from "services/auth";
import { CurrentAuthMethod } from "@jumbo/constants/AppConstants";

import ProxoLogger from "./ProxoLogger/index";

const Master = ({ match }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  const dispatch = useDispatch();

  const onLogoutClick = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    return <Redirect from="master/logout" to="/master/login" />;
  };
  if (
    location.pathname.startsWith("/master") &&
    location.pathname !== "/master/login" &&
    (!authUser || authUser.authority_level !== 0)
  ) {
    return <Redirect to="/master/login" />;
  }
  if (
    authUser &&
    authUser.authority_level === 0 &&
    location.pathname === "/master/login"
  ) {
    return <Redirect to="/master" />;
  }
  const requestUrl = match.url.replace(/\/$/, "");

  return (
    <Switch>
      <Redirect exact path={requestUrl} to={`${requestUrl}/company`} />
      <Route path={`${requestUrl}/user`} component={Main} />
      <Route path={`${requestUrl}/company`} component={CompanyList} />
      <Route path={`${requestUrl}/login`} component={Login} />
      <Route path={`${requestUrl}/logger`} component={ProxoLogger} />
      <Route path={`${requestUrl}/logout`} component={onLogoutClick} />
    </Switch>
  );
};

export default Master;
