import { normalizePeriods } from 'redux/schemas/period';
import { GET_PERIOD, ADD_PERIOD, UPDATE_PERIOD, DELETE_PERIOD, PERIOD_ROW_STATUS } from '../actions/types';

const initialState = {
  periods: [],
  rowStatus: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PERIOD:
      const data = [];
      data['periods'] = action.payload;
      const normalizePeriod = normalizePeriods([data]);
      return {
        ...state,
        periods: normalizePeriod.entities.periods ? normalizePeriod.entities.periods : [],
      };

    case ADD_PERIOD:
      const addData = [];
      addData['periods'] = [action.payload];
      addData['addStatePeriods'] = state.periods;
      const addNormalizeOperators = normalizePeriods([addData]);
      return {
        periods: addNormalizeOperators.entities.periods,
      };
    case UPDATE_PERIOD: {
      const updatedData = [];
      updatedData['updateStatePeriods'] = [action.payload];
      const UpdatedNormalizeData = normalizePeriods([updatedData]);
      const updatedPeriod = UpdatedNormalizeData.result[0].updateStatePeriods[0];

      return {
        ...state,
        periods: {
          ...state.periods,
          [updatedPeriod]: UpdatedNormalizeData.entities.periods[updatedPeriod],
        },
      };
    }
    case DELETE_PERIOD: {
      const deletedPeriod = Object.keys(state.periods)
        .filter(id => Number(id) !== action.payload.id)
        .reduce((obj, key) => {
          obj[key] = state.periods[key];
          return obj;
        }, {});
      return {
        ...state,
        periods: deletedPeriod,
      };
    }

    case PERIOD_ROW_STATUS: {
      return {
        ...state,
        rowStatus: action.payload,
      };
    }

    default:
      return state;
  }
};
