import { GET_OFFERS, ADD_OFFER, UPDATE_OFFER, DELETE_OFFER } from '../actions/types';
import { normalizrCrud } from 'redux/schemas/offerCrud';
import { deleteOfferNormalizr } from 'redux/schemas/deleteOffer';

const initialState = {
  offers: [],
  early_booking_offers: [],
  rolling_early_booking_offers: [],
  free_night_offers: [],
  long_stay_offers: [],
  turbo_early_booking_offers: [],
  upgrade_offers: [],
  special_discount_offers: [],
  special_rate_offers: [],
  senior_reduction_offers: [],
  honeymoon_reduction_offers: [],
  expedient_reduction_offers: [],
  rooms: [],
  selectedRooms: [],
  selectedBoards: [],
  selectedOperators: [],
  get_board: [],
  upgradeSelectedRoom: [],
  upgradeSelectedBoard: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFERS: {
      return {
        ...state,
        offers: action.payload.offers ? action.payload.offers : [],
        early_booking_offers: action.payload.eb_offer ? action.payload.eb_offer : [],
        rolling_early_booking_offers: action.payload.rolling_eb_offer ? action.payload.rolling_eb_offer : [],
        free_night_offers: action.payload.free_night_offer ? action.payload.free_night_offer : [],
        long_stay_offers: action.payload.long_stay_offer ? action.payload.long_stay_offer : [],
        turbo_early_booking_offers: action.payload.turbo_eb_offer ? action.payload.turbo_eb_offer : [],
        upgrade_offers: action.payload.upgrade_offer ? action.payload.upgrade_offer : [],
        special_discount_offers: action.payload.special_discount_offer ? action.payload.special_discount_offer : [],
        special_rate_offers: action.payload.special_rate_offer ? action.payload.special_rate_offer : [],
        senior_reduction_offers: action.payload.senior_reduction_offer ? action.payload.senior_reduction_offer : [],
        honeymoon_reduction_offers: action.payload.honeymoon_reduction_offer ? action.payload.honeymoon_reduction_offer : [],
        expedient_reduction_offers: action.payload.expedient_reduction_offer ? action.payload.expedient_reduction_offer : [],
        rooms: action.payload.get_room ? action.payload.get_room : [],
        selectedRooms: action.payload.selectedRooms ? action.payload.selectedRooms : [],
        selectedOperators: action.payload.selectedOperators ? action.payload.selectedOperators : [],
        get_board: action.payload.get_board ? action.payload.get_board : [],
        selectedBoards: action.payload.selectedBoards ? action.payload.selectedBoards : [],
        upgradeSelectedRoom: action.payload.upgradeSelectedRoom ? action.payload.upgradeSelectedRoom : [],
        upgradeSelectedBoard: action.payload.upgradeSelectedBoard ? action.payload.upgradeSelectedBoard : [],
      };
    }
    case ADD_OFFER: {
      const addedKey = Object.keys(action.payload);
      action.payload['stateOffers'] = state.offers;
      action.payload['stateRooms'] = state.rooms;
      action.payload['stateSelectedRooms'] = state.selectedRooms;
      action.payload['stateBoards'] = state.get_board;
      action.payload['stateOperators'] = state.get_operator;
      action.payload['stateSelectedOperators'] = state.selectedOperators;
      action.payload['stateSelectedBoard'] = state.selectedBoards;
      action.payload['upgradeSelectedRoom'] = state.upgradeSelectedRoom;
      action.payload['upgradeSelectedBoard'] = state.upgradeSelectedBoard;
      action.payload[`state${addedKey}`] = state[addedKey];
      const normalizrCRUD = normalizrCrud([action.payload]);

      return {
        ...state,
        offers: normalizrCRUD.entities.offers,
        early_booking_offers: normalizrCRUD.entities.eb_offer
          ? normalizrCRUD.entities.eb_offer
          : { ...state.early_booking_offers },
        rolling_early_booking_offers: normalizrCRUD.entities.rolling_eb_offer
          ? normalizrCRUD.entities.rolling_eb_offer
          : { ...state.rolling_early_booking_offers },
        free_night_offers: normalizrCRUD.entities.free_night_offer
          ? normalizrCRUD.entities.free_night_offer
          : { ...state.free_night_offers },
        long_stay_offers: normalizrCRUD.entities.long_stay_offer
          ? normalizrCRUD.entities.long_stay_offer
          : { ...state.long_stay_offers },
        turbo_early_booking_offers: normalizrCRUD.entities.turbo_eb_offer
          ? normalizrCRUD.entities.turbo_eb_offer
          : { ...state.turbo_early_booking_offers },
        upgrade_offers: normalizrCRUD.entities.upgrade_offer
          ? normalizrCRUD.entities.upgrade_offer
          : { ...state.upgrade_offers },
        special_discount_offers: normalizrCRUD.entities.special_discount_offer
          ? normalizrCRUD.entities.special_discount_offer
          : { ...state.special_discount_offers },
        special_rate_offers: normalizrCRUD.entities.special_rate_offer
          ? normalizrCRUD.entities.special_rate_offer
          : { ...state.special_rate_offers },
        senior_reduction_offers: normalizrCRUD.entities.senior_reduction_offer
          ? normalizrCRUD.entities.senior_reduction_offer
          : { ...state.senior_reduction_offers },
        honeymoon_reduction_offers: normalizrCRUD.entities.honeymoon_reduction_offer
          ? normalizrCRUD.entities.honeymoon_reduction_offer
          : { ...state.honeymoon_reduction_offers },
        expedient_reduction_offers: normalizrCRUD.entities.expedient_reduction_offer
          ? normalizrCRUD.entities.expedient_reduction_offer
          : { ...state.expedient_reduction_offers },
        rooms: normalizrCRUD.entities.get_room,
        selectedRooms: normalizrCRUD.entities.selectedRooms ? normalizrCRUD.entities.selectedRooms : [],
        selectedBoards: normalizrCRUD.entities.boards,
        selectedOperators: normalizrCRUD.entities.operators ? normalizrCRUD.entities.operators : [],
        get_board: normalizrCRUD.entities.get_board,
        upgradeSelectedRoom: normalizrCRUD.entities.upgradeSelectedRoom
          ? normalizrCRUD.entities.upgradeSelectedRoom
          : { ...state.upgradeSelectedRoom },
        upgradeSelectedBoard: normalizrCRUD.entities.upgradeSelectedBoard
          ? normalizrCRUD.entities.upgradeSelectedBoard
          : { ...state.upgradeSelectedBoard },
      };
    }
    case UPDATE_OFFER: {
      const updatedKey = Object.keys(action.payload);
      action.payload['updateStateOffers'] = state.offers;
      action.payload['updateStateRooms'] = state.rooms;
      action.payload['updateStateSelectedRooms'] = state.selectedRooms;
      action.payload['updateStateBoards'] = state.get_board;
      action.payload['updateStateSelectedBoard'] = state.selectedBoards;
      action.payload['updateStateSelectedOperator'] = state.selectedOperators;
      action.payload[`updateState${updatedKey}`] = state[updatedKey];
      action.payload['upgradeSelectedRoom'] = state.upgradeSelectedRoom;
      action.payload['upgradeSelectedBoard'] = state.upgradeSelectedBoard;
      const updateNormalizrCRUD = normalizrCrud([action.payload]);

      return {
        ...state,
        offers: updateNormalizrCRUD.entities.offers,
        early_booking_offers: updateNormalizrCRUD.entities.eb_offer
          ? updateNormalizrCRUD.entities.eb_offer
          : { ...state.early_booking_offers },
        rolling_early_booking_offers: updateNormalizrCRUD.entities.rolling_eb_offer
          ? updateNormalizrCRUD.entities.rolling_eb_offer
          : { ...state.rolling_early_booking_offers },
        free_night_offers: updateNormalizrCRUD.entities.free_night_offer
          ? updateNormalizrCRUD.entities.free_night_offer
          : { ...state.free_night_offers },
        long_stay_offers: updateNormalizrCRUD.entities.long_stay_offer
          ? updateNormalizrCRUD.entities.long_stay_offer
          : { ...state.long_stay_offers },
        turbo_early_booking_offers: updateNormalizrCRUD.entities.turbo_eb_offer
          ? updateNormalizrCRUD.entities.turbo_eb_offer
          : { ...state.turbo_early_booking_offers },
        upgrade_offers: updateNormalizrCRUD.entities.upgrade_offer
          ? updateNormalizrCRUD.entities.upgrade_offer
          : { ...state.upgrade_offers },
        special_discount_offers: updateNormalizrCRUD.entities.special_discount_offer
          ? updateNormalizrCRUD.entities.special_discount_offer
          : { ...state.special_discount_offers },
        special_rate_offers: updateNormalizrCRUD.entities.special_rate_offer
          ? updateNormalizrCRUD.entities.special_rate_offer
          : { ...state.special_rate_offers },
        senior_reduction_offers: updateNormalizrCRUD.entities.senior_reduction_offer
          ? updateNormalizrCRUD.entities.senior_reduction_offer
          : { ...state.senior_reduction_offers },
        honeymoon_reduction_offers: updateNormalizrCRUD.entities.honeymoon_reduction_offer
          ? updateNormalizrCRUD.entities.honeymoon_reduction_offer
          : { ...state.honeymoon_reduction_offers },
        expedient_reduction_offers: updateNormalizrCRUD.entities.expedient_reduction_offer
          ? updateNormalizrCRUD.entities.expedient_reduction_offer
          : { ...state.expedient_reduction_offers },
        rooms: updateNormalizrCRUD.entities.get_room,
        selectedRooms: updateNormalizrCRUD.entities.selectedRooms ? updateNormalizrCRUD.entities.selectedRooms : [],
        selectedBoards: updateNormalizrCRUD.entities.boards,
        selectedOperators: updateNormalizrCRUD.entities.operators,
        get_board: updateNormalizrCRUD.entities.get_board,
        upgradeSelectedRoom: updateNormalizrCRUD.entities.upgradeSelectedRoom
          ? updateNormalizrCRUD.entities.upgradeSelectedRoom
          : { ...state.upgradeSelectedRoom },
        upgradeSelectedBoard: updateNormalizrCRUD.entities.upgradeSelectedBoard
          ? updateNormalizrCRUD.entities.upgradeSelectedBoard
          : { ...state.upgradeSelectedBoard },
      };
    }
    case DELETE_OFFER: {
      const deletedData = [];
      const deletedKey = Object.keys(action.payload);
      deletedData[0] = action.payload;
      deletedData[0]['id'] = 1;
      deletedData[0]['offerName'] = deletedKey;
      deletedData[0]['deleteStateSelectedRooms'] = state.selectedRooms;
      deletedData[0]['deleteStateSelectedBoard'] = state.selectedBoards;
      deletedData[0][`deleteState`] = state[deletedKey];
      deletedData[0]['deleteStateOffers'] = state.offers;
      deletedData[0]['deleteStateRooms'] = state.rooms;
      deletedData[0]['deleteStateBoards'] = state.get_board;
      const deleteNorm = deleteOfferNormalizr(deletedData);
      return { ...state };
    }
    default:
      return state;
  }
};
