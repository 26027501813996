import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllotmentUpdateService } from "services/allotmentUpdate";
import moment from "moment";
import IntlMessages from "@jumbo/utils/IntlMessages";

function AllotmentUpdate(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllotmentUpdateService());
  }, [dispatch]);

  const handleActionType = action_type => {
    switch (action_type) {
      case 1:
        return "Increase";
      case 2:
        return "Decrease";
      case 3:
        return "Remaining";
    }
  };

  const columns = [
    { title: <IntlMessages id="hotel.name" />, field: "get_hotel.name" },
    { title: <IntlMessages id="room" />, field: "room_codes" },
    { title: <IntlMessages id="operator" />, field: "extranet_ids" },
    {
      title: <IntlMessages id="action.type" />,
      field: "action_type"
    },
    {
      title: <IntlMessages id="start.date" />,
      field: "start_date",
      type: "date",
      render: rowData => {
        return moment(rowData.start_date).format("DD/MM/YYYY");
      }
    },
    {
      title: <IntlMessages id="end.date" />,
      field: "end_date",
      type: "date",
      render: rowData => {
        return moment(rowData.end_date).format("DD/MM/YYYY");
      }
    }
  ];
  const response = useSelector(state => state.allotmentUpdate.allotmentUpdate);
  const { operators } = useSelector(({ operators }) => operators);

  var AllotmentUpdateTemp = [];
  const [hotelAllotmentUpdate, setHotelAllotmentUpdate] = useState(response);

  useEffect(() => {
    if (props.selected.hotelId) {
      response.map(a => {
        if (a?.hotel_id == props.selected.hotelId) {
          var op_names = [];
          var room = [];
          var split_operator = a.extranet_ids.split(",");
          split_operator.map(single_operator => {
            Object.values(operators).map(ops => {
              if (ops.id == single_operator) {
                op_names.push(ops.name, " ");
              }
            });
          });
          var split_room = a.room_codes.split(",");
          split_room.map(single_room => {
            room.push(single_room, " ");
          });
          AllotmentUpdateTemp.push({
            get_hotel: { name: a.get_hotel.name },
            room_codes: room,
            extranet_ids: op_names,
            action_type: handleActionType(a.action_type_id),
            start_date: a.start_date,
            end_date: a.end_date
          });
        }
      });
      setHotelAllotmentUpdate(AllotmentUpdateTemp);
    }
  }, [props.selected.hotelId, response]);
  return (
    <div>
      <Grid>
        <MaterialTable
          // title="Allotment Update Table"
          data={hotelAllotmentUpdate}
          columns={columns}
          options={{ search: false, showTitle: false, addRowPosition: "first" }}
        />
      </Grid>
    </div>
  );
}

export default AllotmentUpdate;
