import { GET_OFFER_TYPES } from '../actions/types';

const initialState = {
  offerTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_TYPES: {
      return {
        ...state,
        offerTypes: action.payload.offerTypes,
      };
    }
    default:
      return state;
  }
};
