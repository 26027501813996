import { Route, Redirect } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import React from 'react';

function PrivateRoute({ component: Component, ...rest }) {
  // const { authUser } = useSelector(({ auth }) => auth);
  const authUser = localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={props => {
        if (!authUser) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        } else if (authUser !== false) {
          return <Component {...props} />;
        }
        // else if(authUser != false && props.location=="/login"){
        //   return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />;
        // }
      }}
    />
  );
}
export { PrivateRoute };
