import { GET_ALLOTMENTUPDATE, ADD_ALLOTMENTUPDATE, UPDATE_ALLOTMENTUPDATE } from './types';

export const getAllotmentUpdate = res => dispatch => {
  dispatch({
    type: GET_ALLOTMENTUPDATE,
    payload: res.data.data,
  });
};

export const addAllotmentUpdate = res => dispatch => {
  dispatch({
    type: ADD_ALLOTMENTUPDATE,
    payload: res.data.data,
  });
};
export const updateAllotmentUpdate = res => dispatch => {
  dispatch({
    type: UPDATE_ALLOTMENTUPDATE,
    payload: res.data.data,
  });
};
