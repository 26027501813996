import React from "react";
import Grid from "@material-ui/core/Grid";
import GridContainer from "@jumbo/components/GridContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function RoomDetailItem(props) {
  const numbers = [
    {
      value: 0,
      label: "0"
    },
    {
      value: 1,
      label: "1"
    },
    {
      value: 2,
      label: "2"
    },
    {
      value: 3,
      label: "3"
    },
    {
      value: 4,
      label: "4"
    },
    {
      value: 5,
      label: "5"
    },
    {
      value: 6,
      label: "6"
    },
    {
      value: 7,
      label: "7"
    },
    {
      value: 8,
      label: "8"
    },
    {
      value: 9,
      label: "9"
    },
    {
      value: 10,
      label: "10"
    }
  ];
  const [name, setName] = React.useState("");
  const renderNumbers = () => {
    return numbers.map((option, idx) => {
      if (!(idx < 0)) {
        return (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        );
      }
    });
  };
  return (
    <div>
      <GridContainer direction="row" alignItems="center">
        <Grid item xs={1} justifyContent="center" alignItems="center">
          <Box fullWidth style={{ paddingTop: "15px" }}>
            <Typography style={{ fontSize: 1 + "rem", color: "#33658a" }}>
              No :{props.index + 1}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" fullWidth={true}>
            <InputLabel id="demo-simple-select-standard-label">Room</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={name}
              onChange={event => (
                (event.target.name = "room_id"),
                props.handleChange(event, props.room.id),
                setName(event.target.value)
              )}
              label="Room"
              renderValue={value => {
                const item = props.rooms.find(item => item.id === value);
                return props.selectedContract !== null
                  ? item?.room_code
                  : item?.code;
              }}
            >
              {props.rooms.map(option => {
                console.log(option);
                return (
                  <MenuItem value={option.id}>
                    {props.selectedContract !== null
                      ? option.agency_room_name
                      : option.room_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="adult"
            select
            label="Adult"
            name="adult"
            value={props.room.adult}
            fullWidth
            onChange={event => props.handleChange(event, props.room.id)}
            disabled={props.room.room_id === 0}
          >
            {props.selectedContract !== null
              ? numbers.map((option, idx) => {
                  return props.selectedRoom?.max_adult >= idx &&
                    props.selectedRoom?.min_adult <= idx ? (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ) : null;
                })
              : renderNumbers()}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="child"
            select
            label="Child"
            name="child"
            value={props.room.child}
            fullWidth
            onChange={event => props.handleChange(event, props.room.id)}
            disabled={props.room.room_id === 0}
          >
            {props.selectedContract !== null
              ? numbers.map((option, idx) => {
                  return props.selectedRoom?.min_child >= idx &&
                    props.selectedRoom?.min_child <= idx ? (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ) : null;
                })
              : renderNumbers()}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="infant"
            select
            label="Infant"
            name="infant"
            value={props.room.infant}
            fullWidth
            onChange={event => props.handleChange(event, props.room.id)}
            disabled={props.room.room_id === 0}
          >
            {props.selectedContract !== null
              ? numbers.map((option, idx) => {
                  return props.selectedRoom?.max_infant >= idx && 0 <= idx ? (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ) : null;
                })
              : renderNumbers()}
          </TextField>
        </Grid>
        <Grid item xs={2} style={{ paddingTop: "15px" }}>
          {props.index + 1 !== 1 ? (
            <Button
              variant="contained"
              style={{ color: "white", backgroundColor: "red" }}
              onClick={() => props.handleDeleteRoom(props.room.id)}
            >
              <IntlMessages id="delete" />
            </Button>
          ) : null}
        </Grid>
        <Divider />
      </GridContainer>
    </div>
  );
}

export default RoomDetailItem;
