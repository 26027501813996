import React, { useState, useEffect } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import TermsConditionsModal from "./TermsConditionsModal";
import { Button, IconButton, TextField } from "@material-ui/core";
import {
  getTermsConditionsService,
  deleteTermsConditionsService,
  getAllContractTermsConditionsService
} from "services/termsConditions";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import withReactContent from "sweetalert2-react-content";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "../Common/Loader";
const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text
  });
};
export default function TermsConditions() {
  const [termsRowData, setTermsRowData] = useState({});
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    dispatch(getTermsConditionsService());
    if (Object.values(allContractTermsConditions).length === 0) {
      dispatch(getAllContractTermsConditionsService());
    }
  }, [dispatch]);
  const termsConditions = useSelector(
    state => state.termsConditions.termsConditions
  );
  const tableRef = React.createRef();
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const allContractTermsConditions = useSelector(
    state => state.termsConditions.allContractTermsConditions
  );

  let isAuthAdd = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.put === 1
  );
  let isAuthDelete = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.delete === 1
  );

  const tableColumns = [
    {
      title: <IntlMessages id="name" />,
      field: "name",
      width: "20%",
      editComponent: props => {
        setTermsRowData(props.rowData);
        handleClickOpen();
        return <TextField></TextField>;
      }
    }
  ];
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setTermsRowData({});
    setOpen(false);
  };
  const hasUsedTerms = term_id => {
    let has = true;
    if (
      Object.values(allContractTermsConditions).filter(
        allContractTermsConditions =>
          allContractTermsConditions.term_id === term_id
      ).length > 0
    ) {
      has = false;
    }
    return has;
  };
  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        columns={tableColumns}
        data={termsConditions}
        icons={{
          Check: () => <ModeCheckIcon style={{ color: "green" }} />,
          Clear: () => <ModeCloseIcon sx={{ color: "red" }} />
        }}
        title=""
        options={{
          tableLayout: "fixed",
          actionsColumnIndex: -1,
          search: false,
          filtering: false,
          addRowPosition: "first"
        }}
        actions={[
          {
            icon: () => (
              <ThemeProvider theme={theme}>
                <ColorButton
                  variant="contained"
                  color="backgroundColor"
                  startIcon={<AddBoxIcon />}
                >
                  <IntlMessages id="add" />
                </ColorButton>
              </ThemeProvider>
            ),
            Check: () => <ModeCheckIcon style={{ color: "green" }} />,
            Clear: () => <ModeCloseIcon sx={{ color: "red" }} />,
            title: "add",
            disabled: isAuthAdd ? false : true,
            tooltip: isAuthAdd
              ? "Add Terms & Conditions"
              : "You are not authorized",
            isFreeAction: true,
            onClick: (event, rowData) => {
              handleClickOpen();
              // setOpen(false);
            }
          }
        ]}
        components={{
          OverlayLoading: () => <Loader />,
          EditRow: props => {
            return (
              <MTableEditRow
                {...props}
                onEditingCanceled={(mode, rowData) => {
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (mode == "update") {
                        Swal.fire({
                          title: intl.formatMessage({ id: "are.you.sure?" }),
                          text: intl.formatMessage({
                            id: "do.you.want.to.cancel.the.changes"
                          }),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#41C329",
                          allowOutsideClick: false,
                          cancelButtonColor: "#d33",
                          confirmButtonText: intl.formatMessage({ id: "yes" }),
                          cancelButtonText: intl.formatMessage({ id: "no" })
                        }).then(result => {
                          if (result.isConfirmed) {
                            props.onEditingCanceled(mode);
                            resolve();
                          } else if (result.isDenied) {
                            reject();
                          }
                        });
                      }
                      if (mode == "add") {
                        Swal.fire({
                          title: intl.formatMessage({ id: "are.you.sure?" }),
                          text: intl.formatMessage({
                            id: "do.you.want.to.cancel.the.changes"
                          }),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#41C329",
                          allowOutsideClick: false,
                          cancelButtonColor: "#d33",
                          confirmButtonText: intl.formatMessage({ id: "yes" }),
                          cancelButtonText: intl.formatMessage({ id: "no" })
                        }).then(result => {
                          if (result.isConfirmed) {
                            props.onEditingCanceled(mode, rowData);
                            resolve();
                          } else if (result.isDenied) {
                            reject();
                          }
                        });
                      }
                      if (mode == "delete") {
                        props.onEditingCanceled(mode, rowData);
                      }
                    });
                  });
                }}
              />
            );
          }
        }}
        editable={{
          deleteTooltip: row =>
            isAuthDelete ? "Delete" : "You are not authorized",
          editTooltip: row =>
            isAuthUpdate ? "Update" : "You are not authorized",
          isDeletable: row => (isAuthDelete ? true : false),
          isEditable: row => (isAuthUpdate ? true : false),
          onRowUpdate: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
              }, 1000);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                if (hasUsedTerms(oldData.id) === true) {
                  dispatch(deleteTermsConditionsService(oldData.id)).then(
                    res => {
                      if (res === 201) {
                        sweetAlerts(
                          "success",
                          intl.formatMessage({ id: "terms.and.conditions" }) +
                            intl.formatMessage({ id: "deleted.successfully" })
                        );
                      }
                    }
                  );
                } else {
                  MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Terms & Conditions  ${intl.formatMessage({
                      id: "is.in.use"
                    })}`
                  });
                }
                resolve();
              }, 1000);
            })
        }}
      />
      <TermsConditionsModal
        dialogStatus={open}
        dialogClose={handleClose}
        termsData={{
          id: termsRowData.id,
          termName: termsRowData.name,
          termCon: termsRowData.term
        }}
      />
    </>
  );
}
