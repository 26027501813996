import { normalize, schema } from 'normalizr';

const city = new schema.Entity('city');
const sub_city = new schema.Entity('sub_city');
const country = new schema.Entity('country');
const region = new schema.Entity('region');
const permission = new schema.Entity('get_permission');
const pivot_office = new schema.Entity('pivot_office', {}, { idAttribute: 'office_id' });

const get_contract = new schema.Entity('contract');

const user = new schema.Entity('users', {
  get_permission: [permission],
  pivot_office: [pivot_office],
  get_contract: [get_contract],
});

const companyHasOffice = new schema.Entity('offices', {
  user: [user],
  city: city,
  region: region,
  country: country,
  sub_city: sub_city,
});

const companies = new schema.Entity('companies', {
  offices: [companyHasOffice],
  city: city,
  sub_city: sub_city,
  region: region,
  country: country,
});

const addUpdateCompany = new schema.Entity(
  'companies',
  {
    offices: [companyHasOffice],
    city: city,
    sub_city: sub_city,
    region: region,
    country: country,
  },
  {
    idAttribute: 'id',
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
        ...entityB,
      };
    },
  },
);

const updateCompanyState = new schema.Entity('companies');

const response = {
  companies: [companies],
  updateCompanyState: [updateCompanyState],
  updateCompany: [addUpdateCompany],
  addedCompany: [companies],
};

export const normalizeTasks = data => normalize(data, [response]);
