import { normalize, schema } from 'normalizr';

const rolling_eb_offer = new schema.Entity('rolling_eb_offer');
const deleteState = new schema.Entity('deleteState');
const deleteStateOffers = new schema.Entity('deleteStateOffers');

const get_board = new schema.Entity('get_board');
const selectedBoards = new schema.Entity('selectedBoards', { get_board: [get_board] });
const rooms = new schema.Entity('rooms');
const get_rooms = new schema.Entity('get_room' /* {
  room: [rooms],
} */);
const selectedRoom = new schema.Entity('selectedRooms', {
  get_room: [get_rooms],
});

const offer = new schema.Entity(
  'offers',
  {},
  {
    processStrategy: (entA, entB, key) => {
      let offer_name = Object.values(entA.offerName);
      let offerID = entA[offer_name[0]][0].id;
      let offerINofferId = entA[offer_name[0]][0].table_id;
      let selectBoardsID = entA.deleteStateOffers[offerID].selectedBoard;
      let selectRoomsID = entA.deleteStateOffers[offerID].selectedRoom;

      if (!entA.hasOwnProperty('upgrade_offers')) {
        selectBoardsID.map(element => delete entA.deleteStateSelectedBoard[element]);
        selectRoomsID.map(element => delete entA.deleteStateSelectedRooms[element]);
      }
      delete entA.deleteStateOffers[offerID];
      delete entA.deleteState[offerINofferId];
      delete entA[offer_name[0]][0];

      return { ...entA };
    },
  },
);
export const deleteOfferNormalizr = data => normalize(data, [offer]);
