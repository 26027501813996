import { normalize, schema } from 'normalizr';

const hotels = new schema.Entity(
  'hotels',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  },
);

const response = {
  hotels: [hotels],
  addHotels: [hotels],
  stateHotels: [hotels],
  updatedHotels: [hotels],
};
export const normalizeHotels = data => normalize(data, [response]);
