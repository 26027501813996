import {
  GET_RESERVATIONS,
  ADD_RESERVATION,
  UPDATE_RESERVATION,
  DELETE_RESERVATION,
  SEND_HOTEL_MAIL,
  SEND_HOTEL_RESPONSE,
} from '../actions/types';

const initialState = {
  entities: {
    reservations: {},
    rooms: {},
    customers: {},
  },
  result: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RESERVATIONS:
      return action.payload;
    case ADD_RESERVATION:
      return {};
    case UPDATE_RESERVATION:
      return {};
    case SEND_HOTEL_RESPONSE:
      return {
        ...state,
        entities: {
          ...state.entities,
          reservations: {
            ...state.entities.reservations,
            [action.payload.id]: {
              ...state.entities.reservations[action.payload.id],
              communication_status: action.payload.communication_status,
              hotel_status: action.payload.hotel_status,
              booking_status: action.payload.booking_status,
            },
          },
        },
      };
    case SEND_HOTEL_MAIL:
      return {
        ...state,
        entities: {
          ...state.entities,
          reservations: {
            ...state.entities.reservations,
            [action.payload.id]: {
              ...state.entities.reservations[action.payload.id],
              communication_status: action.payload.communication_status,
              hotel_status: action.payload.hotel_status,
              booking_status: action.payload.booking_status,
            },
          },
        },
      };
    case DELETE_RESERVATION:
      let newState = { ...state };
      delete newState.entities.reservations[action.payload];
      Object.values(state.entities.rooms).forEach(room => {
        if (room.reservation_id === action.payload) {
          delete newState.entities.rooms[room.id];
        }
      });
      Object.values(state.entities.customers).forEach(customer => {
        if (customer.reservation_id === action.payload) {
          delete newState.entities.customers[customer.id];
        }
      });
      newState.result.splice(
        newState.result.findIndex(reservation => reservation === action.payload),
        1,
      );
      return {
        ...state,
        entities: { ...newState.entities },
        result: [...newState.result],
      };
    default:
      return state;
  }
};
