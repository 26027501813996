import React, { useState, useEffect, Suspense, useRef } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ChooseScreen from "./ChooseScreen";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputBase from "@material-ui/core/InputBase";
import { nonEnterprise } from "../../../../redux/selector/companyHasOfficeSelector";
import { useDispatch, useSelector } from "react-redux";
import { getAdminCompaniesService } from "services/company";
import { Redirect, useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { chooseCompany, chooseOffice } from "redux/actions/Auth";
import Autocomplete from "@mui/material/Autocomplete";
import AppTextInput from "../../Common/formElements/AppTextInput";
import Grid from "@material-ui/core/Grid";
import GridContainer from "../../GridContainer";
import { CircularProgress } from "@material-ui/core";
import { display } from "@mui/system";
import { useIntl } from "react-intl";
import Loader from "@jumbo/components/Common/Loader";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",

    "& > *": {
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
      margin: theme.spacing(2),
      width: theme.spacing(32),
      height: theme.spacing(32)
    }
  },
  paper: {
    position: "relative",
    alignItems: "center",
    marginLeft: theme.spacing(6.5),
    marginRight: theme.spacing(6.5)
  }
}));

// variant = 'default', 'standard'
const ChooseCompany = ({
  variant = "standard",
  wrapperVariant = "bgColor"
}) => {
  const { selectedCompany } = useSelector(({ auth }) => auth);
  const { authUser } = useSelector(({ auth }) => auth);
  const { users } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );
  const { offices } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );
  const { companies } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );
  const [companyID, setCompanyID] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const companyState = useSelector(nonEnterprise);
  const newData = { companies: {}, offices: {} };
  const newCompanyArray = [];
  const onlyCompanyID = [];
  /* localStorage.setItem('selectedCompany', element.id);
!localStorage.getItem('selectedCompany') */
  useEffect(() => {
    dispatch(getAdminCompaniesService());
  }, [dispatch]);

  const intl = useIntl();

  const onSelectCompany = (element, key) => {
    if (authUser.authority_level == 30 && key == "company") {
      setCompanyID(element.id);
      if (newData.offices[element.id].length === 1) {
        localStorage.setItem("selectedCompany", element.id);
        localStorage.setItem(
          "selectedOffice",
          newData["offices"][element.id][0].id
        );
      }
    } else if (key == "office") {
      localStorage.setItem("selectedCompany", companyID);
      localStorage.setItem("selectedOffice", element.id);
      //dispatch(chooseCompany(companyID));
      //dispatch(chooseOffice(element.id));
    } else if (key == "onlyOffice") {
      localStorage.setItem("selectedOffice", element.id);
      history.push("/dashboard");
    } else {
      localStorage.setItem("selectedCompany", element.id);
      //dispatch(chooseCompany(element.id));
      history.push("/dashboard");
    }
  };

  const toDashboard = () => {
    if (
      localStorage.getItem("selectedOffice") &&
      localStorage.getItem("selectedCompany")
    ) {
      history.push("/dashboard");
    }
  };

  if (users.length !== 0 && authUser.authority_level != 11) {
    users[authUser.id].pivot_office.map(officeID => {
      if (newData["companies"][offices[officeID].company_id]) {
        newData["offices"][offices[officeID].company_id].push({
          id: officeID,
          company_name: companies[offices[officeID].company_id].name,
          name: offices[officeID].name,
          company_id: offices[officeID].company_id
        });
      } else {
        newCompanyArray.push({
          id: offices[officeID].company_id,
          name: companies[offices[officeID].company_id].name
        });
        newData["companies"][offices[officeID].company_id] = [
          {
            id: offices[officeID].company_id,
            name: companies[offices[officeID].company_id].name
          }
        ];
        newData["offices"][offices[officeID].company_id] = [
          {
            id: officeID,
            name: offices[officeID].name,
            company_name: companies[offices[officeID].company_id].name,
            company_id: offices[officeID].company_id
          }
        ];
      }
    });
    localStorage.setItem("userOffices", JSON.stringify(newData));
    localStorage.setItem("userCompanies", JSON.stringify(newCompanyArray));
  } else {
    if (companies.length != 0 && authUser.authority_level == 11) {
      localStorage.setItem("selectedCompany", Object.values(companies)[0].id);
    }
  }
  //if (!selectedCompany) {
  if (!localStorage.getItem("selectedCompany")) {
    if (newCompanyArray.length == 1) {
      localStorage.getItem("selectedCompany");
      if (authUser.authority_level !== 30) {
        localStorage.setItem("selectedCompany", newCompanyArray[0].id);
        return (
          <Suspense fallback={<Loader />}>
            <Redirect to="/dashboard" />
          </Suspense>
        );
      } else {
        localStorage.setItem("selectedCompany", newCompanyArray[0].id);
        setCompanyID(newCompanyArray[0].id);
        if (newData["offices"][newCompanyArray[0].id].length == 1) {
          localStorage.setItem(
            "selectedOffice",
            newData["offices"][newCompanyArray[0].id][0].id
          );
          return (
            <Suspense fallback={<Loader />}>
              <Redirect to="/dashboard" />
            </Suspense>
          );
        }
      }
      //history.push('/dashboard');
    } else {
      return (
        <ChooseScreen variant={wrapperVariant}>
          {newCompanyArray && (
            <GridContainer
              style={{ marginBottom: 12, padding: "5px" }}
              spacing={4}
            >
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  options={newCompanyArray}
                  autoComplete="nope"
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={option => option.name}
                  onChange={(e, value) => {
                    onSelectCompany(value, "company");
                  }}
                  renderInput={params => (
                    <AppTextInput
                      {...params}
                      label="Choose a Company"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
            </GridContainer>
          )}
          {authUser.authority_level == 30 && companyID && (
            <GridContainer
              style={{ marginBottom: 12, padding: "5px" }}
              spacing={4}
            >
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  options={newData["offices"][companyID]}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  autoComplete="nope"
                  getOptionLabel={option => option.name}
                  onChange={(e, value) => {
                    onSelectCompany(value, "office");
                  }}
                  renderInput={params => (
                    <AppTextInput
                      {...params}
                      label="Choose a Office"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
            </GridContainer>
          )}
          {authUser.authority_level == 30 && companyID && (
            <GridContainer
              style={{ marginBottom: 12, padding: "5px" }}
              spacing={4}
            >
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    toDashboard();
                  }}
                  style={{
                    position: "relative",
                    top: "15px",
                    marginLeft: "10px"
                  }}
                >
                  {intl.formatMessage({ id: "continue" })}
                </Button>
              </Grid>
            </GridContainer>
          )}

          {/* <Box className={classes.root}>
            {companyState?.map(element => {
              return (
                <Button className={classes.paper} onClick={() => onSelectCompany(element)}>
                  <Paper elevation={3}>{element.name}</Paper>
                </Button>
              );
            })}
          </Box> */}
        </ChooseScreen>
      );
    }
  } else if (location.pathname !== "/login") {
    if (
      authUser.authority_level === 30 &&
      !localStorage.getItem("selectedOffice")
    ) {
      return (
        <ChooseScreen variant={wrapperVariant}>
          <GridContainer style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                options={
                  newData["offices"][
                    parseInt(localStorage.getItem("selectedCompany"))
                  ]
                }
                classes={{
                  option: classes.option
                }}
                autoHighlight
                getOptionLabel={option => option.name}
                onChange={(e, value) => {
                  onSelectCompany(value, "onlyOffice");
                }}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    label="Choose a Office"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password" // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </GridContainer>
        </ChooseScreen>
      );
    } else {
      return <Redirect to="/dashboard" />;
    }
  }
};

export default ChooseCompany;
