import { normalize, schema } from 'normalizr';

const periods = new schema.Entity(
  'periods',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, entityB };
    },
  },
);

const response = {
  periods: [periods],
  addStatePeriods: [periods],
  updateStatePeriods: [periods],
  deleteStatePeriods: [periods],
};

export const normalizePeriods = data => normalize(data, [response]);
