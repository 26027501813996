import { normalize, schema } from 'normalizr';
// Define a users schema
const rate_range = new schema.Entity('rate_ranges', {
  mergeStrategy: (entityA, entityB) => {
    return { ...entityA };
  },
});
// Define your comments schema
// const markup = new schema.Entity('markups');
const updatedMarkups = new schema.Entity('markups', {
  mergeStrategy: (entityA, entityB) => {
    return { ...entityA };
  },
});
const stateDeleteMarkups = new schema.Entity(
  'markup',
  {},
  {
    mergeStrategy: (entityA, entityB) => {},
  },
);
const deletedMarkup = new schema.Entity(
  'markup',
  {
    rate_ranges: [rate_range],
  },
  {
    processStrategy: (entityA, entityB, key) => {
      let all_rate_ranges = entityA.rate_ranges;
      delete entityB.stateMarkups[entityA.id];
      all_rate_ranges.forEach(element => {
        delete entityB.stateRateRanges[element.id];
      });
      delete entityB.stateMarkups[entityA.id];
      return { ...entityA };
    },
  },
);
const markups = new schema.Entity(
  'markups',
  {
    rate_ranges: [rate_range],
  },
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);

// Define your article
const response = {
  //markups: [markups],
  markups: [markups],
  stateMarkups: [markups],
  //  stateDeleteMarkups: [stateDeleteMarkups],
  addMarkups: [markups],
  updatedMarkups: [markups],
  //deletedMarkups: [deletedMarkup],

  addRateRanges: [rate_range],
  stateRateRanges: [rate_range],
  updatedRates: [rate_range],
  deletedRateRanges: [rate_range],
};
export const normalizeTasks = data => normalize(data, [response]);
