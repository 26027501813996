import { normalize, schema } from 'normalizr';
const rooms = new schema.Entity('rooms');
const boards = new schema.Entity('boards');
const state_rooms = new schema.Entity('rooms');
const state_rboards = new schema.Entity('boards');

const cincout = new schema.Entity('cincout', {
  rooms: [rooms],
});
const allotment = new schema.Entity('allotment', {
  rooms: [rooms],
});
const closeout = new schema.Entity('closeout', {
  boards: [boards],
  rooms: [rooms],
});
const occupancy = new schema.Entity('occupancy', {
  rooms: [rooms],
});
const release = new schema.Entity('release', {
  rooms: [rooms],
});
const state_occupancy = new schema.Entity(
  'occupancy',
  {
    rooms: [rooms],
  },
  {
    mergeStrategy: (a, b) => {
      return a;
    },
  },
);
const state_cincout = new schema.Entity(
  'cincout',
  {
    rooms: [rooms],
  },
  {
    mergeStrategy: (a, b) => {
      return a;
    },
  },
);
const state_allotment = new schema.Entity(
  'allotment',
  {
    rooms: [rooms],
  },
  {
    mergeStrategy: (a, b) => {
      return a;
    },
  },
);
const state_closeout = new schema.Entity(
  'closeout',
  {
    boards: [boards],
    rooms: [rooms],
  },
  {
    mergeStrategy: (a, b) => {
      return a;
    },
  },
);

const state_release = new schema.Entity(
  'release',
  {
    rooms: [rooms],
  },
  {
    mergeStrategy: (a, b) => {
      return a;
    },
  },
);
const get_type = new schema.Entity('get_type', {
  release: release,
  allotment: allotment,
  closeout: closeout,
  occupancy: occupancy,
  cincout: cincout,
});
// const updateGetType = new schema.Entity('get_type', {
//   release: release,
//   allotment: allotment,
//   closeout: closeout,
//   occupancy: occupancy,
//   cincout: cincout,
// });
// const updateRestrictions = new schema.Entity('restriction', { get_type: get_type });
const restrictions = new schema.Entity('restriction', { get_type: get_type });
const deletedRestriction = new schema.Entity(
  'restriction',
  { get_type: get_type },
  {
    processStrategy: (entA, entB, key) => {
      return { ...entA };
    },
  },
);

const response = {
  release: [release],
  allotment: [allotment],
  closeout: [closeout],
  cincout: [cincout],
  occupancy: [occupancy],
  get_type: [get_type],
  rooms: [rooms],
  rboards: [boards],
  restriction: [restrictions],
  stateRestriction: [restrictions],
  update_release: [release],
  update_allotment: [allotment],
  update_closeout: [closeout],
  update_cincout: [cincout],
  update_occupancy: [occupancy],
  state_cincout: [state_cincout],
  state_rooms: [state_rooms],
  state_rboards: [state_rboards],
  state_release: [state_release],
  state_allotment: [state_allotment],
  state_closeout: [state_closeout],
  state_occupancy: [state_occupancy],
  deletedRestriction: [deletedRestriction],
  /* stateGetType: [get_type],
  stateRooms: [rooms],
  stateBoards: [boards],
   */
};
export const normalizedRestrictions = data => normalize(data, [response]);
