import React, { useState, useEffect } from "react";
//ICONS
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import ViewListIcon from "@mui/icons-material/ViewList";
import DnsIcon from "@mui/icons-material/Dns";
import { DateTimePicker } from "@material-ui/pickers";
import TransformIcon from "@mui/icons-material/Transform";
import ArticleIcon from "@mui/icons-material/Article";
//STYLES
import pxoStyle from "./ProxoLoggerStyles/proxolabLoggerStyle.js";
import axios from "services/axios/index.js";
import { loggerEndpoint } from "services/axiosLogger/index.js";
//MU-DATAGRID
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
//moment
import moment from "moment";
//SWAL
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
// import the react-json-view component
import ReactJson from "react-json-view";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const MySwal = withReactContent(Swal);

const Logger = () => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = scrollType => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [payload, setPayload] = useState({
    hostname: null,
    projectName: "backoffice_proxolab",
    userEmail: null,
    startDate: null,
    endDate: null
  });
  const [userEmails, setUserEmails] = useState([]);
  const [data, setData] = useState([]);

  const useStyles = makeStyles(theme => ({
    datagrid: {
      marginTop: 5 + "rem",
      "& .MuiDataGrid-cell": {
        display: "flex!important",
        justifyContent: "center!important",
        alignItems: "center!important",
        flexDirection: "row!important",
        color: "black",
        "&:nth-child(2)": {
          color: "black",
          fontWeight: "bolder",
          fontSize: ".9rem"
        }
      },

      "& .MuiDataGrid-columnHeaderTitleContainer": {
        display: "flex!important",
        justifyContent: "center!important",
        alignItems: "center!important",
        flexDirection: "row!important"
      }
    }
  }));
  const classes = useStyles();
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = token;
  useEffect(() => {
    axios
      .post(loggerEndpoint + "getalluseremail", {
        project: "backoffice_proxolab"
      })

      .then(res => {
        console.log(res);
        setUserEmails(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  var date = new Date();

  const handleSubmit = async () => {
    await axios.post(loggerEndpoint + "logforclient", payload).then(res => {
      let comingData = res.data.data;
      if (comingData.length == 0) {
        MySwal.fire(
          "Report",
          `<h3 style="color:rgb(34 65 88); letter-spacing:3px">You haven't got log in your db</h3>`,
          "warning"
        );
      }

      let arr = comingData.map((val, key) => {
        let obj = {};
        obj.id = key + 1;
        obj.time = moment(val.createdAt).format("YYYY-MM-DD HH:mm");
        obj.ip = val.user.ip;
        obj.status = val.data.status;
        obj.message = val.data.message;
        obj.process = val.process;
        return obj;
      });
      setData(arr);
    });
  };

  const [valuee, setValuee] = useState();

  const handleOnCellClick = e => {
    data.map(p => {
      if (p.id == e.id) {
        let j = p.process;
        setValuee(j);
        setOpen(true);
        handleClickOpen("paper");
      }
    });
  };

  const columns = [
    {
      field: "id",
      headerName: <span style={pxoStyle.tableHead}>ID</span>,
      width: 100
    },
    {
      field: "time",
      headerName: <span style={pxoStyle.tableHead}>Time</span>,
      width: 350,
      editable: true
    },
    {
      field: "ip",
      headerName: <span style={pxoStyle.tableHead}>IP</span>,
      width: 350,
      editable: true
    },
    {
      field: "status",
      headerName: <span style={pxoStyle.tableHead}>Status</span>,
      type: "number",
      width: 350,
      editable: true
    },
    {
      field: "message",
      headerName: <span style={pxoStyle.tableHead}>Message</span>,
      type: "number",
      width: 350,
      editable: true
    }
  ];

  const convertExcel = () => {
    if (data.length > 0) {
      let layout = `
            <!DOCTYPE html>
               <html>
                    <head>
                        <title>Blob to Excel</title>
                        <style> 
                            table {
                                border-collapse:collapse;
                                border: 1px solid gray;
                                background-color: white;
                                color: black;
                            }
                            tr{
                                border: 1px solid gray;
                                color: black;
                                
                            }
                            td {
                                border: 1px solid gray;
                            }
                        </style>
                    </head>
                <body>
                <table>
                <tr>
               
                `;

      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          //create the heading row for the table before adding any data rows
          for (var item in data[i]) {
            layout +=
              "<td style='color:red; text-align:center'>" +
              item.toUpperCase() +
              "</td>";
          }
          layout += "</tr>";
        }
        //append data row now
        layout += "<tr>";
        for (var item in data[i]) {
          layout +=
            "<td>" + (data[i][item] === null ? "" : data[i][item]) + "</td>";
        }
        layout += "</tr>";
      }
      layout += `
       </table>
              </body>
              </html>`;
      var blob = new Blob([layout], {
        type: "application/html"
      });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = `pxo_${payload.userEmail +
        "_" +
        moment(new Date()).format("YYYY-MM-DD HH:mm")}.xls`;
      a.click();
    }
  };

  return (
    <div className="container-fluid min-vh-100 ">
      <div className="row mb-3">
        <h3>
          <ViewListIcon
            style={{
              color: "#33658a",
              fontSize: 2 + "rem"
            }}
          />
          &nbsp;
          <span style={pxoStyle.title}>Logger Filter</span>
        </h3>
      </div>

      <div className="row">
        <div id="logTable" className="row mt-4">
          <table className="table">
            <thead>
              <tr>
                {/* <th scope="col">
                  <h5>
                    <FilterListIcon />
                    &nbsp; Filter Options
                  </h5>
                </th> */}
                <th scope="col">
                  <h5>
                    {" "}
                    <DnsIcon />
                    &nbsp;Hostname
                  </h5>
                </th>
                {/* <th scope="col">
                  <h5>
                    <AccountTreeIcon />
                    &nbsp; Project Name
                  </h5>
                </th> */}
                <th scope="col">
                  <h5>
                    <AttachEmailIcon />
                    &nbsp;User Email
                  </h5>
                </th>
                <th scope="col">
                  <h5>
                    <DateRangeIcon />
                    &nbsp;Start Date
                  </h5>
                </th>
                <th scope="col">
                  <h5>
                    <CalendarViewMonthIcon />
                    &nbsp;End Date
                  </h5>
                </th>

                <th scope="col">
                  <h5>
                    <LocationSearchingIcon />
                    &nbsp; Search
                  </h5>
                </th>

                <th scope="col">
                  <h5>
                    <ArticleIcon />
                    &nbsp; Excel
                  </h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select
                    style={{ width: "auto" }}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e =>
                      setPayload({ ...payload, hostname: e.target.value })
                    }
                  >
                    <option defaultValue>Select A Host</option>
                    <option defaultValue="test">testb2b </option>
                    <option defaultValue="stage">stageb2b </option>
                    <option defaultValue="production">b2b</option>
                    <option defaultValue="localhost">localhost</option>
                  </select>
                </td>

                <td>
                  <select
                    style={{ width: "auto" }}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e =>
                      setPayload({ ...payload, userEmail: e.target.value })
                    }
                  >
                    <option defaultValue>Select A User Email</option>
                    {userEmails.map(param => (
                      <option key={param} defaultValue={param}>
                        {param}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <DateTimePicker
                    value={payload.startDate}
                    onChange={date =>
                      setPayload({ ...payload, startDate: date._d })
                    }
                    style={{ width: "auto" }}
                  />
                </td>
                <td>
                  <DateTimePicker
                    value={payload.endDate}
                    onChange={date =>
                      setPayload({ ...payload, endDate: date._d })
                    }
                    style={{ width: "auto" }}
                  />
                </td>

                <td>
                  <button onClick={handleSubmit} className="btn btn-success">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                    &nbsp; Search
                  </button>
                </td>

                <td>
                  <button onClick={convertExcel} class="btn btn-primary">
                    <TransformIcon /> &nbsp;Excel
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div style={{ height: "70%" }} className="row">
        <div>
          <div style={{ marginTop: "3rem", marginBottom: "-3rem" }}>
            <h4 style={{ margin: "0!important" }}>
              {data.length > 0
                ? "Click  the grid row for show log details"
                : ""}
            </h4>
          </div>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">Log Details</DialogTitle>
              <DialogContent dividers={scroll === "paper"}>
                <DialogContentText
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}
                >
                  <ReactJson src={valuee} />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
          <DataGrid
            style={{
              cursor: data.length > 0 ? "pointer" : ""
            }}
            onCellClick={handleOnCellClick}
            rows={data}
            className={classes.datagrid}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </div>
      </div>
    </div>
  );
};

export default Logger;
