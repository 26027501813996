import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import ConstructionIcon from "@mui/icons-material/Construction";
import React, { createRef, useEffect, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import { Delete, Edit } from "@material-ui/icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  deleteReservationService,
  getReservationsService
} from "services/reservation";
import { getBoardsService } from "services/boards";
import { getOperatorService } from "services/operator";
import { getHotelService } from "services/hotels";
import { getRoomService } from "services/rooms";
import { getContractService } from "services/contract";
import { getCurrenciesService } from "services/currency";
import ReservationUpdate from "./ReservationUpdate";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@material-ui/core";
import { getPeriodService, getReservationPeriod } from "services/period";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getMarketService } from "services/market";
import { getOnSaleContractService } from "services/onSaleContract";
import { getRegionsService } from "services/destinations";
import { getAdminCompaniesService } from "services/company";
import { getProductTypesService } from "services/productTypes";
import { getSeasonService } from "services/season";
import { getMarkupsService } from "services/markup";
import { getRateTypesService } from "services/rateType";
import { getBanksService } from "services/banks";
import { getPaymentMethodsService } from "services/paymentMethods";
import { getPaymentConditionsService } from "services/paymentConditions";
import { getAllotmentTypeService } from "services/allotmentType";
import { getStayTypesService } from "services/stayType";
import { getCancellationPoliciesServices } from "services/cancellationPoliciesDetail";
import { getStarRatesService } from "services/starRate";
import { getExtraService } from "services/extras";
import { getServiceService } from "services/services";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import ActionsMenu from "./ActionsMenu";
const MySwal = withReactContent(Swal);

export default function ShowReservation() {
  const tableRef = createRef();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { paymentMethods } = useSelector(
    ({ paymentMethods }) => paymentMethods
  );

  const reservationIDs = useSelector(state => state.reservation.result);
  const reservations = useSelector(state => state.reservation?.entities?.reservations); //prettier-ignore
  const operators = useSelector(state => state.operators.operators);
  const hotels = useSelector(state => state.hotels.hotels);
  const markets_selector = useSelector(state => state.markets.markets);
  //const roomTypes = useSelector(state => state.rooms.roomType);
  const boards = useSelector(state => state.boards.boards);
  const currencies = useSelector(state => state.currencies.currencies);
  const contracts_selector = Object.values(
    useSelector(state => state.contract.contracts)
  );
  const [reservationsData, setReservationsData] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);

  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  let isAuthUpdate = permissionsByAuthUser.some(
    permission => permission.route_id == 4 && permission.put === 1
  );

  const handleBookingStatus = booking_status => {
    console.log(booking_status);
    switch (booking_status) {
      case "1":
        return "New";
      case "2":
        return "Updated";
      case "3":
        return "Cancel";
      case "4":
        return "No Show ";
    }
  };

  const handleCommicationStatus = communication_status => {
    switch (communication_status) {
      case 1:
        return " Pending Sending ";
      case 2:
        return " Sent ";
    }
  };

  const handleHotelStatus = hotel_status => {
    switch (hotel_status) {
      case 1:
        return "Not Sent";
      case 2:
        return "Waiting";
      case 3:
        return "Cancelled";
      case 4:
        return "Confirmed";
    }
  };

  const handleReleaseDialogOpen = () => {
    setReleaseDialogOpen(true);
  };
  const handleReleaseDialogClose = () => {
    setReleaseDialogOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setType(false);
  };

  const handleDelete = rowData => {
    dispatch(deleteReservationService(rowData.id));
    setReleaseDialogOpen(false);
    Swal.fire({
      title: "Deleted!",
      text: "Reservation has been deleted.",
      icon: "success"
    });
  };

  const getContractPeriods = async contract_id => {
    try {
      var result = await getReservationPeriod(contract_id);
      return result.data.data;
    } catch (e) {
      return [];
    }
  };
  const cancellationControl = async rowData => {
    var periods = await getContractPeriods(rowData.contract_id);
    var has_cancellation = false;
    var selectedPeriod = {};
    var dateDiff = 0;
    setSelectedReservation(rowData);
    if (periods.length > 0) {
      selectedPeriod = periods.find(period =>
        moment(rowData.start_date).isBetween(period.start_date, period.end_date)
      );

      dateDiff = moment(rowData.start_date).diff(moment(), "days") + 1;
      if (dateDiff < selectedPeriod.release) {
        handleReleaseDialogOpen();
      } else {
        handleDelete(rowData);
      }
    }
    if (periods.find(p => p.period_type === "Cancellation")) {
      has_cancellation = true;
    }
  };

  useEffect(() => {
    if (reservations !== undefined) {
      setReservationsData(Object.values(reservations));
    }
  }, [reservationIDs, reservations]);
  const dateToConvert = date => {
    var temp = "";
    temp = `${`0${new Date(date).getDate()}`.slice(-2)}/${`0${new Date(
      date
    ).getMonth() + 1}`.slice(-2)}/${new Date(date).getFullYear()}`;
    return temp;
  };
  const columns = [
    {
      headerName: <IntlMessages id="actions" />,
      field: "actions",
      sortable: false,
      width: 75,
      disableClickEventBubbling: true,
      renderCell: params => (
        <ActionsMenu
          params={params}
          isAuthUpdate={isAuthUpdate}
          handleClickOpen={handleClickOpen}
          setSelectedReservation={setSelectedReservation}
          setType={setType}
        />
        // <>
        //   <Grid container spacing={5}>
        //     <Grid item xs={5}>
        //       <Button
        //         variant="contained"
        //         color="primary"
        //         size="small"
        //         disabled={!isAuthUpdate || params.row.start_date <= moment().format('YYYY-MM-DD')}
        //         onClick={() => {
        //           handleClickOpen();
        //           setSelectedReservation(params.row);
        //         }}
        //         startIcon={<ConstructionIcon />}>
        //         <IntlMessages id="edit" />
        //       </Button>
        //     </Grid>
        //     <Grid item xs={5}>
        //       <Button
        //         variant="contained"
        //         color="primary"
        //         size="small"
        //         //disabled={!isAuthUpdate || params.row.start_date <= moment().format('YYYY-MM-DD')}
        //         onClick={() => {
        //           handleClickOpen();
        //           setType(true);
        //           setSelectedReservation(params.row);
        //         }}
        //         startIcon={<VisibilityIcon />}>
        //         <IntlMessages id="view" />
        //       </Button>
        //     </Grid>
        //     {/*  <Grid item xs={5}>
        //       <Button
        //         variant="contained"
        //         color="primary"
        //         size="small"
        //         onClick={() => cancellationControl(params.row)}
        //         startIcon={<Delete />}>
        //         Delete
        //       </Button>
        //     </Grid> */}
        //   </Grid>
        // </>
      )
    },
    {
      headerName: <IntlMessages id="hotel.status" />,
      field: "hotel_status",
      width: 200,
      renderCell: params => {
        return handleHotelStatus(params.row.hotel_status);
      }
    },
    {
      headerName: <IntlMessages id="booking.status" />,
      field: "booking_status",
      width: 200,
      renderCell: params => {
        return handleBookingStatus(params.row.booking_status);
      }
    },
    {
      headerName: <IntlMessages id="hotel.name" />,
      field: "hotelName",
      width: 200,
      renderCell: params => {
        return hotels[params.row.hotel_id] !== undefined
          ? hotels[params.row.hotel_id].name
          : "";
      }
    },
    {
      headerName: <IntlMessages id="room.type" />,
      field: "roomType",
      width: 200
      /*  renderCell: params => {
        return roomTypes.length !== 0 ? roomTypes.find(roomType => roomType.id === params.row.hotel_id).name : '';
      }, */
    },
    {
      headerName: <IntlMessages id="check.in.date" />,
      field: "start_date",
      width: 200,
      renderCell: params => {
        return params.row.start_date !== "0000-00-00"
          ? dateToConvert(params.row.start_date)
          : "00/00/0000";
      }
    },
    {
      headerName: <IntlMessages id="check.out.date" />,
      field: "end_date",
      editable: "never",
      width: 200,
      renderCell: params => {
        return params.row.end_date !== "0000-00-00"
          ? dateToConvert(params.row.end_date)
          : "00/00/0000";
      }
    },
    {
      headerName: <IntlMessages id="communication.status" />,
      field: "communication_status",
      width: 200,
      renderCell: params => {
        return handleCommicationStatus(params.row.communication_status);
      }
    },
    {
      headerName: <IntlMessages id="reservation.create.date" />,
      field: "selling_date",
      width: 200,
      renderCell: params => {
        return params.row.selling_date !== "0000-00-00"
          ? dateToConvert(params.row.selling_date)
          : "00/00/0000";
      }
    },
    {
      headerName: <IntlMessages id="tour.operator" />,
      field: "operator_id",
      width: 200,
      renderCell: params => {
        return operators[params.row.operator_id] !== undefined
          ? operators[params.row.operator_id].name
          : "";
      }
    },
    {
      headerName: <IntlMessages id="tour.op.booking.no" />,
      field: "tourBookNo",
      width: 200
    },
    {
      headerName: <IntlMessages id="proxo.booking.no" />,
      field: "voucher_no",
      width: 200
    },
    {
      headerName: <IntlMessages id="creator.name" />,
      field: "creator_name",
      width: 200
    },
    {
      headerName: <IntlMessages id="stay.nights" />,
      field: "stayNight",
      width: 200,
      editable: "never",
      renderCell: params => {
        var nights = Math.round(
          (Date.parse(params.row.end_date) -
            Date.parse(params.row.start_date)) /
            (1000 * 60 * 60 * 24)
        );
        return nights;
      }
    },

    {
      headerName: <IntlMessages id="board.type" />,
      field: "boardType",
      width: 200,
      renderCell: params => {
        return boards[params.row.board_id]
          ? boards[params.row.board_id].code
          : "";
      }
    },
    {
      headerName: <IntlMessages id="number.of.adult" />,
      field: "adult_num",
      width: 200
    },
    {
      headerName: <IntlMessages id="number.of.child" />,
      field: "child_num",
      width: 200
    },
    {
      headerName: <IntlMessages id="number.of.infant" />,
      field: "infant_num",
      width: 200
    },
    { headerName: <IntlMessages id="amount" />, field: "amount", width: 200 },
    {
      headerName: <IntlMessages id="currency" />,
      field: "currency",
      width: 200,
      renderCell: params => {
        if (params.row.contract_id != 0) {
          var currency_id =
            contracts_selector.length !== 0
              ? contracts_selector.find(
                  contract => contract.id === params.row.contract_id
                ).currency
              : "";
          return currencies[currency_id].name;
        } else {
          return "";
        }
      }
    },
    {
      headerName: <IntlMessages id="contract" />,
      field: "contract_id",
      width: 200
    },
    {
      headerName: <IntlMessages id="market" />,
      field: "market",
      width: 200,
      renderCell: params => {
        var market_name = "";
        if (params.row.contract_id != 0) {
          var markets =
            contracts_selector.length !== 0
              ? contracts_selector.find(
                  contract => contract.id === params.row.contract_id
                ).market
              : "";
          markets.forEach(market => {
            market_name += markets_selector[market].name + ",";
          });
        }
        return market_name;
      }
    }
  ];

  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: "100%",
      height: "100%",
      margin: "fixed"
    },
    table: {
      minWidth: 500,
      margin: "fixed",
      marginTop: "1%",
      color:
        localStorage.getItem("theme-type") === "dark"
          ? "white !important"
          : "black !important"
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ReservationUpdate
        actionDialog={open}
        handleClose={handleClose}
        reservation={selectedReservation}
        type={type}
      />
      <Dialog
        open={releaseDialogOpen}
        onClose={handleReleaseDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Reservation Cancellation In Release"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Reservation cancellation is within the release days. If it is
            cancelled, the penalty will apply. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReleaseDialogClose}>Disagree</Button>
          <Button onClick={() => handleDelete(selectedReservation)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <ReservationUpdate dialog={open} />
      <CssBaseline position="fixed" />
      <CardHeader
        title={intl.formatMessage({ id: "show.reservation" })}
        sx={{ color: "#33658a", paddingLeft: "0px" }}
      />
      <Paper>
        <div position="fixed" style={{ height: 400, width: "100%" }}>
          <DataGrid
            position="fixed"
            rows={reservationsData}
            columns={columns}
            className={classes.table}
            groupable
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700
              }
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
