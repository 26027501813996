import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Grid } from '@material-ui/core';
import moment from 'moment';

function ActionsMenu(props) {
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setPropsType = () => {
    props.setType(true);
  };
  return (
    <Grid>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        onClick={() => {
          console.log(props.params.row.start_date);
          console.log(moment().format('YYYY-MM-DD'));
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>

        <MenuItem
          disabled={!props.isAuthUpdate || props.params.row.start_date <= moment().format('YYYY-MM-DD')}
          onClick={() => {
            props.handleClickOpen();
            handleClose();
            props.setSelectedReservation(props.params.row);
          }}>
          <ConstructionIcon />
          Edit
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            props.handleClickOpen();
            handleClose();
            props.setSelectedReservation(props.params.row);
            setPropsType();
          }}>
          <VisibilityIcon />
          View
        </MenuItem>
      </Menu>
    </Grid>
  );
}

export default ActionsMenu;
