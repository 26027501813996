import React from "react";
import {
  ArrowForward,
  People,
  Edit,
  Settings,
  HomeWork
} from "@material-ui/icons";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import IntlMessages from "../../../utils/IntlMessages";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import Swal from "sweetalert2";
import HomeIcon from "@mui/icons-material/Home";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";

const logoutFunc = () => {
  Swal.fire({
    title: "Are you sure you want to log out?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes!",
    cancelButtonText: "No!",
    reverseButtons: true
  }).then(result => {
    if (result.isConfirmed) {
      localStorage.removeItem("token");
      localStorage.removeItem("selectedCompany");
      localStorage.removeItem("userCompanies");
      localStorage.removeItem("selectedOffice");
      localStorage.removeItem("userOffices");
      return (window.location = "/login");
    }
  });
};
export const isEnterprise = () => {
  if (
    localStorage.getItem("userCompanies") !== "undefined" &&
    localStorage.getItem("selectedCompany")?.length > 0
  ) {
    const selected =
      JSON.parse(localStorage.getItem("userCompanies"))?.filter(
        element => element.id == localStorage.getItem("selectedCompany")
      )[0].name +
      " > " +
      JSON.parse(localStorage.getItem("userOffices"))?.offices[
        localStorage.getItem("selectedCompany")
      ][0].name;

    if (selected.includes(undefined)) {
      return <IntlMessages id="dashboard" />;
    } else {
      return selected;
    }
  } else {
    return <IntlMessages id="dashboard" />;
  }
};

window.addEventListener("company", () => {
  sidebarNavs[0].children[0].name = isEnterprise();
});

export const sidebarNavs = [
  {
    name: "",
    icon: <HomeIcon />,
    type: "section",
    children: [
      {
        name: isEnterprise(),
        type: "item",
        icon: <HomeIcon />,
        link: "/dashboard"
      }
    ]
  },
  {
    name: "",
    icon: <People />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="admin.operations" />,
        type: "item",
        icon: <People />,
        link: "/admin"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="product.definitions" />,
        type: "collapse",
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="definitions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/definitions"
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/markup"
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/cancellationpolicies"
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/termsConditions"
          }
        ]
      }
    ]
  },

  {
    name: "",
    icon: <RateReviewIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="new.contract" />,
        type: "item",
        icon: <RateReviewIcon />,
        link: "/contract/newcontract"
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="sale.management" />,
        type: "collapse",
        icon: <LeaderboardIcon />,
        children: [
          {
            name: <IntlMessages id="allotment.update" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentUpdate"
          },
          {
            name: <IntlMessages id="open.stop.sale" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/openstopsale"
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentList"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: "collapse",
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/create"
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/show"
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/calculate"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: "collapse",
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/reservationReport"
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/contractReport"
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/hotelReport"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="help" />,
        type: "collapse",
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: "item",
            icon: <WhatsAppIcon />,
            link: "/whatsappsupport"
          },
          {
            name: <IntlMessages id="email" />,
            type: "item",
            icon: <LocalPostOfficeIcon />,
            link: "/sendmail"
          },
          {
            name: <IntlMessages id="sss" />,
            type: "item",
            icon: <QuestionMarkIcon />,
            link: "/sss"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="user.settings" />,
        type: "collapse",
        icon: <SettingsIcon />,
        children: [
          {
            name: <IntlMessages id="profile" />,
            type: "item",
            icon: <PersonIcon />,
            link: "/profile"
          }
        ]
      }
    ]
  },
  {
    name: "",
    icon: <PersonIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="logout" />,
        type: "item",
        link: "/logout",
        icon: <LogoutIcon />,
        onClickFunc: logoutFunc
      }
    ]
  }
];

export const sidebarNavsOfficeUser = [
  {
    name: "",
    icon: <HomeIcon />,
    type: "section",
    children: [
      {
        name: isEnterprise(),
        type: "item",
        icon: <HomeIcon />,
        link: "/dashboard"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="product.definitions" />,
        type: "collapse",
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="definitions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/definitions"
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/markup"
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/cancellationpolicies"
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/termsConditions"
          }
        ]
      }
    ]
  },

  {
    name: "",
    icon: <RateReviewIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="new.contract" />,
        type: "item",
        icon: <RateReviewIcon />,
        link: "/contract/newcontract"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="sale.management" />,
        type: "collapse",
        icon: <Settings />,
        children: [
          {
            name: <IntlMessages id="allotment.update" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentUpdate"
          },
          {
            name: <IntlMessages id="open.stop.sale" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/openstopsale"
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentList"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: "collapse",
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/create"
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/show"
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/calculate"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: "collapse",
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/reservationReport"
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/contractReport"
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/hotelReport"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="help" />,
        type: "collapse",
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: "item",
            icon: <WhatsAppIcon />,
            link: "/whatsappsupport"
          },
          {
            name: <IntlMessages id="email" />,
            type: "item",
            icon: <LocalPostOfficeIcon />,
            link: "/sendmail"
          },
          {
            name: <IntlMessages id="SSS" />,
            type: "item",
            icon: <QuestionMarkIcon />,
            link: "/sss"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="user.settings" />,
        type: "collapse",
        icon: <SettingsIcon />,
        children: [
          {
            name: <IntlMessages id="profile" />,
            type: "item",
            icon: <PersonIcon />,
            link: "/profile"
          }
        ]
      }
    ]
  },
  {
    name: "",
    icon: <PersonIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="logout" />,
        type: "item",
        link: "/logout",
        icon: <LogoutIcon />,
        onClickFunc: logoutFunc
      }
    ]
  }
];

export const horizontalDefaultNavs = [
  {
    name: "",
    icon: <HomeIcon />,
    type: "section",
    children: [
      {
        name: isEnterprise(),
        type: "item",
        icon: <HomeIcon />,
        link: "/dashboard"
      }
    ]
  },
  {
    name: "",
    icon: <People />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="admin.operations" />,
        type: "item",
        icon: <People />,
        link: "/admin"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="product.definitions" />,
        type: "collapse",
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="definitions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/definitions"
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/markup"
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/cancellationpolicies"
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/termsConditions"
          }
        ]
      }
    ]
  },

  {
    name: "",
    icon: <RateReviewIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="new.contract" />,
        type: "item",
        icon: <RateReviewIcon />,
        link: "/contract/newcontract"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="sale.management" />,
        type: "collapse",
        icon: <Settings />,
        children: [
          {
            name: <IntlMessages id="allotment.update" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentUpdate"
          },
          {
            name: <IntlMessages id="open.stop.sale" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/openstopsale"
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentList"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: "collapse",
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/create"
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/show"
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/calculate"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: "collapse",
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/reservationReport"
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/contractReport"
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/hotelReport"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="help" />,
        type: "collapse",
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: "item",
            icon: <WhatsAppIcon />,
            link: "/whatsappsupport"
          },
          {
            name: <IntlMessages id="email" />,
            type: "item",
            icon: <LocalPostOfficeIcon />,
            link: "/sendmail"
          },
          {
            name: <IntlMessages id="SSS" />,
            type: "item",
            icon: <QuestionMarkIcon />,
            link: "/sss"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="user.settings" />,
        type: "collapse",
        icon: <SettingsIcon />,
        children: [
          {
            name: <IntlMessages id="profile" />,
            type: "item",
            icon: <PersonIcon />,
            link: "/profile"
          }
        ]
      }
    ]
  },
  {
    name: "",
    icon: <PersonIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="logout" />,
        type: "item",
        link: "/logout",
        icon: <LogoutIcon />,
        onClickFunc: logoutFunc
      }
    ]
  }
];

export const minimalHorizontalMenus = [
  {
    name: "",
    children: [
      {
        name: isEnterprise(),
        type: "item",
        icon: <HomeIcon />,
        link: "/dashboard"
      }
    ]
  },
  {
    name: "",
    icon: <People />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="admin.operations" />,
        type: "collapse",
        icon: <People />,
        children: [
          {
            name: <IntlMessages id="new.company" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/dashboard"
          },
          {
            name: <IntlMessages id="new.office" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/dashboard"
          },
          {
            name: <IntlMessages id="new.user" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/dashboard"
          },
          {
            name: <IntlMessages id="new.operator" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/dashboard"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="product.definitions" />,
        type: "collapse",
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="definitions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/definitions"
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/markup"
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/cancellationpolicies"
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/termsConditions"
          }
        ]
      }
    ]
  },

  {
    name: "",
    icon: <RateReviewIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="new.contract" />,
        type: "item",
        icon: <RateReviewIcon />,
        link: "/contract/newcontract"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="sale.management" />,
        type: "collapse",
        icon: <Settings />,
        children: [
          {
            name: <IntlMessages id="allotment.update" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentUpdate"
          },
          {
            name: <IntlMessages id="open.stop.sale" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/openstopsale"
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentList"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: "collapse",
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/create"
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/show"
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/calculate"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: "collapse",
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/reservationReport"
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/contractReport"
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/hotelReport"
          }
        ]
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="help" />,
        type: "collapse",
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: "item",
            icon: <WhatsAppIcon />,
            link: "/whatsappsupport"
          },
          {
            name: <IntlMessages id="email" />,
            type: "item",
            icon: <LocalPostOfficeIcon />,
            link: "/sendmail"
          },
          {
            name: <IntlMessages id="SSS" />,
            type: "item",
            icon: <QuestionMarkIcon />,
            link: "/sss"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="user.settings" />,
        type: "collapse",
        icon: <SettingsIcon />,
        children: [
          {
            name: <IntlMessages id="profile" />,
            type: "item",
            icon: <PersonIcon />,
            link: "/profile"
          }
        ]
      }
    ]
  },
  {
    name: "",
    icon: <PersonIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="logout" />,
        type: "item",
        link: "/logout",
        icon: <LogoutIcon />,
        onClickFunc: logoutFunc
      }
    ]
  }
];
export const masterSidebarNavs = [
  {
    name: <IntlMessages id="profile" />,
    icon: <PersonIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="logout" />,
        type: "item",
        icon: <LogoutIcon />,
        link: "/master/logout",
        onClickFunc: logoutFunc
      }
    ]
  },
  {
    name: <IntlMessages id="master.operations" />,
    icon: <People />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="company" />,
        type: "item",
        icon: <HomeWork />,
        link: "/master/company"
      },
      {
        name: <IntlMessages id="users" />,
        type: "item",
        icon: <People />,
        link: "/master/user"
      },
      {
        name: <IntlMessages id="logger" />,
        type: "item",
        icon: <SettingsIcon />,
        link: "/master/logger"
      }
    ]
  }
];
