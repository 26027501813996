import {
  ADD_DASHBOARD_HAS_LOADED,
  ADD_DEFINITIONS_HAS_LOADED,
  ADD_ADD_RESERVATIONS_HAS_LOADED,
  ADD_SHOW_RESERVATIONS_HAS_LOADED,
  ADD_NEW_CONTRACT_HAS_LOADED,
} from '../actions/types';
const initialState = {
  hasLoaded: {
    dashboard: false,
    definitions: false,
    addReservations: false,
    showReservations: false,
    newContract: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_DASHBOARD_HAS_LOADED:
      return {
        ...state,
        hasLoaded: {
          ...state.hasLoaded,
          dashboard: action.payload,
        },
      };
    case ADD_DEFINITIONS_HAS_LOADED:
      return {
        ...state,
        hasLoaded: {
          ...state.hasLoaded,
          definitions: action.payload,
        },
      };
    case ADD_ADD_RESERVATIONS_HAS_LOADED:
      return {
        ...state,
        hasLoaded: {
          ...state.hasLoaded,
          addReservations: action.payload,
        },
      };
    case ADD_SHOW_RESERVATIONS_HAS_LOADED:
      return {
        ...state,
        hasLoaded: {
          ...state.hasLoaded,
          showReservations: action.payload,
        },
      };
    case ADD_NEW_CONTRACT_HAS_LOADED:
      return {
        ...state,
        hasLoaded: {
          ...state.hasLoaded,
          newContract: action.payload,
        },
      };

    default:
      return state;
  }
};
