import {
  GET_MASTER_COMPANIES,
  ADD_MASTER_COMPANY,
  SET_MASTER_COMPANY_DETAILS,
  EDIT_MASTER_COMPANY,
} from '../../@jumbo/constants/ActionTypes';

const INITIAL_STATE = {
  masterCompanies: [],
  allCompanies: [],
  currentMasterCompany: null,
};

const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MASTER_COMPANIES:
      return { ...state, masterCompanies: action.payload };
    case ADD_MASTER_COMPANY:
      return {
        ...state,
        masterCompanies: [...state.masterCompanies, action.payload],
      };
    case SET_MASTER_COMPANY_DETAILS:
      return { ...state, currentMasterCompany: action.payload };
    case EDIT_MASTER_COMPANY:
      return {
        ...state,
        masterCompanies: state.masterCompanies.map(company => (company.id === action.payload.id ? action.payload : company)),
      };
    default:
      return state;
  }
};

export default companyReducer;
