import { GET_RATE_TYPES } from '../actions/types';

const initialState = {
  rateTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RATE_TYPES:
      return { ...state, rateTypes: action.payload.rateTypes ? action.payload.rateTypes : [] };
    default:
      return state;
  }
};
