import {GET_ONSALE_CONTRACT, ADD_ONSALE_CONTRACT} from "./types";

export const getOnSaleContract = res => dispatch => {
  dispatch({
    type: GET_ONSALE_CONTRACT,
    payload: res.data.data
  });
};

export const addOnSaleContract = res => dispatch =>
  dispatch({
    type: ADD_ONSALE_CONTRACT,
    payload: res.data.data
  });
