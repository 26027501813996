import { loggerEndpoint } from "../axiosLogger/index";
import jwt from "jwt-decode";
class SendLogService {
  constructor(user) {
    this.url = "http://localhost:3030/proxolab/api/v1/";
    this.projectID = "backoffice_proxolab";
    this.location = window.location;
    this.token = localStorage.getItem("token");
    this.user =
      this.token != null
        ? {
            id: jwt(this.token).id,
            name: jwt(this.token).fullname,
            email: jwt(this.token).email
          }
        : "";
    this.header = {
      Authorization: this.token,
      Accept: "application/json",
      "Content-Type": "application/json"
    };
  }
  async sendCompanyLog(res, data) {
    try {
      await fetch(
        this.url + "companyservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendHotelLog(res, data) {
    try {
      await fetch(
        this.url + "hotelservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendBoardLog(res, data) {
    try {
      await fetch(
        this.url + "boardservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendMarketLog(res, data) {
    try {
      await fetch(
        this.url + "marketservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOfficeDestinationLog(res, data) {
    try {
      delete res.config.data;

      await fetch(
        this.url + "officedestinationservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOfficeLog(res, data) {
    try {
      await fetch(
        this.url + "officeservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOperatorLog(res, data) {
    try {
      await fetch(
        this.url + "operatorservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendProductTypesLog(res, data) {
    try {
      await fetch(
        this.url + "producttypesservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendRoomLog(res, data) {
    try {
      await fetch(
        this.url + "roomservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendSeasonLog(res, data) {
    try {
      await fetch(
        this.url + "seasonservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendUserLog(res, data) {
    try {
      await fetch(
        this.url + "userservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendNewServiceLog(res, data) {
    try {
      await fetch(
        this.url + "newservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendContractServiceLog(res, data) {
    try {
      await fetch(
        this.url + "contractservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendPeriodServiceLog(res, data) {
    try {
      await fetch(
        this.url + "periodservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendContractRoomServiceLog(res, data) {
    try {
      await fetch(
        this.url + "contractroomservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendContractStatusServiceLog(res, data) {
    try {
      await fetch(
        this.url + "contractstatusservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }
  async markupServiceLog(res, data) {
    try {
      await fetch(
        this.url + "markupservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async cancellationPoliciesServiceLog(res, data) {
    try {
      await fetch(
        this.url + "cancellationpoliciesservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async termsConditionsServiceLog(res, data) {
    try {
      await fetch(
        this.url + "termsconditionsservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async additionalPaxServiceLog(res, data) {
    try {
      await fetch(
        this.url + "additionalpaxservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async representativeFeeServiceLog(res, data) {
    try {
      await fetch(
        this.url + "representativefeeservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async contributionFeeServiceLog(res, data) {
    try {
      await fetch(
        this.url + "contributionfeeservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async turnoverServiceLog(res, data) {
    try {
      await fetch(
        this.url + "turnoverservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async advertisingFeeServiceLog(res, data) {
    try {
      await fetch(
        this.url + "advertisingfeeservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async marketingPromotionServiceLog(res, data) {
    try {
      await fetch(
        this.url + "marketingpromotionservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async catalougeContributionServiceLog(res, data) {
    try {
      await fetch(
        this.url + "catalougecontributionservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async offerServiceLog(res, data) {
    try {
      await fetch(
        this.url + "offerservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async occupancyServiceLog(res, data) {
    try {
      await fetch(
        this.url + "occupancyservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async closeoutServiceLog(res, data) {
    try {
      await fetch(
        this.url + "closeoutservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async cincoutServiceLog(res, data) {
    try {
      await fetch(
        this.url + "cincoutservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async cincoutServiceLog(res, data) {
    try {
      await fetch(
        this.url + "cincoutservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async allotmentRestServiceLog(res, data) {
    try {
      await fetch(
        this.url + "allotmentrestservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async releaseServiceLog(res, data) {
    try {
      await fetch(
        this.url + "releaseservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async specialNotesServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "specialnotesservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async paymentServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "paymentservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async conTermsServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "contermsservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async boardSupplementServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "boardSupplementservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async bsReductionServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "bsreductionservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async extrasServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "extrasservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async masterCompanyServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "mastercompanyservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async masterUserServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "masteruserservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async allotmentServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "allotmentservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async stopSaleLogs(res, data) {
    try {
      await fetch(
        this.url + "stopsaleservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async openSaleLogs(res, data) {
    try {
      await fetch(
        this.url + "opensaleservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async reservationServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "reservationservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async userPermissionServiceLogs(res, data) {
    try {
      await fetch(
        this.url + "userpermissionservice",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async errorlog(res, data) {
    try {
      await fetch(
        this.url + "error",

        {
          headers: this.header,
          method: "POST",
          body: JSON.stringify({
            res: res,
            data: data,
            user: this.user,
            location: this.location,
            project: this.projectID
          })
        }
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }
}

let sendLogService = new SendLogService();

export default sendLogService;
