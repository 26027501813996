import { normalizeAddPaxRates } from 'redux/schemas/addPaxRates';
import { GET_ADDPAXRATES, ADD_ADDPAXRATES, UPDATE_ADDPAXRATES, DELETE_ADDPAXRATES } from '../actions/types';

const initialState = {
  addPaxRates: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDPAXRATES:
      const data = [];
      data['addPaxRates'] = action.payload;
      const normalizeAddPaxRate = normalizeAddPaxRates([data]);
      return {
        ...state,
        addPaxRates: normalizeAddPaxRate.entities.addPaxRates ? normalizeAddPaxRate.entities.addPaxRates : [],
      };
    case ADD_ADDPAXRATES:
      const addData = [];
      addData['addPaxRates'] = [action.payload];
      addData['addStateAddPaxRates'] = state.addPaxRates;
      const addNormalizeAddPaxRates = normalizeAddPaxRates([addData]);
      return {
        addPaxRates: addNormalizeAddPaxRates.entities.addPaxRates,
      };
    case UPDATE_ADDPAXRATES:
      const updatedData = [];
      updatedData['updateStateAddPaxRates'] = [action.payload];
      updatedData['oldAddPaxRates'] = state.addPaxRates;
      const updatedNormalizeData = normalizeAddPaxRates([updatedData]);
      // const updatedAddPaxRate = UpdatedNormalizeData.result[0].updateStateAddPaxRates[0];

      return {
        ...state,
        addPaxRates: updatedNormalizeData.entities.addPaxRates,
      };
    case DELETE_ADDPAXRATES:
      const deletedAddPaxRate = Object.keys(state.addPaxRates)
        .filter(id => Number(id) !== action.payload)
        .reduce((obj, key) => {
          obj[key] = state.addPaxRates[key];
          return obj;
        }, {});
      return {
        ...state,
        addPaxRates: deletedAddPaxRate,
      };

    default:
      return state;
  }
};
