import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getOffers, addOffer, updateOffer, deleteOffer } from 'redux/actions/offers';
import axios from './axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};

export const getOfferService = contract_id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('offer', {
      params: {
        contract_id: contract_id,
      },
    })
    .then(res => {
      dispatch(getOffers(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addOfferService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('offer', data)
    .then(res => {
      dispatch(addOffer(res));
      dispatch(fetchSuccess());
      sendLogService.offerServiceLog(res, data);
      return res.data;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateOfferService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('offer', data)
    .then(res => {
      dispatch(updateOffer(res));
      dispatch(fetchSuccess());
      sendLogService.offerServiceLog(res, data);
      return res.data;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sweetAlerts('error', 'Something went wrong!');
      sendLogService.errorlog(e, data);
    });
};
export const deleteOfferService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`offer/${id}`)
    .then(res => {
      dispatch(deleteOffer(res));
      dispatch(fetchSuccess());
      sendLogService.offerServiceLog(res, id);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
