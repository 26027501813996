import { merge } from 'lodash';
import { normalizeTasks } from 'redux/schemas/markup';
import {
  GET_MARKUP,
  ADD_MARKUP,
  UPDATE_MARKUP,
  DELETE_MARKUP,
  ADD_RATE_RANGE,
  UPDATE_RATE_RANGE,
  DELETE_RATE_RANGE,
} from '../actions/types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deleteOfferNormalize } from 'redux/schemas/deleteMarkup';
const MySwal = withReactContent(Swal);

const initialState = {
  markups: [],
  rate_ranges: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MARKUP:
      const data = [];
      data['markups'] = action.payload;
      const normalizeData = normalizeTasks([data]);
      const entities = normalizeData.entities;
      return {
        ...state,
        markups: entities.markups ? entities.markups : state.markups,
        rate_ranges: entities.rate_ranges ? entities.rate_ranges : state.rate_ranges,
      };
    case ADD_MARKUP:
      if (state.markups[action.payload.id]) {
        MySwal.fire('Oops...', 'This markup is already exists!', 'error');
      } else {
        const addData = [];
        addData['addMarkups'] = [action.payload];
        addData['stateMarkups'] = state.markups;
        const addNormalizeData = normalizeTasks([addData]);
        return {
          ...state,
          markups: addNormalizeData.entities.markups ? addNormalizeData.entities.markups : state.markups,
        };
      }
    case UPDATE_MARKUP:
      const updatedData = [];
      updatedData['updatedMarkups'] = [action.payload];
      updatedData['stateMarkups'] = state.markups;

      const updatedNormalizeData = normalizeTasks([updatedData]);
      const markupId = updatedNormalizeData.result[0].updatedMarkups[0];

      return {
        ...state,
        markups: {
          ...state.markups,
          [markupId]: updatedNormalizeData.entities.markups[markupId],
        },
      };
    case DELETE_MARKUP:
      const deletedData = [];
      deletedData[0] = action.payload;
      deletedData[0]['stateDeleteMarkups'] = state.markups;
      deletedData[0]['stateRateRanges'] = state.rate_ranges;

      const deletedNormalizeData = deleteOfferNormalize(deletedData);
      const deletedMarkupId = deletedNormalizeData.result[0];

      return {
        ...state,
        markups: deletedNormalizeData.entities.markups[deletedMarkupId].stateDeleteMarkups
          ? deletedNormalizeData.entities.markups[deletedMarkupId].stateDeleteMarkups
          : state.markups,
        rate_ranges: deletedNormalizeData.entities.markups[deletedMarkupId].stateRateRanges
          ? deletedNormalizeData.entities.markups[deletedMarkupId].stateRateRanges
          : state.rate_ranges,
      };

    //const deletedRates = deletedNormalizeData.entities.rate_ranges[deletedContractRoomID].contract_id;
    /*  const deleteMarkups = Object.keys(state.markups)
        .filter(id => id != action.payload.id)
        .reduce((obj, key) => {
          obj[key] = state.markups[key];
          return obj;
        }, {});
      state.markups[action.payload.id].rate_ranges.forEach(element => {
        delete state.rate_ranges[element];
      });
      
 */

    // Rate Ranges
    case ADD_RATE_RANGE:
      if (state.rate_ranges[action.payload.id]) {
        MySwal.fire('Oops...', 'This markup is already exists!', 'error');
      } else {
        const addRateData = [];
        addRateData['addRateRanges'] = [action.payload];
        addRateData['stateRateRanges'] = state.rate_ranges;
        const addRatesNormalize = normalizeTasks([addRateData]);
        const rateRangesId = addRatesNormalize.result[0].addRateRanges[0];
        const rateMarkupId = addRatesNormalize.entities.rate_ranges[rateRangesId].markup_id;
        state.markups[rateMarkupId].rate_ranges.push(rateRangesId);
        return {
          ...state,
          rate_ranges: addRatesNormalize.entities.rate_ranges ? addRatesNormalize.entities.rate_ranges : state.rate_ranges,
        };
      }
    case UPDATE_RATE_RANGE:
      const updatedRatesData = [];
      updatedRatesData['updatedRates'] = [action.payload];
      const updatedRatesNormalizeData = normalizeTasks([updatedRatesData]);
      const rateRangeId = updatedRatesNormalizeData.result[0].updatedRates[0];
      return {
        ...state,
        rate_ranges: {
          ...state.rate_ranges,
          [rateRangeId]: updatedRatesNormalizeData.entities.rate_ranges[rateRangeId],
        },
      };
    case DELETE_RATE_RANGE:
      const deletedRatesData = [];
      deletedRatesData['deletedRateRanges'] = [action.payload];
      const deletedRatesNormalizeData = normalizeTasks([deletedRatesData]);
      const deletedRatesId = deletedRatesNormalizeData.result[0].deletedRateRanges[0];
      const deletedRatesIndex = state.markups[action.payload.markup_id].rate_ranges.findIndex(
        rates => rates == deletedRatesId,
      );
      state.markups[action.payload.markup_id].rate_ranges.splice(deletedRatesIndex, 1);

      const deleteRateRanges = Object.keys(state.rate_ranges)
        .filter(id => id != action.payload.id)
        .reduce((obj, key) => {
          obj[key] = state.rate_ranges[key];
          return obj;
        }, {});
      return {
        ...state,
        rate_ranges: deleteRateRanges,
      };
    default:
      if (action.markups && action.rate_ranges) {
        return merge({}, state, action.markups.rate_ranges);
      }
      return state;
  }
};
