import React, { useState, Suspense, lazy, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import InputBase from "@material-ui/core/InputBase";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import {
  TableContainer,
  Paper,
  Grid,
  InputLabel,
  Input
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./Loading";
import { getOfferService } from "services/offers";
import { getPriorityService } from "services/priority";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import { getBoardSupplamentService } from "services/boardSupplament";
import { getAdditionalPaxService } from "services/additionalPax";
import { getExtraService } from "services/extras";

const ContractRoom = lazy(() => import("./ContractRoom"));
const Kickback = lazy(() => import("./Kickback"));
const AddPaxRates = lazy(() => import("./AddPaxRates"));
const Extras = lazy(() => import("./Extras"));
const Offers = lazy(() => import("./Offer/Offers"));
const Restrictions = lazy(() => import("./Restrictions/RestrictionsTable"));
const SpecialNotes = lazy(() => import("./SpecialNotes"));
const PaymentCondition = lazy(() => import("./PaymentCondition"));
const ContractTermsConditions = lazy(() =>
  import("./ContractTermsAndConditions/ContractTermsConditions")
);
const ContractCancellationPolicies = lazy(() =>
  import("./ContractCancellationPolicies/ContractCancelationPolicies")
);
const BoardSupplament = lazy(() => import("./BoardSupplament"));

const useStyles = makeStyles(theme => ({
  expand: {
    maxHeight: "30px",
    transform: "rotate(0deg)",
    textAlign: "left",
    marginLeft: "auto",
    fontSize: 11,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    maxHeight: "30px",
    fontSize: 11,
    transform: "rotate(180deg)"
  },
  root: {
    minWidth: "100%",
    fontSize: 11,
    height: "100%",
    padding: "12px"
  },
  bottomNavigate: {
    width: 400,
    fontSize: 11,
    backgroundColor: theme.palette.background.default
  },
  rootCard: {
    minWidth: 600,
    fontSize: 11,
    margin: "0 auto"
  },
  cardContent: {
    padding: 4,
    textAlign: "left",
    "&:last-child": {
      paddingBottom: 2
    }
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "48%",
    fontSize: 11,
    marginTop: "0.1%",
    marginBottom: "2%",
    "& .MuiFormLabel-root": {
      color: "black",
      fontWeight: "bold!important"
    },
    "& .MuiOutlinedInput-input": {
      color: "black", // or black
      fontWeight: "bold!important"
    },
    "& .MuiButton-textSizeSmall": {
      color: "black", // or black
      fontWeight: "bold!important"
    }
  },
  formControlTextField: {
    marginTop: "0.1%",
    margin: theme.spacing(4),
    fontSize: 11,
    minWidth: "94%"
  },
  title: {
    marginTop: "1%",
    fontSize: 11,
    margin: theme.spacing(4)
  },
  textStyle: {
    //margin: theme.spacing(2),
    //marginTop: '0.2%',
    fontSize: 11
  },
  cardActions: {
    fontSize: 11,
    maxWidth: "94%",
    display: "block",
    cursor: "pointer"
  },
  button: {
    fontSize: 11,
    margin: "0 auto",
    marginLeft: "10px"
  },
  addRoomButton: {
    backgroundColor: "cadetblue",
    fontSize: 10
  },
  buttonCard: {
    fontSize: 11,
    marginTop: "0.6%"
  },
  textField: {
    minWidth: 450
  },
  textFieldiforDetail: {
    minWidth: 450,
    maxHeight: 30
  },
  formInCard: {
    minWidth: 100,
    fontSize: 10,
    margin: "0 auto",
    maxHeight: "55px",
    align: "center"
  },
  numberColumns: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  collapse: {
    margin: "0 auto"
  },

  container: {
    paddingBottom: 4,
    paddingRight: 4,
    marginTop: 4,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
    maxHeight: 400
  }
}));

export default function(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    dispatch(getOfferService(selectedContract));
    dispatch(getBoardSupplamentService(selectedContract));
    dispatch(getExtraService(selectedContract));
    dispatch(getAdditionalPaxService(selectedContract));
    dispatch(getPriorityService(selectedContract));
  }, [dispatch]);
  const { selectedContract } = useSelector(({ contract }) => contract);

  const [productExpanded, setProductExpanded] = useState(false);
  const [officeExpanded, setOfficeExpanded] = useState(false);
  const [additionalPaxExpanded, setAdditionalPaxExpanded] = useState(false);
  const [kickbackExpanded, setKickbackExpanded] = useState(false);
  const [hotelExtrasExpanded, setHotelExtrasExpanded] = useState(false);
  const [offersExpanded, setOffersExpanded] = useState(false);
  const [boardSupplementExpanded, setBoardSupplementExpanded] = useState(false);
  const [restrictionsExpanded, setRestrictionsExpanded] = useState(false);
  const [specialConExpanded, setSpecialConExpanded] = useState(false);
  const [paymentConExpanded, setPaymentConExpanded] = useState(false);
  const [termsConExpanded, setTermsConExpanded] = useState(false);
  const [cancelPolExpanded, setCancelPolExpanded] = useState(false);

  const { contracts } = useSelector(({ contract }) => contract);

  const { entities } = useSelector(({ companyHasOffice }) => companyHasOffice);
  const companyInfo =
    entities.companies[contracts[selectedContract].company_id];

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  let isAuthAdd = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.put === 1
  );
  let isAuthDelete = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.delete === 1
  );

  const { hotels } = useSelector(({ hotels }) => hotels);
  const selectedHotel = hotels[contracts[selectedContract].hotel_id];
  const handleExpandClick = () => {
    setProductExpanded(!productExpanded);
  };
  const handleExpandOfficeClick = () => {
    setOfficeExpanded(!officeExpanded);
  };
  const additionalPaxClick = () => {
    /* additionalPaxExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setAdditionalPaxExpanded(!additionalPaxExpanded);
  };

  const kickbackClick = () => {
    setKickbackExpanded(!kickbackExpanded);
  };

  const hotelExtrasClick = () => {
    /* hotelExtrasExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setHotelExtrasExpanded(!hotelExtrasExpanded);
  };
  const boardSupplementClick = () => {
    /* boardSupplementExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setBoardSupplementExpanded(!boardSupplementExpanded);
  };
  const offersClick = () => {
    /* offersExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setOffersExpanded(!offersExpanded);
  };
  const restrictionsClick = () => {
    /* restrictionsExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setRestrictionsExpanded(!restrictionsExpanded);
  };
  const specialConClick = () => {
    /* specialConExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setSpecialConExpanded(!specialConExpanded);
  };
  const cancelPolClick = () => {
    /* specialConExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setCancelPolExpanded(!cancelPolExpanded);
  };
  const paymentCondOnCLick = () => {
    /* paymentConExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setPaymentConExpanded(!paymentConExpanded);
  };

  const termsConOnCLick = () => {
    /* termsConditionsExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setTermsConExpanded(!termsConExpanded);
  };

  return (
    <div className={classes.root}>
      <InputBase
        inputProps={{
          style: {
            marginTop: "0.1%",
            textAlign: "center",
            color:
              localStorage.getItem("theme-type") === "dark" ? "white" : "black"
          }
        }}
        defaultValue={intl.formatMessage({ id: "contract.details" })}
        fullWidth
        disabled
        margin="dense"
      />
      <FormControl className={classes.formControl}>
        <Card className={classes.rootCard}>
          <CardContent className={classes.cardContent}>
            <FormControl
              className={classes.formInCard}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <TextField
                disabled
                id="outlined-disabled"
                margin="dense"
                inputProps={{ style: { textAlign: "center" } }}
                variant="outlined"
                value={selectedHotel.name}
                label={<IntlMessages id="hotel" />}
                className={classes.textFieldiforDetail}
                // defaultValue="TAYYARBEY HOTEL"
              />

              {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
              Product Details
            </Typography> */}
              <CardActions className={classes.cardActions}>
                <Button
                  style={{ fontSize: 7, maxHeight: "45px" }}
                  size="small"
                  onClick={handleExpandClick}
                >
                  <IntlMessages id="hotel.details" />
                  <IconButton
                    className={
                      (classes.expand,
                      {
                        [classes.expandOpen]: productExpanded
                      })
                    }
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Button>
              </CardActions>
            </FormControl>
            <Collapse
              className={classes.collapse}
              in={productExpanded}
              timeout="auto"
            >
              <Card variant="outlined">
                <CardContent className={classes.content}>
                  <Grid container className={classes.container} spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="name-helper">
                          <IntlMessages id="name" />
                        </InputLabel>
                        <Input
                          disabled
                          id="name-helper"
                          variant="outlined"
                          label={<IntlMessages id="type" />}
                          defaultValue={selectedHotel.name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="name-helper">
                          <IntlMessages id="type" />
                        </InputLabel>
                        <Input
                          disabled
                          id="type-helper"
                          variant="outlined"
                          label={<IntlMessages id="type" />}
                          defaultValue={selectedHotel.product_type?.name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="email-helper">
                          <IntlMessages id="email" />
                        </InputLabel>
                        <Input
                          disabled
                          id="email-helper"
                          variant="outlined"
                          label="E-mail"
                          fullWidth
                          defaultValue={selectedHotel.e_mail}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="web-helper">
                          <IntlMessages id="website" />
                        </InputLabel>
                        <Input
                          disabled
                          id="web-helper"
                          variant="outlined"
                          label={<IntlMessages id="website" />}
                          fullWidth
                          defaultValue={selectedHotel.web_page}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="fax-helper">
                          <IntlMessages id="fax" />
                        </InputLabel>
                        <Input
                          disabled
                          id="fax-helper"
                          variant="outlined"
                          label={<IntlMessages id="fax" />}
                          fullWidth
                          defaultValue={selectedHotel.fax}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="phone-helper">
                          <IntlMessages id="phone" />
                        </InputLabel>
                        <Input
                          disabled
                          id="phone-helper"
                          variant="outlined"
                          label={<IntlMessages id="phone" />}
                          defaultValue={selectedHotel.tel}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="rate-helper">
                          <IntlMessages id="star.rating" />
                        </InputLabel>
                        <Input
                          disabled
                          id="Star Rate"
                          variant="outlined"
                          label={<IntlMessages id="star.rating" />}
                          fullWidth
                          defaultValue={selectedHotel.star_rating}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="giata-helper">
                          <IntlMessages id="giata.code" />
                        </InputLabel>
                        <Input
                          disabled
                          id="giata-helper"
                          variant="outlined"
                          label={<IntlMessages id="giata.code" />}
                          fullWidth
                          defaultValue={selectedHotel.giata_code}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="company-helper">
                          <IntlMessages id="company" />
                        </InputLabel>
                        <Input
                          disabled
                          id="company-helper"
                          variant="outlined"
                          label={<IntlMessages id="company" />}
                          fullWidth
                          defaultValue={selectedHotel.company}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="taxoffice-helper">
                          <IntlMessages id="tax.office" />
                        </InputLabel>
                        <Input
                          disabled
                          id="taxoffice-helper"
                          variant="outlined"
                          label={<IntlMessages id="tax.office" />}
                          fullWidth
                          defaultValue={selectedHotel.tax_office}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="taxno-helper">
                          <IntlMessages id="tax.no" />
                        </InputLabel>
                        <Input
                          disabled
                          id="taxno-helper"
                          variant="outlined"
                          label={<IntlMessages id="tax.no" />}
                          fullWidth
                          defaultValue={selectedHotel.tax_no}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Collapse>
          </CardContent>
        </Card>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Card className={classes.rootCard}>
          <CardContent className={classes.cardContent}>
            <FormControl
              className={classes.formInCard}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <TextField
                disabled
                id="outlined-disabled"
                margin="dense"
                inputProps={{ style: { textAlign: "center" } }}
                variant="outlined"
                label={<IntlMessages id="company" />}
                // fullWidth
                //defaultValue="KEYF TRAVEL"
                value={companyInfo?.name}
                className={classes.textField}
              />

              {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
              Office Details
            </Typography> */}

              <CardActions className={classes.cardActions}>
                <Button
                  style={{ fontSize: 7, maxHeight: "45px" }}
                  size="small"
                  onClick={handleExpandOfficeClick}
                >
                  <IntlMessages id="company.detail" />
                  <IconButton
                    className={
                      (classes.expand,
                      {
                        [classes.expandOpen]: officeExpanded
                      })
                    }
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Button>
              </CardActions>
            </FormControl>
            <Collapse in={officeExpanded} timeout="auto">
              <Card variant="outlined">
                <CardContent className={classes.content}>
                  <Grid container className={classes.container} spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="name-helper">
                          <IntlMessages id="name" />
                        </InputLabel>
                        <Input
                          disabled
                          id="name-helper"
                          variant="outlined"
                          label={<IntlMessages id="name" />}
                          defaultValue={companyInfo?.name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="taxno-helper">
                          <IntlMessages id="phone" />
                        </InputLabel>
                        <Input
                          disabled
                          id="taxno-helper"
                          variant="outlined"
                          label={<IntlMessages id="phone" />}
                          fullWidth
                          defaultValue={companyInfo?.phone}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="taxoffice-helper">
                          <IntlMessages id="email" />
                        </InputLabel>
                        <Input
                          disabled
                          id="taxoffice-helper"
                          variant="outlined"
                          label={<IntlMessages id="email" />}
                          fullWidth
                          defaultValue={companyInfo?.email}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="name-helper">
                          <IntlMessages id="tax.office" />
                        </InputLabel>
                        <Input
                          disabled
                          id="type-helper"
                          variant="outlined"
                          label={<IntlMessages id="tax.office" />}
                          defaultValue={companyInfo?.tax_office}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="email-helper">
                          <IntlMessages id="tax.no" />
                        </InputLabel>
                        <Input
                          disabled
                          id="email-helper"
                          variant="outlined"
                          label={<IntlMessages id="tax.no" />}
                          fullWidth
                          defaultValue={companyInfo?.tax_no}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="name-helper">
                          <IntlMessages id="region" />
                        </InputLabel>
                        <Input
                          disabled
                          id="type-helper"
                          variant="outlined"
                          label={<IntlMessages id="refion" />}
                          defaultValue={
                            entities.regions[companyInfo?.region_id]?.name
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="email-helper">
                          <IntlMessages id="country" />
                        </InputLabel>
                        <Input
                          disabled
                          id="email-helper"
                          variant="outlined"
                          label={<IntlMessages id="country" />}
                          fullWidth
                          defaultValue={
                            entities.country[companyInfo?.country_id]?.name
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="name-helper">
                          <IntlMessages id="city" />
                        </InputLabel>
                        <Input
                          disabled
                          id="type-helper"
                          variant="outlined"
                          label={<IntlMessages id="city" />}
                          defaultValue={
                            entities.city[companyInfo?.city_id]?.name
                          }
                        />
                      </FormControl>
                    </Grid>
                    {companyInfo?.subcity_id ? (
                      <Grid item xs={6}>
                        <FormControl fullWidth className={classes.textStyle}>
                          <InputLabel htmlFor="email-helper">
                            <IntlMessages id="sub.city" />
                          </InputLabel>
                          <Input
                            disabled
                            id="email-helper"
                            variant="outlined"
                            label={<IntlMessages id="sub.city" />}
                            fullWidth
                            defaultValue={
                              entities.subcity
                                ? entities.subcity[companyInfo?.subcity_id]
                                    ?.name
                                : ""
                            }
                          />
                        </FormControl>
                      </Grid>
                    ) : (
                      <h1></h1>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Collapse>
          </CardContent>
        </Card>
      </FormControl>
      <Suspense fallback={<Loading />}>
        <TableContainer component={Paper}>
          <ContractRoom
            isAuthAdd={isAuthAdd}
            isAuthUpdate={isAuthUpdate}
            isAuthDelete={isAuthDelete}
          />
        </TableContainer>
      </Suspense>
      <Card className={classes.buttonCard}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={additionalPaxClick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="additional.pax.rates" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: additionalPaxExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={additionalPaxExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {additionalPaxExpanded ? (
                <AddPaxRates
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>

      <Card className={classes.buttonCard}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={boardSupplementClick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="board.supplements" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: boardSupplementExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>

          <Collapse in={boardSupplementExpanded} timeout="auto">
            {/* <BoardSupplament contractRooms={contractRooms} /> */}
            <Suspense fallback={<div>Loading...</div>}>
              {boardSupplementExpanded ? (
                <BoardSupplament
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>

      <Card className={classes.buttonCard}>
        <CardContent className={classes.cardContent}>
          <CardActions className={classes.cardActions} onClick={offersClick}>
            <Button className={classes.button} size="small">
              <IntlMessages id="offers" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: offersExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={offersExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {offersExpanded ? (
                <Offers
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={hotelExtrasClick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="extras.and.other.supplements" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: hotelExtrasExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={hotelExtrasExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {hotelExtrasExpanded ? (
                <Extras
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>

      <Card className={classes.buttonCard}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={restrictionsClick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="restrictions" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: restrictionsExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={restrictionsExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {restrictionsExpanded ? (
                <Restrictions
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard}>
        <CardContent className={classes.cardContent}>
          <CardActions className={classes.cardActions} onClick={kickbackClick}>
            <Button className={classes.button} size="small">
              <IntlMessages id="kickbacks" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: kickbackExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={kickbackExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {kickbackExpanded ? (
                <Kickback
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard}>
        <CardContent className={classes.cardContent}>
          <CardActions className={classes.cardActions} onClick={cancelPolClick}>
            <Button className={classes.button} size="small">
              <IntlMessages id="cancellation.policies.detail" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: cancelPolExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={cancelPolExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {cancelPolExpanded ? (
                <ContractCancellationPolicies
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={paymentCondOnCLick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="payment.conditions" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: paymentConExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={paymentConExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {paymentConExpanded ? (
                <PaymentCondition
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={specialConClick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="special.conditions.and.notes" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: specialConExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={specialConExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {specialConExpanded ? (
                <SpecialNotes
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={termsConOnCLick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="terms.and.conditions" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: termsConExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={termsConExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {termsConExpanded ? (
                <ContractTermsConditions
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
    </div>
  );
}
