import GridContainer from '@jumbo/components/GridContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Button, Divider, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const numbers = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

function RoomUpdate({ no, selectedContract, reservationRooms, setReservationRooms, setRoomsLength, setDeletedRooms, type }) {
  const contract_rooms = useSelector(state => state.contract.contract_room);
  const allRoomTypes = useSelector(state => state.rooms.rooms.roomType);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(
    reservationRooms[no] == undefined
      ? {}
      : Object.values(contract_rooms).find(room => room.id === reservationRooms[no].contract_room_id) !== undefined
      ? Object.values(contract_rooms).find(room => room.id === reservationRooms[no].contract_room_id)
      : {},
    [],
  );

  const handleDeleteRoom = () => {
    console.log(no);
    console.log(reservationRooms);
    if (reservationRooms[no]?.id) {
      setDeletedRooms(prev => [...prev, reservationRooms[no].id]);
    }
    setRoomsLength(prev => prev - 1);
    var oldRooms = reservationRooms;
    delete oldRooms[no];
    var oldKeys = Object.keys(oldRooms);
    var newRooms = {};
    oldKeys.forEach((key, index) => {
      newRooms[index + 1] = oldRooms[key];
    });
    setReservationRooms(newRooms);
  };
  const handleRoomChange = target_name => {
    setReservationRooms(prev => {
      let maxRow =
        prev[no].adult !== undefined
          ? prev[no].adult
          : 0 + prev[no].child !== undefined
          ? prev[no].child
          : 0 + prev[no].infant !== undefined
          ? prev[no].infant
          : 0;
      let customersRow = [];
      for (let i = 0; i < maxRow; i++) {
        customersRow.push({
          gender: '',
          name: '',
          surname: '',
          birthday: '',
          age: '',
        });
      }
      return {
        ...prev,
        [no]: { ...prev[no], customers: customersRow },
      };
    });
  };

  useEffect(() => {
    if (selectedContract !== undefined) {
      var contract_room_types_with_code = [];
      selectedContract.contract_room.forEach(room => {
        var newRoom = { ...contract_rooms[room] };
        newRoom['room_code'] = allRoomTypes[newRoom.room_id].code;
        contract_room_types_with_code.push(newRoom);
      });
      setRooms(contract_room_types_with_code);
    }
  }, [selectedContract]);
  const handleChange = e => {
    if (e.target.name === 'contract_room_id') {
      setSelectedRoom(rooms.find(room => room.id === e.target.value));
    }
    const { name, value } = e.target;
    // Set values
    setReservationRooms(prev => ({
      ...prev,
      [no]: { ...prev[no], ...{ [name]: value } },
    }));
    setSelectedRoom(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    handleRoomChange(e.target.name);
  };
  return (
    <GridContainer direction="row" alignItems="center">
      <Grid item xs={1} justifyContent="center" alignItems="center">
        <Box fullWidth style={{ paddingTop: '15px' }}>
          <Typography> No :{no}</Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="room"
          select
          label={<IntlMessages id="room" />}
          name="contract_room_id"
          fullWidth
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
          onChange={handleChange}
          InputProps={{
            readOnly: type ? true : false,
          }}
          value={reservationRooms[no] ? reservationRooms[no].contract_room_id : null}>
          {rooms.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.room_code + ' - ' + option.agency_room_name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="adult"
          select
          label={<IntlMessages id="adult" />}
          name="adult"
          fullWidth
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
          value={reservationRooms[no] ? reservationRooms[no].adult : null}
          onChange={handleChange}
          disabled={!reservationRooms[no]?.contract_room_id ? true : false}
          InputProps={{
            readOnly: type ? true : false,
          }}>
          {numbers.map((option, index) => {
            return selectedRoom?.max_adult >= index && selectedRoom?.min_adult <= index ? (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ) : null;
          })}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="child"
          select
          label={<IntlMessages id="child" />}
          name="child"
          fullWidth
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
          value={reservationRooms[no] ? reservationRooms[no].child : null}
          onChange={handleChange}
          disabled={!reservationRooms[no]?.contract_room_id ? true : false}
          InputProps={{
            readOnly: true,
          }}>
          {numbers.map((option, index) => {
            return selectedRoom?.max_child >= index && selectedRoom?.min_child <= index ? (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ) : null;
          })}
        </TextField>
      </Grid>
      <Grid item xs={2} style={{ paddingTop: '5px' }}>
        <TextField
          id="infant"
          select
          label={<IntlMessages id="infant" />}
          name="infant"
          fullWidth
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
          onChange={handleChange}
          value={reservationRooms[no] ? reservationRooms[no].infant : null}
          disabled={!reservationRooms[no]?.contract_room_id ? true : false}
          InputProps={{
            readOnly: true,
          }}>
          {numbers.map((option, index) => {
            return selectedRoom?.max_infant >= index && 0 <= index ? (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ) : null;
          })}
        </TextField>
      </Grid>
      <Grid item xs={2} style={{ paddingTop: '15px', paddingLeft: '30px' }}>
        {no !== 1 ? (
          <Button variant="contained" color="primary" onClick={handleDeleteRoom} disabled={type ? true : false}>
            <IntlMessages id="delete" />
          </Button>
        ) : null}
      </Grid>
      <Divider />
    </GridContainer>
  );
}

export default RoomUpdate;
