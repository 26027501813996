import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  getTermsConditions,
  addTermsConditions,
  updateTermsConditions,
  deleteTermsConditions,
  getContractTermsConditions,
  addContractTermsConditions,
  updateContractTermsConditions,
  deleteContractTermsConditions,
  getAllContractTermsConditions
} from "redux/actions/termsConditions";
import axios from "./axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/
const MySwal = withReactContent(Swal);
export const getTermsConditionsService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("termsConditions")
    .then(res => {
      dispatch(getTermsConditions(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addTermsConditionsService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("termsConditions", data)
    .then(res => {
      dispatch(addTermsConditions(res));
      dispatch(fetchSuccess());
      sendLogService.termsConditionsServiceLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateTermsConditionsService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("termsConditions", data)
    .then(res => {
      dispatch(updateTermsConditions(res));
      dispatch(fetchSuccess());
      sendLogService.termsConditionsServiceLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteTermsConditionsService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`termsConditions/${id}`)
    .then(res => {
      dispatch(deleteTermsConditions(res));
      dispatch(fetchSuccess());
      sendLogService.termsConditionsServiceLog(res, id);
      return res.data.code;
    })
    .catch(e => {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `${e.response.data.message}`
      });
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
export const getAllContractTermsConditionsService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`allContractTermsConditions`)
    .then(res => {
      dispatch(getAllContractTermsConditions(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const getContractTermsConditionsService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("contractTerms")
    .then(res => {
      dispatch(getContractTermsConditions(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addContractTermsConditionsService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("contractTerms", data)
    .then(res => {
      dispatch(addContractTermsConditions(res));
      dispatch(fetchSuccess());
      sendLogService.conTermsServiceLogs(res, data);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateContractTermsConditionsService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("contractTerms", data)
    .then(res => {
      dispatch(updateContractTermsConditions(res));
      dispatch(fetchSuccess());
      sendLogService.conTermsServiceLogs(res, data);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteContractTermsConditionsService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`contractTerms/${id}`)
    .then(res => {
      dispatch(deleteContractTermsConditions(res));
      dispatch(fetchSuccess());
      sendLogService.conTermsServiceLogs(res, id);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
