import CardHeader from '@mui/material/CardHeader';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Divider, Grid, TextField } from '@material-ui/core';
import React from 'react';

function ReservationDetails(props) {
  const { reservation } = props;
  const CmtCardStyle = {
    cardheader: {
      paddingLeft: '0px',
    },
  };
  const dividerStyle = {
    marginBottom: {
      margin: '0px 0px 24px 0',
    },
    margin: {
      margin: '34px 0px 24px 0',
    },
  };

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={3}>
      <Grid item xs={12}>
        <CardHeader title={<IntlMessages id="reseervation.details" />} sx={{ color: '#33658a' }} />
        <Divider style={dividerStyle.marginBottom} />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="creator_name"
          label={<IntlMessages id="creator.name" />}
          name="creator_name"
          value={reservation.creator_name}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="booking_date"
          label={<IntlMessages id="booking.date" />}
          name="selling_date"
          type="date"
          format="DD/MM/YYYY"
          value={reservation.selling_date}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="lead_name"
          label={<IntlMessages id="lead.name" />}
          name="lead_name"
          value={reservation.lead_name}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="voucher_no"
          label={<IntlMessages id="proxo.booking.no" />}
          name="voucher_no"
          value={reservation.voucher_no}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="voucher_no"
          label={<IntlMessages id="operator.booking.no" />}
          name="voucher_no"
          value={reservation.voucher_no}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ReservationDetails;
