import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { createRatePlan } from 'redux/actions/ratePlan';
import axios from './axios';
/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';

export const createRatePlanService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('ratePlan', data)
    .then(res => {
      dispatch(createRatePlan(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateRatePlanOpensaleService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('updateRatePlanOpensale', data)
    .then(res => {
      dispatch(createRatePlan(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateRatePlanStopsaleService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('updateRatePlanStopsale', data)
    .then(res => {
      dispatch(createRatePlan(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateRatePlanAllotmentUpdateService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('updateRatePlanAllotmentUpdate', data)
    .then(res => {
      //dispatch(createRatePlan(res));
      dispatch(fetchSuccess());
      return res.data.data;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
