import { SEND_HELP_MAIL } from '../actions/types';

const initialState = {
  result: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_HELP_MAIL:
      return {
        ...state,
        result: action.payload,
      };
    default:
      return state;
  }
};
