import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getStopSale, addStopSale } from 'redux/actions/stopSale';
import axios from './axios';

/** PROXOLAB LOGGER * */
import sendLogService from './proxolabLogger/Logger.js';
/** PROXOLAB LOGGER * */

export const getStopSaleService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('stopsale')
    .then(res => {
      dispatch(getStopSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addStopSaleService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('stopsale', data)
    .then(res => {
      dispatch(addStopSale(res));
      dispatch(fetchSuccess());
      sendLogService.stopSaleLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
