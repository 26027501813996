import { GET_OPEN_SALE, ADD_OPEN_SALE } from '../actions/types';

const initialState = {
  openSale: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPEN_SALE:
      return { ...state, openSale: action.payload };
    case ADD_OPEN_SALE:
      return { ...state, openSale: [...state.openSale, action.payload.data] };
    default:
      return state;
  }
};
