import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";
import { sendMail } from "redux/actions/sendMail";

export const sendMailServices = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post(`sendmail`, data)
    .then(res => {
      dispatch(sendMail(res.data));
      dispatch(fetchSuccess());
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
