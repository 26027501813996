import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { errstyle } from './ErrorStyle';
import { Button } from '@material-ui/core';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box sx={errstyle.layout}>
          <Box sx={errstyle.header}>
            <NewReleasesIcon color="error" style={errstyle.icons} />
            <h2>
              <span style={errstyle.headerText}>Fatal Error</span>
            </h2>
          </Box>
          <Box sx={errstyle.content}>{error.message}</Box>
          <Box sx={errstyle.buttons}>
            <Button onClick={resetErrorBoundary} variant="contained" style={errstyle.cancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default ErrorFallback;
