import {fetchStart, fetchError, fetchSuccess} from "redux/actions";
import {getAllotmentType, addAAllotmentType} from "redux/actions/allotmentType";
import axios from "./axios";

export const getAllotmentTypeService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("allotmentType")
    .then(res => {
      dispatch(getAllotmentType(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
