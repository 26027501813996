import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import GridContainer from "@jumbo/components/GridContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import { Box, Button, Divider, makeStyles } from "@material-ui/core";
import { toInteger } from "lodash";
import RoomSystem from "./RoomSystem";
import CustomerTab from "./CustomerTab";
import HotelDetais from "./HotelDetails";
import CmtCardFooter from "@coremat/CmtCard/CmtCardFooter";
import { useDispatch, useSelector } from "react-redux";
import { addReservationService } from "services/reservation";
import ContractSelection from "./ContractSelection";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CardHeader from "@mui/material/CardHeader";
import { useIntl } from "react-intl";
import message from "../MessageStyle/messageStyle";
import calculate from "../NewReservationCalculateStyle/calculate";
import axios from "services/axios";

import moment from "moment";

const MySwal = withReactContent(Swal);
const useStyles = makeStyles(theme => ({
  greenButton: {
    backgroundColor: "#4caf50",
    color: "#ffffff"
    // you can add your specific CSS here.
  },
  confirmButton: {
    backgroundColor: "#4caf50",
    color: "#ffffff"
    // you can add your specific CSS here.
  }
}));

const AddReservation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [roomCount, setRoomCount] = useState(1);
  const creator_fullname = useSelector(state => state.auth.authUser.fullname);
  const contracts_selector = useSelector(state => {
    let newContracts = [];
    state.onSaleContract.onSaleContract.map(contract => {
      newContracts.push(
        Object.values(state.contract.contracts).find(
          contractDetails => contractDetails.id === contract.contract_id
        )
      );
    });
    return newContracts;
  });

  const today = new Date();
  const [roomTab, setRoomTab] = useState(0);

  const reservation_initial_state = {
    hotel_id: "",
    operator_id: "",
    creator_name: creator_fullname,
    selling_date: `${today.getFullYear()}-${parseInt(today.getMonth() + 1)}-${
      today.getDate() < 10 ? "0" + today.getDate() : today.getDate()
    }`,
    rooms: [],
    contract_id: ""
  };
  const [availableContracts, setAvailableContracts] = useState([]);
  const [reservationDetail, setReservationDetail] = useState(
    reservation_initial_state
  );
  const [selectedContract, setSelectedContract] = useState(null);
  const [saveReservationDB, setSaveReservationDB] = useState();
  const [saveButtonVisible, setSaveButtonVisible] = useState(true);

  const onSubmit = async reservation => {
    //Get message input value from Reservation Message Section
    const message = textMessage.current.value;
    //Reservation message length validation here

    if (!reservation.contract_id) {
      reservation.contract_id = 0;
    }

    //Add text message to reservation object
    reservation.message = message;

    /*********************** RESERVATION CALCULATE REQUEST AND PROCESS ************************/
    const token = localStorage.getItem("token");
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    await axios
      .post("/calculatereservation", reservation)
      .then(res => {
        console.log(res);
        setSaveReservationDB(reservation);
        setSaveButtonVisible(false);
      })
      .catch(err => {
        console.log(err);
      });
    /******************************************************************************************/
  };

  const handleClose = type => {
    var adult_num = 0;
    var child_num = 0;
    var infant_num = 0;
    Object.keys(reservationDetail.rooms).forEach(key => {
      adult_num += Number(reservationDetail.rooms[key].adult);
      child_num += Number(reservationDetail.rooms[key].child);
      infant_num += Number(reservationDetail.rooms[key].infant);
    });
    const resRooms = Object.values(reservationDetail.rooms);
    var reservation = {
      ...reservationDetail,
      type: type,
      lead_name: resRooms[0].customers[0].name,
      adult_num: adult_num,
      child_num: child_num,
      infant_num: infant_num
    };
    onSubmit(reservation);
  };

  const [min, setMin] = useState({
    startDate: null,
    endDate: null,
    contractId: null
  });
  const [minStayControl, setMinStayControl] = useState(true);
  const [contPeriod, setPeriod] = useState();

  useEffect(() => {
    axios
      .get("period", {
        params: {
          cId: 1
        }
      })
      .then(res => {
        setPeriod(res.data.data);
      });
  }, []);
  const handleChange = e => {
    const { name, value } = e.target;

    if (name == "start_date") {
      setMin({ ...min, startDate: value });
    }

    if (name === "end_date") {
      setMin({ ...min, endDate: value });
    }
    if (name === "contract_id") {
      setMin({ ...min, contractId: value });
    }

    // Set values
    if (name === "contract_id") {
      setReservationDetail(prev => ({
        ...prev,
        [name]: Number(value.split("-")[0])
      }));
    } else {
      setReservationDetail(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const c = contPeriod?.filter(val => {
    return String(val.contract_id) == String(min.contractId);
  });

  const allMinStay = c?.map(param => {
    let obj = {
      season: param.code,
      minStay: param.min_stay,
      startDate: param.start_date,
      endDate: param.end_date
    };
    return obj;
  });

  var date1 = moment(min.startDate);
  var date2 = moment(min.endDate);
  var diff = date2.diff(date1, "days");

  if (allMinStay?.length > 0) {
    const x = allMinStay.map((val, key) => {
      let contractStartDate = moment(val.startDate);
      let contractEndDate = moment(val.endDate);

      let resStartDate = moment(min.startDate);
      let resEndDate = moment(min.endDate);

      let resStartDateControl = resStartDate.isBetween(
        contractStartDate,
        contractEndDate,
        "days",
        true
      );

      let resEndDateControl = resEndDate.isBetween(
        contractStartDate,
        contractEndDate,
        "days",
        true
      );

      if (diff < val.minStay) {
        reservationDetail.start_date = "";
        reservationDetail.end_date = "";
        Swal.fire("This date is not valid for min stay date");
      }
    });
  }

  useEffect(() => {
    if (
      reservationDetail.operator_id &&
      reservationDetail.hotel_id &&
      reservationDetail.start_date &&
      reservationDetail.end_date
    ) {
      let available_contracts = contracts_selector.filter(
        contract =>
          reservationDetail.hotel_id === contract?.hotel_id &&
          contract.operators.includes(reservationDetail.operator_id) &&
          new Date(reservationDetail.start_date) <=
            new Date(contract.end_date) &&
          new Date(reservationDetail.start_date) >=
            new Date(contract.start_date)
      );

      setAvailableContracts(available_contracts);
    }
  }, [
    reservationDetail.operator_id,
    reservationDetail.hotel_id,
    reservationDetail.start_date,
    reservationDetail.end_date
  ]);

  useEffect(() => {
    setRoomTab(Object.keys(reservationDetail.rooms).length);
  }, [reservationDetail.rooms]);

  const handleSubmitControl = () => {
    let unGenderedPersons = [];

    Object.values(reservationDetail.rooms).forEach(room => {
      unGenderedPersons = [
        ...room.customers.filter(person => person.gender === "")
      ];
    });

    if (unGenderedPersons.length == 0) {
      handleClose("mono");
    } else {
      MySwal.fire({
        icon: "error",
        title: "Pax not defined",
        text: "Undefined pax exists please define pax",
        timerProgressBar: true
      });
    }
  };

  // Oda eklendiğinde veya eklendikten sonra customerlar girildikten sonra delete butonu ile silinirse customer tab kısmında silinmesi için yazılan fonk.
  const handleDeleteReservartionRoom = id => {
    delete reservationDetail.rooms[id];
  };

  const handleAddRoomReservation = (no, room) => {
    const maxCustomer =
      toInteger(room.adult) + toInteger(room.child) + toInteger(room.infant);

    setReservationDetail(prev => ({
      ...prev
    }));
    const customersArray = [];
    for (let i = 0; i < maxCustomer; i++) {
      customersArray.push({
        gender: room.hasOwnProperty("customers")
          ? room.customers[i]?.hasOwnProperty("gender")
            ? room.customers[i].gender
            : ""
          : "",
        name: room.hasOwnProperty("customers")
          ? room.customers[i]?.hasOwnProperty("name")
            ? room.customers[i].name
            : ""
          : "",
        surname: room.hasOwnProperty("customers")
          ? room.customers[i]?.hasOwnProperty("surname")
            ? room.customers[i].surname
            : ""
          : "",
        birthday: room.hasOwnProperty("customers")
          ? room.customers[i]?.hasOwnProperty("birthday")
            ? room.customers[i].birthday
            : ""
          : "",
        age: room.hasOwnProperty("customers")
          ? room.customers[i]?.hasOwnProperty("age")
            ? room.customers[i].age
            : ""
          : ""
      });
    }

    room.customers = customersArray;

    setReservationDetail(prev => ({
      ...prev,
      rooms: { ...prev.rooms, [no]: room }
    }));

    setRoomCount(0);
  };
  // ** Aynı sayıda oda eklediğimizmide state değişmediği için oda ekleyemem sorununu çözmek için yazılan fonksiyon.
  const [trigger, setTrigger] = useState(false);
  const reservationAddRoom = value => {
    if (roomCount === value) {
      setTrigger(!trigger);
      setRoomCount(value);
    } else {
      setRoomCount(value);
    }
  };

  /********************ROOM DETAILS COMPONENT TURNS HERE **********************/

  {
    /***************************************************************************/
  }

  const CmtCardStyle = {
    cardheader: {
      paddingLeft: "0px"
    }
  };

  const dividerStyle = {
    marginBottom: {
      margin: "0px 0px 24px 0"
    },
    margin: {
      margin: "34px 0px 24px 0"
    }
  };

  /**************************** MESSAGE AREA JS SECTION **************************/

  let toggle = false;
  const textMessage = useRef();
  const clear = () => {
    textMessage.current.value = "";
  };
  let multipleRoom = useRef();
  /*******************************************************************************/

  /****************************** RESERVATION SAVE TO DB *************************/
  const saveReservationDBFunc = () => {
    dispatch(addReservationService(saveReservationDB));
    textMessage.current.value = "";
    setReservationDetail(reservation_initial_state);
    setSaveButtonVisible(true);

    MySwal.fire({
      icon: "success",
      title: "Success",
      text: `Reservation successfully created `,
      timerProgressBar: true
    });
  };
  /*******************************************************************************/

  return (
    <GridContainer>
      <Grid item xs={12}>
        <CmtCard>
          <CardHeader
            style={{ marginLeft: 1 + "rem" }}
            title={intl.formatMessage({ id: "new.reservation" })}
            sx={{ color: "#33658a" }}
          />
          <Divider />
          <CmtCardContent>
            <GridContainer>
              {/***** FIRST COMPONENT (HOTEL) ******/}
              <Grid item xs={12}>
                <HotelDetais
                  handleChange={handleChange}
                  reservationDetail={reservationDetail}
                />
              </Grid>
              {/****************************/}

              {/********SECOND COMPONENT (CONTRACT)*******************/}
              <Grid style={{ marginTop: "4rem" }} item xs={12}>
                {reservationDetail.operator_id &&
                minStayControl &&
                reservationDetail.hotel_id &&
                reservationDetail.selling_date &&
                reservationDetail.start_date &&
                reservationDetail.end_date ? (
                  <>
                    {" "}
                    <CardHeader
                      title={intl.formatMessage({ id: "contract.selection" })}
                      sx={{ color: "#33658a", marginRight: "4rem" }}
                    />
                    <Divider style={dividerStyle.marginBottom} />
                    <ContractSelection
                      handleChange={handleChange}
                      reservationDetail={reservationDetail}
                      contracts={availableContracts}
                      setReservationDetail={setReservationDetail}
                      setSelectedContract={setSelectedContract}
                    />{" "}
                  </>
                ) : (
                  ""
                )}
              </Grid>

              {/*********************************************************/}

              {/**************ROOM DETAILS COMPONENT*********************/}
              <Grid style={{ marginTop: "4rem" }} item xs={12}>
                {reservationDetail.operator_id &&
                reservationDetail.hotel_id &&
                reservationDetail.selling_date &&
                reservationDetail.start_date &&
                reservationDetail.end_date &&
                reservationDetail.board_id ? (
                  <>
                    <CardHeader
                      title={intl.formatMessage({
                        id: "room.details"
                      })}
                      sx={{ color: "#33658a", marginRight: "4rem" }}
                    />

                    <Divider style={dividerStyle.marginBottom} />
                    <RoomSystem
                      handleRoomAdd={handleAddRoomReservation}
                      trigger={trigger}
                      roomCount={roomCount}
                      setReservationDetail={setReservationDetail}
                      reservationDetail={reservationDetail}
                      setRoomTab={setRoomTab}
                      rooms={reservationDetail.rooms}
                      selectedContract={selectedContract}
                      handleDeleteReservartionRoom={
                        handleDeleteReservartionRoom
                      }
                    />
                    <Divider style={dividerStyle.margin} />
                  </>
                ) : (
                  ""
                )}
              </Grid>

              <Grid container justifyContent="flex-end" alignItems="center">
                {selectedContract !== null &&
                reservationDetail.board_id !== undefined ? (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                      }}
                    >
                      <label>
                        {intl.formatMessage({ id: "number.for.multiple.room" })}
                      </label>
                      <Box
                        style={{
                          display: "flex",
                          justifiyContent: "space-between",
                          alignItems: "center",
                          marginTop: "1rem",
                          flexDirection: "row"
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            reservationAddRoom(
                              Number(multipleRoom.current.value)
                            );
                            //setRoomCount(Number(multipleRoom.current.value));
                          }}
                        >
                          <IntlMessages id="add.room" />
                        </Button>
                        <input
                          ref={multipleRoom}
                          type="number"
                          style={{
                            border: "none",
                            boxShadow: "0 0 3px black",
                            outline: "none",
                            marginLeft: "1rem",
                            borderRadius: "5px",
                            width: "3rem",
                            height: "1.8rem",
                            textAlign: "center"
                          }}
                          min="1"
                          max="30"
                        />
                      </Box>
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Grid>
              {/***********************************************************/}

              {/*********************CUSTOMER TABLE ***********************/}
              <Grid item xs={12}>
                <CustomerTab
                  roomLength={roomTab}
                  reservationDetail={reservationDetail}
                  setCustomer={setReservationDetail}
                  selectedContract={selectedContract}
                />
              </Grid>
              {/***********************************************************/}

              {/****************** RESERVATION MESSAGE ********************/}
              {reservationDetail.operator_id &&
              reservationDetail.hotel_id &&
              reservationDetail.selling_date &&
              reservationDetail.start_date &&
              reservationDetail.end_date &&
              Object.values(reservationDetail.rooms).length > 0 &&
              reservationDetail.board_id ? (
                <Grid item xs={12}>
                  <Box style={message.layout}>
                    <CardHeader
                      title={intl.formatMessage({
                        id: "new.reservation.message"
                      })}
                      sx={{
                        color: "#33658a",
                        marginRight: "4rem",
                        marginTop: "2rem"
                      }}
                    />
                    <Box style={message.head}>
                      <input
                        type="text"
                        ref={textMessage}
                        maxLength="300"
                        style={message.text}
                      />
                      <Button onClick={clear} style={message.clear}>
                        Clear
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ) : (
                ""
              )}
              {/***********************************************************/}

              {/*********************RESERVATİON CALCULATE SECTION***********/}
              {reservationDetail.operator_id &&
              reservationDetail.hotel_id &&
              reservationDetail.selling_date &&
              reservationDetail.start_date &&
              reservationDetail.end_date &&
              Object.values(reservationDetail.rooms).length > 0 &&
              reservationDetail.board_id ? (
                <Grid item xs={12}>
                  <Box style={calculate.layout}>
                    <CardHeader
                      title={intl.formatMessage({
                        id: "new.reservation.calculate.result"
                      })}
                      sx={{ color: "#33658a", marginRight: "4rem" }}
                    />
                    <Divider style={{ marginTop: "1rem" }} />
                    <Box style={calculate.content}>
                      <Button
                        variant="contained"
                        className={classes.greenButton}
                        disabled={
                          reservationDetail.rooms.length === 0 ? true : false
                        }
                        onClick={() => handleSubmitControl()}
                      >
                        Calculate
                      </Button>
                      <Box style={calculate.contentSection1}>
                        <Box style={calculate.cell}>
                          <label
                            style={calculate.cellLabel}
                            htmlFor="totalPrice"
                          >
                            Total Price
                          </label>
                          <input
                            readOnly
                            style={calculate.cellInput}
                            type="text"
                          />
                        </Box>
                        <Box style={calculate.cell}>
                          <label style={calculate.cellLabel} htmlFor="netPrice">
                            Net Price
                          </label>
                          <input
                            readOnly
                            style={calculate.cellInput}
                            type="text"
                          />
                        </Box>
                        <Box style={calculate.cell}>
                          <label style={calculate.cellLabel} htmlFor="discount">
                            Discount
                          </label>
                          <input
                            readOnly
                            style={calculate.cellInput}
                            type="text"
                          />
                          <input
                            readOnly
                            style={calculate.cellInput}
                            type="text"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ) : (
                ""
              )}
              {/********************************************************************/}
            </GridContainer>
          </CmtCardContent>
          <Divider />

          {/***********************FOOTER********************************/}
          <CmtCardFooter style={{ width: "100%" }}>
            <Button
              style={{
                backgroundColor: saveButtonVisible == true ? "grey" : "#672323",
                color: "#FFFFFF"
              }}
              variant="contained"
              disabled={saveButtonVisible}
              onClick={saveReservationDBFunc}
            >
              {" "}
              Save Reservation
            </Button>
          </CmtCardFooter>
          {/*************************************************************/}
        </CmtCard>
      </Grid>
    </GridContainer>
  );
};

export default AddReservation;
