import { GET_ADDPAXRATES, ADD_ADDPAXRATES, UPDATE_ADDPAXRATES, DELETE_ADDPAXRATES } from './types';

export const getAddPaxRates = additionalPax => dispatch => {
  dispatch({ type: GET_ADDPAXRATES, payload: additionalPax });
};

export const addAddPaxRates = additionalPax => dispatch =>
  dispatch({
    type: ADD_ADDPAXRATES,
    payload: additionalPax,
  });

export const updateAddPaxRates = addPaxRate => dispatch =>
  dispatch({
    type: UPDATE_ADDPAXRATES,
    payload: addPaxRate,
  });

export const deleteAddPaxRates = id => dispatch =>
  dispatch({
    type: DELETE_ADDPAXRATES,
    payload: id,
  });
