//HOOKS
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//SERVICES
import { getHotelService } from "services/hotels";
import { getOperatorService } from "services/operator";
import { getMarketService } from "services/market";
import { getReservationsService } from "services/reservation";
import {
  getAllCountriesService,
  getRegionsService
} from "services/destinations";
//MATERIAL-UI COMPONENTS
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
import HotelIcon from "@mui/icons-material/Hotel";
import SouthAmericaIcon from "@mui/icons-material/SouthAmerica";
import FlagIcon from "@mui/icons-material/Flag";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DiscountIcon from "@mui/icons-material/Discount";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//SWAL
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
//STYLES
import searchPrice from "./SearchPriceStyle/searchPrice";
import moment from "moment";
import axios from "services/axios";
import IntlMessages from "@jumbo/utils/IntlMessages";
import ChildAge from "./ChildAge";
import { set } from "lodash";
//import ChildAge from "./ChildAge";

const SearchPrice = React.memo(() => {
  const [isFinished, setIsFinished] = useState(false);
  const [
    filterSectionVisibilityChange,
    setFilterSectionVisibilityChange
  ] = useState(true);
  const [v, setV] = useState("block");

  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  const [operatorIsEmpty, setOperatorIsEmpty] = useState(true);

  //CREATE PAYLOAD WITH DEFINITION STATE
  const [definitions, setDefinitions] = useState({
    hotel: null,
    region: null,
    country: null,
    operator: null,
    market: null,
    adult: null,
    child: 0,
    date: null,
    stay: null,
    childNumber: null,
    childAges: []
  });
  useEffect(() => {
    (() => {
      Promise.all([
        dispatch(getOperatorService()),
        dispatch(getMarketService()),
        dispatch(getReservationsService()),
        dispatch(getHotelService()),
        dispatch(getRegionsService()),
        dispatch(getAllCountriesService())
      ]);
    })();

    setIsFinished(true);
  }, []);

  const { operators } = useSelector(({ operators }) => operators);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { markets } = useSelector(({ markets }) => markets);
  const { regions } = useSelector(({ destinations }) => destinations);
  const { allCountries } = useSelector(({ destinations }) => destinations);
  /******* FIND OPERATOR COUNTRIES WITH USE { definition.operator.id } ****************/
  const operatorCountries =
    definitions?.operator &&
    Object.values(operators).find(
      operator => definitions?.operator.id == operator.id
    ).countries;
  /**********************************************************************************************/
  const marketArr = Object.values(markets);

  /**
   * Market ve operatör db bağlantısı olmadığı için ortak country'lerinden
   * eşleştirme yapmak için aşağıdaki kod bloğu çalışmaktadır.
   * Buradaki kodun nasıl çalıştığını bir Allah bir ben biliyordum.
   * Artık sadece Allah biliyor. Fazla kurcalamayın :))))
   */
  let filteredMarketArray = marketArr.reduce((acc, val) => {
    const hasCountry = val.countries?.find(country =>
      operatorCountries?.find(operatorCountry => operatorCountry.id == country)
    );
    if (hasCountry !== undefined) {
      acc.push(val);
    }
    return acc;
  }, []);

  const test = filteredMarketArray != undefined ? filteredMarketArray : [];
  /***************************************************************************/
  //console.log("operatorCounries", operatorCountries);
  const checkMarketCountry = () => {
    if (operatorIsEmpty === false) {
      if (test.length === 0) {
        Swal.fire(
          `Market and operator countries must be equal. Please check first it at definitions`
        );
      }
    } else {
      Swal.fire(`Please select Operators`);
    }
  };

  const useStyles = makeStyles(theme => ({
    datagrid: {
      marginTop: 5 + "rem",
      "& .MuiDataGrid-cell": {
        display: "flex!important",
        justifyContent: "center!important",
        alignItems: "center!important",
        flexDirection: "row!important",
        letterSpacing: ".2rem",
        color: "black",
        "&:nth-child(2)": {
          color: "#30919B",
          fontWeight: "bolder",
          fontSize: ".9rem"
        }
      },

      "& .MuiDataGrid-columnHeaderTitleContainer": {
        display: "flex!important",
        justifyContent: "center!important",
        alignItems: "center!important",
        flexDirection: "row!important"
      }
    },
    aut: {
      background: "white"
    }
  }));
  const classes = useStyles();
  const columns = [
    {
      field: "id",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          {" "}
          <ConfirmationNumberIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />{" "}
          &nbsp; ID
        </span>
      ),
      width: 90
    },
    {
      field: "hotel_name",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          {" "}
          <HotelIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />{" "}
          &nbsp; Hotel Name
        </span>
      ),
      width: 270,
      editable: true
    },
    {
      field: "region_name",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          <SouthAmericaIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />
          &nbsp;Region Name
        </span>
      ),
      width: 270,
      editable: true
    },
    {
      field: "country_Name",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          {" "}
          <FlagIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />
          &nbsp;Country name
        </span>
      ),
      width: 270,
      editable: true
    },
    {
      field: "net_price",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          <AttachMoneyIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />
          &nbsp;Net Price
        </span>
      ),
      width: 270,
      editable: true
    },
    {
      field: "discount",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          <DiscountIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />
          &nbsp; Discount
        </span>
      ),
      width: 270,
      editable: true
    },
    {
      field: "total_price",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          <CardTravelIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />
          &nbsp;Total Price
        </span>
      ),
      width: 270,
      editable: true
    }
  ];
  //Backendden gelen datalar ve title 'ları uygun şekilde grid yapısına uyarlanacak
  const rows = [
    {
      id: 1,
      hotel_name: "Snow",
      region_name: "Jon",
      country_Name: 35,
      net_price: 1000,
      discount: 5,
      total_price: 124
    },
    {
      id: 2,
      hotel_name: "Lannister",
      region_name: "Cersei",
      country_Name: 42,
      net_price: 1000,
      discount: 5,
      total_price: 124
    },
    {
      id: 3,
      hotel_name: "Lannister",
      region_name: "Jaime",
      country_Name: 45,
      net_price: 1000,
      discount: 5,
      total_price: 124
    },
    {
      id: 4,
      hotel_name: "Stark",
      region_name: "Arya",
      country_Name: 16,
      net_price: 1000,
      discount: 5,
      total_price: 124
    },
    {
      id: 5,
      hotel_name: "Targaryen",
      region_name: "Daenerys",
      country_Name: 23,
      net_price: 2334,
      discount: 5,
      total_price: 124
    },
    {
      id: 6,
      hotel_name: "Melisandre",
      region_name: null,
      country_Name: 15,
      net_price: 1000,
      discount: 5,
      total_price: 124
    },
    {
      id: 7,
      hotel_name: "Clifford",
      region_name: "Ferrara",
      country_Name: 44,
      net_price: 1000,
      discount: 5,
      total_price: 124
    },
    {
      id: 8,
      hotel_name: "Frances",
      region_name: "Rossini",
      country_Name: 36,
      net_price: 1000,
      discount: 5,
      total_price: 124
    },
    {
      id: 9,
      hotel_name: "Roxie",
      region_name: "Harvey",
      country_Name: 65,
      net_price: 1000,
      discount: 5,
      total_price: 124
    }
  ];

  /**************** DATE INPUT VALUE DEFAULT TODAY **************/
  var curr = new Date();
  curr.setDate(curr.getDate());
  var today = curr.toISOString().substring(0, 10);
  /****************************************************************/

  /******************HANDLE ALL CHANGES *************************/
  const handleSubmit = async () => {
    if (
      definitions.adult != null &&
      definitions.stay != null &&
      definitions.date != null
      //definitions.child > 0
    ) {
      let definitionsDate = new Date(definitions.date);

      //Add Stay date to Check in date
      definitionsDate = moment(definitionsDate, "DD-MM-YYYY")
        .add(definitions.stay, "days")
        .format("DD/MM/YYYY");
      definitions.stayEndDate = definitionsDate;

      //DELETE definition.child
      //definition.child property use for create child age modal and child age open/close toggle
      //(with definition.child.length)---> (enter for loop) ----> (create child age input and label)
      delete definitions.child;
      console.log(definitions);
      /************************* SEND PAYLOAD TO BACKEND *******************/
      await axios
        .post("searchprice", definitions)
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
      /********************************************************************/

      /**************************** RESULT - FILTER COMPONENT VISIBILITY CHANGES **********/
      setFilterSectionVisibilityChange(false);
      /*******************************************************************************/
    } else {
      Swal.fire({
        title: "Warning",
        text: `Adult Child,Check in Date and Number of Stay Date can not be empty`,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33"
      });
    }
  };
  const [inputVal, setInputVal] = useState([]);
  console.log(inputVal);
  return (
    <Box sx={{ height: "100vh" }}>
      <Grid
        container
        sx={{
          height: 100 + "vh"
        }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              width: 100 + "%"
            }}
          >
            <h3>
              <IntlMessages id="calculate.reservation" />
            </h3>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box sx={searchPrice.boxStyle}>
            {" "}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={Object.values(hotels).map(param => ({
                id: param.id,
                label: param.name
              }))}
              onChange={(_, value) =>
                setDefinitions({
                  ...definitions,
                  hotel: value
                })
              }
              sx={searchPrice.autoComplete}
              renderInput={params => <TextField {...params} label="Hotel" />}
            />
            <Autocomplete
              style={searchPrice.autoComplete}
              className={classes.aut}
              disablePortal
              id="combo-box-demo"
              options={regions.map(param => ({
                id: param.id,
                label: param.name
              }))}
              sx={searchPrice.autoComplete}
              renderInput={params => <TextField {...params} label="Region" />}
              onChange={(_, value) =>
                setDefinitions({
                  ...definitions,
                  region: value
                })
              }
            />
            <Autocomplete
              style={searchPrice.autoComplete}
              className={classes.aut}
              disablePortal
              id="combo-box-demo"
              options={Object.values(operators).map(param => ({
                id: param.id,
                label: param.name
              }))}
              sx={searchPrice.autoComplete}
              renderInput={params => <TextField {...params} label="Operator" />}
              onChange={(e, value) => {
                setOperatorIsEmpty(false);
                setDefinitions({ ...definitions, operator: value });
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box sx={searchPrice.boxStyle}>
            {" "}
            <Autocomplete
              style={searchPrice.autoComplete}
              className={classes.aut}
              disablePortal
              onHighlightChange={() => checkMarketCountry()}
              id="combo-box-demo"
              options={
                operatorCountries != null
                  ? test?.map(param => ({
                      id: param?.id,
                      label: param?.name
                    }))
                  : []
              }
              sx={searchPrice.autoComplete}
              renderInput={params => <TextField {...params} label="Market" />}
              onChange={(e, value) =>
                setDefinitions({ ...definitions, market: value })
              }
            />
            <TextField
              id="outlined-basic"
              label="Adult number"
              variant="outlined"
              sx={{ width: 300, margin: "5px auto" }}
              type="number"
              placeholder="Enter a number"
              onChange={e => {
                const x = e.target.value;

                setDefinitions({
                  ...definitions,
                  adult: Number(e.target.value)
                });
              }}
            />
            <Autocomplete
              style={searchPrice.autoComplete}
              className={classes.aut}
              disablePortal
              id="combo-box-demo"
              options={allCountries.map(param => ({
                id: param.id,
                label: param.name
              }))}
              sx={searchPrice.autoComplete}
              renderInput={params => <TextField {...params} label="Country" />}
              onChange={(e, value) =>
                setDefinitions({ ...definitions, country: value })
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box sx={searchPrice.boxStyle}>
            {" "}
            <TextField
              id="outlined-basic"
              label="Check In Date"
              variant="outlined"
              sx={{ width: 300, margin: "5px auto" }}
              type="date"
              max="2099-12-31"
              min="1990-06-21"
              defaultValue={today}
              placeholder={today}
              onChange={e => {
                const x = e.target.value;

                setDefinitions({
                  ...definitions,
                  date: e.target.value
                });
              }}
            />
            <TextField
              id="outlined-basic"
              label="Stay Date"
              variant="outlined"
              type="number"
              sx={{ width: 300, margin: "5px auto" }}
              placeholder="Enter a number"
              onChange={e => {
                const x = e.target.value;

                setDefinitions({
                  ...definitions,
                  stay: e.target.value
                });
              }}
            />
            <TextField
              id="outlined-basic"
              label="Child number"
              variant="outlined"
              sx={{ width: 300, margin: "5px auto" }}
              type="number"
              min={0}
              max={49}
              placeholder="Enter a number"
              onChange={e => {
                const x = e.target.value;

                setDefinitions({
                  ...definitions,
                  child: Number(e.target.value)
                });
              }}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={() => {
                      setDefinitions({
                        ...definitions,
                        child: 0
                      });
                    }}
                    variant="contained"
                  >
                    Save
                  </Button>
                )
              }}
            />
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                height: definitions.child > 0 ? "15rem" : 0,
                //position: "absolute",
                paddingTop: "1rem",
                // overflowY: definitions.child > 0 ? "scroll" : "",
                // marginTop: "24rem",
                zIndex: "4"
              }}
            >
              {definitions.child > 0 ? (
                <Accordion
                  sx={{
                    width: "20rem",
                    position: "absolute",
                    marginTop: definitions.child > 0 ? "15rem!important" : "",
                    height: definitions.child > 0 ? "15rem" : "",
                    overflowY: definitions.child > 0 ? "scroll" : ""
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography> Child</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {[...Array(definitions.child).keys()].map((val, key) => (
                      // <Accordion
                      //   sx={{
                      //     width: "20rem",
                      //   }}
                      // >
                      //   <AccordionSummary
                      //     expandIcon={<ExpandMoreIcon />}
                      //     aria-controls="panel1a-content"
                      //     id="panel1a-header"
                      //   >
                      //     <Typography>{val + 1}. Child</Typography>
                      //   </AccordionSummary>
                      //   <AccordionDetails>

                      <TextField
                        id="outlined-basic"
                        label={`${val + 1}. Child Age`}
                        variant="outlined"
                        sx={{ width: "100%", margin: "5px auto" }}
                        type="number"
                        placeholder={inputVal[key]}
                        onChange={e => {
                          console.log(e);
                          setInputVal([...inputVal, e.target.value]);
                          const childObj = {
                            id: val + 1,
                            age: parseInt(e.target.value)
                          };

                          let newChildAges;
                          if (
                            definitions.childAges.find(
                              child => child.id === val + 1
                            )
                          ) {
                            newChildAges = definitions.childAges.map(child => {
                              if (child.id === val + 1) {
                                return childObj;
                              }

                              return child;
                            });
                          } else {
                            newChildAges = [...definitions.childAges, childObj];
                          }

                          setDefinitions({
                            ...definitions,
                            childAges: newChildAges
                          });
                        }}
                      />
                      //   </AccordionDetails>
                      // </Accordion>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : (
                ""
              )}
            </Grid>
          </Box>
        </Grid>

        <Grid item style={{ textAlign: "left" }} xs={12}>
          <Box
            sx={{
              width: 100 + "%"
            }}
          >
            <Button
              onClick={handleSubmit}
              style={{
                marginTop: "2rem",
                marginBottom: "1rem"
              }}
              variant="contained"
            >
              Search
            </Button>
          </Box>
        </Grid>
        {!filterSectionVisibilityChange == true ? (
          <div
            style={{
              width: "100%",
              height: "400px",
              margin: "2rem 1rem"
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              style={{ height: "100%" }}
            />
          </div>
        ) : (
          ""
        )}
      </Grid>
    </Box>
  );
});

export default SearchPrice;
