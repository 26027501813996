import React, { useEffect, useState } from "react";
import { getOperatorService } from "services/operator";
import { getHotelService } from "services/hotels";
import { getContractService } from "services/contract";
import { useDispatch, useSelector } from "react-redux";
import { getRoomService } from "services/rooms";
import { getBoardsService } from "services/boards";
import { getOnSaleContractService } from "services/onSaleContract";
import AddReservation from "./index";
import { addAddReservationsLoaded } from "redux/actions/hasLoaded";
const AddReservationLoader = () => {
  const dispatch = useDispatch();
  const [isFinished, setIsFinished] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);
  const hasLoaded = useSelector(state => state?.hasLoaded.hasLoaded); //prettier-ignore
  useEffect(() => {
    const firstLoader = async () => {
      await Promise.all([
        dispatch(getOnSaleContractService()),
        dispatch(
          getContractService(
            authUser.authority_level,
            localStorage.getItem("selectedCompany"),
            null,
            null,
            authUser.id
          )
        ),
        dispatch(getOperatorService()),
        dispatch(getHotelService()),
        dispatch(getBoardsService()),
        dispatch(getRoomService())
      ]);
    };
    if (hasLoaded.addReservations == false && hasLoaded.dashboard == false) {
      firstLoader()
        .then(() => {
          dispatch(addAddReservationsLoaded(true));
          setIsFinished(true);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setIsFinished(true);
    }
  }, []);
  return <React.Fragment>{isFinished && <AddReservation />}</React.Fragment>;
};

export default AddReservationLoader;
