import { GET_PRODUCT_TYPES, ADD_PRODUCT_TYPE, UPDATE_PRODUCT_TYPE, DELETE_PRODUCT_TYPE } from '../actions/types';

const initialState = {
  productTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_TYPES:
      return {
        ...state,
        productTypes: action.payload,
      };
    case ADD_PRODUCT_TYPE:
      return {
        productTypes: [...state.productTypes, action.payload],
      };
    case UPDATE_PRODUCT_TYPE:
      const productTypeIndex = state.productTypes.findIndex(type => type.id === action.payload.id);
      const updatedProductType = [...state.productTypes];
      updatedProductType[productTypeIndex] = action.payload;
      return {
        ...state,
        productTypes: updatedProductType,
      };
    case DELETE_PRODUCT_TYPE:
      const deletedProductType = state.productTypes.filter(type => type.id !== action.payload.id);
      return {
        ...state,
        productTypes: deletedProductType,
      };
    default:
      return state;
  }
};
