import { GET_RATE_TYPES } from './types';
import { normalizeRateTypes } from 'redux/schemas/rateType';

export const getRateTypes = res => dispatch => {
  const normalizedSeason = normalizeRateTypes(res.data.data);
  dispatch({
    type: GET_RATE_TYPES,
    payload: normalizedSeason.entities,
  });
};
