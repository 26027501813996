import { normalize, schema } from 'normalizr';

//const room = new schema.Entity('room', {}, { idAttribute: 'id' });
const status = new schema.Entity('status');
const operator = new schema.Entity('operator');
const board = new schema.Entity('board');
const contract_room_price = new schema.Entity('contract_room_price');
const contract_allotment_type = new schema.Entity('contract_allotment_type');
const contract_user = new schema.Entity('contract_user');
const contract_season = new schema.Entity('contract_season');
const countries = new schema.Entity('countries');

//const contractHasBoard = new schema.Entity('contractHasBoard');

const contract_room = new schema.Entity('contract_room', {
  //room: room,
  contract_room_price: [contract_room_price],
});
const get_operator = new schema.Entity('get_operator', {
  operator: [operator],
});

const contract_status = new schema.Entity('contract_status', {
  status,
});
const contract_has_board = new schema.Entity('contract_has_board', {
  board,
});

const market = new schema.Entity('market', {
  countries: [countries],
});
const contract = new schema.Entity('contracts', {
  get_operator: get_operator,
  contract_room: [contract_room],
  contract_status: [contract_status],
  contract_has_board: [contract_has_board],
  contract_allotment_type: [contract_allotment_type],
  contract_user: [contract_user],
  contract_season: [contract_season],
  market: [market],
});
export const normalizeTasks = data => normalize(data, [contract]);
