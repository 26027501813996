//30919B
const pxoStyle = {
  title: {
    letterSpacing: 0.3 + "rem",
    color: "#33658a",
    borderBottom: "1px solid",
    paddingBottom: 1 + "rem"
  },
  tableHead: {
    color: "black",

    fontWeight: "bolder",
    fontFamily: "arial",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  }
};
export default pxoStyle;
