import { CREATE_RATE_PLAN, UPDATE_RATE_PLAN } from './types';

export const createRatePlan = res => dispatch => {
  dispatch({
    type: CREATE_RATE_PLAN,
    payload: res.data.data,
  });
};

export const updateRatePlan = res => dispatch => {
  dispatch({
    type: UPDATE_RATE_PLAN,
    payload: res.data.data,
  });
};
