import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import {
  createTheme,
  Grid,
  makeStyles,
  ThemeProvider,
  withStyles
} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { addUserPermissionService } from "services/userPermission";
import {
  userMemo,
  authUserMemo,
  loginUser,
  usersObject,
  companyData
} from "redux/selector/companyHasOfficeSelector";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import { green } from "@material-ui/core/colors";

// const styles = theme => ({
//   disabledButton: {
//     backgroundColor:  green[500],
//   }
// });
const MySwal = withReactContent(Swal);

const MuiSwitch = withStyles({
  switchBase: {
    color: "#ffffff",
    opacity: 0.8,
    "&$checked": {
      color: "#33658A",
      opacity: 1
    },
    "&$checked + $track": {
      backgroundColor: "#33658A",
      opacity: 0.5
    },
    "&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: "#33658A"
    }
  },
  checked: {},
  track: {}
})(Switch);

function Row(props) {
  const intl = useIntl();
  const authortiyLevel = useSelector(authUserMemo);
  const permissionStateSelector = useSelector(
    state => state.companyHasOffice.entities.get_permission
  );
  //const classes = styles(theme);
  const filterPermissionState = Object.values(permissionStateSelector).filter(
    suppRed => suppRed.user_id == props.rowData.id
  );

  const dispatch = useDispatch();

  /*   const submitChanges = async () => {
    await dispatch(addUserPermissionService(permissionState));
    Swal.fire({
      title: "Success",
      text: "User Permission added",
      icon: "success",
      confirmButtonText: "Ok"
    }).then(props.expandRowDetail(props.rowData.id));
  }; */

  const [permissionState, setPermissionState] = useState(filterPermissionState);
  const [total, setTotal] = useState({
    0: 1,
    1: 1,
    2: 1,
    3: 1,
    4: 1,
    5: 1,
    6: 1
  });

  useEffect(() => {
    let getTotal = { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1 };
    let sub = 1;
    filterPermissionState.map(element => {
      sub = 1;
      if (element.delete) {
        sub++;
      }
      if (element.put) {
        sub++;
      }
      if (element.post) {
        sub++;
      }
      getTotal[element.route_id - 1] = sub;
    });
    setTotal(getTotal);
  }, [permissionStateSelector]);

  const routes = {
    1: { name: "accounting", placeholder: <IntlMessages id="accounting" /> },
    2: { name: "contract", placeholder: <IntlMessages id="contracting" /> },
    3: { name: "product", placeholder: <IntlMessages id="product" /> },
    4: { name: "reservation", placeholder: <IntlMessages id="reservation" /> },
    5: { name: "yield", placeholder: <IntlMessages id="yield" /> },
    6: {
      name: "sale_management",
      placeholder: <IntlMessages id="sale.management" />
    },
    7: {
      name: "reports_operations",
      placeholder: <IntlMessages id="reports.operations" />
    }
  };

  const handleChange = event => {
    let keys = event.target.name;
    let ids = event.target.id - 1;

    if (keys !== "get" && keys !== "all") {
      if (event.target.checked) {
        setTotal({ ...total, [ids]: total[ids] + 1 });
      } else {
        setTotal({ ...total, [ids]: total[ids] - 1 });
      }
      setPermissionState({
        ...permissionState,
        [ids]: {
          ...permissionState[ids],
          [keys]: event.target.checked ? 1 : 0,
          ["get"]: permissionState[ids]["get"]
            ? permissionState[ids]["get"]
            : event.target.checked
            ? 1
            : 0
        }
      });
    } else if (keys == "all") {
      if (event.target.checked) {
        setTotal({ ...total, [ids]: 4 });
      } else {
        setTotal({ ...total, [ids]: 1 });
      }
      setPermissionState({
        ...permissionState,
        [ids]: {
          ...permissionState[ids],
          ["get"]: event.target.checked ? 1 : 0,
          ["post"]: event.target.checked ? 1 : 0,
          ["put"]: event.target.checked ? 1 : 0,
          ["delete"]: event.target.checked ? 1 : 0
        }
      });
    } else {
      if (event.target.checked) {
        setPermissionState({
          ...permissionState,
          [ids]: {
            ...permissionState[ids],
            [keys]: event.target.checked ? 1 : 0
          }
        });
      } else {
        setTotal({ ...total, [ids]: 1 });
        setPermissionState({
          ...permissionState,
          [ids]: {
            ...permissionState[ids],
            [keys]: event.target.checked ? 1 : 0,
            ["post"]: 0,
            ["put"]: 0,
            ["delete"]: 0
          }
        });
      }
    }
    // setPermissionState([ ...permissionState, [ids]=>[ ...permissionState[ids], [keys] => [] event.target.checked ] ]);
  };

  const submitChanges = () => {
    try {
      dispatch(addUserPermissionService(permissionState));
      Swal.fire({
        title: "Success",
        text: "User Permission updated",
        icon: "success",
        confirmButtonText: "Ok"
      }).then(props.expandRowDetail(props.rowData.id));
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Error" + err,
        icon: "success",
        confirmButtonText: "Ok"
      });
    }
  };

  return (
    <>
      <Grid
        container
        xs={12}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {filterPermissionState.map(elements => {
          let isChecked = total[elements.route_id - 1] == 4 ? true : false;
          let color = isChecked ? "primary" : "secondary";
          return (
            <Grid item xs={1}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {routes[elements.route_id]?.placeholder}{" "}
                  <FormControlLabel
                    disabled={authortiyLevel == 20}
                    control={
                      <MuiSwitch
                        id={elements.route_id}
                        checked={total[elements.route_id - 1] == 4}
                        onChange={handleChange}
                        name="all"
                      />
                    }
                    label={<IntlMessages id="all" />}
                  />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    disabled={authortiyLevel == 20}
                    control={
                      <Switch
                        id={elements.route_id}
                        checked={permissionState[elements.route_id - 1].get}
                        onChange={handleChange}
                        name="get"
                        color="primary"
                      />
                    }
                    label={<IntlMessages id="view.permissions" />}
                  />
                  <FormControlLabel
                    disabled={authortiyLevel == 20}
                    control={
                      <Switch
                        id={elements.route_id}
                        checked={permissionState[elements.route_id - 1].post}
                        onChange={handleChange}
                        name="post"
                        color="primary"
                      />
                    }
                    label={<IntlMessages id="add.permissions" />}
                  />
                  <FormControlLabel
                    disabled={authortiyLevel == 20}
                    control={
                      <Switch
                        id={elements.route_id}
                        checked={permissionState[elements.route_id - 1].put}
                        onChange={handleChange}
                        name="put"
                        color="primary"
                      />
                    }
                    label={<IntlMessages id="update.permissions" />}
                  />
                  <FormControlLabel
                    disabled={authortiyLevel == 20}
                    control={
                      <Switch
                        id={elements.route_id}
                        checked={permissionState[elements.route_id - 1].delete}
                        onChange={handleChange}
                        name="delete"
                        color="primary"
                      />
                    }
                    label={<IntlMessages id="delete.permissions" />}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          );
        })}

        <Grid item xs={1}>
          <Button
            hidden={authortiyLevel == 20}
            variant="contained"
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
            onClick={submitChanges}
            startIcon={<SaveIcon />}
          >
            <IntlMessages id="save" />
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default function UserPermission() {
  const userSelectorData = useSelector(usersObject);
  const stateCompany = useSelector(companyData);
  const loginUserData = useSelector(loginUser);
  const officesObject = useSelector(
    state => state.companyHasOffice.entities.offices
  );

  const getUser = () => {
    let usersArray = [];
    if (
      loginUserData.authority_level == 10 ||
      loginUserData.authority_level == 11
    ) {
      usersArray = Object.values(userSelectorData);
    }
    if (loginUserData.authority_level == 20) {
      // get loginUser Companies
      const loginUserCompanies = getUserCompanies();
      //check if the user has the same company as the login user
      Object.values(userSelectorData).map(user => {
        if (user.pivot_office) {
          user.pivot_office.map(officeID => {
            if (
              loginUserCompanies.includes(officesObject[officeID].company_id)
            ) {
              !usersArray.includes(user) && usersArray.push(user);
            }
          });
        }
      });
    }

    return usersArray;
  };

  const getUserCompanies = () => {
    let loginUserCompanies = [];
    if (userSelectorData[loginUserData.id]?.pivot_office && stateCompany) {
      userSelectorData[loginUserData.id].pivot_office.map(officeID => {
        if (!loginUserCompanies.includes(officesObject[officeID].company_id)) {
          loginUserCompanies.push(officesObject[officeID].company_id);
        }
      });
      return loginUserCompanies;
    }
    return loginUserCompanies;
  };

  const expandRowDetail = id => {
    //find cancellationPoliciesDetail rowData by code
    const rowData = tableRef.current.state.data.find(c => c.id == id);
    // find Material Table data id
    const tableDataId = rowData.tableData.id;
    // toggle detail panel
    tableRef.current.onToggleDetailPanel(
      [tableDataId],
      tableRef.current.props.detailPanel
    );
  };

  const tableRef = useRef();

  const userData = getUser();

  const [columnsBoard, setColumnsBoard] = useState([
    {
      title: <IntlMessages id="name" />,
      field: "fullname",
      type: "string",
      validate: rowData => (rowData.fullname ? true : false)
    },
    {
      title: <IntlMessages id="email" />,
      field: "email",
      type: "mail",
      validate: rowData => (rowData.email ? true : false)
    }
  ]);

  return (
    <MaterialTable
      columns={columnsBoard}
      data={userData}
      tableRef={tableRef}
      detailPanel={rowData => (
        <Row rowData={rowData} expandRowDetail={expandRowDetail} />
      )}
      options={{
        showTitle: false,
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        addRowPosition: "first"
      }}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
    />
  );
}
