import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import CardHeader from '@mui/material/CardHeader';
import IntlMessages from '@jumbo/utils/IntlMessages';

function ContractSelectionUpdate(props) {
  const { reservation, setReservationDetail, handleChange, type } = props;
  const [contract_type, setContract_type] = useState(reservation.contract_id !== 0 ? 'true' : 'false');

  const contracts = useSelector(state => {
    let newContracts = [];
    state.onSaleContract.onSaleContract.map(contract => {
      newContracts.push(
        Object.values(state.contract.contracts).find(contractDetails => contractDetails.id === contract.contract_id),
      );
    });
    return newContracts;
  });
  const handleRadioChange = e => {
    const { name, value } = e.target;
    setContract_type(value);
  };
  const boards = useSelector(state => state.boards.boards);
  const selectedContract = contracts.find(contract => contract.id === reservation.contract_id);
  const contract_has_board = useSelector(state => state.contract.contract_has_board);

  const [boardState, setBoardState] = useState([]);
  useEffect(() => {
    let contract_boards = [];
    let newReservationDetail = { ...props.reservationDetail };
    if (contract_type === 'true') {
      if (selectedContract !== undefined) {
        selectedContract.contract_has_board.map(board => {
          var newBoard = contract_has_board[board];
          newBoard['code'] = boards[newBoard.board_id].code;
          contract_boards.push(newBoard);
        });
        setBoardState(contract_boards);
        props.setSelectedContract(selectedContract);
        //SelectingReservationPeriod(selectedContract.id);
      } else {
        setBoardState([]);
      }
    } else {
      delete newReservationDetail.contract_id;
      setBoardState(boards);
    }
    delete newReservationDetail.board_id;
    /*  setReservationDetail(prev => ({
      ...newReservationDetail,
    })); */
    //props.setReservationDetail(newReservationDetail);
  }, [contract_type, selectedContract]);
  const marginStyle = {
    marginTop: {
      margin: '24px 0px 0px 0',
    },
    marginBottom: {
      margin: '0px 0px 24px 0',
    },
  };
  if (boardState) {
    (contract_type === false ? boardState : Object.values(boardState)).map(param => {
      if (param.code == 'AI') {
        param.label = 'All Inclusive';
      }
      if (param.code == 'AI+') {
        param.label = 'All Inclusive + Additional Services';
      }
      if (param.code == 'BB') {
        param.label = 'Bed & Breakfast';
      }
      if (param.code == 'DO') {
        param.label = 'Dinner Only';
      }
      if (param.code == 'FB') {
        param.label = 'Full Board';
      }
      if (param.code == 'FB+') {
        param.label = ' Full Board + Additional Services';
      }
      if (param.code == 'HB') {
        param.label = 'Half Board';
      }
      if (param.code == 'HB+') {
        param.label = ' Half Board + Additional Services';
      }
      if (param.code == 'LO') {
        param.label = 'Lunch Only';
      }
      if (param.code == 'RO') {
        param.label = ' Room Only';
      }
      if (param.code == 'SC') {
        param.label = 'Self Catering';
      }
    });
  }
  return (
    <Grid container>
      <Grid item style={marginStyle.marginBottom} xs={12}>
        {!type ? (
          <Fragment>
            <CardHeader title={<IntlMessages id="contract.selection" />} sx={{ color: '#33658a' }} />
            <RadioGroup row aria-label="contract" value={contract_type} name="contract_based" onChange={handleRadioChange}>
              <FormControlLabel value={'true'} control={<Radio />} label="Contract based" />
              <FormControlLabel value={'false'} control={<Radio />} label="Not contract based" />
            </RadioGroup>{' '}
          </Fragment>
        ) : null}
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Contracts</InputLabel>
          {contract_type == 'true' ? (
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              labelId="demo-simple-select-label"
              name="contract_id"
              value={reservation?.contract_id ? reservation.contract_id : ''}
              label="Contracts"
              displayEmpty={true}
              disabled={contract_type !== 'true'}
              onChange={props.handleChange}
              readOnly={type ? true : false}>
              {contracts.map((contract, index) => (
                <MenuItem value={`${contract.id}`}>{contract.contract_name}</MenuItem>
              ))}
            </Select>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Board Type</InputLabel>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            labelId="demo-simple-select-label"
            name="board_id"
            value={reservation.board_id}
            label="Board Type"
            onChange={props.handleChange}
            readOnly={type ? true : false}>
            {Object.values(boardState).map(board => {
              return <MenuItem value={board.id}>{board.code + ' - ' + board.label}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={1}>
        <TextField
          name="hotel_confirmation_no"
          label="Confirmation No"
          value={reservation.hotel_confirmation_no}
          fullWidth
          onChange={props.handleChange}
          InputProps={{
            readOnly: type ? true : false,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ContractSelectionUpdate;
