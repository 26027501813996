import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getHotel, addHotel, updateHotel, deleteHotel } from 'redux/actions/hotels';
import axios from './axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};

export const getHotelService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('hotel')
    .then(res => {
      dispatch(getHotel(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addHotelService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('hotel', data)
    .then(res => {
      dispatch(addHotel(res));
      dispatch(fetchSuccess());
      sendLogService.sendHotelLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateHotelService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('hotel', data)
    .then(res => {
      dispatch(updateHotel(res));
      dispatch(fetchSuccess());
      sendLogService.sendHotelLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sweetAlerts('error', 'Something went wrong!');
      sendLogService.errorlog(e, data);
    });
};
export const deleteHotelService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`hotel/${id}`)
    .then(res => {
      dispatch(deleteHotel(res));
      dispatch(fetchSuccess());
      sendLogService.sendHotelLog(res, id);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
