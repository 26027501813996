import { GET_MARKETS, ADD_MARKET, UPDATE_MARKET, DELETE_MARKET } from '../actions/types';
import { normalizeMarkets } from 'redux/schemas/market';
const initialState = {
  markets: [],
  marketCountries: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MARKETS:
      const data = [];
      data['markets'] = action.payload;
      const normalizeMarket = normalizeMarkets([data]);
      return {
        ...state,
        markets: normalizeMarket.entities.markets ? normalizeMarket.entities.markets : [],
        marketCountries: normalizeMarket.entities.countries ? normalizeMarket.entities.countries : [],
      };
    case ADD_MARKET:
      const addData = [];
      addData['markets'] = [action.payload];
      addData['add_market'] = state.markets;
      const addNormalizeMarket = normalizeMarkets([addData]);
      return {
        ...state,
        markets: addNormalizeMarket.entities.markets,
      };
    case UPDATE_MARKET:
      const updatedData = [];
      updatedData['updated_market'] = [action.payload];
      updatedData['state_market'] = state.markets;
      updatedData['state_countries'] = state.marketCountries;
      const UpdatedNormalizeData = normalizeMarkets([updatedData]);
      const updatedMarket = UpdatedNormalizeData.result[0].updated_market[0];
      return {
        ...state,
        markets: UpdatedNormalizeData.entities.markets ? UpdatedNormalizeData.entities.markets : [],
        marketCountries: UpdatedNormalizeData.entities.countries ? UpdatedNormalizeData.entities.countries : [],
      };
    case DELETE_MARKET:
      const deletedMarket = Object.keys(state.markets)
        .filter(id => Number(id) !== action.payload.id)
        .reduce((obj, key) => {
          obj[key] = state.markets[key];
          return obj;
        }, {});
      return {
        ...state,
        markets: deletedMarket,
      };

    default:
      return state;
  }
};
