import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  deleteCotractRoom,
  getContract,
  updateCotractRoom,
  addContract,
  addCotractRoom,
  getContractRoom,
  updateContract,
  changeContractStatus,
  copyContract
} from "redux/actions/contract";
import axios from "./axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SET_SELECTED_CONTRACT } from "redux/actions/types";
/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
import { addAddPaxRates, deleteAddPaxRates } from "redux/actions/addPaxRates";
/**PROXOLAB LOGGER **/
export const ControlContent = data => {
  let errorString = "";
  data.forEach(error => {
    errorString = errorString + "-" + error + "<br></br>";
  });
  return errorString;
};
const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text
  });
};

export const getContractService = (
  authLevel,
  companyID,
  page,
  rowsPerPage,
  authUserID
) => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("getContract", {
      props: {
        authLevel: authLevel,
        companyID: companyID,
        page: page * rowsPerPage,
        rowsPerPage: rowsPerPage,
        authUserID: authUserID
      }
    })
    .then(res => {
      dispatch(getContract(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const getContractRoomsService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`contractRoom/${id}`)
    .then(res => {
      dispatch(getContractRoom(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addContractRoomService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("contractRoom", data)
    .then(res => {
      dispatch(addCotractRoom(res));
      dispatch(fetchSuccess());
      sendLogService.sendContractRoomServiceLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateContractRoomService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("contractRoom", data)
    .then(res => {
      dispatch(updateCotractRoom(res));
      //addpaxın action reducerinin yapısına uyması için
      if (res.data.newInfant) {
        if (res.data.newInfant.get_pax_rooms.length > 0) {
          res.data.newInfant["rooms"] = [];
          res.data.newInfant.get_pax_rates.map(
            rate => (res.data.newInfant[rate.period_id] = rate.value)
          );
          res.data.newInfant.get_pax_rooms.map(room =>
            res.data.newInfant["rooms"].push(room.contract_room_id)
          );
          delete res.data.newInfant.get_pax_rates;
          delete res.data.newInfant.get_pax_rooms;
          dispatch(addAddPaxRates(res.data.newInfant));
        } else {
          dispatch(deleteAddPaxRates(res.data.newInfant.id));
        }
      }
      dispatch(fetchSuccess());
      sendLogService.sendContractRoomServiceLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteContractRoomService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`contractRoom/${id}`)
    .then(res => {
      dispatch(deleteCotractRoom(res));
      //res.data.newInfant=>oda silinirken default kaydedilen infattan da silinmesi için
      //addpaxın action reducerinin yapısına uyması için
      if (res.data.newInfant) {
        if (res.data.newInfant.get_pax_rooms.length > 0) {
          res.data.newInfant["rooms"] = [];
          res.data.newInfant.get_pax_rates.map(
            rate => (res.data.newInfant[rate.period_id] = rate.value)
          );
          res.data.newInfant.get_pax_rooms.map(room =>
            res.data.newInfant["rooms"].push(room.contract_room_id)
          );
          delete res.data.newInfant.get_pax_rates;
          delete res.data.newInfant.get_pax_rooms;
          dispatch(addAddPaxRates(res.data.newInfant));
        } else {
          dispatch(deleteAddPaxRates(res.data.newInfant.id));
        }
      }
      dispatch(fetchSuccess());
      sendLogService.sendContractRoomServiceLog(res, id);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
export const addContractService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("contract", data)
    .then(res => {
      dispatch(addContract(res));
      dispatch({ type: SET_SELECTED_CONTRACT, payload: res.data.data.id });
      dispatch(fetchSuccess());
      sendLogService.sendContractServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateContractService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("contract", data)
    .then(res => {
      dispatch(updateContract(res));
      dispatch(fetchSuccess());
      sendLogService.sendContractServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const changeContractStatusService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("contractStatus", data)
    .then(res => {
      dispatch(changeContractStatus(res));
      dispatch(fetchSuccess());
      sendLogService.sendContractStatusServiceLog(res, data);
      sweetAlerts(
        "success",
        `Contract saved as ${res.data.data.contract_status[0].name.toLowerCase()}.`
      );
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const copyContractService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("copyContract", data)
    .then(res => {
      dispatch(copyContract(res));
      dispatch(fetchSuccess());
      sweetAlerts("success", "Contract Copied Successfully!");
      sendLogService.sendContractServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const ContractConfirmControlService = data => async dispatch => {
  let code = 0;
  const text = data.status_id
    ? "Satışa Açmak ister misiniz?"
    : "Konfirm etmek ister misiniz?";
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  //dispatch(fetchStart());
  const res = await axios
    .post("confirmControl", {
      id: data.id
    })
    .then(res => {
      return res.data;
    });
  // dispatch(contractConfirmControl(res));
  //dispatch(fetchSuccess());
  if (res.data.length != 0) {
    await Swal.fire({
      icon: "warning",
      html: ControlContent(res.data)
    });
  } else {
    let ConfimStatusData = {
      contract_id: data.id,
      status_id: data.status_id ? data.status_id : 2
    };
    if (res.sub_control.length != 0) {
      await Swal.fire({
        icon: "info",
        html: ControlContent(res.sub_control),
        title: "Kontrata Eksik Veriler Var. " + text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Evet",
        denyButtonText: `Hayır`
      }).then(async result => {
        //Read more about isConfirmed, isDenied below
        if (result.isConfirmed) {
          await dispatch(changeContractStatusService(ConfimStatusData)).then(
            resp => {
              code = resp;
            }
          );
          //data.setOpen(false);
        }
      });
    } else {
      await dispatch(changeContractStatusService(ConfimStatusData)).then(
        resp => {
          code = resp;
        }
      );
      //data.setOpen(false);
    }
  }
  return code;
};
export const controlContractRoominUseService = (contractId, id) => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`contractRoomDeleteControl/${contractId}/${id}`)
    .then(res => {
      let control = false;
      if (res.data.data.length == 0) {
        control = true;
      } else {
        Swal.fire({
          icon: "warning",
          html: ControlContent(res.data.data)
        });
        control = false;
      }
      dispatch(fetchSuccess());
      sendLogService.sendContractRoomServiceLog(res, id);
      return control;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
export const controlContractRoomRateType = (
  contract_id,
  room_id
) => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`contractRoomUpdateControl/${contract_id}/${room_id}`)
    .then(res => {
      dispatch(fetchSuccess());
      return res.data;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const addExcelContractService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("excelContract", data)
    .then(res => {
      dispatch(addContract(res));
      dispatch({ type: SET_SELECTED_CONTRACT, payload: res.data.data.id });
      dispatch(fetchSuccess());
      sendLogService.sendContractServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const addExcelContractRoomService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("excelContractRoom", data)
    .then(res => {
      dispatch(addCotractRoom(res));
      dispatch(fetchSuccess());
      sendLogService.sendContractRoomServiceLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
