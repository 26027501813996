import React, { Fragment, useEffect, useState } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import {
  addOperatorService,
  deleteOperatorService,
  getOperatorService,
  updateOperatorService
} from "services/operator";
import { useDispatch, useSelector } from "react-redux";
import { getCountriesService } from "services/destinations";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Modal,
  Typography,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  makeStyles
} from "@material-ui/core/styles";
import { blue, orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";
import SelectAll from "./SelectAll";
import { split, values } from "lodash";
import { useIntl } from "react-intl";
import { PhoneFormatCustom } from "../Common/NumberFormat/NumberFormat";
import { multiDataSetter } from "@jumbo/utils/commonHelper";
import ModeCheckIcon from "@mui/icons-material/Check";
import MultiSelect from "../Common/MultiSelect";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "../Common/Loader";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);

const InfoButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(blue[400]),
    backgroundColor: blue[400],
    "&:hover": {
      backgroundColor: blue[500]
    }
  }
}))(Button);

const CountryModalStyles = makeStyles(theme => ({
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "1px solid #000",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: 30,
    overflowY: "scroll",
    maxHeight: "500px",
    width: "500px"
  }
}));

const phoneControl = phone => {
  if (phone != undefined || phone != null) {
    if (phone.length < 18) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
const MySwal = withReactContent(Swal);
export default function NewOperator({ isAuthAdd, isAuthDelete, isAuthUpdate }) {
  const CustomAutocomplete = withStyles({
    tag: {
      backgroundColor: "#2E2E2E",
      height: 24,
      position: "relative",
      zIndex: 0,
      "& .MuiChip-label": {
        color: "white"
      },
      "& .MuiChip-deleteIcon": {
        color: "#848484"
      },
      "&:after": {
        content: '""',
        right: 10,
        top: 6,
        height: 12,
        width: 12,
        position: "absolute",
        backgroundColor: "white",
        zIndex: -1
      }
    }
  })(Autocomplete);

  const countryModalClasses = CountryModalStyles();

  const [showCountryModal, setShowCountryModal] = useState(false);
  const [operatorCountries, setOperatorCountries] = useState([]);

  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (Object.values(operators).length === 0) {
      dispatch(getOperatorService());
    }
    if (Object.values(countriesState).length === 0) {
      dispatch(getCountriesService(["", 0]));
    }
  }, [dispatch]);

  const tableRef = React.createRef();
  const { operators } = useSelector(({ operators }) => operators);
  const countriesState = useSelector(state => state.destinations.countries);
  const contracts = useSelector(state => state.contract.contracts);

  const controlSameRow = newData => {
    if (
      Object.values(operators).filter(
        op => op.code === newData.code.toUpperCase()
      ).length == 0 ||
      Object.values(operators).filter(
        op => op.name === newData.name.toUpperCase()
      ).length == 0
    )
      return true;
  };
  const isTrueEmail = email => {
    return /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+.)+([.])+[a-zA-Z0-9.-]{2,4}$/.test(
      email
    );
  };

  const tableColumns = [
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="code" />
          </b>
        </h5>
      ),
      field: "code",
      width: "20%",
      validate: rowData => !!rowData.code,
      editComponent: props => (
        <TextField
          // label={<IntlMessages id="code" />}
          placeholder={intl.formatMessage({ id: "code" })}
          required
          value={props.value}
          error={!props.value}
          onChange={e => {
            props.onChange(e.target.value);
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="name" />
          </b>
        </h5>
      ),
      field: "name",
      width: "20%",
      validate: rowData => !!rowData.name,
      editComponent: props => (
        <TextField
          // label={<IntlMessages id="name" />}
          placeholder={intl.formatMessage({ id: "name" })}
          required
          value={props.value}
          error={!props.value}
          onChange={e => {
            props.onChange(e.target.value);
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="post.address" />
          </b>
        </h5>
      ),
      field: "post_address",
      width: "20%",
      validate: rowData => !!rowData.post_address,
      editComponent: props => (
        <TextField
          // label={<IntlMessages id="post.address" />}
          placeholder={intl.formatMessage({ id: "post.address" })}
          required
          value={props.value}
          error={!props.value}
          onChange={e => {
            props.onChange(e.target.value);
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="phone" />
          </b>
        </h5>
      ),
      field: "phone",
      type: "phone",
      width: "20%",
      validate: rowData => !phoneControl(rowData.phone),
      editComponent: props => (
        <TextField
          // label={<IntlMessages id="phone" />}
          placeholder="+xx (xxx) xxx-xxxx"
          // placeholder={intl.formatMessage({ id: 'phone' })}
          required
          value={props.value}
          error={phoneControl(props.value)}
          onChange={e => {
            props.onChange(e.target.value);
          }}
          InputProps={{
            inputComponent: PhoneFormatCustom
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="contact.person" />
          </b>
        </h5>
      ),
      field: "contact_person",
      width: "20%",
      validate: rowData => !!rowData.contact_person,
      editComponent: props => (
        <TextField
          // label={<IntlMessages id="contact.person" />}
          placeholder={intl.formatMessage({ id: "contact.person" })}
          required
          value={props.value}
          error={!props.value}
          onChange={e => {
            props.onChange(e.target.value);
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="contact.email" />
          </b>
        </h5>
      ),
      field: "contact_email",
      width: "20%",
      validate: rowData => isTrueEmail(rowData.contact_email),
      editComponent: props => (
        <TextField
          // label={<IntlMessages id="contact.email" />}
          placeholder={intl.formatMessage({ id: "contact.email" })}
          required
          value={props.value}
          error={
            values.e_mail !== ""
              ? isTrueEmail(props.value)
                ? false
                : true
              : true
          }
          onChange={e => {
            console.log(e);
            props.onChange(e.target.value);
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="countries" />
          </b>
        </h5>
      ),
      field: "countries",
      type: "string",
      //cellStyle: { wordBreak: 'break-all' },
      /* validate: rowData =>
        rowData.countries && rowData.countries.length !== 0 ? true : false, */
      editComponent: ({ onChange, value, rowData }) => {
        return (
          countriesState.length > 0 && (
            <MultiSelect
              options={multiDataSetter("name", "id", countriesState)}
              value={multiDataSetter("name", "id", value)}
              onChange={e => onChange(e)}
              labelledBy="Select"
              overrideStrings={{ selectSomeItems: "Select countries" }}
            />
            // <SelectAllTest
            //   options={countriesState}
            //   labelKey={"name"}
            //   valueKey={"id"}
            //   // data={data.rowData.countries}
            //   placeholder={"Countries"}
            // />
          )
        );
      },
      // editComponent: props => (
      //   <SelectAll
      //     optionLabel={'name'}
      //     optionValue={'name'}
      //     optionId={'id'}
      //     options={countriesState}
      //     label="none"
      //     onChange={val => {
      //       const c = val.map(k => {
      //         if (k?.id === undefined) {
      //           return k;
      //         } else {
      //           return k.name;
      //         }
      //       });
      //       props.onChange(val);
      //     }}
      //     value={props.value ? props.value : []}
      //     placeholder={'Countries'}
      //   />
      // ),
      render: rowData => {
        if (!rowData.countries) {
          return "No Country Selected";
        }

        if (rowData.countries.length > 4) {
          return (
            <InfoButton
              onClick={() => checkCountryHandler(rowData.countries)}
              variant="contained"
              color="backgroundColor"
              startIcon={<InfoIcon />}
            >
              <IntlMessages id="show.countries" style={{ color: "#fff" }} />
            </InfoButton>
          );
        } else {
          let countryArr = [];
          rowData.countries.map((item, idx) => {
            if (idx !== rowData.countries.length - 1) {
              countryArr.push(item.name, ", ");
            } else {
              countryArr.push(item.name);
            }
          });
          return countryArr;
        }
      }
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="status" />
          </b>
        </h5>
      ),
      field: "isActive",
      editComponent: props => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => {
                  props.onChange(e.target.checked);
                }}
                name="isActive"
                checked={props.rowData.isActive}
              />
            }
            label={props.rowData.isActive ? "Active" : "Disable"}
          />
        );
      },
      render: rowData => {
        return !rowData.isActive ? "Disable" : "Active";
      }
    }
  ];

  const checkCountryHandler = data => {
    setOperatorCountries(data.map(country => country.name));
    setShowCountryModal(!showCountryModal);
  };

  const hasOperator = operator_id => {
    //To check if the operator is in the contract
    var has = true;
    Object.values(contracts).forEach(contract => {
      var contract_operators = contract.operators;
      if (contract_operators.find(operator => operator == operator_id)) {
        has = false;
        return has;
      }
    });
    return has;
  };
  return (
    <Fragment>
      <MaterialTable
        tableRef={tableRef}
        columns={tableColumns}
        data={Object.values(operators)}
        title=""
        options={{
          tableLayout: "fixed",
          actionsColumnIndex: -1,
          search: false,
          filtering: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          addRowPosition: "first"
        }}
        actions={[
          isAuthAdd
            ? undefined
            : {
                icon: "add",
                disabled: true,
                position: "toolbar",
                tooltip: "You are not authorized"
              }
        ]}
        icons={{
          Add: props => (
            <ThemeProvider theme={theme}>
              <ColorButton
                variant="contained"
                color="backgroundColor"
                startIcon={<AddBoxIcon />}
              >
                <IntlMessages id="add" />
              </ColorButton>
            </ThemeProvider>
          ),
          Check: () => <ModeCheckIcon style={{ color: "green" }} />,
          Clear: () => <ModeCloseIcon sx={{ color: "red" }} />
        }}
        components={{
          OverlayLoading: () => <Loader />,
          EditRow: props => (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == "update") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "add") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          ),
          onRowAdd: props => (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
            />
          )
        }}
        editable={{
          deleteTooltip: row =>
            isAuthDelete ? "Delete" : "You are not authorized",
          editTooltip: row =>
            isAuthUpdate ? "Update" : "You are not authorized",
          isDeletable: row => (isAuthDelete ? true : false),
          isEditable: row => (isAuthUpdate ? true : false),
          onRowAdd: isAuthAdd
            ? newData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    console.log(newData);
                    if (
                      newData.code &&
                      newData.name &&
                      newData.countries &&
                      newData.countries.length !== 0
                    ) {
                      if (
                        Object.values(operators).filter(
                          op => op.code === newData.code.toUpperCase()
                        ).length == 0 &&
                        Object.values(operators).filter(
                          op => op.name === newData.name.toUpperCase()
                        ).length == 0
                      ) {
                        newData.code = newData.code.toUpperCase();
                        newData.name = newData.name.toUpperCase();
                        dispatch(addOperatorService(newData)).then(res => {
                          if (res === 200) {
                            MySwal.fire({
                              icon: "success",
                              text:
                                intl.formatMessage({ id: "operator" }) +
                                intl.formatMessage({
                                  id: "created.successfully"
                                })
                            });
                          }
                        });
                        resolve();
                      } else {
                        MySwal.fire({
                          icon: "error",
                          title: "Oops...",
                          text:
                            intl.formatMessage({ id: "operator" }) +
                            intl.formatMessage({ id: "is.already.exists" })
                        });
                        resolve();
                      }
                    } else {
                      MySwal.fire(
                        "Oops...",
                        intl.formatMessage({ id: "please.fill.in.all.fields" }),
                        "error"
                      );
                      reject();
                    }
                  }, 1000);
                })
            : undefined,

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              console.log(newData);
              console.log(oldData);

              setTimeout(() => {
                const otherOperators = Object.values(operators).filter(
                  op => op.id !== oldData.id
                );
                if (
                  newData.code &&
                  newData.name &&
                  newData.countries &&
                  newData.countries.length !== 0
                ) {
                  if (
                    otherOperators.filter(
                      op => op.code === newData.code.toUpperCase()
                    ).length == 0 ||
                    otherOperators.filter(
                      op => op.name === newData.name.toUpperCase()
                    ).length == 0
                  ) {
                    newData.code = newData.code.toUpperCase();
                    newData.name = newData.name.toUpperCase();
                    newData.countries =
                      typeof newData.countries == "string"
                        ? newData.countries?.split(",")
                        : newData.countries;
                    dispatch(updateOperatorService(newData, oldData)).then(
                      res => {
                        if (res === 201) {
                          MySwal.fire({
                            icon: "success",
                            text:
                              intl.formatMessage({ id: "operator" }) +
                              intl.formatMessage({
                                id: "updated.successfully"
                              })
                          });
                        }
                      }
                    );
                    resolve();
                  } else {
                    MySwal.fire({
                      icon: "error",
                      title: "Oops...",
                      text:
                        intl.formatMessage({ id: "operator" }) +
                        intl.formatMessage({ id: "is.already.exists" })
                    });
                    resolve();
                  }
                } else {
                  MySwal.fire(
                    "Oops...",
                    intl.formatMessage({ id: "please.fill.in.all.fields" }),
                    "error"
                  );
                  reject();
                }
              }, 1000);
            }),

          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                if (hasOperator(oldData.id) == true) {
                  dispatch(deleteOperatorService(oldData.id)).then(res => {
                    if (res === 201) {
                      MySwal.fire({
                        icon: "success",
                        text:
                          intl.formatMessage({ id: "operator" }) +
                          intl.formatMessage({ id: "deleted.successfully" })
                      });
                    }
                  });
                } else {
                  MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text:
                      intl.formatMessage({ id: "operator" }) +
                      intl.formatMessage({ id: "is.in.use" })
                  });
                }
                resolve();
              }, 1000);
            })
        }}
      />
      <Modal
        open={showCountryModal}
        onClose={() => setShowCountryModal(!showCountryModal)}
        aria-labelledby="country-detail-modal-title"
        aria-describedby="country-detail-modal-description"
      >
        <Box className={countryModalClasses.content}>
          <Typography
            id="country-detail-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "30px" }}
          >
            OPERATOR COUNTRIES
          </Typography>
          {operatorCountries.map((country, idx) => (
            <Typography
              key={idx}
              className="country-detail-modal-description"
              sx={{ mt: 2 }}
              style={{ marginBottom: "6px" }}
            >
              {country}
            </Typography>
          ))}
        </Box>
      </Modal>
    </Fragment>
  );
}
