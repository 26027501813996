import React, { useEffect, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { useLocation } from "react-router";
import CmtVertical from "../../../../../@coremat/CmtNavigation/Vertical";

import {
  sidebarNavs,
  masterSidebarNavs,
  sidebarNavsOfficeUser,
  isEnterprise
} from "../menus";
import {
  authUserMemo,
  companyData
} from "redux/selector/companyHasOfficeSelector";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    paddingBottom: "8rem",
    transition: "all 0.3s ease",
    // '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
    //   height: 'calc(80% - 16px)',
    // },
    ".Cmt-modernLayout &": {
      height: "calc(80% - 7px)"
    },
    ".Cmt-miniLayout &": {
      height: "calc(80% - 9px)"
    },
    // '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
    //   height: 'calc(80% - 16px)',
    // },
    // overflow: 'visible!important',
    // '&:hover': {
    //   overflow: 'hidden!important',
    // },
    "MuiCollapse-root makeStyles-navCollapseItem-511 MuiCollapse-entered &": {
      overflow: "visible!important"
    }
  }
}));

const SideBar = () => {
  const location = useLocation();
  const classes = useStyles();
  const authUserState = useSelector(authUserMemo);
  const [menuItems, setMenuItems] = useState([]);
  const companyState = useSelector(companyData);
  const { authUser } = useSelector(({ auth }) => auth);
  const [companyName, setCompanyName] = useState("");

  useEffect(
    () => {
      setMenuItems(isMaster());
    },
    [sidebarNavs],
    companyName
  );

  const isMaster = () => {
    if (location.pathname.includes("/master")) {
      return masterSidebarNavs;
    }
    if (authUserState === 30) {
      let sidebarNavsWithoutAdmin = sidebarNavs.filter(
        item => item.children[0].link !== "/admin"
      );
      return sidebarNavsWithoutAdmin;
    } else {
      return sidebarNavs;
    }
  };

  useEffect(() => {
    if (authUser.authority_level !== 10 && authUser.authority_level !== 11) {
      let company =
        Object.values(companyState).length > 0 &&
        Object.values(companyState).find(element => {
          return (
            element.id == parseInt(localStorage.getItem("selectedCompany"))
          );
        });

      const parsedUserCompanies = JSON.parse(
        localStorage.getItem("userCompanies")
      );
      if (parsedUserCompanies != undefined) {
        parsedUserCompanies.map(element => {
          if (element.id == localStorage.getItem("selectedCompany")) {
            element.name = company.name;
            return element;
          } else {
            return element;
          }
        });
      }

      const changeLocalStorage = () => {
        // Wait for the company name to be changed
        localStorage.setItem(
          "userCompanies",
          JSON.stringify(parsedUserCompanies)
        );
        sidebarNavs[0].children[0].name = isEnterprise();
        setCompanyName(sidebarNavs[0].children[0].name);
      };
      changeLocalStorage();
    }
  }, [companyState]);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={menuItems} />
    </PerfectScrollbar>
  );
};

export default SideBar;
