import { normalizedRestrictions } from 'redux/schemas/restrictions';
import { GET_RESTRICTIONS, UPDATE_RESTRICTIONS, DELETE_RESTRICTIONS, ADD_RESTIRICTION } from '../actions/types';
const initialState = {
  restrictions: [],
  release: [],
  allotment: [],
  closeout: [],
  cincout: [],
  occupancy: [],
  get_type: [],
  rooms: [],
  rboards: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RESTRICTIONS:
      const data = [];
      /* const release = [];
      const allotment = [];
      const closeout = [];
      const cincout = [];
      const occupancy = [];
      
      action.payload.map(restriction => {
        if (restriction.get_type.table_name == 'release_restrictions') {
          release.push(restriction.get_type.release);
        } else if (restriction.get_type.table_name == 'allotment_restrictions') {
          allotment.push(restriction.get_type.allotment);
        } else if (restriction.get_type.table_name == 'closeout_restrictions') {
          closeout.push(restriction.get_type.closeout);
        } else if (restriction.get_type.table_name == 'cincout_restrictions') {
          cincout.push(restriction.get_type.cincout);
        } else if (restriction.get_type.table_name == 'occupancy_restrictions') {
          occupancy.push(restriction.get_type.occupancy);
        }
      }); */
      data['restriction'] = action.payload;
      const normalizedRestriction = normalizedRestrictions([data]);
      return {
        ...state,
        restrictions: normalizedRestriction.entities.restriction ? normalizedRestriction.entities.restriction : [],
        release: normalizedRestriction.entities.release ? normalizedRestriction.entities.release : [],
        allotment: normalizedRestriction.entities.allotment ? normalizedRestriction.entities.allotment : [],
        closeout: normalizedRestriction.entities.closeout ? normalizedRestriction.entities.closeout : [],
        occupancy: normalizedRestriction.entities.occupancy ? normalizedRestriction.entities.occupancy : [],
        cincout: normalizedRestriction.entities.cincout ? normalizedRestriction.entities.cincout : [],
        get_type: normalizedRestriction.entities.get_type ? normalizedRestriction.entities.get_type : [],
        rooms: normalizedRestriction.entities.rooms ? normalizedRestriction.entities.rooms : [],
        rboards: normalizedRestriction.entities.boards ? normalizedRestriction.entities.boards : [],
      };

    case ADD_RESTIRICTION:
      const adddata = [];
      adddata['get_type'] = state.get_type;
      adddata['cincout'] = state.cincout;
      adddata['rooms'] = state.rooms;
      adddata['rboards'] = state.rboards;
      adddata['release'] = state.release;
      adddata['allotment'] = state.allotment;
      adddata['closeout'] = state.closeout;
      adddata['occupancy'] = state.occupancy;
      adddata['restriction'] = action.payload;
      adddata['stateRestriction'] = state.restrictions;

      const addNormalizedRestriction = normalizedRestrictions([adddata]);

      return {
        ...state,
        restrictions: addNormalizedRestriction.entities.restriction,
        release: addNormalizedRestriction.entities.release ? addNormalizedRestriction.entities.release : [],
        allotment: addNormalizedRestriction.entities.allotment ? addNormalizedRestriction.entities.allotment : [],
        closeout: addNormalizedRestriction.entities.closeout ? addNormalizedRestriction.entities.closeout : [],
        occupancy: addNormalizedRestriction.entities.occupancy ? addNormalizedRestriction.entities.occupancy : [],
        cincout: addNormalizedRestriction.entities.cincout ? addNormalizedRestriction.entities.cincout : [],
        get_type: addNormalizedRestriction.entities.get_type,
        rooms: addNormalizedRestriction.entities.rooms ? addNormalizedRestriction.entities.rooms : [],
        rboards: addNormalizedRestriction.entities.boards ? addNormalizedRestriction.entities.boards : [],
      };

    case UPDATE_RESTRICTIONS:
      const updateData = [];
      // updateData['get_type'] = state.get_type;
      updateData['state_cincout'] = state.cincout;
      updateData['state_rooms'] = state.rooms;
      updateData['state_rboards'] = state.rboards;
      updateData['state_release'] = state.release;
      updateData['state_allotment'] = state.allotment;
      updateData['state_closeout'] = state.closeout;
      updateData['state_occupancy'] = state.occupancy;
      updateData[`update_${action.payload.type}`] = [action.payload.data];
      // updateData['stateRestriction'] = state.restrictions;

      const updateNormalizedRestriction = normalizedRestrictions([updateData]);
      return {
        ...state,
        release: updateNormalizedRestriction.entities.release ? updateNormalizedRestriction.entities.release : [],
        allotment: updateNormalizedRestriction.entities.allotment ? updateNormalizedRestriction.entities.allotment : [],
        closeout: updateNormalizedRestriction.entities.closeout ? updateNormalizedRestriction.entities.closeout : [],
        occupancy: updateNormalizedRestriction.entities.occupancy ? updateNormalizedRestriction.entities.occupancy : [],
        cincout: updateNormalizedRestriction.entities.cincout ? updateNormalizedRestriction.entities.cincout : [],
        rooms: updateNormalizedRestriction.entities.rooms ? updateNormalizedRestriction.entities.rooms : [],
        rboards: updateNormalizedRestriction.entities.boards ? updateNormalizedRestriction.entities.boards : [],
      };

    case DELETE_RESTRICTIONS:
      //const deletedData = [];
      let delete_rest_name = action.payload.get_type.table_name.split('_')[0];
      let deleted_get_type = action.payload.get_type.id;
      let deleted_rest_id = action.payload.get_type[delete_rest_name].id;
      let deleted_rooms = action.payload.get_type[delete_rest_name].rooms;
      if (delete_rest_name === 'closeout') {
        let deleted_boards = action.payload.get_type.closeout.boards;
        deleted_boards.map(db => {
          delete state.rboards[db];
        });
      } else {
        deleted_rooms.map(dr => {
          delete state.rooms[dr];
        });
      }
      delete state.get_type[deleted_get_type];
      delete state[delete_rest_name][deleted_rest_id];

      /* deletedData[0]['delete_cincout'] = state.cincout;
      deletedData[0]['delete_rooms'] = state.rooms;
      deletedData[0]['delete_rboards'] = state.rboards;
      deletedData[0]['delete_release'] = state.release;
      deletedData[0]['delete_allotment'] = state.allotment;
      deletedData[0]['delete_closeout'] = state.closeout;
      deletedData[0]['delete_occupancy'] = state.occupancy; */
      // deletedData[0][`delete_${action.payload.get_type.table_name.split('_')[0]}`] = action.payload;

      // const deleteNormalizedRestriction = normalizedRestrictions([deletedData]);
      return {
        ...state,
      };

    default:
      return state;
  }
};
