import { normalizeTasks } from "redux/schemas/room";
import {
  GET_ROOM,
  ADD_ROOM,
  UPDATE_ROOM,
  DELETE_ROOM,
  ADD_ROOMS
} from "./types";

export const getRoom = res => dispatch => {
  dispatch({
    type: GET_ROOM,
    payload: res.data
  });
};
export const addRoom = res => dispatch => {
  dispatch({
    type: ADD_ROOM,
    payload: res.data
  });
};
export const addRooms = res => dispatch => {
  dispatch({
    type: ADD_ROOMS,
    payload: res.data
  });
};

export const updateRoom = res => dispatch => {
  dispatch({
    type: UPDATE_ROOM,
    payload: res.data
  });
};

export const deleteRoom = res => dispatch =>
  dispatch({
    type: DELETE_ROOM,
    payload: res.data
  });
