import {
  getRestrictions,
  addOccupancy,
  addCloseOut,
  addRelease,
  addAllotment,
  addCinCout,
  updateOccupancy,
  updateCloseOut,
  updateRelease,
  updateAllotment,
  updateCinCout,
  deleteOccupancy,
  deleteCloseOut,
  deleteRelease,
  deleteAllotment,
  deleteCinCout,
} from 'redux/actions/restrictions';
import { fetchStart, fetchError, fetchSuccess } from '../redux/actions/Common';
import axios from './axios';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

export const getRestrictionsService = selectedContract => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('restriction', {
      params: {
        contract_id: selectedContract,
      },
    })
    .then(res => {
      dispatch(getRestrictions(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
// Occupancy Services
export const addOccupancyService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('restriction/occupancy', data)
    .then(res => {
      dispatch(addOccupancy(res));
      dispatch(fetchSuccess());
      sendLogService.occupancyServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateOccupancyService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('restriction/occupancy', data)
    .then(res => {
      dispatch(updateOccupancy(res));
      dispatch(fetchSuccess());
      sendLogService.occupancyServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteOccupancyService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`restriction/occupancy`, {
      params: {
        id: data.id,
        contract_id: data.selectedContract,
      },
    })
    .then(res => {
      dispatch(deleteOccupancy(res));
      dispatch(fetchSuccess());
      sendLogService.occupancyServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
// Occupancy Services End

// CloseOut Services Start
export const addCloseOutService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('restriction/closeout', data)
    .then(res => {
      dispatch(addCloseOut(res));
      dispatch(fetchSuccess());
      sendLogService.closeoutServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateCloseOutService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('restriction/closeout', data)
    .then(res => {
      dispatch(updateCloseOut(res));
      dispatch(fetchSuccess());
      sendLogService.closeoutServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteCloseOutService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`restriction/closeout`, {
      params: {
        id: data.id,
        contract_id: data.selectedContract,
      },
    })
    .then(res => {
      dispatch(deleteCloseOut(res));
      dispatch(fetchSuccess());
      sendLogService.closeoutServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
// CloseOut Services End

// Release Services Start
export const addReleaseService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('restriction/release', data)
    .then(res => {
      dispatch(addRelease(res));
      dispatch(fetchSuccess());
      sendLogService.releaseServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateReleaseService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('restriction/release', data)
    .then(res => {
      dispatch(updateRelease(res));
      dispatch(fetchSuccess());
      sendLogService.releaseServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteReleaseService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`restriction/release`, {
      params: {
        id: data.id,
        contract_id: data.selectedContract,
      },
    })
    .then(res => {
      dispatch(deleteRelease(res));
      dispatch(fetchSuccess());
      sendLogService.releaseServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
// Release Services End

// Allotment Services Start
export const addAllotmentService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('restriction/allotment', data)
    .then(res => {
      dispatch(addAllotment(res));
      dispatch(fetchSuccess());
      sendLogService.allotmentRestServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateAllotmentService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('restriction/allotment', data)
    .then(res => {
      dispatch(updateAllotment(res));
      dispatch(fetchSuccess());
      sendLogService.allotmentRestServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteAllotmentService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`restriction/allotment`, {
      params: {
        id: data.id,
        contract_id: data.selectedContract,
      },
    })
    .then(res => {
      dispatch(deleteAllotment(res));
      dispatch(fetchSuccess());
      sendLogService.allotmentRestServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
// Allotmen Services End

// Check-in / Check-out Services Start
export const addCinCoutService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('restriction/cincout', data)
    .then(res => {
      dispatch(addCinCout(res));
      dispatch(fetchSuccess());
      sendLogService.cincoutServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateCinCoutService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('restriction/cincout', data)
    .then(res => {
      dispatch(updateCinCout(res));
      dispatch(fetchSuccess());
      sendLogService.cincoutServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const deleteCinCoutService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`restriction/cincout`, {
      params: {
        id: data.id,
        contract_id: data.selectedContract,
      },
    })
    .then(res => {
      dispatch(deleteCinCout(res));
      dispatch(fetchSuccess());
      sendLogService.cincoutServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
// Check-in / Check-out Services End
