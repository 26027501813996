import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { NavLink } from "react-router-dom";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import CmtImage from "../../../../@coremat/CmtImage";
import ContentLoader from "../../ContentLoader";
import { AuhMethods } from "../../../../services/auth";
import IntlMessages from "../../../utils/IntlMessages";
import AuthWrapper from "./AuthWrapper";
import Grid from "@material-ui/core/Grid";
import { func, symbol } from "prop-types";
import axios from "services/axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Row } from "react-grid-system";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
    fontSize: 20
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    },
    "& :-webkit-autofill": {
      WebkitBoxShadow:
        localStorage.getItem("theme-type") == "dark"
          ? "0 0 0 100px #363636 inset"
          : "0 0 0 #ffffff #363636 inset"
    }
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  },
  logo: {
    textAlign: "end"
  }
}));
// variant = 'default', 'standard'
const SignIn = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default",
  match
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const requestUrl = match.url.replace(/\/$/, "");

  const theme = localStorage.getItem("theme-type");

  /***********************ACTIVATION COMPONENT VARIABLES*************************/
  const [activation, setActivation] = useState();
  const [activateToggle, setActivateToggle] = useState(true);
  /*   const [controlRandomActivationCode, setControlRandomActivationCode] = useState(); */
  const [timeCount, setTimeCount] = useState(120);
  /********************************************************************************/

  const payload = {
    email: email,
    password: password
  };

  const onSubmit = () => {
    if (email == "" && password == "") {
      setActivateToggle(true);
      MySwal.fire(
        `<span style='font-size:100px;'></span>`,
        `<h3 style="color: ${
          theme === "dark" ? "rgb(61 124 194);" : "rgb(34 65 88);"
        } letter-spacing:3px">E-mail and Password and Required</h3>`,
        "warning"
      );
    } else {
      axios
        .post("controluser", payload)
        .then(res => {
          if (res.data.code == 200) {
            if (requestUrl === "/login") {
              setActivateToggle(false);
              axios.post("activation", email).then(response => {
                MySwal.fire(
                  `<span style='font-size:30px; color:orangered'></span>`,
                  `<h3 style="color:${
                    theme === "dark" ? "rgb(61 124 194);" : "rgb(34 65 88);"
                  }  letter-spacing:3px">Activation Code Send to Email</h3>`,
                  "warning"
                );
              });
            } else {
              dispatch(AuhMethods[method].onMasterLogin({ email, password }));
            }
          } else {
            MySwal.fire(
              `<span style='font-size:100px;'></span>`,
              `<h3 style="color:${
                theme === "dark" ? "rgb(61 124 194);" : "rgb(34 65 88);"
              } letter-spacing:3px">Email or Password Wrong</h3>`,
              "warning"
            );
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (activateToggle == false) {
      if (timeCount != 0) {
        setTimeout(() => setTimeCount(timeCount - 1), 1 * 1000);
      } else {
        MySwal.fire("warning", "your token is expaired!").then(result => {
          MySwal.fire("Deleted!", "Anasayfaya Yönlendiriliceksiniz").then(
            asd => {
              setActivateToggle(true);
            }
          );
        });
      }
    } else {
      setTimeCount(120);
    }
  }, [activateToggle, timeCount]);

  /***********************Control Activation code with user input**********************/
  console.log(activation);
  const handleActivation = () => {
    const data = {
      activation: activation,
      email: email
    };
    axios
      .post("controlactivatecode", data)
      .then(res => {
        if (res.data.code == 200) {
          console.log(res.data.message);
          if (requestUrl === "/login") {
            dispatch(AuhMethods[method].onLogin({ email, password }));
          } /* else {
            dispatch(AuhMethods[method].onMasterLogin({ email, password }));
          } */
        } else {
          console.log(res.data.message);
          MySwal.fire(
            `<span style='font-size:30px; color:orangered'></span>`,
            `<h3 style="color:${
              theme === "dark" ? "rgb(61 124 194);" : "rgb(34 65 88);"
            } letter-spacing:3px">Activation Code Not Match</h3>`,
            "warning"
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  /**************Get User Email and send to backend / Validation Email******************/
  const [errParam, setErrParam] = useState();
  const errText = errParam ? "Please input valid email !" : "";

  //const [takePass, setTakePass] = useState();
  const onSubmitTakePass = () => {
    const counts = [];
    if (email == null) {
      setErrParam(true);
    } else {
      setErrParam(false);
      const takePassArray = email.split("");

      /*************Find @ symbol in input value array***********/
      const findTakePassEmailSymbol = takePassArray.some(function(symbol) {
        return symbol == "@";
      });
      /***************************************************************/

      if (findTakePassEmailSymbol == true) {
        /*******************Find @ symbol count in array for validation*********************/
        var controlEmailSymbolCount = takePassArray.forEach(function(x) {
          counts[x] = (counts[x] || 0) + 1;
        });
        /************************************************************************************/

        if (counts["@"] > 1) {
          setErrParam(true);
        } else {
          axios.post("forgot-password", email).then(response => {
            MySwal.fire(
              "Report",
              `<h3 style="color:rgb(34 65 88); letter-spacing:3px">${response.data.message}</h3>`,
              "warning"
            );
          });
        }
      } else {
        setErrParam(true);
      }
    }
  };

  /********************************************************************/
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  /************************ For Forgot Password set State and Change Component********************/
  const [toggle, setToggle] = useState(true);
  const forgotPass = () => {
    setToggle(!toggle);
  };
  useEffect(() => {}, [toggle]);

  /***********************************************************************************************/

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CmtImage src="/images/auth/login-img.png" />
        </Box>
      ) : null}

      {toggle == true ? (
        <Box className={classes.authContent}>
          <Box>
            <Row justify="center" align="center">
              <CmtImage src="/images/mundosai.png" style={{ width: "68%" }} />
            </Row>
            <Typography
              component="div"
              variant="h1"
              className={classes.titleRoot}
            >
              <span
                style={{
                  color: theme === "dark" ? "rgb(61 124 194)" : "rgb(34 65 88)",
                  letterSpacing: "4px"
                }}
              >
                LOGIN
              </span>
            </Typography>
            {activateToggle == true ? (
              <>
                <form type="submit" onKeyDown={handleKeyDown}>
                  <Box mb={2}>
                    <TextField
                      label={<IntlMessages id="appModule.email" />}
                      fullWidth
                      onChange={event => setEmail(event.target.value)}
                      defaultValue={email}
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldRoot}
                      autoComplete="off"
                      required
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      type="password"
                      label={<IntlMessages id="appModule.password" />}
                      fullWidth
                      onChange={event => setPassword(event.target.value)}
                      defaultValue={password}
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldRoot}
                      autoComplete="new-password"
                      required
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                  >
                    <FormControlLabel
                      className={classes.formcontrolLabelRoot}
                      control={<Checkbox name="checkedA" />}
                      label="Remember me"
                    />
                    <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                      <span
                        onClick={forgotPass}
                        style={{
                          marginTop: "1rem",
                          fontSize: "13px",
                          color:
                            theme === "dark"
                              ? "rgb(61 124 194)"
                              : "rgb(34 65 88)",
                          cursor: "pointer",
                          textDecoration: "underline"
                        }}
                      >
                        <IntlMessages id="appModule.forgotPassword" /> ?
                      </span>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={5}
                  >
                    <Button
                      onClick={() => {
                        onSubmit();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>

                    {/* <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                <NavLink to="/signup">
                  <IntlMessages id="signIn.signUp" />
                </NavLink>
              </Box> */}
                  </Box>
                </form>
              </>
            ) : (
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Grid item xs={5}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                  >
                    <input
                      type="text"
                      style={{
                        border: "1px solid gray",
                        borderRadius: "3px",
                        outline: "none",
                        paddingLeft: "4px",
                        height: "2.3rem",
                        fontSize: "15px",
                        textAlign: "center",
                        letterSpacing: "3px",
                        color: "black"
                      }}
                      onChange={e => setActivation(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} mb={5}>
                  <span style={{ color: "red", marginLeft: "1em" }}>
                    {" "}
                    &#9200; {timeCount}
                  </span>
                </Grid>
                <Grid item xs={5}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-around"
                    mb={5}
                  >
                    <Button
                      onClick={handleActivation}
                      variant="contained"
                      color="primary"
                    >
                      <span style={{ color: "white" }}>&#10003;</span>
                    </Button>

                    <Button
                      onClick={() => {
                        setActivateToggle(true);
                        setTimeCount(120);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      X
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>

          {/* {dispatch(AuhMethods[method].getSocialMediaIcons())} */}

          <ContentLoader />
          {/* <Box className={classes.logo}>
            {theme === 'dark' ? (
              <CmtImage style={{ width: '82px' }} src="images/logo-dark.png" />
            ) : (
              <CmtImage src="images/logo-white.png" />
            )}
          </Box> */}
        </Box>
      ) : (
        <Box className={classes.authContent}>
          <Typography
            component="div"
            variant="h1"
            className={classes.titleRoot}
          >
            <span
              style={{
                color: theme === "dark" ? "rgb(61 124 194)" : "rgb(34 65 88)",
                letterSpacing: "4px"
              }}
            >
              FORGOT PASSWORD
            </span>
          </Typography>
          <form type="submit" onKeyDown={handleKeyDown}>
            <Box mb={2}>
              <TextField
                error={errParam}
                helperText={errText}
                label={<IntlMessages id="appModule.email" />}
                type="email"
                fullWidth
                onChange={event => setEmail(event.target.value)}
                defaultValue={email}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                autoComplete="off"
              />
            </Box>

            <Grid container>
              <Grid item xs={9}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={5}
                >
                  <Button
                    onClick={onSubmitTakePass}
                    variant="contained"
                    color="primary"
                  >
                    Take a Password
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box
                  display="flex"
                  alignItems="right"
                  justifyContent="space-between"
                  mb={5}
                >
                  <Button
                    onClick={forgotPass}
                    variant="contained"
                    color="primary"
                  >
                    Login Page
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>

          {/* {dispatch(AuhMethods[method].getSocialMediaIcons())} */}

          <ContentLoader />
          <Box className={classes.logo}>
            {theme === "dark" ? (
              <CmtImage style={{ width: "82px" }} src="images/logo-dark.png" />
            ) : (
              <CmtImage src="images/logo-white.png" />
            )}
          </Box>
        </Box>
      )}
    </AuthWrapper>
  );
};

export default SignIn;
