import { GET_APPLY_TYPE } from '../actions/types';
import { normalizeApplyType } from 'redux/schemas/applyType';

const initialState = {
  applyType: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLY_TYPE:
      const normalizedApplyTypes = normalizeApplyType(action.payload);
      return { ...state, applyType: normalizedApplyTypes.entities.apply_types };
    default:
      return state;
  }
};
