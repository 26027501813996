import { getProductTypes, addProductType, updateProductType, deleteProductType } from 'redux/actions/productTypes';
import axios from './axios';
import { fetchStart, fetchError, fetchSuccess } from '../redux/actions/Common';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};
export const getProductTypesService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('productType')
    .then(res => {
      dispatch(getProductTypes(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addProductTypesService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('productType', data)
    .then(res => {
      dispatch(addProductType(res));
      dispatch(fetchSuccess());
      sendLogService.sendProductTypesLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateProductTypesService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('productType', data)
    .then(res => {
      dispatch(updateProductType(res));
      dispatch(fetchSuccess());
      sendLogService.sendProductTypesLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteProductTypesService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`productType/${id}`)
    .then(res => {
      dispatch(deleteProductType(res));
      dispatch(fetchSuccess());
      sendLogService.sendProductTypesLog(res, id);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
