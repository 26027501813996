import { normalize, schema } from 'normalizr';

const seasons = new schema.Entity(
  'seasons',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  },
);
const response = {
  seasons: [seasons],
  stateSeasons: [seasons],
  updatedSeason: [seasons],
};

export const normalizeSeasons = data => normalize(data, [response]);
