import React, { useEffect, lazy, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOperatorService } from "services/operator";
import { getHotelService } from "services/hotels";
import { getContractService } from "services/contract";
import { getMarkupsService } from "services/markup";
import { getMarketService } from "services/market";
import { getCurrenciesService } from "services/currency";
import { getSeasonService } from "services/season";
import { getStarRatesService } from "services/starRate";
import { getProductTypesService } from "services/productTypes";
import { getCountriesService, getRegionsService } from "services/destinations";
import { getBoardsService } from "services/boards";
import { getRateTypesService } from "services/rateType";
import { getAllotmentTypeService } from "services/allotmentType";
import { getBanksService } from "services/banks";
import { getPaymentMethodsService } from "services/paymentMethods";
import { getPaymentConditionsService } from "services/paymentConditions";
import { getStayTypesService } from "services/stayType";
import { getCancellationPoliciesServices } from "services/cancellationPoliciesDetail";
import { getAdminCompaniesService } from "services/company";
import { getReservationsService } from "services/reservation";
import { getOnSaleContractService } from "services/onSaleContract";
import { getExtraService } from "services/extras";
import { getServiceService } from "services/services";
import { getRoomService } from "services/rooms";
import { MemoizedContractSeason } from "./ContractSeason";
import { useWhyDidYouUpdate } from "@jumbo/utils/useWhyDidYouUpdate";
import PageLoader from "../PageComponents/PageLoader";
import { Card, lighten, makeStyles } from "@material-ui/core";
import useStyles from "@coremat/CmtCard/CmtCardHeader.style";
import { addNewContractLoaded } from "redux/actions/hasLoaded";
const ContractSeasonLoader = props => {
  const dispatch = useDispatch();
  const [isFinished, setIsFinished] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);
  const hasLoaded = useSelector(({ hasLoaded }) => hasLoaded.hasLoaded);

  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: "100%",
      height: "100%",
      margin: "20px auto 0 auto",
      fontSize: "12px!important"
    },
    formControl: {
      margin: theme.spacing(2),
      fontSize: "12px!important",
      minWidth: "-webkit-fill-available;",
      maxWidth: "-webkit-fill-available;"
    },
    rootcard: {
      minWidth: "100%",
      maxWidth: "100%",
      margin: "0 auto",
      fontSize: "12px!important",
      backgroundColor: lighten(theme.palette.background.paper, 0.1)
    }
  }));

  const classes = useStyles();

  useEffect(() => {
    const firstLoader = async () => {
      await Promise.all([
        dispatch(
          getContractService(
            authUser.authority_level,
            localStorage.getItem("selectedCompany"),
            null,
            null,
            authUser.id
          )
        ),
        dispatch(getOnSaleContractService()),
        dispatch(getRegionsService()),
        dispatch(getAdminCompaniesService()),
        dispatch(getBoardsService()),
        dispatch(getHotelService()),
        dispatch(getProductTypesService()),
        dispatch(getSeasonService()),
        dispatch(getOperatorService()),
        dispatch(getMarkupsService()),
        dispatch(getCurrenciesService()),
        dispatch(getRateTypesService()),
        dispatch(getBanksService()),
        dispatch(getPaymentMethodsService()),
        dispatch(getPaymentConditionsService()),
        dispatch(getAllotmentTypeService()),
        dispatch(getStayTypesService()),
        dispatch(getCancellationPoliciesServices()),
        dispatch(getReservationsService()),
        dispatch(getStarRatesService()),
        dispatch(getExtraService()),
        dispatch(getServiceService()),
        dispatch(getRoomService()),
        dispatch(getCountriesService(["", 0]))
      ]);
    };
    dispatch(getMarketService());

    if (hasLoaded.dashboard === false && hasLoaded.newContract === false) {
      firstLoader().then(() => {
        dispatch(addNewContractLoaded(true));
        setIsFinished(true);
      });
    } else {
      setIsFinished(true);
    }
  }, []);
  return isFinished ? <MemoizedContractSeason props={props} /> : <PageLoader />;
};

export default ContractSeasonLoader;

export const MemoizedContractSeasonLoader = React.memo(ContractSeasonLoader);
