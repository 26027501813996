import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Office from "./Office";
import Company from "./Company";
import Users from "./Users";
import UserPermission from "./UserPermission";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {
  userMemo,
  officeMemo,
  companyMemo,
  get_destinations
} from "redux/selector/companyHasOfficeSelector";
import { useSelector } from "react-redux";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { authUserMemo } from "redux/selector/companyHasOfficeSelector";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  root2: {
    flexGrow: 0.7,
    textAlign: "center"
  },
  tabs: {
    maxWidth: "100%",
    width: "100%"
  },
  tabpanel: {
    marginLeft: "auto",
    marginRight: "auto"
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default function CompanyLogin({
  countryCache,
  cityCache,
  divisionCache,
  subCityCache,
  setCountryCache,
  setDivisionCache,
  setCityCache,
  setSubCityCache
}) {
  const userData = useSelector(userMemo);
  const officeData = useSelector(officeMemo);
  const companyState = useSelector(companyMemo);
  const destinationData = useSelector(get_destinations);
  const authUserState = useSelector(authUserMemo);

  const [value, setValue] = React.useState(0);
  const [checkedDestination, setCheckedDestination] = React.useState([]);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CloneProps(props) {
    const { children, ...other } = props;
    return children(other);
  }

  useEffect(() => {
    setCheckedDestination(destinationData);
  }, [destinationData]);

  return (
    <Paper square className={classes.root}>
      {authUserState !== 30 ? (
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          {authUserState !== 0 && (
            <Tab
              icon={<BusinessOutlinedIcon />}
              label={<IntlMessages id="COMPANY" />}
            />
          )}

          <Tab
            icon={<HomeWorkOutlinedIcon />}
            label={<IntlMessages id="OFFICES" />}
          />

          <CloneProps>
            {tabProps =>
              userData.length != 0 ? (
                <Tab
                  {...tabProps}
                  icon={<PersonOutlineOutlinedIcon />}
                  label={<IntlMessages id="USERS" />}
                />
              ) : Object.values(officeData)?.length != 0 ? (
                checkedDestination.length != 0 ? (
                  <Tab
                    {...tabProps}
                    icon={<PersonOutlineOutlinedIcon />}
                    label={<IntlMessages id="USERS" />}
                  />
                ) : (
                  <Tooltip
                    title={
                      <IntlMessages id={"Firstly, Define Office Destination"} />
                    }
                  >
                    <div className={classes.root2}>
                      <Tab
                        {...tabProps}
                        icon={<PersonOutlineOutlinedIcon />}
                        label={<IntlMessages id="USERS" />}
                        disabled
                      />
                    </div>
                  </Tooltip>
                )
              ) : (
                <Tooltip title="Firstly, Please Add Office">
                  <div className={classes.root2}>
                    <Tab
                      {...tabProps}
                      icon={<PersonOutlineOutlinedIcon />}
                      label="USERS"
                      disabled
                    />
                  </div>
                </Tooltip>
              )
            }
          </CloneProps>

          <CloneProps>
            {tabProps =>
              userData.length != 0 ? (
                <Tab
                  {...tabProps}
                  icon={<ManageAccountsOutlinedIcon size={21} />}
                  label={<IntlMessages id="USER.PERMISSION" />}
                />
              ) : (
                <Tooltip
                  title={<IntlMessages id={"Firstly, Please Add User"} />}
                >
                  <div className={classes.root2}>
                    <Tab
                      {...tabProps}
                      icon={<ManageAccountsOutlinedIcon size={21} />}
                      label="USER PERMISSION"
                      disabled
                    />
                  </div>
                </Tooltip>
              )
            }
          </CloneProps>
        </Tabs>
      ) : (
        <Tab
          className={classes.tabpanel}
          icon={<HomeWorkOutlinedIcon />}
          label={<IntlMessages id="OFFICES" />}
        />
      )}
      {authUserState !== 30 ? (
        <>
          <TabPanel value={value} index={0}>
            <Company
              companyState={companyState}
              countryCache={countryCache}
              divisionCache={divisionCache}
              cityCache={cityCache}
              subCityCache={subCityCache}
              setCountryCache={setCountryCache}
              setDivisionCache={setDivisionCache}
              setCityCache={setCityCache}
              setSubCityCache={setSubCityCache}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Office
              countryCache={countryCache}
              divisionCache={divisionCache}
              cityCache={cityCache}
              subCityCache={subCityCache}
              setCountryCache={setCountryCache}
              setDivisionCache={setDivisionCache}
              setCityCache={setCityCache}
              setSubCityCache={setSubCityCache}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Users />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <UserPermission />
          </TabPanel>
        </>
      ) : (
        <TabPanel value={value} index={0}>
          <Office
            countryCache={countryCache}
            divisionCache={divisionCache}
            cityCache={cityCache}
            subCityCache={subCityCache}
            setCountryCache={setCountryCache}
            setDivisionCache={setDivisionCache}
            setCityCache={setCityCache}
            setSubCityCache={setSubCityCache}
          />
        </TabPanel>
      )}
    </Paper>
  );
}
