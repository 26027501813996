import { GET_STATUS } from './types';
import { normalizeStatus } from 'redux/schemas/restrictionStatus';

export const getStatus = res => dispatch => {
  const normalizedStatus = normalizeStatus(res.data.data);

  dispatch({
    type: GET_STATUS,
    payload: normalizedStatus.entities,
  });
};
