import { GET_STAR_RATES } from '../actions/types';

const initialState = {
  starRates: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STAR_RATES:
      return { ...state, starRates: action.payload };
    default:
      return state;
  }
};
