import {
  GET_ADMIN_COMPANIES,
  ADD_ADMIN_COMPANY,
  UPDATE_ADMIN_COMPANY,
  /* GET_OFFICES, */
  ADD_OFFICE,
  UPDATE_OFFICE,
  ADD_ADMIN_USER,
  UPDATE_ADMIN_USER,
  DELETE_ADMIN_COMPANY,
  DELETE_OFFICE,
  DELETE_ADMIN_USER,
  OFFICE_DESTINATION_COUNTRIES,
  OFFICE_DESTINATION_DIVISIONS,
  OFFICE_DESTINATION_CITIES,
  ADD_OFFICE_DESTINATION,
  UPDATE_OFFICE_DESTINATION,
  FETCH_COMPANY_ERROR,
  FETCH_COMPANY_SUCCESS,
} from '../../@jumbo/constants/ActionTypes';

//COMPANY

export const getAdminCompanies = getCompanies => dispatch => {
  dispatch({ type: GET_ADMIN_COMPANIES, payload: getCompanies.data });
};

export const addAdminCompany = addCompany => dispatch => {
  dispatch({ type: ADD_ADMIN_COMPANY, payload: addCompany.data.data });
};

export const updateAdminCompany = updateCompany => dispatch => {
  dispatch({ type: UPDATE_ADMIN_COMPANY, payload: updateCompany.data.data });
};

export const deleteAdminCompany = deleteCompany => dispatch => {
  dispatch({ type: DELETE_ADMIN_COMPANY, payload: deleteCompany.data });
};

/* export const getOffices = getOffice => dispatch => dispatch({ type: GET_OFFICES, payload: getOffice }); */

//OFFİCE

export const addOffice = addOffice => dispatch => {
  dispatch({ type: ADD_OFFICE, payload: addOffice.data.data });
};

export const updateOffice = updateOffice => dispatch => {
  dispatch({ type: UPDATE_OFFICE, payload: updateOffice.data });
};

export const deleteOffice = deleteOffice => dispatch => {
  dispatch({ type: DELETE_OFFICE, payload: deleteOffice.data });
};

export const destinationCountries = destination => dispatch => {
  dispatch({ type: OFFICE_DESTINATION_COUNTRIES, payload: destination.data });
};

export const destinationCities = destinationCity => dispatch => {
  dispatch({ type: OFFICE_DESTINATION_CITIES, payload: destinationCity.data });
};

export const destinationDivisions = destinationDivision => dispatch => {
  dispatch({ type: OFFICE_DESTINATION_DIVISIONS, payload: destinationDivision.data });
};


export const addDestination = addDestination => dispatch => {
  dispatch({ type: ADD_OFFICE_DESTINATION, payload: addDestination.data });
};

export const updateDestination = updateDestination => dispatch => {
  dispatch({ type: UPDATE_OFFICE_DESTINATION, payload: updateDestination.data });
};

//USER
export const addUser = addUser => dispatch => {
  dispatch({ type: ADD_ADMIN_USER, payload: addUser.data });
};

export const updateUser = updateUser => dispatch => {
  dispatch({ type: UPDATE_ADMIN_USER, payload: updateUser.data });
};

export const deleteUser = deleteUser => dispatch => {
  dispatch({ type: DELETE_ADMIN_USER, payload: deleteUser.data });
};

export const fetchCompanyError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_COMPANY_ERROR,
      payload: JSON.parse(error),
    });
  };
};

export const fetchCompanySuccess = message => {
  return dispatch => {
    dispatch({
      type: FETCH_COMPANY_SUCCESS,
      payload: message || '',
    });
  };
};
