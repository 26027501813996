import React, { useContext, useEffect } from "react";
import {
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography
} from "@material-ui/core";
import CmtAvatar from "../../../../@coremat/CmtAvatar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AuhMethods } from "../../../../services/auth";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
//import PersonIcon from '@material-ui/icons/Person';
//import SettingsIcon from '@material-ui/icons/Settings';
import SidebarThemeContext from "../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext";
import SidebarToggleHandler from "../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler";
import { useDispatch, useSelector } from "react-redux";
import {
  companyData,
  nonEnterprise
} from "../../../../redux/selector/companyHasOfficeSelector";
import { useHistory, useLocation } from "react-router";
import { chooseCompany } from "redux/actions/Auth";
import LayoutContext from "@coremat/CmtLayouts/LayoutContext";
import Tooltip from "@mui/material/Tooltip";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { isEnterprise, sidebarNavs } from "./menus";
import { getContractService } from "services/contract";
import { toInteger } from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "30px 16px 12px 16px",
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
    marginBottom: "0.2rem"
  },
  userInfo: {
    paddingTop: 24,
    transition: "all 0.1s ease",
    opacity: 1,
    ".Cmt-miniLayout .Cmt-sidebar-content:not &": {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: "all 0.3s ease"
    }
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  userSubTitle: {
    fontSize: 11,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25
  }
}));

const SidebarHeader = () => {
  const { isSidebarOpen, setSidebarOpen } = useContext(LayoutContext);
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const { authUser } = useSelector(({ auth }) => auth);
  //const { selectedCompany } = useSelector(({ auth }) => auth);
  const companyState = useSelector(companyData);
  const companyArray = useSelector(nonEnterprise);
  const classes = useStyles({ sidebarTheme });
  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = React.useState([]);
  console.log(selectedCompany);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [companyAnchorEl, setCompanyAnchorEl] = React.useState(null);
  const [changeCompany, setChangeCompany] = React.useState(null);
  console.log(companyState);
  const history = useHistory();
  const location = useLocation();
  console.log(location);
  useEffect(() => {
    if (authUser.authority_level != 10 && authUser.authority_level != 11) {
      setSelectedCompany(
        JSON.parse(localStorage.getItem("userCompanies"))?.filter(
          element => element.id == localStorage.getItem("selectedCompany")
        )
      );
    }
  }, [authUser]);

  // Change sidebar name if company name is changed
  useEffect(() => {
    if (authUser.authority_level !== 10 && authUser.authority_level !== 11) {
      let company =
        Object.values(companyState).length > 0 &&
        Object.values(companyState).find(element => {
          return (
            element.id == parseInt(localStorage.getItem("selectedCompany"))
          );
        });
      let selectedUserCompany = [];
      selectedUserCompany.push(company);
      setSelectedCompany(selectedUserCompany);
    }
  }, [companyState]);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCompany = event => {
    setCompanyAnchorEl(event.currentTarget);
    setChangeCompany(true);
  };

  const refreshPage = () => {
    dispatch(
      getContractService(
        authUser.authority_level,
        localStorage.getItem("selectedCompany"),
        0,
        20,
        authUser.id
      )
    );
  };

  const handleCompanyClose = event => {
    if (event.target.id) {
      localStorage.setItem("selectedCompany", event.target.id);
      window.dispatchEvent(new Event("company"));
      setSelectedCompany(
        JSON.parse(localStorage.getItem("userCompanies"))?.filter(
          element => element.id == event.target.id
        )
      );
      dispatch(chooseCompany(event.target.id));
      refreshPage();
    }
    setChangeCompany(false);
    setCompanyAnchorEl(null);
  };

  const handleOfficeClose = event => {
    if (event) {
      localStorage.setItem("selectedCompany", event.company_id);
      localStorage.setItem("selectedOffice", event.id);
      window.dispatchEvent(new Event("company"));
      setSelectedCompany(
        JSON.parse(localStorage.getItem("userCompanies"))?.filter(
          element => element.id == event.company_id
        )
      );
      dispatch(chooseCompany(event.company_id));
      refreshPage();
    }
    setChangeCompany(false);
    setCompanyAnchorEl(null);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  /*   const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    if (location.pathname.includes('master')) {
      history.push('/master/login');
    } else {
      history.push('/login');
    }
  }; */

  return (
    <div className={classes.root}>
      {isSidebarOpen ? (
        <Tooltip title="Close Sidebar">
          <span
            onClick={() => setSidebarOpen(!isSidebarOpen)}
            style={{
              display: "flex",
              width: "100%",
              cursor: "pointer",
              fontSize: 20
            }}
          >
            MUNDOSAI B2B
            <ArrowLeftIcon
              style={{ fontSize: 28 }}
              onClick={() => setSidebarOpen(!isSidebarOpen)}
            />
          </span>
        </Tooltip>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CmtAvatar
            src={"/images/proxo.jpg"}
            alt="User Avatar"
            onClick={() => setSidebarOpen(!isSidebarOpen)}
          />
          <ArrowRightIcon
            onClick={() => setSidebarOpen(!isSidebarOpen)}
            style={{ fontSize: "28px", cursor: "pointer" }}
          />
        </div>
      )}
      {/*       <div className={classes.userInfo} onClick={handlePopoverOpen}>
        <div
          className="pointer"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <div className="mr-2">
            <Typography className={classes.userTitle} component="h3" variant="h6">
              {authUser.fullname}
            </Typography>
            <Typography className={classes.userSubTitle}>{authUser.email}</Typography>
          </div>
          <ArrowDropDownIcon />
        </div>
      </div> */}

      {authUser.authority_level === 20 &&
        (JSON.parse(localStorage.getItem("userCompanies"))?.length !== 1 ? (
          <div className={classes.userInfo} onClick={handleCompany}>
            <div
              className="pointer"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end"
              }}
            >
              <div className="mr-2">
                <Typography
                  className={classes.userTitle}
                  component="h3"
                  variant="h6"
                >
                  Selected Company
                </Typography>
                <Typography className={classes.userSubTitle}>
                  {selectedCompany?.length ? selectedCompany[0]?.name : ""}
                </Typography>
              </div>
              <ArrowDropDownIcon />
            </div>
          </div>
        ) : (
          <div className={classes.userInfo}>
            <div
              className="pointer"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end"
              }}
            >
              <div className="mr-2">
                <Typography
                  className={classes.userTitle}
                  component="h3"
                  variant="h6"
                >
                  Selected Company
                </Typography>
                <Typography className={classes.userSubTitle}>
                  {selectedCompany[0]?.name}
                </Typography>
              </div>
            </div>
          </div>
        ))}

      {authUser.authority_level === 30 &&
        (JSON.parse(localStorage.getItem("userCompanies"))?.length !== 1 ||
        (Object.values(
          JSON.parse(localStorage.getItem("userOffices"))["offices"]
        ).length === 1 &&
          JSON.parse(localStorage.getItem("userOffices"))["offices"][
            parseInt(localStorage.getItem("selectedCompany"))
          ].length !== 1) ? (
          <div className={classes.userInfo} onClick={handleCompany}>
            <div
              className="pointer"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end"
              }}
            >
              <div className="mr-2">
                <Typography
                  className={classes.userTitle}
                  component="h3"
                  variant="h6"
                >
                  Selected Office & Company
                </Typography>
                <Typography className={classes.userSubTitle}>
                  {
                    JSON.parse(localStorage.getItem("userOffices"))["offices"][
                      parseInt(localStorage.getItem("selectedCompany"))
                    ].filter(
                      element =>
                        element.id ==
                        JSON.parse(localStorage.getItem("selectedOffice"))
                    )[0].name
                  }{" "}
                  (
                  {
                    JSON.parse(localStorage.getItem("userOffices"))[
                      "companies"
                    ][parseInt(localStorage.getItem("selectedCompany"))][0].name
                  }
                  )
                </Typography>
              </div>
              <ArrowDropDownIcon />
            </div>
          </div>
        ) : (
          <div className={classes.userInfo}>
            <div
              className="pointer"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end"
              }}
            >
              <div className="mr-2">
                <Typography
                  className={classes.userTitle}
                  component="h3"
                  variant="h6"
                >
                  Selected Office & Company
                </Typography>
                <Typography className={classes.userSubTitle}>
                  {
                    JSON.parse(localStorage.getItem("userOffices"))["offices"][
                      parseInt(localStorage.getItem("selectedCompany"))
                    ][0].name
                  }{" "}
                  (
                  {
                    JSON.parse(localStorage.getItem("userOffices"))[
                      "companies"
                    ][parseInt(localStorage.getItem("selectedCompany"))][0].name
                  }
                  )
                </Typography>
              </div>
            </div>
          </div>
        ))}

      {/*    {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}>
          <Paper elevation={8}>
            <MenuList>
               <MenuItem onClick={handlePopoverClose}>
                <PersonIcon />
                <div className="ml-2">Profile</div>
              </MenuItem> */}
      {/* <MenuItem onClick={handlePopoverClose}>
                <SettingsIcon />
                <div className="ml-2">Settings</div>
              </MenuItem> 
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <div className="ml-2">Logout</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}*/}
      {changeCompany && (
        <Popover
          open={changeCompany}
          anchorEl={companyAnchorEl}
          container={companyAnchorEl}
          onClose={handleCompanyClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
        >
          {authUser.authority_level != 30 ? (
            <Paper elevation={8}>
              <MenuList>
                {JSON.parse(localStorage.getItem("userCompanies"))?.map(
                  element =>
                    element.id !==
                    parseInt(localStorage.getItem("selectedCompany")) ? (
                      <MenuItem onClick={handleCompanyClose}>
                        <div className="ml-2" id={element.id}>
                          {element.name}
                        </div>
                      </MenuItem>
                    ) : (
                      <></>
                    )
                )}
              </MenuList>
            </Paper>
          ) : (
            <Paper elevation={8}>
              <MenuList>
                {Object.values(
                  JSON.parse(localStorage.getItem("userOffices"))["offices"]
                )?.map(element =>
                  element.map(data =>
                    data.id !==
                    parseInt(localStorage.getItem("selectedOffice")) ? (
                      <MenuItem onClick={() => handleOfficeClose(data)}>
                        <div className="ml-2" id={data.id}>
                          {data.name} ({data.company_name})
                        </div>
                      </MenuItem>
                    ) : (
                      <></>
                    )
                  )
                )}
              </MenuList>
            </Paper>
          )}
        </Popover>
      )}
    </div>
  );
};

export default SidebarHeader;
