import React, { useState, useEffect, useRef } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import {
  addMarkupService,
  addRateRangesServices,
  deleteMarkupService,
  deleteRateRangesServices,
  getMarkupsService,
  updateMarkupService,
  updateRateRangesServices
} from "services/markup";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import { Autocomplete } from "@material-ui/lab";
import InputAdornment from "@mui/material/InputAdornment";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { PriceFormatCustom } from "../Common/NumberFormat/NumberFormat";
import { Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange, red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import SelectAll from "./SelectAll";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import { multiDataSetter } from "@jumbo/utils/commonHelper";
import MultiSelect from "../Common/MultiSelect";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import Loader from "../Common/Loader";

const themeMode = localStorage.getItem("theme-type");
const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);
const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text
  });
};
//const tableRef = React.createRef();

function Markup() {
  const themeCancel = createTheme({
    palette: {
      primary: red
    }
  });
  const dispatch = useDispatch();
  const [rateRangeData, setRateRangeData] = useState([]);
  const intl = useIntl();
  useEffect(() => {
    dispatch(getMarkupsService());
  }, [dispatch]);

  const { markups } = useSelector(({ markups }) => markups);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const { rate_ranges } = useSelector(({ markups }) => markups);

  let isAuthAdd = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.put === 1
  );
  let isAuthDelete = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.delete === 1
  );

  const [isAddClick, setIsAddClick] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalRateData, setModalRateData] = useState([]);

  const checkModalData = (modalData, rate_ranges) => {
    let data = {
      pp: [],
      pr: []
    };

    const setData = () => {
      return Object.values(rate_ranges)
        ?.filter(range => modalData.rate_ranges.includes(range.id))
        .map((range, idx) => {
          return { ...range, count: idx };
        })
        .map(val => {
          if (val.apply_type.length > 1) {
            data.pp.push(val.max_rate);
            data.pr.push(val.max_rate);
          } else {
            if (val.apply_type[0] === 1) {
              data.pp.push(val.max_rate);
            } else {
              data.pr.push(val.max_rate);
            }
          }
        });
    };

    setData();

    const last_pp = data.pp[data.pp.length - 1];
    const last_pr = data.pr[data.pr.length - 1];

    if (last_pp > last_pr) {
      sweetAlerts("error", intl.formatMessage({ id: "pp.pr.not.equal" }));
    } else if (last_pr > last_pp) {
      sweetAlerts("error", intl.formatMessage({ id: "pp.pr.not.equal" }));
    } else {
      setOpen(false);
    }
  };
  // const clickEdit = () => {
  //   tableRef.current.props.data.map(k => {
  //     k.tableData = { id: 0 };
  //   });
  //   setIsAddClick(!isAddClick);
  // };

  //For keyboard date picker
  const date = new Date();
  const today = `${date.getFullYear()}-${date.getMonth() +
    1}-${date.getDate()}`;

  //For Invalid Date Control
  const controlDate = date => {
    if (date != "Invalid date") {
      return true;
    }
    return false;
  };

  const columnsMarkUp = [
    {
      title: "Button",
      align: "center",
      field: "button",
      render: props => (
        <Button
          style={{
            backgroundColor: "#ffa726"
          }}
          onClick={() => {
            setOpen(true);
            setModalData(props);
          }}
        >
          ADD RATE RANGE
        </Button>
      ),
      editComponent: props => <></>
    },
    {
      title: "Code",
      align: "center",
      field: "code",
      validate: rowData => (rowData.code ? true : false)
    },
    {
      title: "Mark Up Name",
      align: "center",
      field: "name",
      validate: rowData => (rowData.name ? true : false)
    },
    {
      title: "Selling Start Date",
      align: "center",
      field: "selling_start_date",
      render: rowData => {
        return moment(rowData.selling_start_date).format("DD/MM/YYYY");
      },
      validate: rowData => controlDate(rowData.selling_start_date),
      editComponent: props => (
        (props.rowData.selling_start_date = props.value
          ? moment(props.value).format("YYYY-MM-DD")
          : today),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="selling_start_date"
              error={props.rowData.selling_start_date ? false : true}
              disablePast
              //format={localStorage.getItem('format')}
              format="dd/MM/yyyy"
              autoOk="true"
              value={moment(props.rowData.selling_start_date).format(
                "YYYY-MM-DD"
              )}
              onChange={date =>
                props.onChange(moment(date).format("YYYY-MM-DD"))
              }
              InputLabelProps={{
                shrink: true
              }}
            />
          </MuiPickersUtilsProvider>
        )
      )
    },
    {
      title: "Selling End Date",
      align: "center",
      field: "selling_end_date",
      render: rowData => {
        return moment(rowData.selling_end_date).format("DD/MM/YYYY");
      },
      validate: rowData => controlDate(rowData.selling_end_date),
      editComponent: props => (
        (props.rowData.selling_end_date = props.value
          ? moment(props.value).format("YYYY-MM-DD")
          : today),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="selling_end_date"
              error={props.rowData.selling_end_date ? false : true}
              disablePast
              //format={localStorage.getItem('format')}
              format="dd/MM/yyyy"
              autoOk="true"
              value={moment(props.rowData.selling_end_date).format(
                "YYYY-MM-DD"
              )}
              minDate={props.rowData.selling_start_date}
              onChange={date =>
                props.onChange(moment(date).format("YYYY-MM-DD"))
              }
              InputLabelProps={{
                shrink: true
              }}
            />
          </MuiPickersUtilsProvider>
        )
      )
    },
    {
      title: "Stay Start Date",
      align: "center",
      field: "accomodation_start_date",
      render: rowData => {
        return moment(rowData.accomodation_start_date).format("DD/MM/YYYY");
      },
      validate: rowData => controlDate(rowData.accomodation_start_date),
      editComponent: props => (
        (props.rowData.accomodation_start_date = props.value
          ? moment(props.value).format("YYYY-MM-DD")
          : today),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="accomodation_start_date"
              error={props.rowData.accomodation_start_date ? false : true}
              disablePast
              format="dd/MM/yyyy"
              autoOk="true"
              value={moment(props.rowData.accomodation_start_date).format(
                "YYYY-MM-DD"
              )}
              onChange={date => {
                props.onChange(moment(date).format("YYYY-MM-DD"));
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </MuiPickersUtilsProvider>
        )
      )
    },
    {
      title: "Stay End Date",
      align: "center",
      field: "accomodation_end_date",
      render: rowData => {
        return moment(rowData.accomodation_end_date).format("DD/MM/YYYY");
      },
      validate: rowData => controlDate(rowData.accomodation_end_date),
      editComponent: props => (
        (props.rowData.accomodation_end_date = props.value
          ? moment(props.value).format("YYYY-MM-DD")
          : today),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="accomodation_end_date"
              error={props.rowData.accomodation_end_date ? false : true}
              disablePast
              format="dd/MM/yyyy"
              autoOk="true"
              value={moment(props.rowData.accomodation_end_date).format(
                "YYYY-MM-DD"
              )}
              onChange={date => {
                props.onChange(moment(date).format("YYYY-MM-DD"));
              }}
              minDate={props.rowData.accomodation_start_date}
              InputLabelProps={{
                shrink: true
              }}
            />
          </MuiPickersUtilsProvider>
        )
      )
    }
  ];
  const tableRef = useRef();
  let isUpdating = useRef(false);
  let isAdding = useRef(false);
  let updatedData = useRef();

  useEffect(() => {
    let rowData;
    //find markups rowData by code
    if (
      tableRef.current.state.data.length >= 0 &&
      Object.values(markups).length >= 0 &&
      isUpdating.current === false &&
      isAdding.current === true
    ) {
      // get last RowData
      rowData = Object.values(markups).at(-1);
      setOpen(true);
      setModalData(rowData);
    }
    if (
      tableRef.current.state.data.length >= 0 &&
      Object.values(markups).length >= 0 &&
      isUpdating.current === true &&
      isAdding.current === false
    ) {
      // get last RowData
      rowData = updatedData.current;
      setOpen(true);
      setModalData(rowData);
    }
  }, [markups]);

  // const expandRowDetail = newData => {
  //   setOpen(true);
  //   setModalData(newData);
  // };
  return (
    <div>
      <MaterialTable
        tableRef={tableRef}
        columns={columnsMarkUp}
        data={Object.values(markups)}
        title=""
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          actionsColumnIndex: -1,
          search: false,
          addRowPosition: "first"
        }}
        components={{
          OverlayLoading: () => <Loader />,
          EditRow: props => (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (e.keyCode === 27) {
                  props.onEditingCanceled(props.mode, props.data);
                }
              }}
            />
          ),
          onRowAdd: props => (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (e.keyCode === 27) {
                  props.onEditingCanceled(props.mode, props.data);
                }
              }}
            />
          ),
          Header: props => {
            return (
              <TableHead>
                <TableCell align="center" rowSpan={2}></TableCell>

                <TableCell align="center" rowSpan={2}>
                  <IntlMessages id="code" />
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  <IntlMessages id="name" />
                </TableCell>
                <TableCell colSpan={2} align="center">
                  <IntlMessages id="selling.date" />
                </TableCell>
                <TableCell colSpan={2} align="center">
                  <IntlMessages id="stay.date" />
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  <IntlMessages id="actions" />
                </TableCell>
                <TableRow>
                  <TableCell align="center">
                    <IntlMessages id="start.date" />
                  </TableCell>
                  <TableCell align="center">
                    <IntlMessages id="end.date" />
                  </TableCell>
                  <TableCell align="center">
                    <IntlMessages id="start.date" />
                  </TableCell>
                  <TableCell align="center">
                    <IntlMessages id="end.date" />
                  </TableCell>
                </TableRow>
              </TableHead>
            );
          },
          EditRow: props => (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (e.keyCode === 27) {
                  props.onEditingCanceled(props.mode, props.data);
                }
              }}
            />
          ),
          onRowAdd: props => (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (e.keyCode === 27) {
                  props.onEditingCanceled(props.mode, props.data);
                }
              }}
            />
          )
        }}
        actions={[
          isAuthAdd
            ? undefined
            : {
                icon: "add",
                disabled: true,
                position: "toolbar",
                tooltip: "You are not authorized",
                onClick: (event, rowData) => {}
              }
        ]}
        icons={{
          Add: props => (
            <ThemeProvider theme={theme}>
              <ColorButton
                variant="contained"
                color="backgroundColor"
                startIcon={<AddBoxIcon />}
              >
                <IntlMessages id="add" />
              </ColorButton>
            </ThemeProvider>
          ),
          Edit: () =>
            themeMode === "dark" ? (
              <ModeEditIcon style={{ color: "white" }} />
            ) : (
              <ModeEditIcon style={{ color: "black" }} />
            ),
          Check: () => <ModeCheckIcon style={{ color: "green" }} />,
          Clear: () => <ModeCloseIcon sx={{ color: "red" }} />
        }}
        editable={{
          deleteTooltip: row =>
            isAuthDelete ? "Delete" : "You are not authorized",
          editTooltip: row => {
            return isAuthUpdate ? "Update" : "You are not authorized";
          },
          isDeletable: row => (isAuthDelete ? true : false),
          isEditable: row => (isAuthUpdate ? true : false),
          onRowAdd: isAuthAdd
            ? newData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    setIsAddClick(true);
                    if (
                      !newData.code ||
                      !newData.name ||
                      !newData.accomodation_start_date ||
                      !newData.accomodation_end_date ||
                      !newData.selling_start_date ||
                      !newData.selling_end_date
                    ) {
                      sweetAlerts(
                        "error",
                        intl.formatMessage({ id: "empty.value" })
                      );
                    } else if (
                      moment(newData.selling_start_date).isAfter(
                        newData.selling_end_date
                      )
                    ) {
                      sweetAlerts(
                        "error",
                        intl.formatMessage({
                          id: "end.date.cant.be.smaller.than.start.date"
                        }),
                        "Ooops..."
                      );
                      reject();
                    } else if (
                      moment(newData.accomodation_start_date).isAfter(
                        newData.accomodation_end_date
                      )
                    ) {
                      sweetAlerts(
                        "error",
                        intl.formatMessage({
                          id: "end.date.cant.be.smaller.than.start.date"
                        }),
                        "Ooops..."
                      );
                      reject();
                    } else {
                      console.log(newData);
                      //(newData.contract_id = 1); // burası gelen contract_idye göre değişçek
                      const addData = async () => {
                        //set isAdding to true to get inside of Useeffect control
                        isAdding.current = true;
                        await dispatch(addMarkupService(newData));
                        resolve();
                      };
                      // Call addData function and set isAdding to false after finishing adding the data
                      addData().then(() => {
                        isAdding.current = false;
                      });
                    }
                    resolve();
                  }, 1000);
                })
            : undefined,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                setIsAddClick(true);
                markups[newData.id] = newData;
                if (
                  !newData.code ||
                  !newData.name ||
                  !newData.accomodation_start_date ||
                  !newData.accomodation_end_date ||
                  !newData.selling_start_date ||
                  !newData.selling_end_date
                ) {
                  sweetAlerts(
                    "error",
                    intl.formatMessage({ id: "empty.value" })
                  );
                } else if (
                  moment(newData.selling_start_date).isAfter(
                    newData.selling_end_date
                  )
                ) {
                  sweetAlerts(
                    "error",
                    intl.formatMessage({
                      id: "end.date.cant.be.smaller.than.start.date"
                    }),
                    "Ooops..."
                  );
                  reject();
                } else if (
                  moment(newData.accomodation_start_date).isAfter(
                    newData.accomodation_end_date
                  )
                ) {
                  sweetAlerts(
                    "error",
                    intl.formatMessage({
                      id: "end.date.cant.be.smaller.than.start.date"
                    }),
                    "Ooops..."
                  );
                  reject();
                } else {
                  // Call expand function after updating the data
                  const updateData = async () => {
                    //set Updating to true to get inside of Useeffect control
                    isUpdating.current = true;
                    updatedData.current = newData;
                    await dispatch(updateMarkupService(markups[newData.id]));
                    //expandRowDetail(newData);

                    resolve();
                  };
                  // Call updateData function and set isUpdating to false after finishing updating the data
                  updateData().then(() => {
                    isUpdating.current = false;
                    updatedData.current = {};
                  });
                }
                resolve();
              }, 1000);
            }),
          onRowUpdateCancelled: () => {
            setIsAddClick(true);
          },
          onRowAddCancelled: () => {
            setIsAddClick(true);
          },
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                dispatch(deleteMarkupService(oldData.id));
                resolve();
              }, 1000);
            })
        }}
      />
      ,
      {open && (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              minWidth: "-webkit-max-content",
              "& .MuiPaper-root": {
                width: "100%",
                minWidth: "-webkit-max-content"
              }
            }
          }}
          maxWidth="md"
          open={open}
          //onClose={() => setOpen(false)}
        >
          <DialogContent>
            {
              <MarkupRates
                rateRangeData={rateRangeData}
                setRateRangeData={setRateRangeData}
                rowData={modalData}
                modalRateData={modalRateData}
              ></MarkupRates>
            }
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={themeCancel}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => checkModalData(modalData, rate_ranges)}
              >
                Cancel
              </Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default Markup;

function MarkupRates(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { rate_ranges } = useSelector(({ markups }) => markups);
  const [columnsValues, setColumnsValues] = useState([]);
  const [lastRange, setLastRange] = useState(0);

  let modalData = {
    pp: [],
    pr: [],
    pp_pr: []
  };

  useEffect(() => {
    setModalData();
    setLastRange(
      props.rowData.rate_ranges[
        Object.keys(props.rowData.rate_ranges).splice(-1)[0]
      ]
    );
  }, [rate_ranges]);

  const setModalData = () => {
    return Object.values(rate_ranges)
      ?.filter(range => props.rowData.rate_ranges?.includes(range.id))
      .map((range, idx) => {
        return { ...range, count: idx };
      })
      .map(val => {
        if (val.apply_type.length > 1) {
          modalData.pp_pr.push(val);
        } else {
          if (val.apply_type[0] === 1) {
            modalData.pp.push(val);
          } else {
            modalData.pr.push(val);
          }
        }
      });
  };

  const ratesWithMarkup = Object.values(rate_ranges).filter(
    rate_range => rate_range.markup_id == props.rowData?.id
  );
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const isAuthAdd = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.post === 1
  );
  const isAuthUpdate = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.put === 1
  );
  const isAuthDelete = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.delete === 1
  );
  let rateType = {
    1: {
      id: 1,
      name: "Per Person",
      code: "PP"
    },
    2: {
      id: 2,
      name: "Per Room",
      code: "PR"
    }
  };
  useEffect(() => {
    setColumnsValues([
      {
        title: <IntlMessages id="rate.type" />,
        field: "apply_type",
        align: "center",
        cellStyle: {
          textAlign: "center"
        },
        validate: rowData => (rowData.apply_type ? true : false),

        render: rowData => {
          let rateString = "";
          rowData.apply_type.forEach(element => {
            rateString = rateString + rateType[element].code + " ";
          });
          return rateString;
        },
        editComponent: ({ onChange, value, rowData }) => {
          // ** Burada update mi yoksa yeni bir eklememi yapıyor onun kontrolünü yapıyoruz.
          let isEditing = rowData.hasOwnProperty("id");

          // pp pr girebilmesi için ikisinin de en yüksek value'su eşit olmalı

          /**
           * {count: index}
           *
           * pp {}
           * pr {}
           * pp, pr {}
           *
           * pp seçtim:
           * pp, pr var mı ?
           *  * pp,pr en sonuncunun count'ı al
           *  * pp en sonuncu count'ı al
           *    * pp.count > pp,pr.count
           *      * pp'nin son değerini al
           *      * değilse pp,pr'nin en son değerini al
           *
           * * pr seçtim:
           * pp, pr var mı ?
           *  * pp,pr en sonuncunun count'ı al
           *  * pr en sonuncu count'ı al
           *    * pr.count > pp,pr.count
           *      * pr'nin son değerini al
           *      * değilse pp,pr'nin en son değerini al
           *
           * pp, pr seçtim:
           *  * pp'nin en sonuncu değerinin maxını al
           *  * pr'nin en sonuncu değerin maxını al
           *  * ikisi eşitse okay değilse cort
           */
          if (value?.length) {
            // ** pp,pr ikisi seçili ise
            if (value?.length > 1) {
              let maxPRCount = 0;
              let maxPRRate = 0;

              let maxPPCount = 0;
              let maxPPRate = 0;

              // ** modalData içerisinde pp,pr var mı
              if (modalData.pp_pr.length > 0) {
                // ** pp , pr'nin en büyük değerini al
                const maxPP_PRCount = Math.max(
                  ...modalData.pp_pr.map(data => data.count)
                );
                const maxPP_PRRate = parseFloat(
                  modalData.pp_pr.find(pp_pr => pp_pr.count === maxPP_PRCount)
                    .max_rate
                ).toFixed(2);

                // ** modal içerisinde pr var mı  pr'nin en yüksek değerini al
                if (modalData.pr.length > 0) {
                  maxPRCount = Math.max(
                    ...modalData.pr.map(data => data.count)
                  );
                  maxPRRate = parseFloat(
                    modalData.pr.find(pr => pr.count === maxPRCount).max_rate
                  ).toFixed(2);
                }
                // ** modal içerisinde pp var mı  pp'nin en yüksek değerini al
                if (modalData.pp.length > 0) {
                  maxPPCount = Math.max(
                    ...modalData.pp.map(data => data.count)
                  );
                  maxPPRate = parseFloat(
                    modalData.pp.find(pp => pp.count === maxPPCount).max_rate
                  ).toFixed(2);
                }

                /* Eğer sadece modalData içerisindeki pp_pr içerisinde 1 tane eleman varsa
                  alttaki count kontrolünü geçemediği için öncesine bi kontrol ekledim. 
                */
                if (modalData.pp_pr.length === 1) {
                  if (modalData.pp.length > 0 || modalData.pr.length > 0) {
                    if (maxPPRate === maxPRRate) {
                      if (!isEditing) {
                        parseFloat(maxPPRate) > parseFloat(maxPP_PRRate)
                          ? (rowData.min_rate = parseFloat(maxPPRate) + 0.01)
                          : (rowData.min_rate =
                              parseFloat(maxPP_PRRate) + 0.01);
                      }
                    }
                    // ** değerler eşit değilse error
                    else {
                      if (!isEditing) {
                        sweetAlerts(
                          "error",
                          intl.formatMessage({ id: "pp.pr.not.equal" })
                        );
                        value = [];
                      }
                    }
                  } else {
                    !isEditing &&
                      (rowData.min_rate = parseFloat(maxPP_PRRate) + 0.01);
                  }
                } else {
                  // **Eğer en son PPPR ekliyse Rate From'u PPPR yap
                  if (
                    maxPP_PRCount > maxPPCount &&
                    maxPP_PRCount > maxPRCount
                  ) {
                    !isEditing &&
                      (rowData.min_rate = parseFloat(maxPP_PRRate) + 0.01);
                  } else {
                    // ** PP ve PR ' nin maxRateleri eşit mi kotrol et eğer eşitse oluştur değilse error bas
                    if (maxPPRate === maxPRRate) {
                      // ** Burada update mi yoksa yeni bir eklememi yapıyor onun kontrolünü yapıyoruz.
                      if (!isEditing) {
                        parseFloat(maxPPRate) > parseFloat(maxPP_PRRate)
                          ? (rowData.min_rate = parseFloat(maxPPRate) + 0.01)
                          : (rowData.min_rate =
                              parseFloat(maxPP_PRRate) + 0.01);
                      }
                    }
                    // ** değerler eşit değilse error
                    else {
                      if (!isEditing) {
                        sweetAlerts(
                          "error",
                          intl.formatMessage({ id: "pp.pr.not.equal" })
                        );
                        value = [];
                      }
                    }
                  }
                }
              } else {
                // ** pr'nin en yüksek değerini al
                if (modalData.pr.length > 0) {
                  maxPRCount = Math.max(
                    ...modalData.pr.map(data => data.count)
                  );
                  maxPRRate = parseFloat(
                    modalData.pr.find(pr => pr.count === maxPRCount).max_rate
                  ).toFixed(2);
                }
                // ** modal içerisinde pp var mı  pp'nin en yüksek değerini al
                if (modalData.pp.length > 0) {
                  maxPPCount = Math.max(
                    ...modalData.pp.map(data => data.count)
                  );
                  maxPPRate = parseFloat(
                    modalData.pp.find(pp => pp.count === maxPPCount).max_rate
                  ).toFixed(2);
                }

                // ** PP ve PR ' nin maxRateleri eşit mi kotrol et eğer eşitse oluştur değilse error bas
                if (maxPPRate === maxPRRate) {
                  // ** Eğer PP ve PR 'nin max rateleri eşit ise PPPR'nin Min Ratesini  PP PR 'nin Max Ratesi Yap
                  if (maxPPRate === 0) {
                    rowData.min_rate = 0;
                  } else {
                    rowData.min_rate = parseFloat(maxPPRate) + 0.01;
                  }
                }
                // ** değerler eşit değilse error
                else {
                  if (!isEditing) {
                    sweetAlerts(
                      "error",
                      intl.formatMessage({ id: "pp.pr.not.equal" })
                    );
                    value = [];
                  }
                }
              }
            } else {
              // ** pr seçilirse
              if (value[0].id === 2) {
                let maxPRCount = 0;
                let maxPRRate = 0;

                // ** modalData içerisinde pp,pr var mı
                if (modalData.pp_pr.length > 0) {
                  // ** pp , pr'nin en büyük değerini al
                  const maxPP_PRCount = Math.max(
                    ...modalData.pp_pr.map(data => data.count)
                  );
                  const maxPP_PRRate = parseFloat(
                    modalData.pp_pr.find(pp_pr => pp_pr.count === maxPP_PRCount)
                      .max_rate
                  ).toFixed(2);

                  // ** pr'nin en yüksek değerini al
                  if (modalData.pr.length > 0) {
                    maxPRCount = Math.max(
                      ...modalData.pr.map(data => data.count)
                    );
                    maxPRRate = parseFloat(
                      modalData.pr.find(pr => pr.count === maxPRCount).max_rate
                    ).toFixed(2);
                  }

                  // ** Eğer PPPR var ise ve bu PPPR değeri PR 'nin max_ratesinden büyük ise yeni oluşturulacak PR nin min_ratesine PPPR'nin max_ratesini ata
                  // ** Eğer PPPR yok ise  PR 'nin max_ratesinden büyük  değil ise yeni oluşturulacak PR nin min_ratesine PR'nin max_ratesini ata
                  parseFloat(maxPP_PRRate) > parseFloat(maxPRRate)
                    ? (rowData.min_rate = parseFloat(maxPP_PRRate) + 0.01)
                    : (rowData.min_rate = parseFloat(maxPRRate) + 0.01);
                } else {
                  // ** pr'nin en yüksek değerini al
                  if (modalData.pr.length > 0) {
                    maxPRCount = Math.max(
                      ...modalData.pr.map(data => data.count)
                    );
                    maxPRRate = parseFloat(
                      modalData.pr.find(pr => pr.count === maxPRCount).max_rate
                    ).toFixed(2);

                    // ** Eğer PPPR yoksa PR ' nin min_ratesine PR 'nin max_ratesini ata
                    rowData.min_rate = parseFloat(maxPRRate) + 0.01;
                    // ** Max pri yi RateFrom ' a ata
                  } else {
                    // ** Eğer PR değeri yok ise direkt 0 ata
                    rowData.min_rate = 0;
                  }
                }
              }
              // eğer pp seçildiyse
              else if (value[0].id === 1) {
                let maxPPCount = 0;
                let maxPPRate = 0;

                // ** modalData içerisinde pp,pr var mı
                if (modalData.pp_pr.length > 0) {
                  // ** pp , pr'nin en büyük değerini al
                  const maxPP_PRCount = Math.max(
                    ...modalData.pp_pr.map(data => data.count)
                  );
                  const maxPP_PRRate = parseFloat(
                    modalData.pp_pr.find(pp_pr => pp_pr.count === maxPP_PRCount)
                      .max_rate
                  ).toFixed(2);

                  // ** pp'nin en yüksek değerini al
                  if (modalData.pp.length > 0) {
                    maxPPCount = Math.max(
                      ...modalData.pp.map(data => data.count)
                    );
                    maxPPRate = parseFloat(
                      modalData.pp.find(pp => pp.count === maxPPCount).max_rate
                    ).toFixed(2);
                  }

                  // ** Eğer PPPR var ise ve bu PPPR değeri PP 'nin max_ratesinden büyük ise yeni oluşturulacak PP nin min_ratesine PPPR'nin max_ratesini ata
                  // ** Eğer PPPR yok ise  PP 'nin max_ratesinden büyük  değil ise yeni oluşturulacak PP nin min_ratesine PP'nin max_ratesini ata
                  parseFloat(maxPP_PRRate) > parseFloat(maxPPRate)
                    ? (rowData.min_rate = parseFloat(maxPP_PRRate) + 0.01)
                    : (rowData.min_rate = parseFloat(maxPPRate) + 0.01);
                } else {
                  // ** pp'nin en yüksek değerini al
                  if (modalData.pp.length > 0) {
                    maxPPCount = Math.max(
                      ...modalData.pp.map(data => data.count)
                    );
                    maxPPRate = parseFloat(
                      modalData.pp.find(pp => pp.count === maxPPCount).max_rate
                    ).toFixed(2);

                    // ** Eğer PPPR yok ise ve önceden bi PP var ise bunun son değerini yeni oluşturulacak PP'nin min ratesine ata
                    rowData.min_rate = parseFloat(maxPPRate) + 0.01;
                  } else {
                    // ** Eğer PPPR ve PP yok ise ilk PP oluşturuluyor ise min_rateyi 0 Yap
                    rowData.min_rate = 0;
                  }
                }
              }
            }
          }

          //rateTypeCheck(rowData);
          if (!(value?.length > 0 && value[0] instanceof Object)) {
            value = multiDataSetter(
              "code",
              "id",
              value?.map(v => rateType[v])
            );
          }
          return (
            <MultiSelect
              disabled={
                rowData.tableData?.hasOwnProperty("editing") ? true : false
              }
              options={multiDataSetter("code", "id", Object.values(rateType))}
              value={multiDataSetter("code", "id", value)}
              onChange={e => onChange(e)}
            />
          );
        }
      },
      {
        title: <IntlMessages id="rate.from" />,
        field: "min_rate",
        type: "numeric",
        align: "center",
        cellStyle: {
          textAlign: "center"
        },
        render: rowData => {
          return rowData.min_rate ? rowData.min_rate : "";
        },
        editComponent: p => (
          <TextField
            type="number"
            value={p.rowData.min_rate}
            onChange={e => {
              p.onChange(e.target.value);
            }}
            style={{ width: "50%" }}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              readOnly: true,
              shrink: true,
              step: 0.01,
              min: 0
            }}
          />
        )
      },
      {
        title: <IntlMessages id="rate.to" />,
        field: "max_rate",
        type: "numeric",
        align: "center",
        cellStyle: {
          textAlign: "center"
        },
        validate: rowData =>
          rowData.max_rate && rowData.max_rate !== "0.00" ? true : false,
        editComponent: props => (
          <TextField
            error={
              props.rowData.tableData?.hasOwnProperty("editing")
                ? ""
                : props.rowData.min_rate <= props.rowData.max_rate &&
                  props.rowData.max_rate != ""
                ? false
                : true
            }
            //defaultValue={props.rowData.min_rate ? props.rowData.min_rate : 0.01}
            value={props.value ? props.value : null}
            onChange={e => {
              props.onChange(e.target.value);
            }}
            InputProps={{
              inputComponent: PriceFormatCustom,
              readOnly: props.rowData.tableData?.hasOwnProperty("editing")
            }}
            style={{ width: "50%" }}
            /* inputProps={{
            shrink: true,
            step: 0.01,
            min: props.rowData.min_rate,
            //inputComponent: {NumberFormatCustom},
          }} */
          />
        )
      },

      {
        title: <IntlMessages id="value" />,
        field: "value",
        type: "number",
        align: "center",
        cellStyle: {
          textAlign: "center"
        },
        render: rowData => {
          return rowData.value ? rowData.value : "";
        },
        validate: rowData => (rowData.value || rowData.percent ? true : false),
        editComponent: p => (
          <TextField
            error={p.rowData.value || p.rowData.percent ? false : true}
            //type="number"
            disabled={
              p.rowData.hasOwnProperty("percent")
                ? Number(p.rowData.percent) !== 0 || null
                  ? p.rowData.percent !== ""
                    ? Number(p.rowData.percent) > 0
                      ? true
                      : false
                    : false
                  : false
                : false
            }
            InputProps={{
              inputComponent: PriceFormatCustom
            }}
            /* inputProps={{
              shrink: true,
              // inputComponent: NumberFormatCustom,
              step: 0.01,
              inputProps: {
                min: 0,
              },
            }} */
            value={p.value}
            onChange={e => p.onChange(e.target.value > 0 ? e.target.value : "")}
          />
        )
      },
      {
        title: <IntlMessages id="percent" />,
        field: "percent",
        type: "number",
        align: "center",
        cellStyle: {
          textAlign: "center"
        },
        validate: rowData => (rowData.value || rowData.percent ? true : false),
        editComponent: props => (
          <TextField
            error={props.rowData.value || props.rowData.percent ? false : true}
            //type="number"
            disabled={
              props.rowData.hasOwnProperty("value")
                ? Number(props.rowData.value) !== 0 || null
                  ? props.rowData.value !== ""
                    ? Number(props.rowData.value) > 0
                      ? true
                      : false
                    : false
                  : false
                : false
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
              inputComponent: PriceFormatCustom
              /* inputProps: {
                step: 0.01,
                inputProps: {
                  min: 0,
                },
              }, */
            }}
            value={
              Number(props.rowData.percent) > 0
                ? parseFloat(props.rowData.percent) /* .toFixed(2) */
                : ""
            }
            onChange={e =>
              props.onChange(e.target.value > 0 ? e.target.value : "")
            }
          />
        )
      }
    ]);
  }, [rate_ranges]);
  return (
    <MaterialTable
      title={<IntlMessages id="rates" />}
      options={{
        search: false,
        actionsColumnIndex: -1,
        addRowPosition: "first"
      }}
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
            onEditingCanceled={(mode, rowData) => {
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (mode == "update") {
                    Swal.fire({
                      title: intl.formatMessage({ id: "are.you.sure?" }),
                      text: intl.formatMessage({
                        id: "do.you.want.to.cancel.the.changes"
                      }),
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#41C329",
                      allowOutsideClick: false,
                      cancelButtonColor: "#d33",
                      confirmButtonText: intl.formatMessage({ id: "yes" }),
                      cancelButtonText: intl.formatMessage({ id: "no" })
                    }).then(result => {
                      if (result.isConfirmed) {
                        props.onEditingCanceled(mode);
                        resolve();
                      } else if (result.isDenied) {
                        reject();
                      }
                    });
                  }
                  if (mode == "add") {
                    Swal.fire({
                      title: intl.formatMessage({ id: "are.you.sure?" }),
                      text: intl.formatMessage({
                        id: "do.you.want.to.cancel.the.changes"
                      }),
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#41C329",
                      allowOutsideClick: false,
                      cancelButtonColor: "#d33",
                      confirmButtonText: intl.formatMessage({ id: "yes" }),
                      cancelButtonText: intl.formatMessage({ id: "no" })
                    }).then(result => {
                      if (result.isConfirmed) {
                        props.onEditingCanceled(mode, rowData);
                        resolve();
                      } else if (result.isDenied) {
                        reject();
                      }
                    });
                  }
                  if (mode == "delete") {
                    props.onEditingCanceled(mode, rowData);
                  }
                });
              });
            }}
          />
        ),
        onRowAdd: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        )
      }}
      columns={columnsValues}
      data={Object.values(rate_ranges).filter(
        rate_range => rate_range.markup_id == props.rowData.id
      )}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: "add",
              disabled: true,
              position: "toolbar",
              tooltip: "You are not authorized"
            }
      ]}
      icons={{
        Add: props => (
          <ThemeProvider theme={theme}>
            <ColorButton
              variant="contained"
              color="backgroundColor"
              startIcon={<AddBoxIcon />}
            >
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Clear: () => <ModeCloseIcon sx={{ color: "red" }} />
      }}
      editable={{
        deleteTooltip: row =>
          isAuthDelete ? "Delete" : "You are not authorized",
        editTooltip: row =>
          isAuthUpdate ? "Update" : "You are not authorized",
        isDeletable: row =>
          isAuthDelete ? (row.id == lastRange ? true : false) : false,
        isEditable: row => (isAuthUpdate ? true : false),
        onRowAdd: isAuthAdd
          ? newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  newData["max_rate"] = newData["max_rate"]
                    ? newData["max_rate"]
                    : (parseFloat(newData["min_rate"]) + 0.01).toFixed(2);
                  newData.markup_id = props.rowData.id;

                  if (
                    !newData.max_rate ||
                    !newData.apply_type ||
                    !(newData.percent || newData.value)
                  ) {
                    reject();
                    sweetAlerts(
                      "error",
                      intl.formatMessage({ id: "empty.value" })
                    );
                  } else if (
                    newData.max_rate < 0 ||
                    newData.apply_type < 0 ||
                    newData.percent < 0 ||
                    newData.value < 0
                  ) {
                    reject();
                    sweetAlerts(
                      "error",
                      intl.formatMessage({ id: "negative.value" })
                    );
                  } else if (newData.max_rate <= newData.min_rate) {
                    reject();
                    sweetAlerts(
                      "error",
                      intl.formatMessage({ id: "rate.error" })
                    );
                  } else {
                    newData["apply_type"] = newData.apply_type.map(
                      type => type.id
                    );
                    dispatch(addRateRangesServices(newData));
                    resolve();
                  }
                }, 1000);
              })
          : undefined,
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if (
                !newData.min_rate ||
                !newData.max_rate ||
                !newData.apply_type ||
                !(newData.percent || newData.value)
              ) {
                sweetAlerts("error", intl.formatMessage({ id: "empty.value" }));
              } else if (
                newData.max_rate < 0 ||
                newData.apply_type < 0 ||
                newData.percent < 0 ||
                newData.value < 0
              ) {
                reject();
                sweetAlerts(
                  "error",
                  intl.formatMessage({ id: "negative.value" })
                );
              } else {
                if (newData?.apply_type[0].hasOwnProperty("id")) {
                  newData["apply_type"] = newData?.apply_type.map(
                    type => type.id
                  );
                }

                dispatch(updateRateRangesServices(newData));
              }

              resolve();
            }, 1000);
          }),
        onRowDelete: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              dispatch(deleteRateRangesServices(newData.id));

              resolve();
            }, 1000);
          })
      }}
    />
  );
}
