import React from 'react';
import CmtAvatar from '@coremat/CmtAvatar';
import CmtCard from '@coremat/CmtCard';
import CmtCardExpendableContent from '@coremat/CmtCardExpendableContent';
import { Grid, Typography } from '@material-ui/core';
import CmtCardFooter from '@coremat/CmtCard/CmtCardFooter';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '95%',
    height: '100%',
    marginLeft: theme.spacing(5),
    fontSize: '12px!important',
    color: localStorage.getItem('theme-type') === 'dark' ? 'white' : 'black',
  },

  title: {
    fontSize: '14px',
    color: 'grey',
    margin: 5,
  },
}));

export default function SSS() {
  const classes = useStyles();

  const values = [
    {
      id: 1,
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. ',
      answer: ` Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high heat. Add chicken, shrimp and
                chorizo, and cook, stirring occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a large plate
                and set aside, leaving chicken and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
                pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add saffron broth and remaining 4
                1/2 cups chicken broth; bring to a boil.`,
      color: 'primary',
    },
    {
      id: 2,
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. ',
      answer: ` Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high heat. Add chicken, shrimp and
                  chorizo, and cook, stirring occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a large plate
                  and set aside, leaving chicken and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
                  pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add saffron broth and remaining 4
                  1/2 cups chicken broth; bring to a boil.`,
      color: 'amber',
    },
    {
      id: 3,
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. ',
      answer: ` Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high heat. Add chicken, shrimp and
                  chorizo, and cook, stirring occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a large plate
                  and set aside, leaving chicken and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
                  pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add saffron broth and remaining 4
                  1/2 cups chicken broth; bring to a boil.`,
      color: 'orange',
    },
    {
      id: 4,
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. ',
      answer: ` Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high heat. Add chicken, shrimp and
                    chorizo, and cook, stirring occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a large plate
                    and set aside, leaving chicken and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
                    pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add saffron broth and remaining 4
                    1/2 cups chicken broth; bring to a boil.`,
      color: 'blue',
    },
    {
      id: 5,
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. ',
      answer: ` Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high heat. Add chicken, shrimp and
                    chorizo, and cook, stirring occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a large plate
                    and set aside, leaving chicken and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
                    pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add saffron broth and remaining 4
                    1/2 cups chicken broth; bring to a boil.`,
      color: 'grey',
    },
    {
      id: 6,
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dignissim metus sapien, at auctor massa tincidunt a. ',
      answer: ` Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high heat. Add chicken, shrimp and
                    chorizo, and cook, stirring occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a large plate
                    and set aside, leaving chicken and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
                    pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add saffron broth and remaining 4
                    1/2 cups chicken broth; bring to a boil.`,
      color: 'secondary',
    },
  ];

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Frequently Asked Questions</Typography>
      {values.map(elem => {
        return (
          <CmtCard style={{ marginTop: '3%', paddingLeft: '2%', paddingTop: '2% ' }}>
            <Grid container spacing={5} wrap="nowrap">
              <Grid item>
                <CmtAvatar color={elem.color} size="small">
                  {elem.id}
                </CmtAvatar>
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: '15px' }}>{elem.question}</Typography>
              </Grid>
            </Grid>
            <CmtCardExpendableContent>
              <CmtCardFooter separator={{ color: '#bdbdbd', borderWidth: 3, borderStyle: 'solid' }} />
              <Typography style={{ fontSize: '13px' }}>{elem.answer}</Typography>
            </CmtCardExpendableContent>
          </CmtCard>
        );
      })}
    </div>
  );
}
